import React, { useEffect, useRef, useState } from 'react'
import './manage-store-landing.styles.scss';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBasket, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import StorePurchases from './store/store-purchases.component';
import PurchaseView from '../../SHARED/tranaction-view/transaction-view.component';
import SaleContainer from './sale-manager/sale-container.component';
import { ModalConfirm } from '@/components/modal/modal.component';
import AddItemsToStore from './store/add-items-to-store.component';
import ManageStorePage from './store/manage-store-page.component';

const ManageStoreLanding = ({ 
    gameData, 
    playerList, 
    gameElements, 
    gameActivity, 
    purchases,
    gameStats
}) => {
    
 
    const [ saleList, setSaleList] = useState([]);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const approveRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        if (!gameElements) return;
        getSales();
    }, [gameElements])

    function getSales() {
        const now = new Date().getTime();
        setSaleList(
            Object.values(gameElements)
            .filter(e => 
                e.type === 'sale' &&
                e.status === 'active' &&
                (!e.opt_dateOpen || e.opt_dateOpen < now) &&
                (!e.opt_dateClose || e.opt_dateClose > now)
            )
            .sort((a,b) => a.ts > b.ts ? 1 : -1)
        )
    }    
    
    return (
        <div className='manage-store-landing'>
            <Routes>
                <Route path='' element={
                    <div className='g-card store-card'>
                        <BackButton cancel={() => navigate(`/manage/${gameData.path}`)} />
                        <div className="g-space-1"></div>
                        <div className='card-title'>
                            <div className='title-with-icon'>
                                <FontAwesomeIcon icon={faShoppingCart} />Manage Game Store
                            </div>
                        </div>
                        <hr />
                        
                        <div className="g-space-1"></div>
                        <ManageStorePage
                            gameData={gameData}
                            gameElements={gameElements}
                            gameStats={gameStats}
                            saleList={saleList}
                        />
                        
                    </div>
                }></Route>
                <Route
                    path='sales/*'
                    element={
                        <div className='g-card sale-card'>
                            <BackButton cancel={() => navigate(-1)} />
                            <div className="g-space-1"></div>
                            <SaleContainer 
                                gameData={gameData} 
                                gameElements={gameElements} 
                            />
                        </div>
                    }
                ></Route>
                <Route 
                    path='purchases'
                    element={
                        <div className='g-card store-card'>
                            <BackButton cancel={() => navigate(`/manage/${gameData.path}`)} />
                            <div className="g-space-1"></div>
                            <div className='card-title'>
                                <div className='title-with-icon'>
                                    <FontAwesomeIcon icon={faShoppingBasket} />Purchases
                                </div>
                            </div>
                            <hr />
                            <StorePurchases
                                gameData={gameData}
                                gameElements={gameElements}
                                playerList={playerList}
                                purchases={purchases}
                                gameActivity={gameActivity}
                            />
                        </div>
                    }
                ></Route>
                
                {/* <Route 
                    path='/:itemPath' 
                    element={
                        <StoreItemSettings 
                            gameData={gameData} 
                            gameElements={gameElements} 
                        />
                    }
                ></Route> */}
                <Route 
                    path='/additems' 
                    element={
                        <div className='g-card store-card'>
                            <BackButton cancel={() => navigate(-1)} />
                            <div className="g-space-1"></div>
                            <div className='card-title'>
                                <div className='title-with-icon'>
                                    <FontAwesomeIcon icon={faShoppingBasket} />Add Items to the Store
                                </div>
                            </div>
                            <hr />
                            <AddItemsToStore 
                                gameData={gameData} 
                                gameElements={gameElements} 
                            />
                        </div>
                    }
                ></Route>
            </Routes>
            <ModalConfirm
                show={confirmMessage}
                cancel={() => setConfirmMessage(null)}
                message={confirmMessage}
                onApprove={approveRef.current}
            />
        </div>
    )
}

export default ManageStoreLanding
/**
 * 
 * <div className='store-item-list'>
                        {
                            (itemsToDisplay) 
                            ?
                            itemsToDisplay
                            .sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                            .map(i => {
                                const now = new Date().getTime();
                                let sale = saleList.filter(s => 
                                    s.opt_itemList.includes(i.id) &&
                                    (
                                        (s.startDate ? s.startDate < now : true) &&
                                        (s.endDate ? s.endDate > now : true)
                                    )
                                );
                                return (
                                <div key={i.id} className='item-card g-list-item'>
                                    <div className='item-card-icon'>
                                        <ImageContainer src={i.icon} className='item-icon' />
                                    </div>
                                    <b>{i.name}</b>
                                    <span className='meta type'>{properCase(i.opt_itemType)}</span>
                                    <span className='meta cat'>{i.opt_cat}</span>
                                    <div className='price'>
                                        <ImageContainer src='/images/icons/coin.png' className='coin-icon' />
                                        <b className={sale.length > 0 ? 'sale' : ''}>
                                            {
                                                i.opt_itemType === 'contribution' ?
                                                'varies'
                                                :
                                                i.opt_marketPrice
                                            }
                                        </b>
                                        {
                                            (sale.length > 0) &&
                                            <b>{i.opt_marketPrice - (i.opt_marketPrice * (sale[0].opt_salePercentage * .01)).toFixed(0).toLocaleString()}</b>
                                        }
                                    </div>
                                    <div className='buttons'>
                                        <div className='buttons'>
                                            <button 
                                                className='g-button med-btn'
                                                title='Edit Item'
                                                onClick={() => navigate(`/manage/${gameData.path}/elements/items/edit/${i.path}`)}
                                            >
                                                <FontAwesomeIcon icon={faPen} />
                                            </button>
                                            <button 
                                                className='g-button med-btn'
                                                title='Remove from Store'
                                                onClick={() => removeFromStore(i)}
                                            >
                                                {
                                                    (loading && loading === i.id)
                                                    ?
                                                    <FontAwesomeIcon icon={faRefresh} className='spinning-icon' />
                                                    :
                                                    <FontAwesomeIcon icon={faBan} />
                                                }
                                            </button>
                                        </div>
                                        {/* <button 
                                            className='g-button med-btn'
                                            onClick={() => navigate(i.path)}
                                        >
                                            <FontAwesomeIcon icon={faGear} />
                                        </button> 
                                    </div>
                                </div>
                                )
                            })
                            :
                            <div className='center-col'>
                                No Items in the Store...
                            </div>
                        }
                        </div>
 */