import React from 'react'
import './player-name-icon.styles.scss';
import UserIcon from '@/components/layout-auth/user-icon/user-icon.component';

const PlayerNameIcon = ({ player }) => {
    return (
        <div className='player-name-icon'>
            {
                (player) &&
                <>
                    <div>{player.fName}{player.lName ? ` ${player.lName}` : ''}</div>
                    <div className='user-icon' >
                        <UserIcon profile={player} reverse={true} small={true} />
                    </div>
                </>
            }
        </div>
    )
}

export default PlayerNameIcon