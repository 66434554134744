import React, { useEffect, useLayoutEffect, useState } from 'react'
import './head-bar.styles.scss'

import { useSelector } from 'react-redux';
import UserDropdown from './user-dropdown.component';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import UserIcon from '../user-icon/user-icon.component';
import long_logo from '@/assets/logo_long.png'

const HeadBar = () => {

    const currentUser = useSelector(state => state.userData.currentUser);
    const userProfile = useSelector(state => state.userData.userProfile);
    const [ showDropdown, setShowDropdown ] = useState(false);
    const [ pageTitle, setPageTitle ] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!location) {return;}
        switch (location.pathname) {
            case '/home':
                setPageTitle('Games I Manage');
                break;
            default:
                setPageTitle('');
                break;
        }
    }, [location])

    return (
        <div className="head-bar-container">
            <div className="logo-container">
                <Link to={'/loading'}>
                    <img src={long_logo} alt="Logo" className="logo" />
                </Link>
                {
                    (pageTitle) &&
                    <>
                    <div className='menu-spacer'></div>
                    <h3>{pageTitle}</h3>
                    </>
                }
            </div>
            {/* <SearchContainer setShowSearchModal={setShowSearchModal} location='head-bar' /> */}
            <div className='head-menu-box'>
                <div className='head-menu-flex'>
                    <div className='head-menu'>
                        {
                            (userProfile && userProfile.admin) &&
                                <>
                                    
                                    <div className='head-menu-item' onClick={() => navigate('/admin')}>
                                        Admin
                                    </div>
                                    <div className='menu-spacer'></div>
                                </>
                        }
                    </div>
                    <button className='head-user-info g-button text-only'  onClick={() => setShowDropdown(!showDropdown)}>
                        {
                            (currentUser && userProfile) &&
                            <>
                                <div >{userProfile.fName} {userProfile.lName}</div>
                                <div className='user-icon'>
                                    <UserIcon profile={userProfile} reverse={true} planIcon={true} />
                                </div>

                            </>
                            
                        }
                    </button>
                </div>
                <UserDropdown show={showDropdown} />
            </div>
        </div>

    )
}

export default HeadBar