export function getCurrentSale(item, saleList) {
    let newPrice = 0;
    let sale;

    const applicableSales = saleList.filter(s => 
        s.opt_itemList.includes(item.id)
    )

    if (applicableSales.length > 0) {
        let sortedList = applicableSales.sort((a,b) => a.opt_salePercentage < b.opt_salePercentage ? 1 : -1)
        sale = sortedList[0];
        newPrice = item.opt_marketPrice - (item.opt_marketPrice * (sale.opt_salePercentage * .01))

        console.log(newPrice);

        return {
            'newPrice': newPrice,
            'sale': sale
        }
    }

    console.log(newPrice);

    return false;
}