import React, {useEffect, useRef, useState } from 'react'
import './manage-game.styles.scss';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ManageHeadBar from '@/components/MANAGE-GAME/manage-layout/manage-game-head-bar/manage-head-bar.component';
import ManageLanding from '@/components/MANAGE-GAME/manage-landing.component';
import ManageGameSidebar from '@/components/MANAGE-GAME/manage-layout/manage-game-sidebar/manage-game-sidebar.component';
import { getGameActivityFromDb, getGameDataFromDb, getPlayerDataFromDb, getGameElementsFromDb, getActionItemsFromDb, getUniqueDocumentFromDb, getLatestGameMessageFromDb } from '@/utils/getDataFromDb';
import { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import { off } from 'firebase/database';
import { Desktop, LargeTablet, Mobile, Tablet } from '@/utils/responsiveQueries';
import ManageMobileHeader from '@/components/MANAGE-GAME/manage-layout/manage-game-head-bar/manage-mobile-head.component';
import { setActionItems, setPendingActionItems } from '@/state/slices/contentSlice';
import { auth, UseCloudFunction } from '@/utils/firebase.utils';
import Loader from '@/components/loader/loader.comp';
import { testMemberRestrictions } from '@/utils/membershipRestrictions';
import UpgradeRequiredModal from '@/components/SHARED/upgrade-required-modal/upgrade-required-modal.component';

const ManageGamePage = () => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const managingList = useSelector(state => state.contentData.managingList);
    const membership = useSelector(state => state.userData.membership);
    const newPasscode = useSelector(state => state.contentData.newPasscode);
    const { gamePath } = useParams();
    const [ currentGame, setCurrentGame ] = useState(null);
    const [ gameElements, setGameElements ] = useState(null);
    const [ playerList, setPlayerList ] = useState({});
    const [ gameStats, setGameStats ] = useState(null);
    const [ gameActivity, setGameActivity ] = useState({});
    const [ teamMembers, setTeamMembers ] = useState(null);
    const [ passcodes, setPasscodes ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ sevenDaysNew, setSevenDaysNew ] = useState(null);
    const [ actionItems, setActionItems ] = useState(null);
    const [ latestMessage, setLatestMessage ] = useState(null);
    const [ showUpgradeModal, setShowUpgradeModal ]  = useState();
    const onApprove = useRef();
    const elementsRef = useRef();
    const playersRef = useRef();
    const activityRef = useRef();
    const actionItemsRef = useRef();
    const pendingCountRef = useRef();
    const gettingDataRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    let gameListener;
    let elementsListener;
    let playersListener;
    let activityListener;
    let actionItemsListener;
    let moreActivityListener;
    let statsListener;
    let teamListener;
    let messageListener;

    useEffect(() => {
        setTimeout(() => {
            if (!managingList || Object.values(managingList).filter(m => m.path === gamePath).length === 0) {
                setConfirmMessage("Sorry. You do not have access to manage this game.");
                onApprove.current = () => {
                    navigate('/home');
                }
            }
        }, 2000)
        
        setTimeout(() => {
            const currentlySignedInUser = auth.currentUser;
            // console.log(currentlySignedInUser);
            if (!currentlySignedInUser) {
                navigate('/logout');
            }
        }, 1000);

        let localStorageSize = function () {
            let _lsTotal = 0,_xLen, _x;
            for (_x in localStorage) {
            if (!localStorage.hasOwnProperty(_x)) continue;
                _xLen = (localStorage[_x].length + _x.length) * 2;
                _lsTotal += _xLen;
            }
            return  (_lsTotal / 1024).toFixed(2);
        }
        console.log(localStorageSize() + 'kb');
        

        return () => {
            if (gameListener) gameListener();
            if (elementsListener) elementsListener();
            if (playersListener) playersListener();
            if (activityListener) activityListener();
            if (actionItemsListener) actionItemsListener();
            if (moreActivityListener) moreActivityListener();
            if (statsListener) statsListener();
            if (teamListener) teamListener();
            if (messageListener) messageListener();
        }
    }, [])

    useEffect(() => {
        if (
            !location.pathname.includes('/activity') &&
            !location.pathname.includes('/transaction/')
        ) {
            window.localStorage.removeItem('activity-list-filter');
            localStorage.removeItem('scrollpoint');
            localStorage.removeItem('totalCount');
        }
    }, [location])

    useEffect(() => {
        if (!managingList || !gamePath || !membership) {return;}
        if (Object.values(managingList).filter(m => m.path === gamePath).length > 0 && !gettingDataRef.current) {
            getGameData();
            gettingDataRef.current = true;
        }
    }, [managingList, gamePath, membership])

    useEffect(() => {
        if (!newPasscode) return;
        const codes = {...passcodes}
        codes[newPasscode.playerId] = newPasscode.code;
        setPasscodes({...codes});
    }, [newPasscode])

    function getGameData() {
        getGameDataFromDb({
            'gamePath': gamePath, 
            'status': 'active', 
            'callback': callback, 
            'handleListener': handleListener
        })
        function callback(data) {
            setCurrentGame(data);
            // console.log(data)
            const sortedGames = Object.values(managingList).sort((a,b) => a.lastActivity < b.lastActivity ? 1 : -1);
            let index = 0;
            for (let i=0; i < sortedGames.length; i++) {
                const game = sortedGames[i];
                if (game.path === data.path) {
                    index = i;
                }
            }
            const testedGame = testMemberRestrictions({'gameData': data, 'membership': membership, 'index': index})
            if (testedGame.disabled) {
                setShowUpgradeModal(true);
                return;
            }
            // change var(--primary) to game color
            var r = document.querySelector(':root');
            r.style.setProperty('--primary', data.color);
            r.style.setProperty('--primary-trans', data.color+'a6');
            getGameElements(data.gameId);
            getPlayerList(data.gameId);
            getGameActivity(data.gameId);
            getGameStats(data.gameId);
            pendingCountRef.current = 0;
            getActionItems(data.gameId);
            getTeamMembers(data.gameId);
            getPasscodes(data.path);
            getLatestMessage(data.gameId);
        }
        function handleListener(unsub) {
            gameListener = unsub;
        }
    }

    function getGameElements(gameId) {
        getGameElementsFromDb({
            'gameId': gameId, 
            'callback': callback, 
            'handleListener': handleListener
        })
        function callback(data) {
            elementsRef.current = {...elementsRef.current, ...data.elements}
            setGameElements(elementsRef.current);
            // console.log(elementsRef.current);
        }
        function handleListener(unsub) {
            elementsListener = unsub;
        }
    }

    function getPlayerList(gameId) {
        getPlayerDataFromDb({
            'gameId': gameId, 
            'callback': callback, 
            'handleListener': handleListener
        })
        function callback(data) {
            // console.log(data);
            if (data.status !== 'active') {
                let tempList = {...playersRef.current};
                if (tempList[data.playerId]) {
                    delete tempList[data.playerId];
                }
                playersRef.current = {...tempList};
                setPlayerList(playersRef.current);    
            } else {
                playersRef.current = {...playersRef.current, ...{[data.playerId]: data}}
                setPlayerList(playersRef.current);
            }
        }
        function handleListener(unsub) {
            playersListener = unsub;
        }
    }

    function getGameActivity(gameId) {
        getGameActivityFromDb({
            'gameId': gameId, 
            'callback': callback, 
            'handleListener': handleListener
        })
        function callback(data) {
            activityRef.current = {...activityRef.current, ...data.activity}
            setGameActivity(activityRef.current);
        }
        function handleListener(unsub) {
            activityListener = unsub;
        }
    }

    function loadMoreActivity() {
        let startDate;
        if (!activityRef.current) {
            startDate = new Date().getTime();
        } else {
            let dates = Object.values(activityRef.current).map(a => a.ts).sort();
            console.log(dates);
            startDate = dates[0];
        }
        getGameActivityFromDb({
            'gameId': currentGame.gameId, 
            'startDate': startDate, 
            'more': true, 
            'callback': callback, 
            'handleListener': handleListener
        })
        function callback(data) {
            activityRef.current = {...activityRef.current, ...{[data.id]: data}}
            setGameActivity(activityRef.current);
        }
        function handleListener(unsub) {
            moreActivityListener = unsub;
        }
    }

    function getActionItems(gameId) {
        console.log('getting actionitems')
        getActionItemsFromDb({
            'gameId': gameId, 
            'callback': callback, 
            'handleListener': handleListener
        })
        function callback(data) {
            actionItemsRef.current = {...actionItemsRef.current, ...data.actionItems}
            setActionItems(actionItemsRef.current);
        }
        function handleListener(unsub) {
            actionItemsListener = unsub
        }
    }

    function getGameStats(gameId) {
        getUniqueDocumentFromDb({
            "collection": "gameStats", 
            "docId": gameId,
            "callback": callback,
            "handleListener": handleListener
        })
        function callback(data) {
            setGameStats(data)
        }
        function handleListener(unsub) {
            statsListener = unsub;
        }
    }

    function getTeamMembers(gameId) {
        getUniqueDocumentFromDb({
            "collection": "teamMembers",
            "docId": gameId,
            "callback": callback,
            "handleListener": handleListener
        })
        function callback(data) {
            setTeamMembers(data);
        }
        function handleListener(unsub) {
            teamListener = unsub;
        }
    }

    async function getPasscodes(gamePath) {
        const res = await UseCloudFunction(
            'getPrintableData',
            {
                'gamePath': gamePath,
                'userProfile': userProfile,
                'codesOnly': true
            }
        )
        console.log(res);
        if (res.error) {
            setAlertMessage('There was an error fetching passcodes.')
            return;
        }
        setPasscodes(res.passcodes);
    }

    async function getLatestMessage(gameId) {
        getLatestGameMessageFromDb({
            'gameId': gameId,
            'callback': callback,
            'handleListener': handleListener
        })
        function callback(data) {
            console.log(data);
            setLatestMessage(data)
        }
        function handleListener(unsub) {
            messageListener = unsub;
        }
    }

    return (
        <>
        {
            (currentGame) 
            ?
            <div className='manage-game-page' style={
                (currentGame.bg.type === 'gradient')
                ?
                {'background': `linear-gradient(to bottom, ${currentGame.bg.hexTop}, ${currentGame.bg.hexBottom})`}
                :
                {'background': `url("${currentGame.bg.url}") no-repeat center center fixed`}
            }>
                <Desktop>
                    <ManageHeadBar gameData={currentGame} />
                    <div className='manage-content'>
                        <ManageGameSidebar 
                            gameData={currentGame} 
                            actionItems={actionItems} 
                            gameElements={gameElements}
                        />
                        <ManageLanding 
                            gameData={currentGame} 
                            gameElements={gameElements} 
                            playerList={playerList} 
                            gameActivity={gameActivity} 
                            loadMoreActivity={loadMoreActivity} 
                            gameStats={gameStats}
                            actionItems={actionItems}
                            teamMembers={teamMembers}
                            passcodes={passcodes}
                            latestMessage={latestMessage}
                        />
                    </div>
                </Desktop>
                
                <LargeTablet>

                </LargeTablet>

                <Tablet>

                </Tablet>

                <Mobile>
                    <ManageMobileHeader gameData={currentGame} />
                </Mobile>
            </div>
            :
            <div className='loading-page'>
                <Loader />
            </div>
        }
        <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
        <ModalConfirm show={confirmMessage} cancel={() => setConfirmMessage(null)} message={confirmMessage} onApprove={onApprove.current} noCancel={true} />
        <UpgradeRequiredModal 
            show={showUpgradeModal} 
            cancel={() => setShowUpgradeModal(false)} 
            closeButton={false}
            message='Sorry! Because you are using the Free Plan, you are unable to access this game and its content because it exceeds what the plan allows.' 
        />
        </>
    )
}

export default ManageGamePage