import React from 'react'
import './user-dropdown.styles.scss'

import { Link } from 'react-router-dom'
import { version } from '@/changelog-version'
import { useSelector } from 'react-redux'

const UserDropdown = ({ show }) => {
    
    const root = useSelector(state => state.userData.root);
    const membership = useSelector(state => state.userData.membership);

    return (
        <div className={show ? 'active dropdown-container' : 'dropdown-container'}>
            <div className="dropdown-menu">
                <Link to='/loading'>My Games</Link>
                <Link to='/profile'>My Profile</Link>
                <Link to='/myplan'>My Plan</Link>
                {/* {
                    (membership && membership.membership > 0) &&
                    <a href='https://billing.stripe.com/p/login/test_9AQ2aK2Nj3jaaYM9AA' target="_blank">Manage My Plan</a>
                } */}
                <Link to='/changelog' target='_blank'>Change Log</Link>
                <Link to='/logout'>Sign Out</Link>
                <p className='meta'>{version}</p>
            </div>
        </div>
    )
}

export default UserDropdown