import React, { useEffect, useState } from 'react'
import './secret-codes-list.styles.scss';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { format } from 'date-fns';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { useNavigate } from 'react-router-dom';
import FoundCodeItem from '@/components/PLAYER/submit-code/found-code-item.component';
import PlayerNameIcon from '../player-name-icon.component';

const MySecretCodesList = ({ playerData, gameElements, playerActivity }) => {
    
    const [ loading, setLoading ] = useState(true);
    const [ sortedList, setSortedList ] = useState([]);
    const navigate = useNavigate();
    
    useEffect(() => {
        const timer = setTimeout(() => {
            if (!playerData || !playerActivity) {
                setLoading(false);
            }
        }, 3000)

        return () => {
            if (timer) clearTimeout(timer);
        }
    }, [])

    useEffect(() => {
        console.log(playerActivity);
        // console.log(playerData);
        // console.log(gameElements);
        if (!playerData || !gameElements || !playerActivity) return;
        const codeActivity = Object.values(playerActivity)
            .sort((a,b) => a.ts < b.ts ? 1 : -1)
            .filter(a => a.type === 'secretCode')
        setSortedList(codeActivity);
        setLoading(false);
    }, [playerData, gameElements, playerActivity])

    return (
        <div className='found-codes-list'>
            <div className='g-card list-card'>
                <BackButton cancel={() => navigate(-1)} />
                <div>
                    <div className='card-title split'>
                        My Found Secret Codes
                        {
                            (location.pathname.includes('/manage/')) &&
                            <PlayerNameIcon player={playerData} />
                        }
                    </div>
                    <div className="g-space-1"></div>
                    <hr className='no-margin'/>
                    <div className="g-space-0-5"></div>
                    {
                        (sortedList) &&
                        <div className='summary meta'>
                            <div className='info'>
                                Found Secret Codes: 
                                <ImageContainer src='/images/icons/secret.png' className='summary-icon' />
                                <b>{sortedList.length.toLocaleString()}</b>
                            </div>
                        </div>
                    }
                </div>
                {
                    (loading)
                    ?
                    <div className='center-col'><ThreeDotsLoader /></div>
                    :
                    (sortedList)
                    ?
                    sortedList.map(a => {
                        
                        return (
                            <div key={a.ts} >
                                <FoundCodeItem codeInfo={a} />
                            </div>
                        )
                    })
                    : 
                    <h4 className='center-col'>
                        No earnings yet...
                    </h4>
                }
            </div>
        </div>
    )
}

export default MySecretCodesList