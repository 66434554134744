import React, { useEffect, useState } from 'react'
import './create-edit-form-2v2.styles.scss';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIcons, faImage, faPalette, faPanorama } from '@fortawesome/free-solid-svg-icons';
import Modal from '@/components/modal/modal.component';
import GameIconUpload from './game-images/game-icon.component';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import GameHeaderUpload from './game-images/game-header.component';
import GameBgChoice from './game-images/game-bg.component';
import { useNavigate } from 'react-router-dom';
import SampleLayout from './game-images/sample-layout.component';
import GameColorChoice from './game-images/game-colors.component';

const CreateEditForm2v2 = ({ edit, formData, saveData, verifyAndMoveOn, cancel, template }) => {

    const root = useSelector(state => state.userData.root);
    const [ showIconModal, setShowIconModal ] = useState(false);
    const [ approveIcon, setApproveIcon ] = useState(false);
    const [ showHeaderModal, setShowHeaderModal ] = useState(false);
    const [ approveHeader, setApproveHeader ] = useState(false);
    const [ showBackgroundModal, setShowBackgroundModal ] = useState(false);
    const [ approveBackground, setApproveBackground ] = useState(false);
    const [ showColorModal, setShowColorModal ] = useState(false);
    const [ approveColor, setApproveColor ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ req ] = useState(['bg', 'header', 'icon', 'color']);
    const navigate = useNavigate();

    useEffect(() => {
        if (formData.icon) {return;}
        saveData('icon', {'url': `${import.meta.env.VITE_ROOT}/images/themes/icons/gamably.png`, 'upload': false});
        saveData('header', {'url': `${import.meta.env.VITE_ROOT}/images/themes/logos/gamably.png`, 'upload': false})
        saveData('bg', {'type': 'gradient', 'hexBottom': '#d1cede', 'hexTop': '#e9e7f4'});
        saveData('color', '#674ea7')
    }, [formData])
    
    useEffect(() => {
        if (!formData.gameName && !edit && !template) {
            navigate('/home/creategame');
        }
    }, [formData, edit, template])

    function saveChosenIcon() {
        setLoading(true);
        setApproveIcon(true);
        setTimeout(() => {
            setApproveIcon(false);
            setShowIconModal(false);
            setLoading(false);
        }, 500)
    }

    function saveChosenHeader() {
        setLoading(true);
        setApproveHeader(true);
        setTimeout(() => {
            setApproveHeader(false);
            setShowHeaderModal(false);
            setLoading(false);
        }, 500)
    }

    function saveChosenBackground() {
        setLoading(true);
        setApproveBackground(true);
        setTimeout(() => {
            setApproveBackground(false);
            setShowBackgroundModal(false);
            setLoading(false);
        }, 500)
    }

    function saveChosenColor() {
        setLoading(true);
        setApproveColor(true);
        setTimeout(() => {
            setApproveColor(false);
            setShowColorModal(false);
            setLoading(false);
        }, 500)
    }

    return (
        <div className='new-game-form' id='new-game-form'>
            {
                (!template && location.pathname.includes('/creategame')) &&
                <h3>Set Your Game Design</h3>
            }
            {/* <div className="g-space-1"></div> */}
            <div className='game-designer'>  
                <div className='top-button-row'>
                    <button className='g-button' onClick={() => setShowIconModal(true)}><FontAwesomeIcon icon={faIcons} />Icon</button>
                    <button className='g-button' onClick={() => setShowHeaderModal(true)}><FontAwesomeIcon icon={faPanorama} />Header</button>
                    <button className='g-button' onClick={() => setShowBackgroundModal(true)}><FontAwesomeIcon icon={faImage} />Background</button>
                    <button className='g-button' onClick={() => setShowColorModal(true)}><FontAwesomeIcon icon={faPalette} />Color</button>
                </div>
                <div className='g-space-20'></div>
                
                <div className='field'>
                    <label>Design Preview</label>    
                </div>
            
                {
                    (formData.icon && formData.bg && formData.header) &&
                    <SampleLayout formData={formData} />
                }
            </div>
            <div className="g-space-20"></div>
            {   
                (location.pathname.includes('/creategame')) &&
                <>
                
                <hr />
                <div className='buttons'>
                    <button className='g-button' onClick={() => navigate(-1)}>Back</button>
                    <button className='g-button' onClick={() => cancel()}>Cancel</button>
                    <button className='g-button primary' onClick={() => verifyAndMoveOn(req, 'part3')}>Next</button>
                </div>
                </>
            }
            <Modal show={showIconModal} cancel={() => setShowIconModal(false)} text='Choose a New Icon'>
                <div className='modal-children'>
                    <div className='g-space-20'></div>
                    <GameIconUpload saveData={saveData} formData={formData} onApprove={approveIcon} />
                    <div className='g-space-20'></div>
                    <hr />
                    <div className='buttons'>
                        <button className='g-button' onClick={() => setShowIconModal(false)}>Cancel</button>
                        <button className='g-button primary' onClick={() => saveChosenIcon(true)}>
                        {
                            (loading)
                            ?
                            <ThreeDotsLoader />
                            :
                            'Done'
                        }</button>
                    </div>
                </div>
            </Modal>
            <Modal show={showHeaderModal} cancel={() => setShowHeaderModal(false)} text='Choose a New Game Header'>
                <div className='modal-children'> 
                    <div className='g-space-20'></div>
                    <GameHeaderUpload saveData={saveData} formData={formData} onApprove={approveHeader} />
                    <div className='g-space-20'></div>
                    <hr />
                    <div className='buttons'>
                        <button className='g-button' onClick={() => setShowHeaderModal(false)}>Cancel</button>
                        <button className='g-button primary' onClick={() => saveChosenHeader(true)}>
                        {
                            (loading)
                            ?
                            <ThreeDotsLoader />
                            :
                            'Done'
                        }</button>
                    </div>
                </div>
            </Modal>
            <Modal show={showBackgroundModal} cancel={() => setShowBackgroundModal(false)} text='Choose a New Background'>
                <div className='modal-children'> 
                    <div className='g-space-20'></div>
                    <GameBgChoice saveData={saveData} formData={formData} onApprove={approveBackground} />
                    <div className='g-space-20'></div>
                    <hr />
                    <div className='buttons'>
                    <button className='g-button' onClick={() => setShowBackgroundModal(false)}>Cancel</button>
                        <button className='g-button primary' onClick={() => saveChosenBackground(true)}>
                        {
                            (loading)
                            ?
                            <ThreeDotsLoader />
                            :
                            'Done'
                        }</button>
                    </div>
                </div>
            </Modal>
            <Modal show={showColorModal} cancel={() => setShowColorModal(false)} text='Choose a Button Color'>
                <div className='modal-children'> 
                    <div className='g-space-20'></div>
                    <GameColorChoice saveData={saveData} formData={formData} onApprove={approveColor} />
                    <div className='g-space-20'></div>
                    <hr />
                    <div className='buttons'>
                    <button className='g-button' onClick={() => setShowColorModal(false)}>Cancel</button>
                        <button className='g-button primary' onClick={() => saveChosenColor(true)}>
                        {
                            (loading)
                            ?
                            <ThreeDotsLoader />
                            :
                            'Done'
                        }</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default CreateEditForm2v2