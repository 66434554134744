import React from 'react';
import './sidebar-container.styles.scss';
import { useSelector } from 'react-redux';
import SidebarLeft from './sidebar-left.component';

const SidebarLeftContainer = ({ orgId, setShowBugModal }) => {

    const myOrgs = useSelector(state => state.contentData.myOrgs);
    
    return (
        <div className='sidebar-left-container'>
            {
                (orgId && myOrgs) &&
                <div className='sidebar-header'>
                    <span>{myOrgs[orgId].name}</span>
                </div>
            }
            <div className='g-card'>
                <SidebarLeft orgId={orgId} setShowBugModal={setShowBugModal} />
            </div>
        </div>
    )
}

export default SidebarLeftContainer