import React, { useEffect, useState } from 'react'
import './levels-landing.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCubes } from '@fortawesome/free-solid-svg-icons';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import LevelsList from './levels-list.component';
import LevelsForm from './levels-form.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import UpgradeRequiredModal from '@/components/SHARED/upgrade-required-modal/upgrade-required-modal.component';
import { useSelector } from 'react-redux';
import { membershipRestrictions, testMemberRestrictions } from '@/utils/membershipRestrictions';
import { properCase } from '@/utils/properCase';

const LevelsLandingPage = ({ gameElements, gameData }) => {
    
    const membership = useSelector(state => state.userData.membership);
    const [ showUpgradeModal, setShowUpgradeModal ] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        checkMembership();
    }, [])

    function checkMembership() {
        const element = 'levels'
        const res = testMemberRestrictions({'gameData': gameData, 'membership': membership, 'element': element})
        // console.log(res);

        if (res.disabled) {
            let message = ''
            const planRest = membershipRestrictions[membership.membership];
            if (res.disabledReason.includes(element)) {
                message = `While using the ${planRest.title}, you are not able to access ${properCase(element)}.`;
            }
            setShowUpgradeModal(message)
        }
    }
    
    return (
        <div className='levels-landing'>
            <div className='g-card levels-card'>
                <BackButton cancel={() => navigate(-1)} />
                <div className="g-space-1"></div>
                <div className='card-title'>
                    <ImageContainer src='/images/icons/rankings.png' alt='levels icon' className='head-icon' />
                    Create and Edit Levels
                </div>
                <div className="g-space-1"></div>
                <div className='levels-landing-content'>
                    <Routes>
                        <Route path='' element={<LevelsList gameElements={gameElements} gameData={gameData} />}></Route>
                        <Route path='create' element={<LevelsForm gameElements={gameElements} gameData={gameData} />}></Route>
                        <Route path='edit/:levelPath' element={<LevelsForm gameElements={gameElements} gameData={gameData} />}></Route>
                        <Route path="*" element={<Navigate replace to="/404" />} />
                    </Routes>
                </div>
            </div>
            <UpgradeRequiredModal
                show={showUpgradeModal}
                cancel={() => setShowUpgradeModal(null)}
                message={showUpgradeModal}
            />
        </div>
    )
}

export default LevelsLandingPage