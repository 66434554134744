import React, { useEffect, useState } from 'react';
import './player-collectibles.styles.scss';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import StoreItemCard from './store-components/store-item-card.component';

const PlayerCollectibles = ({ 
    playerData,
    gameStats,
    gameElements,
    teamMembers,
    collections,
    itemsToDisplay,
    saleList
 }) => {
    
    const [ chosenCollection, setChosenCollection ] = useState(null);
    const [ collectionItems, setCollectionItems ] = useState(null);

    useEffect(() => {
        if (!chosenCollection) {
            setCollectionItems(null);
            return;
        }
        const items = itemsToDisplay.filter(i => i.opt_collections.includes(chosenCollection.id));
        setCollectionItems([...items]);

    }, [chosenCollection])

    return (
        <div className='player-collectibles-container'>
            
            {
                
                (!chosenCollection && collections) &&
                <>
                <div className='card-title'>Choose a Collection...</div>
                <hr />
                <div className='collection-boxes'>
                    
                    {
                        (collections.length > 0) ?
                        collections.map(c => (
                            <button 
                                key={c.id} 
                                className='g-button collection-box'
                                onClick={() => setChosenCollection(c)}
                            >
                                <div className='collection-icon'>
                                    <ImageContainer src={c.icon} className='collection-icon' />
                                </div>
                                <div className='collection-title'>
                                    {c.name}
                                </div>
                            </button>
                        ))
                        :
                        <div className='center-col'>
                            <h4>No Collections yet...</h4>
                        </div>
                    }
                </div>
                </>
            }
            {
                (chosenCollection) &&
                <div className='collection-data'>
                    <BackButton cancel={() => setChosenCollection(null)} />
                    <div className="g-space-1"></div>
                    <div className='card-title'>
                        <ImageContainer src={chosenCollection.icon} className='head-icon' />  
                        {chosenCollection.name}
                    </div>
                    <hr />
                    {
                        (collectionItems && collectionItems.length > 0)
                        ?
                        <>
                        
                        <div className='collection-items'>
                            {
                                collectionItems.map(i => (
                                    <div key={i.id}>
                                        <StoreItemCard 
                                            item={i} 
                                            playerData={playerData} 
                                            gameStats={gameStats} 
                                            gameElements={gameElements}
                                            teamMembers={teamMembers}
                                            saleList={saleList}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                        </>
                        :
                        <h4 className='center-col'>No items available in this Collection</h4>
                    }
                </div>
            }
        </div>
    )
}

export default PlayerCollectibles