import React, { useEffect, useState } from 'react';
import './transaction-view.styles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import UserIcon from '@/components/layout-auth/user-icon/user-icon.component';
import { format } from 'date-fns';
import ImageContainer from '../image-container/image-container.component';
import TransactionItemCard from './transaction-item-card.component';
import HiddenText from '../hidden-text/hidden-text.component';
import { stripHtml } from '@/utils/sanitize';

const TransactionView = ({
    gameData,
    gameElements,
    playerList,
    purchases,
    gameActivity
}) => {

    const [ transactionData, setTransactionData ] = useState(null);
    const [ player, setPlayer ] = useState(null);
    const { transPath } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!transPath || !playerList || !gameActivity) return
        const trans = Object.values(gameActivity).filter(a => a.path === transPath)[0];
        console.log(trans);
        if (!trans) {
            // navigate(-1);
            return
        }
        setTransactionData(trans);
        setPlayer(playerList[trans.playerId]);
    }, [transPath, playerList, gameActivity])

    return (
        <div className='transaction-view'>
            <BackButton cancel={() => navigate(-1)} />
            <div className="g-space-1"></div>
            
            {
                (transactionData && player && gameElements) &&
                <div className='transaction-data'>
                    <div className='header'>
                        {
                            (transactionData.type === 'purchase') ?
                            <div className='title'>
                                <ImageContainer src='/images/icons/shopping-cart.png' />
                                <div className='title-info'>
                                    <h3>Purchase Details</h3>
                                </div>
                            </div> :
                            (transactionData.type === 'rewards') ?
                            <div className='title'>
                                <ImageContainer src='/images/icons/treasure.png' />
                                <div className='title-info'>
                                    <h3>Rewards Earned</h3>
                                </div>
                            </div> :
                            (transactionData.type === 'approvedChallenge') ?
                            <div className='title'>
                                <ImageContainer src='/images/icons/challenge.png' />
                                {/* <h3>Challenge Approved<br />{gameElements[transactionData.elementId].name}</h3> */}
                                <div className='title-info'>
                                    <h3>Challenge Approved</h3>
                                    <h3>{gameElements[transactionData.elementId].name}</h3>
                                    {
                                        (gameElements[transactionData.elementId].desc) &&
                                        <p className='meta'>{
                                            gameElements[transactionData.elementId].desc && 
                                            stripHtml(gameElements[transactionData.elementId].desc).substring(0,80)
                                        }
                                        {
                                            gameElements[transactionData.elementId].desc && 
                                            stripHtml(gameElements[transactionData.elementId].desc).length > 80 ? 
                                            '...' : 
                                            ''
                                        }</p>
                                    }
                                </div>
                            </div> :
                            (transactionData.type === 'levels') ?
                            <div className='title'>
                                <ImageContainer src={transactionData.icon} />
                                <div className='title-info'>
                                    <h3>Level Earned<br />{gameElements[transactionData.elementId].name}</h3>
                                </div>
                            </div> :
                            (transactionData.type === 'approvedItem') ?
                            <div className='title'>
                                <ImageContainer src={transactionData.icon} />
                                <div className='title-info'>
                                    <h3>Item Approved<br />{gameElements[transactionData.elementId].name}</h3>
                                </div>
                            </div> :
                            (transactionData.type === 'secretCode') ?
                            <div className='title'>
                                <ImageContainer src={transactionData.icon} />
                                <div className='title-info'>
                                    <h3>Secret Code Found</h3>
                                    <h3>{<HiddenText text={transactionData.title} hidden={true} />}</h3>
                                    {
                                        (transactionData.info) &&
                                        <p>{transactionData.info}</p>
                                    }
                                </div>
                            </div> :
                            ''
                        }
                        <div className='player-info'>
                            <div className='player-name'>
                                {`${player.fName}${player.lName ? ` ${player.lName}` : ''}`}
                            </div>
                            <UserIcon profile={player} small={true} />
                        </div>
                    </div>                
                    <hr />
                    {
                        (transactionData.type === 'purchase') &&
                        <div className='transaction-info'>
                            <div className='date'>
                                Purchased: <b>{format(new Date(transactionData.ts), "Pp")}</b>
                            </div>
                            <div className='total'>
                                Total Cost:
                                <ImageContainer src='/images/icons/coin.png'
                                className='coin-icon' />
                                <b>
                                    {transactionData.cartTotal}
                                </b>
                            </div>
                            <div className='total'>
                                Item Count: <b>{transactionData.cartQuantity}</b>
                            </div>
                        </div>
                    }
                    <div className='g-space-1'></div>
                    <div className='item-list'>
                    {
                        (transactionData.data && Object.values(transactionData.data).length > 0) &&
                        Object.values(transactionData.data).map(d => (
                            <div key={d.id}>
                                <TransactionItemCard 
                                    elementData={d} 
                                    transType={transactionData.type} 
                                />
                            </div>
                        ))
                    }
                    </div>
                </div>
            }
            <div className='g-space-2'></div>
            <div className='meta'>{transactionData && transactionData.id}</div>
        </div>
    )
}

export default TransactionView