import React, { useState } from 'react';
import './information.styles.scss';

import TextEditor from '@/components/SHARED/text-editor/text-editor.component';
import { sanitize } from '@/utils/sanitize';
import TextAreaWordCount from '@/components/SHARED/text-area-word-count/text-area-word-count.component';

const ManageContentInformation = ({ type, saveData, formData, existingDescription, clearEditor, setClearEditor, textEditorLabel }) => {

    return (
        <div className='information-form'>
            <div className='g-list-item form-section'>
                <h3>Information</h3>
                <hr />
                <div className='field'>
                    <label>{type === 'secretCode' ? 'Secret Code Word(s)' : 'Name'}<span className='required'>*</span></label>
                    <input 
                        type='text' 
                        id='name' 
                        value={
                            formData && formData.name ? 
                            formData.name : 
                            ''
                        } 
                        onChange={(e) => saveData('name', sanitize(e.target.value))} 
                    />
                </div>
                <div className="g-space-1"></div>
                <div className='field'>
                    <label>{textEditorLabel}</label>
                    {
                        (type === "challenge")
                        ?
                        <TextEditor 
                            setTextContent={(e) => saveData('desc', e)} 
                            textContent={formData && formData.desc} 
                            existingContent={existingDescription} 
                            clearEditor={clearEditor} 
                            setClearEditor={setClearEditor} 
                        />
                        :
                        <TextAreaWordCount 
                            saveData={saveData} 
                            count={50} 
                            keyId="desc"
                            existingText={formData.desc ?? ""}
                        />
                    }
                </div>
                <div className="g-space-1"></div>
            </div>
        </div>
    )
}

export default ManageContentInformation;