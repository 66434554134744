import React from 'react'
import './search-bar.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive';
import { mediaQueries } from '@/utils/responsiveQueries';

const SearchBar = ({ search, id }) => {

    const isMobileDevice = useMediaQuery({[mediaQueries.mobile.type]: mediaQueries.mobile.size});

    return (
        <div className='search-box'>
            <input 
                type="text" 
                className={`search-input ${
                    isMobileDevice ? 
                    'mobile' : 
                    ''
                }`} 
                placeholder="Search..." 
                onChange={(e) => search(e.target.value, id)} 
            />
            <div className={`search-icon ${isMobileDevice ? 'mobile': ''}`}>
                <FontAwesomeIcon icon={faSearch} />
            </div>
        </div>
    )
}

export default SearchBar