import React, { useEffect } from 'react'

const PlayerGroups = ({ gameData, playerData }) => {

    useEffect(() => {
        document.getElementById(`head-logo`).scrollIntoView({'behavior': 'smooth'});
        const btns = document.getElementsByClassName('menu-btn')
        for (let btn of btns) {
            btn.classList.remove('active');
        }
        document.getElementById('groups').classList.add('active');
    }, []);

    return (
        <div>PlayerGroups</div>
    )
}

export default PlayerGroups