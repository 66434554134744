import { query as dbQuery, endAt, equalTo, get, limitToLast, onChildAdded, orderByChild, ref, startAfter, startAt } from "firebase/database";
import { collection, doc, query as fbQuery, getDoc, getDocs, onSnapshot, orderBy, where } from "firebase/firestore";
import { db, rtDb } from "./firebase.utils";

export async function getGamesFromDb(e) {
    let gamesRef;
    if (e.appId) {
        gamesRef = fbQuery(collection(db, `games`), where('appId', '==', e.appId));
    } else {
        gamesRef = fbQuery(collection(db, `games`), orderBy('created'));
    }
    const docSnap = await getDocs(inviteRef);
    const invites = {};
    docSnap.forEach(doc => {
        if (!doc.exists) {return;}
        invites[doc.id] = doc.data();
        return;
    })
    return invites;
}

export async function getOrgsFromDb(e) {
    let orgsRef;
    
    if (e.lastOrg) {
        orgsRef = fbQuery(collection(db, `orgs`), where('status', '==', 'active'), where('ts', '>', e.lastOrg))
        console.log('attaching org listener');
    } else if (e.orgId) {
        orgsRef = fbQuery(collection(db, `orgs`), where('orgId', '==', e.orgId))
    } else {
        orgsRef = fbQuery(collection(db, `orgs`), where('status', '==', 'active'));
    }
    const unsubscribe = onSnapshot(orgsRef, docs => {
        docs.forEach(doc => {
            const data = doc.data();
            e.callback(data);
        });
    })
    e.handleListener(unsubscribe);
}

export async function getInvitesFromDb(e) {
    let inviteRef;
    if (e.email) {
        inviteRef = fbQuery(collection(db, `orgInvites`), where('status', '==', 'pending'), where('email', '==', e.email));
    } else if (e.orgId && e.status) {
        inviteRef = fbQuery(collection(db, `orgInvites`), where('status', '==', e.status), where('orgId', '==', e.orgId));
    } else if (e.orgId) {
        inviteRef = fbQuery(collection(db, 'orgInvites'), where('orgId', '==', e.orgId))
    }
    const unsubscribe = onSnapshot(inviteRef, docs => {
        docs.forEach(doc => {
            const data = doc.data();
            e.callback(data, doc.id);
        });
    })
    e.handleListener(unsubscribe);
}

export async function getAllUsersFromDb(e) {

    const regsRef = fbQuery(collection(db, `regs`), where('status', '==', 'active'))
    let regs = {};
    const regsDocs = await getDocs(regsRef);
    regsDocs.forEach(doc => {
        const data = doc.data();
        data.orgId = doc.id;
        // console.log(data);
        regs[doc.id] = data;
    });
    // console.log(regs)

    let usersRef;
    
    if (e.lastUser) {
        usersRef = fbQuery(collection(db, `users`), where('status', '==', 'active'), where('ts', '>', e.lastUser))
        console.log('attaching user listener');
    } else {
        usersRef = fbQuery(collection(db, `users`), where('status', '==', 'active'));
    }
    const unsubscribe = onSnapshot(usersRef, docs => {
        docs.forEach(doc => {
            const data = doc.data();
            let userRegs = []
            Object.values(regs).filter(reg => reg.list.includes(data.email))
            .map(reg => {
                userRegs.push({
                    'name': reg.name,
                    'role': reg.roles[data.email],
                    'orgId': reg.orgId
                })
            })
            data.regs = userRegs;
            e.callback(data);
        });
    })
    e.receiveListener(unsubscribe);
}

export async function getOrgUsersFromDb(e) {
    const regsRef = doc(db, `regs/${e.orgId}`);
    const regsDoc = await getDoc(regsRef)
    .then(doc => { return doc.exists ? doc.data() : null});
    // console.log(regsDoc)
    for (let userEmail of regsDoc.list) {
        const userRef = fbQuery(collection(db, 'users'), where('email', '==', userEmail))
        const docSnap = await getDocs(userRef);
        docSnap.forEach(async doc => {
            let data = {};
            data = doc.data();
            data.role = regsDoc.roles[userEmail];
            e.callback(data);
        });
    }
    const invites = await getInvites({'org': e.orgId});
    const invite = Object.values(invites).map(invite => {
        let data = {};
        data.email = invite.email;
        data.status = invite.status;
        data.role = invite.role;
        e.callback(data);
    })
}

export async function getUserOrgs(e) {
    let orgsRef;
    
    if (e.lastOrg) {
        orgsRef = fbQuery(collection(db, `regs`), where('status', '==', 'active'), where('lastUpdate', '>', e.lastUpdate))
        console.log('attaching org listener');
    } else if (e.orgId) {
        orgsRef = fbQuery(collection(db, `orgs`), where('orgId', '==', e.orgId))
    } else {
        orgsRef = fbQuery(collection(db, `orgs`), where('status', '==', 'active'));
    }
    const unsubscribe = onSnapshot(orgsRef, docs => {
        docs.forEach(doc => {
            const data = doc.data();
            e.callback(data);
        });
    })
    e.receiveListener(unsubscribe);
}

export async function getLastActivity(appId, callback) {
    const lastActRef = dbQuery(ref(rtDb, 'activity'), orderByChild('user'), equalTo(appId), limitToLast(1))
    let lastAct = await get(lastActRef, snap => {
        return snap.exists() ? snap.val() : null;
    }, {onlyOnce: true});
    callback(appId, lastAct.val());
}

export async function getActivityLogs(e) {
    let activityLogsRef;
    console.log(e)
    
    if (e.appId && e.lastId) {
        activityLogsRef = dbQuery(ref(rtDb, 'userActivity'), orderByChild('user'), equalTo(e.appId), startAfter(e.lastId), limitToLast(40));
    } else if (e.appId && e.type) {
        activityLogsRef = dbQuery(ref(rtDb, 'userActivity'), orderByChild('sort1'), equalTo(`${e.appId}-${e.type}`));
    } else if (e.appId) {
        activityLogsRef = dbQuery(ref(rtDb, 'userActivity'), orderByChild('user'), equalTo(e.appId), limitToLast(40));
    } else if (e.type) {
        activityLogsRef = dbQuery(ref(rtDb, 'userActivity'), orderByChild('type'), equalTo(e.type), limitToLast(40));
    } else if (e.startDate && e.endDate) {
        activityLogsRef = dbQuery(ref(rtDb, 'userActivity'), orderByChild('ts'), startAt(e.startDate), endAt(e.endDate));
    } else if (e.startDate) {
        activityLogsRef = dbQuery(ref(rtDb, 'userActivity'), orderByChild('ts'), startAt(e.startDate));
    } else if (e.lastId) {
        activityLogsRef = dbQuery(ref(rtDb, 'userActivity'), startAfter(e.lastId), limitToLast(40));
    } else {
        console.log('getting all')
        activityLogsRef = dbQuery(ref(rtDb, 'userActivity'), limitToLast(40));
    }
    onChildAdded(activityLogsRef, snap => {
        const data = snap.exists() ? snap.val() : null;
        e.callback(data);
    });
    e.receiveListener(activityLogsRef);
}

export async function getBugReports(e) {
    let bugsRef;
    
    if (e.onlyActive) {
        bugsRef = fbQuery(collection(db, `bugReports`), where('status', '==', 'active'))
    } else {
        bugsRef = fbQuery(collection(db, `bugReports`));
    }
    const unsubscribe = onSnapshot(bugsRef, docs => {
        docs.forEach(doc => {
            const data = doc.data();
            e.callback(data);
        });
    })
    e.receiveListener(unsubscribe);
}
