import React from 'react';
import './quote.styles.scss';
import UnauthLayout from '@/components/layout-unauth/unauth-layout.component';
import ContactUsForm from '@/components/SHARED/contact-us/contact-us-form.component';

const QuotePage = () => {
    return (
        <div className='quote-page'>
            <UnauthLayout>
                <div className='quote-page-content'>
                    <div className='g-card contact-us-card'>
                        <h3>Contact Us!</h3>
                        <hr />
                        <ContactUsForm />
                    </div>
                </div>
            </UnauthLayout>
        </div>
    )
}

export default QuotePage