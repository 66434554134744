import React, { useEffect, useRef, useState } from 'react';
import './contact-us-form.styles.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { useSelector } from 'react-redux';
import { ModalAlert } from '@/components/modal/modal.component';

const ContactUsForm = ({ tall }) => {

    const currentUser = useSelector(state => state.userData.currentUser);
    const userProfile = useSelector(state => state.userData.userProfile);
    const [ formData, setFormData ] = useState({});
    const [ readyToSend, setReadyToSend ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ sent, setSent ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const dataRef = useRef();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!userProfile) return;
        dataRef.current = {
            ...dataRef.current, 
            ...{
                'fName': userProfile.fName,
                'lName': userProfile.lName,
                'email': userProfile.email,
            }
        }
        setFormData(dataRef.current);
    }, [userProfile])

    useEffect(() => {
        if (!formData) return;
        if (formData.fName && formData.lName && formData.email && formData.title && formData.reason && formData.message) {
            setReadyToSend(true);
        } else {
            setReadyToSend(false);
        }
    }, [formData])

    function saveData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}};
        setFormData(dataRef.current);
    }

    async function sendContact() {
        setLoading(true);
        const tempData = {...dataRef.current};
        tempData.ts = new Date().getTime();
        if (userProfile) {
            tempData.appId = userProfile.appId;
        }
        const res = await UseCloudFunction('submitContactUs', {'contactData': tempData})
        console.log(res);
        if (res.error) {
            setAlertMessage(`Sorry, something went wrong. Please try again later. (Error: ${res.error})`);
            return;
        }
        setLoading(false);
        setSent(true);
    }

    return (
        <div className='contact-us-form'>
            {
                (sent)
                ?
                <div className='center-col'>
                    <h2>Thank you for reaching out!</h2>
                    <p>Someone from Gamably will be in touch with you very soon!</p>
                </div>
                :
                <div>
                    <div className={`two-inputs ${tall ? 'tall' : ''}`}>
                        <div className='field small'>
                            <label>First Name</label>
                            <input type='text' className={userProfile ? 'disabled' : ''} value={formData.fName ?? ''} onChange={e => saveData('fName', e.target.value)} />
                        </div>
                        <div className='field small'>
                            <label>Last Name</label>
                            <input type='text' className={userProfile ? 'disabled' : ''} value={formData.lName ?? ''} onChange={e => saveData('lName', e.target.value)} />
                        </div>
                    </div>
                    <div className={`two-inputs ${tall ? 'tall' : ''}`}>
                        <div className='field small'>
                            <label>Email</label>
                            <input type='text' className={userProfile ? 'disabled' : ''} value={formData.email ?? ''} onChange={e => saveData('email', e.target.value)} />
                        </div>
                        <div className='field small'>
                            <label>Job Title</label>
                            <input type='text' value={formData.title ?? ''} onChange={e => saveData('title', e.target.value)} />
                        </div>
                    </div>
                    <div className='field small'>
                        <label>Reason for Contacting Us</label>
                        <select value={formData.reason ?? ''} onChange={e => saveData('reason', e.target.value)} >
                            <option value=''>Select one...</option>
                            <option value='quote'>I would like an Enterprise Quote</option>
                            <option value='feature'>I have a feature idea</option>
                            <option value='problem'>I have a problem I'd like to report</option>
                        </select>
                    </div>
                    <div className='field small'>
                        <label>How can we help?</label>
                        <textarea rows='3' value={formData.message ?? ''} onChange={e => saveData('message', e.target.value)}></textarea>
                    </div>
                    {
                        (loading)
                        ?
                        <div className='buttons'>
                            <button className='g-button disabled'>Cancel</button>
                            <button className='g-button disabled'><ThreeDotsLoader /></button>
                        </div>
                        :
                        (readyToSend)
                        ?
                        <div className='buttons'>
                            <button className='g-button' onClick={() => navigate(-1)}>Cancel</button>
                            <button className='g-button primary' onClick={() => sendContact()}>Send Message</button>
                        </div>
                        :
                        <div className='buttons'>
                            <button className='g-button' onClick={() => navigate(-1)}>Cancel</button>
                            <button className='g-button disabled primary'>Send Message</button>
                        </div>
                    }
                </div>
            }
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
        </div>
    )
}

export default ContactUsForm