import React, { useEffect, useRef, useState } from 'react'
import './text-editor.styles.scss'

import { ContentState, EditorState, Modifier } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from 'draft-js-export-html';
import { resizeImage } from '@/utils/resizeImage';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { toolbar } from './toolbar';
import htmlToDraft from 'html-to-draftjs';

/**
 * npm i draft-js react-draft-wysiwyg draft-js-export-html
 * include utils/resizeImage.js
 * include init.js
 */

const TextEditor = ({ textContent, setTextContent, existingContent, clearEditor, setClearEditor }) => {

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const editorRef = useRef();
    const storage = getStorage();
    const editor = useRef(null);

    useEffect(() => {
        if (existingContent) {
            insertExistingHtml(existingContent)
        }
    },[existingContent])

    useEffect(() => {
        if (!clearEditor) {return;}
        setEditorState(EditorState.createEmpty());
        setClearEditor(false);
    }, [clearEditor])

    function insertExistingHtml(e) {
        let { contentBlocks, entityMap } = htmlToDraft(e);
        let contentState = Modifier.replaceWithFragment(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap()
        )
        setEditorState(EditorState.createWithContent(contentState))
        // EditorState.push(editorState, contentState, 'insert-fragment')

    }

    const htmlToContentState = (html) => {
        // const focus = () => editor.current.focus();
        // focus();
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            return EditorState.createWithContent(contentState);
        }
        return EditorState.createEmpty();
    };

    function saveEditorState(e) {
        setEditorState(e)
        editorRef.current = e;
        setTextContent(getContentAsHTML())
    }

    function getContentAsHTML() {
        const contentState = editorRef.current.getCurrentContent()
        const options = {
        }
        return stateToHTML(contentState, options);
    } 

    return (
        <Editor
            editorState={editorState}
            toolbar={toolbar}
            toolbarClassName="new-editor-toolbar"
            wrapperClassName="wrapperClassName"
            editorClassName="editor"
            onEditorStateChange={(editorState) => saveEditorState(editorState)}
        />
    )
}

export default TextEditor