import React, { useEffect, useState } from 'react'
import './hosted-icon-modal.styles.scss';
import Modal from '@/components/modal/modal.component';
import hostedImages from '@/utils/hostedIcons.json';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { useLocation } from 'react-router-dom';

const HostedIconModal = ({ show, cancel, selectIcon, type}) => {
    
    const [ display, setDisplay ] = useState('miltons')
    const [ visibility ] = useState({
        'terrain': ['challenge','level','badge', 'item', 'team', 'collection', 'profile', 'secretCode'],
        'items': ['challenge','level','badge', 'item', 'team', 'collection', 'profile', 'secretCode'],
        'levels': ['challenge', 'level','badge', 'item', 'team', 'collection', 'profile', 'secretCode'],
        'badges': ['challenge', 'level','badge', 'item', 'team', 'collection', 'profile', 'secretCode'],
        'miltons': ['challenge','level','item','badge','team','collection','profile', 'secretCode']
    })
    const location = useLocation();

    function makeChoice(url) {
        selectIcon(url);
        cancel();
    }

    return (
        <div className='hosted-icon-modal'>
            <Modal show={show} cancel={cancel} cls='very-wide' closeButton={true} text="Choose an existing icon" >
                <div className='modal-children'> 
                    <div className='hosted-icon-modal-content'>
                        <div className='hosted-icon-grid'>
                            <div className='button-row'>
                            {
                                (type && visibility.miltons.includes(type)) &&
                                <button className={`g-button text-only ${display === 'miltons' ? 'active' : ''}`} onClick={() => setDisplay('miltons')}>Milton the Chameleon</button>
                            }
                            {
                                (type && visibility.terrain.includes(type)) &&
                                <button className={`g-button text-only ${display === 'terrain' ? 'active' : ''}`} onClick={() => setDisplay('terrain')}>Terrain</button>
                            }
                            {
                                (type && visibility.items.includes(type)) &&
                                <button className={`g-button text-only ${display === 'items' ? 'active' : ''}`} onClick={() => setDisplay('items')}>Items</button>
                            }
                            {
                                (type && visibility.levels.includes(type)) &&
                                <button className={`g-button text-only ${display === 'levels' ? 'active' : ''}`} onClick={() => setDisplay('levels')}>Levels</button>
                            }
                            {
                                (type && visibility.badges.includes(type)) &&
                                <button className={`g-button text-only ${display === 'badges' ? 'active' : ''}`} onClick={() => setDisplay('badges')}>Badges</button>
                            }
                            
                            </div>
                            <div className='icons'>
                            {
                                (display) &&
                                hostedImages[display].map(i => (
                                    <div key={i.id} className='icon-item'>
                                        <ImageContainer src={i.url} className='clickable' onClick={() => makeChoice(i.url)} />
                                        <b>{i.name}</b>
                                    </div>
                                ))   
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </Modal> 
        </div>
    )
}

export default HostedIconModal