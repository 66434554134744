import CreateEditForm1 from '@/components/HOME/create-edit-game/create-edit-form-1.component';
import BackButton from '@/components/SHARED/back-button/back-button.component'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const SettingsInfo = ({
    formData,
    saveData,
    validJoinCode,
    saveSettings,
    cancel,
    setJoinCodeEdited
}) => {

    const navigate = useNavigate();

    return (
        <div className='settings-info'>
            <div className='g-card'>
                <BackButton cancel={cancel} />
                <div className="g-space-1"></div>
                <div className='card-title'>Settings: Game Information</div>
                <div className="g-space-1"></div>
                <div className='g-list-item grey-background'>
                    <div className="g-space-1"></div>
                    <CreateEditForm1 
                        edit={true} 
                        formData={formData} 
                        saveData={saveData} 
                        validJoinCode={validJoinCode} 
                        setJoinCodeEdited={setJoinCodeEdited}
                    />
                </div>
                <div className="g-space-1"></div>
                <div className='buttons'>
                    <button className='g-button' onClick={cancel}>Cancel</button>
                    <button className='g-button primary' onClick={() => saveSettings('info')}>Save Changes</button>
                </div>
            </div>
        </div>
    )
}

export default SettingsInfo