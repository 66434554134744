import React, { useEffect, useState } from 'react'
import './rewards.styles.scss'

import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { UseCloudDistribute } from '@/utils/firebase.utils';
import ViewRewardsModal from './view-rewards-modal.component';
import { switchPlayerMenu } from '../side-menu.component';
import HiddenText from '@/components/SHARED/hidden-text/hidden-text.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { setNewStuffCount } from '@/state/slices/contentSlice';

const PlayerRewards = ({ gameData, playerData, playerActivity}) => {

    const root = useSelector(state => state.userData.root);
    const [ rewardModalIsOpen, setRewardModalIsOpen ] = useState(false)
    const [ modalActivity, setModalActivity ] = useState(null)
    const [ activityToDisplay, setActivityToDisplay ] = useState(null)
    const dispatch = useDispatch();

    useEffect(() => {
        if (!playerActivity) {return;}

        console.log(playerActivity);
        let newActivityObj = {}
        for (let a of Object.values(playerActivity).filter(a => a.display === 'reward')) {
            newActivityObj[a.id] = a
        }
        console.log(newActivityObj);
        setActivityToDisplay(newActivityObj)
    }, [playerActivity])

    useEffect(() => {
        const pageTitle = document.getElementById('page-title');
        if (pageTitle) {
            pageTitle.innerText = 'My Rewards'
        }
        switchPlayerMenu('rewards-btn');
    }, []);


    function handleRewardModalOpen(activityId) {
        setModalActivity(playerActivity[activityId])
        setRewardModalIsOpen(true)
    }

    function handleRewardModalClose() {
        setRewardModalIsOpen(false)
    }

    async function openReward(id, newItem) {
        let savedNewStuffCount = Number(window.localStorage.getItem(`${gameData.gameId}_newRewardCount`));
        savedNewStuffCount--;
        window.localStorage.setItem(`${gameData.gameId}_newRewardCount`, savedNewStuffCount);
        dispatch(setNewStuffCount(savedNewStuffCount));
        document.getElementById(`reward-${id}-div`).classList.add('unclickable-treasure');
        document.getElementById(`reward-${id}-div`).classList.remove('clickable-treasure');
        document.getElementById(`reward-${id}`).src = `${import.meta.env.VITE_ROOT}/images/icons/treasure.gif`
        setTimeout( async () => {
            handleRewardModalOpen(id);
            const data = {
                'gameId': gameData.gameId,
                'playerId': playerData.playerId,
                'rewardId': id
            }
            console.log(data);
            const res = await UseCloudDistribute('markRewardAsViewed', data)
            console.log(res);
        }, 2000)
    }

    return (
        <div className='player-rewards g-card'>
            <div className='player-title'>
                <ImageContainer src='/images/icons/treasure.png' alt='rewards icon' className='player-title-icon' />
                My Rewards
            </div>
            <hr />
            <div className='reward-grid'>
            {
                (activityToDisplay && playerData) &&
                Object.values(activityToDisplay)
                .sort((a,b) => a.ts < b.ts ? 1 : -1)
                .map(act => (
                    (playerData.newStuff && playerData.newStuff.includes(act.id))
                    ?
                    <div key={act.ts} className="reward-item">
                        <div className='clickable-treasure' id={`reward-${act.id}-div`} onClick={() => openReward(act.id, true)}>
                            <ImageContainer alt='closed treasure icon' id={`reward-${act.id}`} src='/images/icons/treasure-cl.png' />
                        </div>
                    </div>
                    :
                    <div key={act.ts}>
                        <div id={`reward-${act.ts}-div`} >
                            <table className='treasure-table'>
                                <tbody>
                                    <tr>
                                        <td className='opened-treasure-td'>
                                            <ImageContainer src='/images/icons/treasure-op.png' alt='open rewards icon' className='opened-treasure' />
                                            <ImageContainer src={act.icon} alt='activity icon' className='activity-icon' />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-td'>
                                            <span className='text'>
                                                <span className='desc'>
                                                    {act.event}
                                                </span>
                                                <br />
                                                {act.type === 'secretCode' ? <HiddenText text={act.title} hidden={true} /> : <span>{act.title}<br /></span>}
                                                
                                                <span className='desc'>Earned {format(new Date(act.ts), 'Pp')}</span>
                                            </span>
                                            <div className="g-space-20"></div>
                                            <div className='center-col'>
                                                <button className='g-button small-btn' onClick={() => handleRewardModalOpen(act.id)}>View Rewards</button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                ))
            }
            </div>
            <ViewRewardsModal isOpen={rewardModalIsOpen} onClose={() => handleRewardModalClose()} activity={modalActivity} width={'normal'} closeButton={true} />
        </div>
    )
}

export default PlayerRewards