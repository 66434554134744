import React, { useState, useEffect } from 'react'

import DOMPurify from 'isomorphic-dompurify'
import { useNavigate } from 'react-router-dom'
import SampleLayout from './game-images/sample-layout.component';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';


const CreateEditFormReview = ({ edit, formData, saveData, verifyAndMoveOn, cancel, validJoinCode, loading }) => {

    const navigate = useNavigate();

    useEffect(() => {
        if (!formData.gameName) {
            navigate('/home/creategame');
        }
    }, [formData])

    return (
        <div className='new-game-form' id='new-game-form'>
            {
                (formData) &&
                <>
                <div className='review-row'>
                    <div className='title'>Name:</div>
                    <div className='data'>{formData.gameName}</div>
                </div>
                <div className='review-row'>
                    <div className='title'>Join Code:</div>
                    <div className='data'>{`https://gamably.com/join/${formData.joinCode}`}</div>
                </div>
                <div className='review-row'>
                    <div className='title'>Short Description:</div>
                    <div className='data'>{formData.shortDesc}</div>
                </div>
                <div className='review-row'>
                    <div className='title'>Long Description:</div>
                    <div className='data'>{<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(formData.desc) }} />}</div>
                </div>
                {/* <div className='review-row'>
                    <div className='title'>Icon:</div>
                    <div className='data'><img className='icon' src={formData.icon.url} /></div>
                </div>
                <div className='review-row'>
                    <div className='title'>Header:</div>
                    <div className='data'><img className='header' src={formData.header.url} /></div>
                </div>
                <div className='review-row'>
                    <div className='title'>Background:</div>
                    <div className='data'>
                        {
                            (formData.bg.type === 'image')
                            ?
                            <img className='background-image' src={formData.bg.url} /> : 
                            <div className='background-gradient' style={{
                                background: formData.bg.hexBottom, 
                                background: `-webkit-linear-gradient(to top, ${formData.bg.hexBottom}, ${formData.bg.hexTop})`,
                                background: `linear-gradient(to top, ${formData.bg.hexBottom}, ${formData.bg.hexTop})`}}
                            ></div>
                        }
                    </div>
                </div> */}
                <div className='review-row'>
                    <div className='title'>Game Type:</div>
                    <div className='data'>{formData.type === 'items' ? 'Currency, Items, and a Store' : formData.type === 'points' ? 'Points and Leaderboards' : 'Full Game'}</div>
                </div>
                <div className='review-row'>
                    <div className='title'>Roster Through Uploads:</div>
                    <div className='data'>{formData.enroll_upload ? 'Allowed' : 'Not Allowed'}</div>
                </div>
                <div className='review-row'>
                    <div className='title'>Roster Through Email-Based Accounts:</div>
                    <div className='data'>{formData.enroll_email ? 'Allowed' : 'Not Allowed'}</div>
                </div>
                {
                    (formData.enroll_email) &&
                    <div className='review-row'>
                        <div className='title'>Limit List:</div>
                        <div className='data'>{formData.enroll_limitList && formData.enroll_limitList.join(', ')}</div>
                    </div>
                }
                <div className='review-row'>
                    <div className='title'>Design Preview:</div>
                </div>
                <SampleLayout formData={formData} />
                </>
            }
            <hr />
            <div className='buttons'>
                {
                    (loading)
                    ?
                    <>
                        <button className='g-button'>Back</button>
                        <button className='g-button'>Cancel</button>
                        <button className='g-button' ><ThreeDotsLoader /></button>
                    </>
                    :
                    <>
                        <button className='g-button' onClick={() => navigate(-1)}>Back</button>
                        <button className='g-button' onClick={() => cancel()}>Cancel</button>
                        <button className='g-button primary' onClick={() => verifyAndMoveOn([], 'final')}>Finalize Game</button>
                    </>
                }
            </div>
        </div>
    )
}

export default CreateEditFormReview