import React, { useEffect, useRef, useState } from 'react';
import CreateEditForm4 from '@/components/HOME/create-edit-game/create-edit-form-4.component';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { getRegsFromDb } from '@/utils/getDataFromDb';

const SettingsRostering = ({ formData, saveData, gameData, saveSettings, cancel }) => {

    const [ bannedList, setBannedList ] = useState(null);
    const bannedRef = useRef();
    let bannedListener;

    useEffect(() => {
        if (!gameData) return;
        getBannedList();

        return () => {
            if (bannedListener) bannedListener();
        }
    }, [gameData])

    function getBannedList() {
        getRegsFromDb({'gameId': gameData.gameId, 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            console.log(data);
            bannedRef.current = data.join(', ');
            setBannedList(bannedRef.current);
        }
        function handleListener(unsub) {
            bannedListener = unsub;
        }
    }

    function saveBannedList(e) {
        bannedRef.current = e;
        setBannedList(bannedRef.current);
        saveData('banned', bannedRef.current);
    }

    return (
        <div className='settings-rostering'>
            <div className='g-card'>
                <BackButton cancel={cancel} />
                <div className="g-space-1"></div>
                <div className='card-title'>Settings: Rostering</div>
                <div className="g-space-1"></div>
                <div className='g-list-item grey-background'>
                    <CreateEditForm4 edit={true} formData={formData} saveData={saveData} gameData={gameData}  />
                    <div className={`field ${!formData.enroll_email ? 'disabled' : ''}`}>
                        <label className='no-flex'>
                            <h3>Banned Emails/Domains</h3>
                            <p>List any emails or domains that should not have access to your game. <b>Separate them with commas.</b></p>
                        </label>
                        <textarea 
                            id='banned-list-input' 
                            rows="3"
                            placeholder='ex: joe@email.com, @gamably.com'
                            value={bannedList ?? ''}
                            onChange={(e) => saveBannedList(e.target.value)}
                        ></textarea>
                    </div>
                </div>
                <div className="g-space-1"></div>
                <div className='buttons'>
                    <button className='g-button' onClick={cancel}>Cancel</button>
                    <button className='g-button primary' onClick={() => saveSettings('rostering')}>Save Changes</button>
                </div>
            </div>
        </div>
    )
}

export default SettingsRostering