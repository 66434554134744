import React, { useEffect, useRef, useState } from 'react';
import './add-items-to-store.styles.scss';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { properCase } from '@/utils/properCase';
import { faFilter, faPlus, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchBar from '@/components/SHARED/search-bar/search-bar.component';
import CategoryFilterModal from '../../create-elements/components/category-filter-modal.component';
import { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import ItemSearchBar from '@/components/SHARED/search-bar/item-search-bar.component';
import { UseCloudFunction } from '@/utils/firebase.utils';

const AddItemsToStore = ({ gameData, gameElements }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ availableItems, setAvailableItems ] = useState(null);
    const [ itemsToDisplay, setItemsToDisplay ] = useState(null);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ prices, setPrices ] = useState({});
    const [ loading, setLoading ] = useState(null);
    const priceRef = useRef({});
    const approveRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        if (!gameElements) return;
        let avail = Object.values(gameElements)
        .filter(e => 
            e.type === 'item' &&
            !e.opt_market
        )
        setAvailableItems([...avail]);
        setItemsToDisplay([...avail]);
        avail.map(i => {
            if (i.opt_marketPrice) {
                savePrice(i.id, i.opt_marketPrice);
            }
        })
    }, [gameElements])

    

    function savePrice(itemId, price) {
        priceRef.current = {...priceRef.current, ...{[itemId]: [price]}}
        setPrices(priceRef.current);
    }

    function addItemToStore(item) {
        if (item.opt_itemType !== 'contribution' && !priceRef.current[item.id]) {
            setAlertMessage('Please include a market price before adding this item to the store.');
            return;
        }
        setConfirmMessage('Are you sure you want to add this item to the store?<br /><br />NOTE: Be sure to check the item\'s visibility and availability in the Manage Elements List. Adding it to the store may not make it available if there are open and close dates assigned.');
        approveRef.current = () => {
            continueWithAddition();
        }

        async function continueWithAddition() {
            setLoading(item.id);
            const res = await UseCloudFunction(
                'changeStoreStatus',
                {
                    'item': item,
                    'appId': userProfile.appId,
                    'marketPrice': priceRef.current[item.id],
                }
            );
            console.log(res);
            setLoading(null);
        }
    }

    return (
        <div className='add-items-to-store'>
            <ItemSearchBar
                availableItems={availableItems}
                setItemsToDisplay={setItemsToDisplay}
            />
            <div className="g-space-1"></div>
            <div className='item-list'>
                {
                    (itemsToDisplay) &&
                    itemsToDisplay
                    .sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                    .map(i => (
                        <div key={i.id} className='item-card g-list-item'>
                            <div className='item-card-icon'>
                                <ImageContainer src={i.icon} className='item-icon' />
                            </div>
                            <b>{i.name}</b>
                            <span className='meta type'>{properCase(i.opt_itemType)}</span>
                            <span className='meta cat'>{i.opt_cat}</span>
                            <div className={`price ${i.opt_itemType === 'contribution' ? 'disabled' : ''}`}>
                                <ImageContainer src='/images/icons/coin.png' className='coin-icon' />
                                <div className='field small'>
                                    <input
                                        type="number"
                                        value={prices[i.id] ?? ''}
                                        onChange={(e) => savePrice(i.id, Number(e.target.value))}
                                    />
                                </div>
                            </div>
                            <div className='buttons'>
                                <div className='buttons'>
                                    <button 
                                        className='g-button med-btn'
                                        title='Edit Item'
                                        onClick={() => loading === i.id ? null : addItemToStore(i)}
                                    >
                                        Add Item
                                        {
                                            (loading === i.id)
                                            ?
                                            <FontAwesomeIcon icon={faRefresh} className='spinning-icon' />
                                            :
                                            <FontAwesomeIcon icon={faPlus} />
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            <ModalConfirm
                show={confirmMessage}
                cancel={() => setConfirmMessage(null)}
                message={confirmMessage}
                onApprove={approveRef.current}
            />
            <ModalAlert
                show={alertMessage}
                cancel={() => setAlertMessage(null)}
                message={alertMessage}
            />
        </div>
    )
}

export default AddItemsToStore