import React, { useEffect } from 'react'
import './home-landing.styles.scss'
import { switchOrgMenu } from '@/components/layout-auth/sidebar-left/sidebar-left.component'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AgeCheck from '@/components/layout-auth/age-check/age-check.component'

const HomeLanding = () => {

    // const profile = useSelector(state => state.userData.userProfile);
    const managing = useSelector(state => state.contentData.managingList);
    const enrolled = useSelector(state => state.contentData.enrolledList);
    const navigate = useNavigate();

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (managing) {
                navigate('managing');
            } else {
                navigate ('enrolled');
            }
        }, 500);

        return () => {
            if (timeout) clearTimeout(timeout);
        }
    }, [])

    return (
        <div className='home-landing-container'>
            <AgeCheck />
            <div className='home-landing'>
                <div className='home-content'>
                    
                </div>
            </div>
        </div>
    )
}

export default HomeLanding