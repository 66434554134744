export function formatNumber(number) {
    // Define the suffixes for thousand, million, billion, etc.
    const suffixes = ['', 'k', 'M', 'B', 'T'];
    // Divide the number by 1000 until it's less than 1000 and find the appropriate suffix
    let suffixIndex = 0;
    while (number >= 1000 && suffixIndex < suffixes.length - 1) {
        number /= 1000;
        suffixIndex++;
    }
    // Round the number to 1 decimal place
    number = Math.round(number * 10) / 10;
    // Append the suffix
    const formattedNumber = number.toString() + suffixes[suffixIndex];
    return formattedNumber;
}