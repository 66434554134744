import React, { useEffect, useState } from 'react'
import './managing-container.styles.scss';

import Layout from '@/components/layout-auth/layout.comp'
import { switchOrgMenu } from '@/components/layout-auth/sidebar-left/sidebar-left.component';
import ManagingList from './managing-list.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faRocket } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import AgeCheck from '@/components/layout-auth/age-check/age-check.component';
import { useSelector } from 'react-redux';
import { membershipRestrictions, testMemberRestrictions } from '@/utils/membershipRestrictions';
import UpgradeRequiredModal from '@/components/SHARED/upgrade-required-modal/upgrade-required-modal.component';

const ManagingContainer = () => {

    const managingList = useSelector(state => state.contentData.managingList);
    const membership = useSelector(state => state.userData.membership);
    const userProfile = useSelector(state => state.userData.userProfile);
    const [ showUpgradeModal, setShowUpgradeModal ] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            switchOrgMenu('home-menu-managing')
        }, 5)
    }, [])

    function checkMembership() {
        if (!managingList || Object.values(managingList).length === 0) {
            navigate('/home/creategame');
            return;
        }
        const res = testMemberRestrictions({'gameCount': Object.values(managingList).length, 'membership': membership});
        if (res.disabled && res.disabledReason.includes('games')) {
            const planRest = membershipRestrictions[membership.membership];
            const message = `While using the ${planRest.title}, you are only able to have ${planRest.gameCount} active Games.`;
            setShowUpgradeModal(message);
            return;
        }
        navigate('/home/creategame')
    }

    return (
        <div className='managing-container '>
            <AgeCheck />
            {/* <Layout> */}
            <div className='managing-content'>
                <div className='g-card'>
                    
                        <div className='section-title with-icon'>
                            <span>Games I Manage</span>
                            {
                                (userProfile && managingList) &&
                                <button className='g-button' onClick={() => checkMembership()}>Create a New Game <FontAwesomeIcon icon={faRocket} size="lg" className='button-icon' /></button>
                            }
                        </div>
                        <hr className='no-top-margin' />
                        <ManagingList />
                    
                </div>
                <div className="g-space-2"></div>
            </div>
            <UpgradeRequiredModal 
                show={showUpgradeModal} 
                cancel={() => setShowUpgradeModal(false)} 
                closeButton={true} 
                message={showUpgradeModal} 
            />
            {/* </Layout> */}
        </div>
    )
}

export default ManagingContainer