import React from 'react';
import './item-type.styles.scss';
import ToggleInput from '@/components/SHARED/toggle-input/toggle-input.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';

const ManageItemType = ({ type, saveData, formData }) => {
    return (
        <div className='item-type'>
            <div className='g-list-item form-section'>
                <h3>Item Type<span className='required'>*</span></h3>
                <hr />
                <p>Choose what type of item this will be.</p>
                <div className="item-type-content">
                    <div className='toggle-with-text'>
                        <ToggleInput 
                            contentKey="opt_itemType" 
                            checked={formData.opt_itemType === 'inventory'} 
                            saveData={saveData}
                            value='inventory' 
                        />
                        <div className='toggle-text'>
                            <div className='image'>
                                <ImageContainer 
                                    src='/images/icons/item-inventoryitem.png' 
                                    alt='inventory icon' 
                                    className='label-image' 
                                />
                            </div>
                            <div className='text'>
                                <h3>Inventory Item</h3>
                                <span className='meta'>Can be either earned or purchased in the Marketplace, and will be stored in the player's inventory to be used as a game mechanic at a later date. These items can be sold or traded to other players.</span>
                            </div> 
                        </div>
                    </div>
                    <div className="g-space-1"></div>
                    <div className='toggle-with-text'>
                        <ToggleInput 
                            contentKey="opt_itemType" 
                            checked={formData.opt_itemType === 'realworld'} 
                            saveData={saveData}
                            value='realworld' 
                        />
                        <div className='toggle-text'>
                            <div className='image'>
                                <ImageContainer 
                                    src='/images/icons/item-realworld.png' 
                                    alt='inventory icon' 
                                    className='label-image' 
                                />
                            </div>
                            <div className='text'>
                                <h3>Real World Item</h3>
                                <span className='meta'>Must be purchased in the Marketplace, and represents a real world item for you to distribute. Players will be able to see their purchase history, but these items will not be kept in their inventory.</span> 
                            </div>
                        </div>
                    </div>
                    <div className="g-space-1"></div>
                    <div className='toggle-with-text'>
                        <ToggleInput 
                            contentKey="opt_itemType" 
                            checked={formData.opt_itemType === 'collectible'} 
                            saveData={saveData}
                            value='collectible' 
                        />
                        <div className='toggle-text'>
                            <div className='image'>
                                <ImageContainer 
                                    src='/images/icons/item-collectionlist.png' 
                                    alt='inventory icon' 
                                    className='label-image' 
                                />
                            </div>
                            <div className='text'>
                                <h3>Collectible Item</h3>
                                <span className='meta'>Can be either earned or purchased in the Marketplace, and will appear in the player's collections. These items can be sold or traded to other players.</span> 
                            </div>
                        </div>
                    </div>
                    <div className="g-space-1"></div>
                    <div className='toggle-with-text'>
                        <ToggleInput 
                            contentKey="opt_itemType" 
                            checked={formData.opt_itemType === 'lottery'} 
                            saveData={saveData}
                            value='lottery' 
                        />
                        <div className='toggle-text'>
                            <div className='image'>
                                <ImageContainer 
                                    src='/images/icons/item-lottery.png' 
                                    alt='inventory icon' 
                                    className='label-image' 
                                />
                            </div>
                            <div className='text'>    
                                <h3>Lottery Ticket</h3>
                                <span className='meta'>Can be either earned or purchased in the Marketplace, and count as one chance of winning a lottery. A winner will be chosen from those who own these types of tickets. These can be sold or traded to other players.</span> 
                            </div>
                        </div>
                    </div>
                    <div className="g-space-1"></div>
                    <div className='toggle-with-text'>
                        <ToggleInput 
                            contentKey="opt_itemType" 
                            checked={formData.opt_itemType === 'contribution'} 
                            saveData={saveData}
                            value='contribution' 
                        />
                        <div className='toggle-text'>
                        <div className='image'>
                                <ImageContainer 
                                    src='/images/icons/item-contribution.png' 
                                    alt='inventory icon' 
                                    className='label-image' 
                                />
                            </div>
                            <div className='text'>   
                                <h3>Player-choice Contribution</h3>
                                <span className='meta'>Purchased in the Marketplace, this is an opportunity for a player to contribute currency to a collection. You can set a goal for the players to achieve. Players will be able to see their purchase history, but these items will not be kept in their inventory.</span> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="g-space-1"></div>
            </div>
        </div>
    )
}

export default ManageItemType