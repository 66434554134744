import React from 'react';
import './player-trade-portal.styles.scss';

const PlayerTradePortal = () => {
    return (
        <div className='player-trade-portal'>
            
        </div>
    )
}

export default PlayerTradePortal