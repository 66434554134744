import React, { useEffect, useRef, useState } from 'react'
import './featured-content.styles.scss'
import placeholder_icon from '@/assets/mystery-challenge.png'
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import Modal, { ModalAlert } from '@/components/modal/modal.component';
import SearchBar from '@/components/SHARED/search-bar/search-bar.component';
import CategoryFilterModal from '../create-elements/components/category-filter-modal.component';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faRandom } from '@fortawesome/free-solid-svg-icons';
import { properCase } from '@/utils/properCase';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { useDispatch, useSelector } from 'react-redux';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';

const FeaturedContent = ({ gameData, gameElements }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ element, setElement ] = useState(null);
    const [ showChoiceModal, setShowChoiceModal ] = useState(false);
    const [ showFilterModal, setShowFilterModal ] = useState(false);
    const [ contentToDisplay, setContentToDisplay ] = useState([]);
    const [ selectedCategories, setSelectedCategories ] = useState([]);
    const [ categoryList, setCategoryList ] = useState([]);
    const [ selectedElement, setSelectedElement ] = useState(null);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const catRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!gameData || !gameElements) return;
        if (gameData.featuredElement) {
            setElement(gameElements[gameData.featuredElement])
        } else {
            setElement(null);
        }
        setCategoryList([...new Set(Object.values(gameElements).filter(c => c.type !== 'secretCode' && c.opt_visible).map(c => properCase(c.type)))])
        setContentToDisplay(Object.values(gameElements).filter(e => e.type !== 'secretCode'))
        if (gameData.featuredElement) {
            setSelectedElement(gameData.featuredElement);
        }
        catRef.current = [];
    }, [gameData, gameElements])

    function chooseCategory(e) {
        let catList = [...catRef.current];
        if (catList.includes(e)) {
            catList.splice(catList.indexOf(e), 1)
        } else {
            catList.push(e)
        }
        catRef.current = catList;
        setSelectedCategories(catRef.current);
        if (catRef.current.length > 0) {
            setContentToDisplay(Object.values(gameElements).filter(a => a.type!== 'secretCode' && catRef.current.includes(properCase(a.type))))
        } else {
            setContentToDisplay(Object.values(gameElements).filter(a => a.type !== 'secretCode'))
        }
        console.log(catList);
    }

    function search(e) {
        if (!e) {
            setContentToDisplay(Object.values(gameElements).filter(a => a.type !== 'secretCode'))
            return;
        }
        setContentToDisplay(Object.values(gameElements).filter(a => a.type !== 'secretCode' && (a.name.toLowerCase().includes(e) || a.desc && a.desc.toLowerCase().includes(e))))
    }

    function cancelAction() {
        setSelectedElement(null);
        setShowChoiceModal(false);
    }

    async function saveChosenElement() {
        setLoading(true);
        let tempData = {
            ...gameData, 
            ...{
                'featuredElement': selectedElement,
                'ts': new Date().getTime(),
            }
        }
        const res = await UseCloudFunction('saveGame', {'gameObj': tempData, 'appId': userProfile.appId, 'action': 'set featured element'})
        console.log(res);
        if (res.error) {
            setAlertMessage(`Something went wrong. Please try again later. (Error: ${res.error})`)
            return;
        }
        setShowChoiceModal(false);
        setLoading(false);
    }

    return (
        <div className='featured-content-container'>
            <div className='featured-element'>
                <b>Featured Element</b>
                {
                    (element)
                    ?
                    <div className='featured-element-content'>
                        <ImageContainer src={element.icon} alt={`${element.name} icon`} className='featured-icon' />
                        <div>{element.name.length > 20 ? `${element.name.substring(0, 20)}...` : element.name}<br /><span className='meta'>{element.type}</span></div>
                    </div>
                    :
                    <div className='featured-element-content'>
                        <img src={placeholder_icon} alt='mystery placeholder icon' className='featured-icon' />
                        <div>No featured element chosen</div>
                    </div>
                }
                <div className='center'>
                    <button className='g-button small-btn' onClick={() => setShowChoiceModal(true)}>Update</button>
                </div>
            </div>
            <Modal show={showChoiceModal} cancel={() => setShowChoiceModal(false)} text='Choose a featured game element' closeButton={true} >
                <div className='modal-children'> 
                    <div className="g-space-0-5"></div>
                    <div className='featured-search'>
                        <SearchBar search={search} />
                        <button type='button' className='g-button med-btn' onClick={() => setShowFilterModal(true)}>
                            Filter by Type
                            <FontAwesomeIcon 
                                icon={faFilter}
                                className={selectedCategories.length > 0 ? 'full' : 'empty'}
                            />
                        </button>
                    </div>
                    <div className="g-space-1"></div>
                    <div className='elements-to-choose'>
                        <div 
                            className={`element-item g-list-item clickable ${!selectedElement ? 'chosen': ''}`}
                            onClick={() => setSelectedElement(null)}
                        >
                            <div className='element-info'>
                                <FontAwesomeIcon icon={faRandom} size='xl' className='element-icon' />
                                <div>Choose a Random Game Element</div>    
                            </div>   
                        </div>
                        {
                            (contentToDisplay.length > 0) &&
                            contentToDisplay.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                            .map(c => (
                                <div key={c.id} className={`element-item g-list-item clickable ${selectedElement === c.id ? 'chosen' : ''}`} onClick={() => setSelectedElement(c.id)}>
                                    <div className='element-info'>
                                        <ImageContainer src={c.icon} alt={`${c.name} icon`} className='element-icon' />
                                        <div>{c.name}</div>
                                    </div>
                                    <div>{properCase(c.type)}</div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="g-space-1"></div>
                {
                    (loading)
                    ?
                    <div className='buttons'>
                        <button className='g-button' >Cancel</button>
                        <button className='g-button' ><ThreeDotsLoader /></button>
                    </div>
                    :
                    <div className='buttons'>
                        <button className='g-button' onClick={() => cancelAction()}>Cancel</button>
                        <button className={`g-button primary`} onClick={() => saveChosenElement()}>Set Element as Featured</button>
                    </div>
                }
            </Modal>
            <CategoryFilterModal 
                show={showFilterModal} 
                cancel={() => setShowFilterModal(false)} 
                chooseCategory={chooseCategory} 
                categoryList={categoryList} 
                selectedCategories={selectedCategories} 
                color='var(--primary)'
            />
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
        </div>
    )
}

export default FeaturedContent