import React, { useEffect, useRef, useState } from 'react'
import './collections-list.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faFilter, faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import SearchBar from '@/components/SHARED/search-bar/search-bar.component';
import CategoryFilterModal from '../components/category-filter-modal.component';
import { format } from 'date-fns';
import { stripHtml } from '@/utils/sanitize';
import { useLocation, useNavigate } from 'react-router-dom';
import VisibilityButton from '../components/visibility-button.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import UpgradeRequiredModal from '@/components/SHARED/upgrade-required-modal/upgrade-required-modal.component';
import { membershipRestrictions, testMemberRestrictions } from '@/utils/membershipRestrictions';
import { useSelector } from 'react-redux';

const CollectionsList = ({ gameElements, gameData }) => {
    
    const membership = useSelector(state => state.userData.membership);
    const [ showFilterModal, setShowFilterModal ] = useState(false);
    const [ contentToDisplay, setContentToDisplay ] = useState([]);
    const [ categoryList, setCategoryList ] = useState([]); 
    const [ selectedCategories, setSelectedCategories ] = useState([]);
    const [ showUpgradeModal, setShowUpgradeModal ] = useState(false);
    const catRef = useRef();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        catRef.current = [];
    }, [])

    useEffect(() => {
        if (!gameElements) {return;}
        setContentToDisplay(Object.values(gameElements).filter(a => a.type === 'collection'));
        setCategoryList([...new Set(Object.values(gameElements).filter(c => c.type === 'collection' && c.opt_cat).map(c => c.opt_cat))])
    }, [gameElements])

    function checkMembership(e) {
        const res = testMemberRestrictions({'gameData':gameData, 'membership':membership, 'create': true});
        if (res.disabled && res.disabledReason.includes('elements')) {
            const planRest = membershipRestrictions[membership.membership];
            const message = `While using the ${planRest.title}, you are only able to have ${planRest.elementCount} active Game Elements per game.`;
            setShowUpgradeModal(message);
            return;
        }
        navigate('create');
    }

    function chooseCategory(e) {
        let catList = [...catRef.current];
        if (catList.includes(e)) {
            catList.splice(catList.indexOf(e), 1)
        } else {
            catList.push(e)
        }
        catRef.current = catList;
        setSelectedCategories(catRef.current);
        if (catRef.current.length > 0) {
            setContentToDisplay(Object.values(gameElements).filter(a => a.type === 'collection' && catRef.current.includes(a.opt_cat)))
        } else {
            setContentToDisplay(Object.values(gameElements).filter(a => a.type === 'collection'))
        }
    }

    function search(e) {
        if (!e) {
            setContentToDisplay(Object.values(gameElements).filter(a => a.type === 'collection'))
            return;
        }
        setContentToDisplay(Object.values(gameElements).filter(a => a.type === 'collection' && (a.name.toLowerCase().includes(e) || a.desc && a.desc.toLowerCase().includes(e))))
    }

    return (
        <div className='collection-list'>
            <div className='collection-list-search'>
                <SearchBar search={search} />
                <button type='button' className='g-button med-btn' onClick={() => setShowFilterModal(true)}>
                    Filter by Category
                    <FontAwesomeIcon 
                        icon={faFilter}
                        className={selectedCategories.length > 0 ? 'full' : 'empty'} 
                    />
                </button>
                <button type='button' className='g-button med-btn' onClick={() => checkMembership()}>Create a New Collection<FontAwesomeIcon icon={faPlus} /></button>
            </div>
            <div className="g-space-2"></div>
            <div className='collection-list-div'>
                <div className='g-list-item collection-list-head row'>
                    <div></div>
                    <div>Name</div>
                    <div>Instructions</div>
                    <div>Created</div>
                    <div>Edited</div>
                    <div></div>
                </div>
                <div className='g-space-0-5'></div>
                <div className='collection-list'>
                    {
                        (contentToDisplay.length > 0)
                        ?
                        contentToDisplay.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                        .map(c => (
                            <div key={c.id} className='g-list-item row'>
                                <ImageContainer src={c.icon} alt={`${c.name} collection icon`} className='collection-icon' />
                                <div className='collection-name'>{c.name}</div>
                                <div className='collection-desc meta'>{c.desc && stripHtml(c.desc).substring(0,60)}{c.desc && stripHtml(c.desc).length > 60 ? '...' : ''}</div>
                                <div className='meta'>{format(new Date(c.ts_created), 'P')}</div>
                                <div className='meta'>{format(new Date(c.ts), 'P')}</div>
                                <div className='buttons'>    
                                <VisibilityButton element={c} />
                                    <button className='g-button med-btn' title='View collection data'><FontAwesomeIcon icon={faChartPie} /></button>
                                    <button className='g-button med-btn' onClick={() => navigate(`edit/${c.path}`)} title='Edit this collection'><FontAwesomeIcon icon={faPen} /></button>
                                </div>
                            </div>
                        ))
                        :
                        <p>No collections yet...</p>
                    }
                </div>
            </div>


            <CategoryFilterModal 
                show={showFilterModal} 
                cancel={() => setShowFilterModal(false)} 
                chooseCategory={chooseCategory} 
                categoryList={categoryList} 
                selectedCategories={selectedCategories} 
                color='var(--collection-color)'
            />
            <UpgradeRequiredModal
                show={showUpgradeModal}
                cancel={() => setShowUpgradeModal(false)}
                closeButton={true}
                message={showUpgradeModal}
            />
        </div>
    )
}

export default CollectionsList