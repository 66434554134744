export function countWords(str) {
        // Remove leading and trailing whitespaces
    str = str.trim();
    
    // Split the string into an array of words using whitespace as delimiter
    var words = str.split(/\s+/);
    
    // Return the number of words
    return words.length;
}

export function limitWords(str, count) {
    // str = str.trim();
    var words = str.split(/\s+/);
    var limitedWords = words.slice(0, count);
  
    // Join the words back into a string
    var result = limitedWords.join(" ");
    
    // Return the first 200 words as a string
    return result;
}