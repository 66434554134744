import React, { useEffect, useRef, useState } from 'react'
import './prereq-form.styles.scss';

import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import SearchBar from '@/components/SHARED/search-bar/search-bar.component';
import ItemSelector from './item-selector.component';


const PrereqForm = ({ type, formData, saveData, gameData, gameElements, availableItems, teams, disabled }) => {

    const [ levels, setLevels ] = useState([]);
    const [ badges, setBadges ] = useState([]);
    const [ selectedBadges, setSelectedBadges ] = useState([]);
    const badgesRef = useRef();
    const [ items, setItems ] = useState([]);
    const [ selectedItems, setSelectedItems ] = useState([]);
    const itemsRef = useRef();
    const [ teamsToDisplay, setTeamsToDisplay ] = useState([]);
    const [ selectedTeams, setSelectedTeams ] = useState([]);
    const teamsRef = useRef();

    useEffect(() => {
        if (!gameElements) {return;}
        setLevels(Object.values(gameElements).filter(c => c.type === 'level' && c.status === 'active'));
        setBadges(Object.values(gameElements).filter(c => c.type === 'badge' && c.status === 'active'));
        badgesRef.current = [];
        setItems(Object.values(gameElements).filter(c => c.type === 'item' && c.status === 'active'));
        itemsRef.current = [];
    }, [gameElements])

    useEffect(() => {
        if (!teams) return;
        setTeamsToDisplay([...teams]);
        teamsRef.current = [];
    }, [teams])

    useEffect(() => {
        if (!formData) {return;}
        if (formData.opt_prereqBadges && formData.opt_prereqBadges.length > 0) {
            badgesRef.current = [...formData.opt_prereqBadges]   
            setSelectedBadges(badgesRef.current);
        }
        if (formData.opt_prereqItems && formData.opt_prereqItems.length > 0) {
            itemsRef.current = [...formData.opt_prereqItems];
            setSelectedItems(itemsRef.current);
        }
        if (formData.opt_prereqTeams && formData.opt_prereqTeams.length > 0) {
            teamsRef.current = [...formData.opt_prereqTeams];
            setSelectedTeams(teamsRef.current);
        }
    }, [formData])

    useEffect(() => {
        if (disabled) {
            saveData('opt_prereqPoints', null);
            saveData('opt_prereqLevel', null);
            badgesRef.current = [];
            setSelectedBadges([...badgesRef.current]);
            saveData('opt_prereqBadges', badgesRef.current);
            itemsRef.current = [];
            setSelectedItems([...itemsRef.current]);
            saveData('opt_prereqItems', itemsRef.current);
            teamsRef.current = [];
            setSelectedTeams([...teamsRef.current]);
            saveData('opt_prereqTeams', teamsRef.current);
        }
    },  [disabled])

    function search(text, id) {
        if (!text && id) {
            if (id === "badge") {
                setBadges(Object.values(gameElements).filter(c => c.type === 'badge' && c.status === 'active'));
            } else if (id === 'item') {
                setItems(Object.values(gameElements).filter(c => c.type === 'item' && c.status === 'active'));
            } else if (id === 'team') {
                setTeamsToDisplay([...teams])
            }
        } else if (id === 'team') {
            const filteredTeams = Object.values(teams)
            .filter(t => t.name.toLowerCase().includes(text.toLowerCase()))
            setTeamsToDisplay(filteredTeams);
        } else {
            const filteredElements = Object.values(gameElements)
            .filter(
                c => c.type === id && 
                c.status === 'active' && 
                (
                    c.name.toLowerCase().includes(text.toLowerCase()) ||
                    (
                        c.desc &&
                        c.desc.toLowerCase().includes(text.toLowerCase())
                    )
                )
            )
            if (id === 'badge') {
                setBadges(filteredElements);
            } else if (id === 'item') {
                setItems(filteredElements);
            }
        }
    }

    function chooseMultipleOption(id, category) {
        let tempList;
        if (category === 'badges') {
            tempList = [...badgesRef.current];
            if (tempList.includes(id)) {
                tempList.splice(tempList.indexOf(id), 1);   
            } else {
                tempList.push(id);
            }
            badgesRef.current = tempList;
            setSelectedBadges(badgesRef.current);
            saveData('opt_prereqBadges', badgesRef.current);
        }
        else if (category === 'items') {
            tempList = [...itemsRef.current];
            if (tempList.includes(id)) {
                tempList.splice(tempList.indexOf(id), 1);   
            } else {
                tempList.push(id);
            }
            itemsRef.current = tempList;
            setSelectedItems(itemsRef.current);
            saveData('opt_prereqItems', itemsRef.current);
        }
        else if (category === 'teams') {
            tempList = [...teamsRef.current];
            if (tempList.includes(id)) {
                tempList.splice(tempList.indexOf(id), 1);
                console.log('removing team')   
            } else {
                tempList.push(id);
                console.log('adding team')
            }
            teamsRef.current = [...tempList];
            setSelectedTeams(teamsRef.current);
            saveData('opt_prereqTeams', teamsRef.current);
        }
    }

    return (
        <div className='prereq-form'>
            <div className={`field small ${disabled ? 'disabled' : ''}`}>
                <label>How much XP should a player earn and/or what Level should a player achieve before being allowed to access this {type}?</label>
                <div className='g-double-col'>
                    
                    <input 
                        type='number' 
                        className='' 
                        placeholder='Min Points' 
                        value={formData.opt_prereqPoints ?? ''} 
                        onChange={(e) => saveData('opt_prereqPoints', Number(e.target.value))} 
                    />
                    <select value={formData.opt_prereqLevel ?? ''} onChange={(e) => saveData('opt_prereqLevel', e.target.value)} required>
                        <option value=''>Choose a Level...</option>
                        {
                            (levels) &&
                            levels.sort((a,b) => a.opt_pointMinimum > b.opt_pointMinimum ? 1 : -1)
                            .map(l => (
                                <option key={l.id} value={l.id}>{l.name}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
            <div className="g-space-2"></div>
            <div className={`field small ${disabled ? 'disabled' : ''}`}>
                <label>What Badge(s) should a player earn before being allowed to access this {type}?</label>
                <div className='g-list-item multiple-select'>
                    <SearchBar search={search} id="badge" />
                    <div className='select-list'>
                    {
                        (badges.length > 0) 
                        ?
                        badges.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                        .map(b => (
                            <div key={b.id} className={`multiple-select-option ${selectedBadges.includes(b.id) ? 'active' : ''}`} onClick={() => chooseMultipleOption(b.id, 'badges')}>
                                <ImageContainer src={b.icon} alt={`${b.name} badge icon`} />
                                {b.name}
                            </div>
                        ))
                        :
                        <span>No Badges...</span>
                    }
                    </div>
                </div>
            </div>
            {/* <div className="g-space-2"></div>
            <div className={`field small ${disabled ? 'disabled' : ''}`}>
                <label>What Item(s) should a player earn before being allowed to access this {type}?</label>
                <div className='g-list-item multiple-select'>
                    <SearchBar search={search} id="item" />
                    <div className='select-list'>
                    {
                        (items.length > 0) 
                        ?
                        items.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                        .map(i => (
                            <div key={i.id} className={`multiple-select-option ${selectedItems.includes(i.id) ? 'active' : ''}`} onClick={() => chooseMultipleOption(i.id, 'items')}>
                                <ImageContainer src={i.icon} alt={`${i.name} item icon`} />
                                {i.name}
                            </div>
                        ))
                        :
                        <span>No Items...</span>
                    }
                    </div>
                </div>
            </div> */}
            <div className="g-space-2"></div>
            <div className={disabled ? 'disabled' : ''}>
                <ItemSelector 
                    type='prereq'
                    formData={formData}
                    saveData={saveData}
                    gameElements={availableItems}
                    gameData={gameData}
                    inst={`What Inventory Item(s) should a player own before being allowed to access this ${type}`}
                    saveKey='opt_prereqItems'
                />
            </div>
            <div className="g-space-2"></div>
            <div className={`field small ${disabled ? 'disabled' : ''}`}>
                <label>Of what Team(s) should a player be a member before being allowed to access this {type}?</label>
                <div className='g-list-item multiple-select'>
                    <SearchBar search={search} id="team" />
                    <div className='select-list'>
                    {
                        (teamsToDisplay.length > 0) 
                        ?
                        teamsToDisplay.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                        .map(t => (
                            <div 
                                key={t.playerId} 
                                className={`multiple-select-option ${selectedTeams.includes(t.playerId) ? 'active' : ''}`} 
                                onClick={() => chooseMultipleOption(t.playerId, 'teams')}
                            >
                                <ImageContainer src={t.icon} alt={`${t.name} team icon`} />
                                {t.name}
                            </div>
                        ))
                        :
                        <span>No Teams Yet...</span>
                    }
                    </div>
                </div>
            </div>
            <div className="g-space-1"></div>
        </div>
    )
}

export default PrereqForm;