import React from 'react';
import './unauth-head.styles.scss';
import { Link, useNavigate } from 'react-router-dom';
import logo_long from '@/assets/logo_long.png';
import { useSelector } from 'react-redux';

const UnauthHeader = () => {

    const currentUser = useSelector(state => state.userData.currentUser);
    const navigate = useNavigate();

    return (
        <div className='unauth-head-container'>
            <div className="g-container">
                <div className='unauth-head'>
                    <div className='logo-div'>
                        <Link to='/'><img src={logo_long} /></Link>
                    </div>
                    <div className="links-div">
                        <Link to="/features" className='head-link'>Features</Link>
                        <Link to="/pricing" className='head-link'>Pricing</Link>
                        {
                            (currentUser) 
                            ?
                            <Link to="/loading" className='head-link'>My Games</Link>
                            :
                            <Link to="/signin"><button className='g-button small-btn primary' >Sign In</button></Link>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UnauthHeader