import React from 'react'
import './badge-purpose.styles.scss';
import { faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ManageBadgePurpose = ({ formData, saveData }) => {

    function changeToggle(e) {
        if (e) {
            saveData('opt_purposeGroup', true);
        } else {
            saveData('opt_purposeGroup', false);
        }
    }

    return (
        <div className='manage-content-badge-purpose g-list-item form-section'>
            <h3>Purpose</h3>
            <hr />
            <div className='field'>
                <label>Assign this Badge to individual players or groups.</label>
            </div>
            <div className="g-space-1"></div>
            <div className='user-group-toggle'>
                <FontAwesomeIcon className={`icon ${!formData.opt_purposeGroup ? 'selected' : ''}`} icon={faUser} size="2xl" />
                <div className='toggle-input'>
                    <input className="toggle" type="checkbox" checked={formData.opt_purposeGroup ?? false} onChange={(e) => changeToggle(e.target.checked)} />
                </div>
                <FontAwesomeIcon className={`icon group ${formData.opt_purposeGroup ? 'selected' : ''}`} icon={faUsers} size="2xl" />
            </div>
            <div className='g-space-1'></div>
        </div>
    )
}

export default ManageBadgePurpose