import React from 'react'
import './found-code-item.styles.scss';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import HiddenText from '@/components/SHARED/hidden-text/hidden-text.component';
import { format } from 'date-fns';

const FoundCodeItem = ({ codeInfo }) => {
    return (
        <div className='found-code-item g-list-item'>
            <ImageContainer src={codeInfo.icon} alt='found code item icon' className='found-code-icon' />
            <HiddenText text={codeInfo.title} hidden={true} />
            <div>{codeInfo.info}</div>
            <div>{format(new Date(codeInfo.ts), 'Pp')}</div>
        </div>
    )
}

export default FoundCodeItem