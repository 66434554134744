import React, { useEffect, useRef, useState } from 'react'
import './player-list-container.styles.scss';

import { Route, Routes, useNavigate } from 'react-router-dom';
import ManagerPlayerView from './player-view.component';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import xp_icon from '@/assets/icons/xp.png';
import coin_icon from '@/assets/icons/coin.png';
import coins_icon from '@/assets/icons/coins.png';
import AddPlayer from './add-player.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { createAlias } from '@/utils/aliases';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { useSelector } from 'react-redux';
import { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import { sortPlayers } from '@/utils/sortPlayers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFilterCircleXmark, faPrint } from '@fortawesome/free-solid-svg-icons';
import { membershipRestrictions, testMemberRestrictions } from '@/utils/membershipRestrictions';
import UpgradeRequiredModal from '@/components/SHARED/upgrade-required-modal/upgrade-required-modal.component';

const PlayerListContainer = ({ 
    gameData, 
    gameElements, 
    playerList, 
    gameActivity, 
    gameStats,
    passcodes 
}) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const membership = useSelector(state => state.userData.membership);
    const [ sortedPlayers, setSortedPlayers] = useState(null);
    const [ showAliases, setShowAliases ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ viewHeight, setViewHeight ] = useState('0px');
    const [ showPlayerInfo, setShowPlayerInfo ] = useState(false);
    const [ currentSort, setCurrentSort ] = useState(null);
    const [ currentFilter, setCurrentFilter ] = useState(null);
    const [ showUpgradeModal, setShowUpgradeModal ] = useState(false);
    const [ passcodePlayers, setPasscodePlayers ] = useState([]);
    const [ playerCount, setPlayerCount ] = useState(0);
    const approveRef = useRef();
    const navigate = useNavigate();
    let timeout;

    useEffect(() => {
        timeout = setTimeout(() => {
            const chartContainer = document.getElementsByClassName('chart-card');
            if (chartContainer.length > 0) {
                setViewHeight(`${chartContainer[0].clientHeight - 198}px`)
                // console.log(chartContainer[0].clientHeight);
            }
        }, 500);

        return () => {
            if (timeout) clearTimeout(timeout);
        }
    }, [location])

    useEffect(() => {
        if (!playerList) {return;}
        setPasscodePlayers(Object.values(playerList).filter(p => p.enroll_method === 'upload'));
        // console.log(playerList);
        // console.log(currentSort);
        // console.log(currentFilter);
        sortTable()
        const sortedPlayers = sortPlayers({
            'playerList': playerList,
            'sortBy': 'name',
            'filter': 'players'
        })
        setPlayerCount(sortedPlayers.length);
    }, [playerList, currentFilter, currentSort, showAliases])

    function checkMembership(e) {
        const res = testMemberRestrictions({'gameData':gameData, 'membership':membership, 'create': true});
        // console.log(res);
        if (res.disabled && res.disabledReason.includes('players')) {
            const planRest = membershipRestrictions[membership.membership];
            const message = `While using the ${planRest.title}, you are only able to have ${planRest.playerCount} active Players/Teams per game.`;
            setShowUpgradeModal(message);
            return;
        }
        navigate('add');
    }

    function sortTable() {
        const sortBy = currentSort ? currentSort : showAliases ? 'alias' : 'name';
        let playersToDisplay;
        if (currentFilter) {
            const playerArr = Object.values(playerList).filter(p => p.info === currentFilter);
            playersToDisplay = sortPlayers({
                'playerList': playerArr, 
                'sortBy': sortBy,
                'filter': 'players'
            });
        } else {
            playersToDisplay = sortPlayers({
                'playerList': playerList, 
                'sortBy': sortBy,
                'filter': 'players'
            });
        }
        setSortedPlayers([...playersToDisplay]);        
    }

    function clearFilter() {
        setCurrentFilter(null);
    }

    function printLogins() {
        window.open(`/printlogins/${gameData.path}`, '_new')
    }

    async function newAliases() {
        setConfirmMessage(`Are you sure you want to change all of the players' Aliases? This action cannot be undone.`)
        approveRef.current = () => {
            continueNewAliases();
        }

        async function continueNewAliases() {
            let newObj = {}
            for (let p of Object.values(playerList).filter(p => !p.group)) {
                newObj[p.playerId] = {
                    "playerId": p.playerId,
                    "alias": createAlias(),
                }
            }
            console.log(newObj);
            const res = await UseCloudFunction('savePlayerData', {'appId':userProfile.appId, 'gameId': gameData.gameId, 'dataList': newObj})
            console.log(res);
            if (res.error) {
                setAlertMessage(`Something went wrong. Try again later. (Error: ${res.error})`)
                return;
            }
            setAlertMessage('Aliases have been changed for all players.');
        }
    }

    function cancel() {
        navigate(`/manage/${gameData.path}`);
    }

    return (
        <div className='player-list-container'>
            <Routes>
                
                <Route path='' element={
                    (gameData) &&
                    <div className='g-card chart-card'>    
                        <BackButton cancel={cancel} />
                        <div className="g-space-1"></div>
                        
                        <div className='card-title'>
                            View Players
                            <div className='button-row'>
                                {
                                    (currentFilter) &&
                                    <button 
                                        className='g-button med-btn' 
                                        title='Clear Player Info Filter' 
                                        onClick={() => clearFilter()}
                                    >
                                        <FontAwesomeIcon icon={faFilterCircleXmark} />
                                    </button>
                                }
                                <button 
                                    className='g-button med-btn' 
                                    onClick={() => checkMembership()}
                                >
                                    Add New Players
                                </button>
                                <button 
                                    className='g-button med-btn' 
                                    onClick={() => setShowPlayerInfo(!showPlayerInfo)}
                                >
                                    {
                                        showPlayerInfo ? 
                                        'Hide Player Info' : 
                                        'Show Player Info'
                                    }
                                </button>
                                <button 
                                    className='g-button med-btn' 
                                    onClick={() => setShowAliases(!showAliases)}
                                >
                                    {showAliases ? 'Show Real Names' : 'Show Aliases'}
                                </button>
                                <button 
                                    className="g-button med-btn" 
                                    onClick={() => newAliases()}
                                >
                                    Change Aliases
                                </button>
                                { 
                                    (passcodePlayers.length > 0) &&
                                    <button 
                                        className="g-button med-btn" 
                                        title='Print Login Information'
                                        onClick={() => printLogins()}
                                    >
                                        <FontAwesomeIcon icon={faPrint} />
                                    </button>
                                }
                                {/* <button 
                                    className="g-button med-btn" 
                                    title='Download Current Stats'
                                >
                                    <FontAwesomeIcon icon={faDownload} />
                                </button> */}
                            </div>
                        </div>
                        <div className="g-space-1"></div>
                        <hr className='no-margin'/>
                        <p className='meta'>Player Count: {playerCount}/{membershipRestrictions[membership.membership].playerCount.toLocaleString()}</p>
                        <div className='chart-grid'>
                            <div className='chart-row'>
                                {/* <div className='chart-row'> */}
                                    <div className='number-div'>#</div>
                                    <button className='g-button text-only name-div' onClick={() => setCurrentSort(null)}>Name</button>
                                {/* </div> */}
                                {
                                    (showPlayerInfo) &&
                                    <div className='info-div' >
                                        <button className='g-button text-only name-div' onClick={() => setCurrentSort('playerInfo')}>
                                            Player Info
                                        </button>
                                    </div>
                                }
                                {
                                    (gameData.access.includes('points')) &&
                                    <button className='g-button text-only chart-button' onClick={() => setCurrentSort('points')} title='Total XP'>
                                        <img src={xp_icon} className='icon' />
                                    </button>
                                }
                                {
                                    (gameData.access.includes('levels')) &&
                                    <button className='g-button text-only chart-button' onClick={() => setCurrentSort('points')} title='Level'>
                                        <ImageContainer src='/images/icons/rankings.png' className='icon' alt='levels icon' />
                                    </button>
                                }
                                {
                                    (gameData.access.includes('currency')) &&
                                    <button className='g-button text-only chart-button' onClick={() => setCurrentSort('currencyTotal')} title='Total Currency Earned'>
                                        <img src={coins_icon} className='icon' />
                                    </button>
                                }
                                {
                                    (gameData.access.includes('currency')) &&
                                    <button className='g-button text-only chart-button' onClick={() => setCurrentSort('currency')} title='Current Currency Balance'>
                                        <img src={coin_icon} className='icon' />
                                    </button>
                                }
                                {
                                    (gameData.access.includes('badges')) &&
                                    <button className='g-button text-only chart-button' onClick={() => setCurrentSort('badges')} title='Badges'>
                                        <ImageContainer src='/images/icons/badges.png' className='icon' alt='badges icon' />
                                    </button>
                                }
                                {
                                    (gameData.access.includes('items')) &&
                                    <button className='g-button text-only chart-button' onClick={() => setCurrentSort('items')} title='Items'>
                                        <ImageContainer src='/images/icons/items.png' className='icon' alt='items icon' />
                                    </button>
                                }
                                {
                                    (gameData.access.includes('secretCodes')) &&
                                    <button className='g-button text-only chart-button' onClick={() => setCurrentSort('codes')} title='Codes Found'>    
                                        <ImageContainer src='/images/icons/secret2.png' className='icon' alt='secret codes icon' />
                                    </button>                        
                                }
                                {
                                    (gameData.access.includes('challenges')) &&
                                    <button className='g-button text-only chart-button' onClick={() => setCurrentSort('challenges')} title='Challenges Completed'>
                                        <ImageContainer src='/images/icons/challenge.png' className='icon' alt='challenges icon' />
                                    </button>
                                }
                                {
                                    (gameData.access.includes('quests')) &&       
                                    <button className='g-button text-only chart-button' onClick={() => setCurrentSort('quests')} title='Quests Completed'>
                                        <ImageContainer src='/images/icons/quests.png' className='icon' alt='quests icon' />
                                    </button>
                                }
                            </div>
                            <div className='chart-bottom-grid' style={{'height': viewHeight}}>
                            {
                                (sortedPlayers) &&
                                sortedPlayers.map((player, index) => {
                                    let playerLevel;
                                    if (player.levels) {
                                        const lastLevel = Object.values(player.levels).sort((a,b) => a.ts < b.ts ? 1 : -1)[0]
                                        playerLevel = gameElements[lastLevel.id];
                                    }
                                    return (
                                    <div key={player.playerId} className='chart-row' id={`name-row-${player.playerId}`}>
                                        <div className='number-div'>{index + 1}</div>
                                        <button 
                                            className='g-button text-only name-div' 
                                            onClick={() => navigate(player.path)}
                                        >
                                        {
                                                showAliases
                                                ? 
                                                player.alias
                                                : 
                                                `${
                                                    player.lName
                                                    ? 
                                                    `${player.lName}, `
                                                    :
                                                    ''
                                                }${player.fName}`
                                            }
                                        </button>
                                        {
                                            (showPlayerInfo) &&
                                            <button 
                                                className='g-button text-only name-div'
                                                onClick={() => setCurrentFilter(player.info)}
                                            >
                                                {player.info}
                                            </button>
                                        }
                                    
                                        {
                                            (gameData.access.includes('points')) &&
                                            <button className='g-button text-only chart-button' onClick={() => navigate(`${player.path}/myxpearnings`)}>
                                                {player.points ? player.points.toLocaleString() : '0'}
                                            </button>
                                        }
                                        {
                                            (gameData.access.includes('levels')) &&
                                            <button 
                                                className='g-button text-only chart-button' 
                                                title={playerLevel ? playerLevel.name : ''}
                                                onClick={() => navigate(`${player.path}/mylevels`)}
                                            >
                                                {
                                                    playerLevel ? 
                                                    <ImageContainer 
                                                        src={playerLevel.icon} 
                                                        className='level-icon' 
                                                    /> :
                                                    <span className='no-level'>No level</span>
                                                }
                                            </button>
                                        }
                                        {
                                            (gameData.access.includes('currency')) &&
                                            <button className='g-button text-only chart-button'  onClick={() => navigate(`${player.path}/mycurrency`)}>
                                                {player.currencyTotal ? player.currencyTotal.toLocaleString() : '0'}
                                            </button>
                                        }
                                        {
                                            (gameData.access.includes('currency')) &&
                                            <button className='g-button text-only chart-button' onClick={() => navigate(`${player.path}/mycurrency`)}>
                                                {player.currency ? player.currency.toLocaleString() : '0'}
                                            </button>
                                        }
                                        {
                                            (gameData.access.includes('badges')) &&
                                            <button className='g-button text-only chart-button' onClick={() => navigate(`${player.path}/mybadges`)}>
                                                {player.badges ? Object.keys(player.badges).length : '0'}
                                            </button>
                                        }
                                        {
                                            (gameData.access.includes('items')) &&
                                            <button className='g-button text-only chart-button' onClick={() => navigate(`${player.path}/inventory`)}>
                                                {player.items ? Object.values(player.items).reduce((partialSum, a) => partialSum + a.count, 0) : '0'}
                                            </button>
                                        }
                                        {
                                            (gameData.access.includes('secretCodes')) &&
                                            <button className='g-button text-only chart-button' onClick={() => navigate(`${player.path}/mysecretcodes`)}>
                                                {player.codes ? player.codes.length : '0'}
                                            </button>
                                        }
                                        {
                                            (gameData.access.includes('challenges')) &&
                                            <button className='g-button text-only chart-button' onClick={() => navigate(`${player.path}/mychallenges`)}>
                                                {player.challenges ? Object.values(player.challenges).filter(ch => ch.status === 'approved').length : '0'}
                                            </button>
                                        }
                                        {
                                            (gameData.access.includes('quests')) &&
                                            <button className='g-button text-only chart-button'>
                                                {(player.quests) ? Object.keys(player.quests).length : 0}
                                            </button>
                                        }
                                    </div>
                                )})
                            }
                            </div>
                        </div>
                        
                       
                    </div>
                    }
                ></Route>
                <Route 
                    path=':playerPath/*' 
                    element={
                        <ManagerPlayerView 
                            gameData={gameData} 
                            playerList={playerList} 
                            gameElements={gameElements} 
                            gameActivity={gameActivity} 
                            setMainAlert={setAlertMessage} 
                            gameStats={gameStats}
                            passcodes={passcodes}
                        />
                    }
                ></Route>   
                <Route path='add' element={<AddPlayer gameData={gameData} />}></Route>
            </Routes>
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
            <ModalConfirm show={confirmMessage} cancel={() => setConfirmMessage(null)} message={confirmMessage} onApprove={approveRef.current} />
            <UpgradeRequiredModal
                show={showUpgradeModal}
                cancel={() => setShowUpgradeModal(false)}
                closeButton={true}
                message={showUpgradeModal}
            />
        </div>
    )
}

export default PlayerListContainer