import React, { useState } from 'react';
import './layout.styles.scss';

import { useLocation } from 'react-router-dom';
import HeadBar from './head-bar/head-bar.component';
import SidebarLeftContainer from './sidebar-left/sidebar-container.component';
import { Desktop, LargeTablet, Mobile, Tablet } from '@/utils/responsiveQueries';
import HeaderMobile from './head-bar/header-mobile.component';

const Layout = ({ children, orgId, setShowBugModal }) => {

    const location = useLocation();

    return (
        <div className='layout-container'>
            <Desktop>
                <HeadBar />
                <div className={`desktop-container ${location.pathname.includes('joingame') ? 'no-left-col' : ''}`}>
                    <div className='sidebar'>
                        {
                            (location.pathname.includes('/manage')) 
                            ?
                            <SidebarLeftContainer orgId={orgId} setShowBugModal={setShowBugModal} />
                            :
                            (!location.pathname.includes('joingame'))
                            ?
                            <SidebarLeftContainer setShowBugModal={setShowBugModal} />
                            :
                            null
                        }
                    </div>                
                    <div className='desktop-content-container'>
                        <div id='scroll-point'></div>
                        {children}
                        <div className="g-space-20"></div>
                    </div>
                </div>
            </Desktop>

            <LargeTablet>
                <HeaderMobile />
                <div className='mobile-content-container  scrolling-container'>
                    {children}
                </div>
            </LargeTablet>

            <Tablet>
                <HeaderMobile />
                <div className='mobile-content-container  scrolling-container'>
                    {children}
                </div>
            </Tablet>

            <Mobile>
                <HeaderMobile />
                <div className='mobile-content-container'>
                    {children}
                </div>
            </Mobile>
            
        </div>
    )
}

export default Layout