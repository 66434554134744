import React, { useState, useEffect } from 'react'

import treasure from '@/assets/treasure.png';
import podium from '@/assets/podium.png';
import full_game from '@/assets/full_game.png';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Modal from '@/components/modal/modal.component';
import UpgradeRequiredModal from '@/components/SHARED/upgrade-required-modal/upgrade-required-modal.component';

const CreateEditForm3 = ({ edit, formData, saveData, verifyAndMoveOn, cancel, validJoinCode }) => {

    const membership = useSelector(state => state.userData.membership);
    const [ req ] = useState(['type']);
    const [ showUpgradeModal, setShowUpgradeModal ] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!formData.gameName) {
            navigate('/home/creategame');
        }
    }, [formData])

    function chooseType(e) {
        if (e === 'full' && membership.membership === 0) {
            
            return;
        }        
        saveData('type', e);
        if (e === 'points') {
            saveData(
                'access', 
                [
                    'points', 
                    'leaderboards', 
                    'levels', 
                    'badges', 
                    'challenges', 
                    'secretCodes', 
                    'teams'
                ]
            );
            saveData('sortLeaders', 'points');
        } else if (e === 'currency') {
            saveData(
                'access', 
                [
                    'currency', 
                    'marketplace', 
                    'items', 
                    'challenges', 
                    'secretCodes', 
                    'teams', 
                    'badges'
                ]
            )
            saveData('sortLeaders', 'currency');
        } else if (e === 'full') {
            saveData(
                'access', 
                [
                    'points', 
                    'leaderboards', 
                    'levels', 
                    'badges', 
                    'challenges', 
                    'secretCodes', 
                    'currency', 
                    'marketplace', 
                    'items'
                ]
            )
            saveData('sortLeaders', 'points');
        }
    }

    return (
        <div className='new-game-form' id='new-game-form'>
            <h3>Choose What Game Mechanics You Want to Use</h3>
            <div className="g-space-1"></div>
            <div className={`g-card game-type-card ${formData.type === 'currency' ? 'active': ''}`} onClick={() => chooseType('currency')}>
                <div className='image-grid'>
                    <img src={treasure} />
                    
                </div>
                <div className='explanation-grid'>
                    <h4>Currency, Items, and a Store</h4>
                    <p>Players earn currency by finding secret codes and completing challenges. They can then spend that currency on items in the store and try to complete collections!</p>
                </div>
            </div>
            <div className="g-space-20"></div>
            <div className={`g-card game-type-card ${formData.type === 'points' ? 'active': ''}`} onClick={() => chooseType('points')}>
                <div className='image-grid'>
                    <img src={podium} />
                    
                </div>
                <div className='explanation-grid'>
                    <h4>Points and Leaderboards</h4>
                    <p>Players earn Experience Points by finding secret codes and completing challenges. Their progress is tracked on a leaderboard and they can earn badges and level up!</p>
                </div>
            </div>
            <div className="g-space-20"></div>
            <div 
                className={`g-card game-type-card ${
                    formData.type === 'full' ? 
                    'active' : 
                    ''
                }`} 
                onClick={() => 
                    membership.membership < 1 ? 
                    setShowUpgradeModal('Sorry! You must upgrade your account to be able to take advantage of the full range of game elements in the platform!') : 
                    chooseType('full')
                }
            >
                <div className='image-grid'>
                    <img src={full_game} />
                    
                </div>
                <div className={`explanation-grid`}>
                    <h4>Full Featured Game!</h4>
                    <p>Use any combination of the above games along with premium features like Quests, Prize Packs, and more!</p>
                </div>
            </div>
            <div className="g-space-20"></div>
            <div className='buttons'>
                <button className='g-button' onClick={() => navigate(-1)}>Back</button>
                <button className='g-button' onClick={() => cancel()}>Cancel</button>
                <button className='g-button primary' onClick={() => verifyAndMoveOn(req, 'part4')}>Next</button>
            </div>
            <UpgradeRequiredModal 
                show={showUpgradeModal} 
                cancel={() => setShowUpgradeModal(false)} 
                closeButton={true} 
                message={showUpgradeModal} 
                newTab={true}
            />
        </div>
    )
}

export default CreateEditForm3