import React, { useEffect } from 'react'
import './full-page-loader.styles.scss';
import Loader from './loader.comp';


const FullPageLoader = ({ show, cancel, text }) => {
    const showHideClassName = show ? "full-page-loader display-block" : "full-page-loader display-none";

    useEffect(() => {
        // console.log(show)
		if (show) {
		    document.body.style.overflow = 'hidden'; 
		} else {
			document.body.style.overflow = 'unset';
		}
	},[show]) 

    return (
        <div className={showHideClassName}>
            {/* <div className='g-list-item'> */}
                <Loader />
            {/* </div> */}
            {
                (text) &&
                <div className='center-col text'>
                    <div className='g-space-2'></div>
                    <div>{text}</div>
                </div>
            }
        </div>
    )
}

export default FullPageLoader