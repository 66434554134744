import React, { useEffect, useState } from 'react';
import './header-mobile.styles.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faChartLine, faClock, faCommentDots, faFolderTree, faGear, faHandsBubbles, faHome, faInfoCircle, faListCheck, faMountainCity, faUser, faUsers, faX } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import SidebarLeftContainer from '../sidebar-left/sidebar-container.component';
import logo_long from '@/assets/logo_long.png';
import icon from '@/assets/icon.png';

// import SidebarLeft from 'components/sidebar-left/sidebar-left.component';

const HeaderMobile = ({ isMobileDevice }) => {
    
    const screenSize = useSelector(state => state.contentData.screenSize);
    const [menuOpen, setMenuOpen] = useState(false);
    const profile = useSelector(state => state.userData.userProfile)
    const user = useSelector(state => state.userData.currentUser)
    const location = useLocation();
    
    const navigate = useNavigate();
    
    useEffect(() => {
        // document.getElementById('slide-menu').classList.toggle('show');
    }, [menuOpen])

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const menuItems = [
        {
            'path': '',
            'label': 'My List'
        }
    ]

    return (
        <div className="mobile-header">
            <div className={`mobile-header__logo ${screenSize}`}>
                <img src={logo_long} alt="Logo" />
            </div>
            <div className="mobile-header__menu">
                {
                    (location.pathname.includes('/manage')) &&
                    <button className="mobile-header__menu-button" id="slide-menu" onClick={toggleMenu}>
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                }
                <div className={`mobile-header__menu-items ${menuOpen ? "show": ""}`}>
                    <div className='close-button' onClick={() => setMenuOpen(!menuOpen)}><FontAwesomeIcon icon={faX} size='xl' /></div>
                    {
                        location.pathname.includes('manage') &&
                        <SidebarLeftContainer closeSlider={() => setMenuOpen(!menuOpen)} />
                    }
                    {
                        location.pathname.includes('coach') &&
                        <SidebarLeftContainer closeSlider={() => setMenuOpen(!menuOpen)} />
                    }
                    
                </div>
                
            </div>

            <div className='mobile-bottom-menu'>
                <FontAwesomeIcon className='event-menu-item menu-assignments' icon={faListCheck} size='xl' onClick={() => navigate('')} />
                <FontAwesomeIcon className='event-menu-item menu-staff' icon={faUser} size='xl' onClick={() => navigate('staff')} />
                {/* <FontAwesomeIcon className='event-menu-item menu-groups' icon={faUsers} size='lg' onClick={() => navigate('groups')} /> */}
                <FontAwesomeIcon className='event-menu-item menu-locations' icon={faMountainCity} size='xl' onClick={() => navigate('locations')} />
                <FontAwesomeIcon className='event-menu-item menu-timeslots' icon={faClock} size='xl' onClick={() => navigate('timeslots')} />
                <FontAwesomeIcon className='event-menu-item menu-categories' icon={faFolderTree} size='xl' onClick={() => navigate('categories')} />
                <FontAwesomeIcon className='event-menu-item menu-information' icon={faInfoCircle} size='xl' onClick={() => navigate('information')} />
            </div>
            
        </div>
    );
};

export default HeaderMobile;