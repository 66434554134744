import React from 'react'
import './rewards-card.styles.scss';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';

const RewardsCard = ({ reward }) => {
    return (
        <div className='rewards-card g-list-item'>
            <ImageContainer src={reward.icon} className='rewards-card-icon' />
            {reward.title}
        </div>
    )
}

export default RewardsCard