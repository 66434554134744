import React from 'react'
import './layered-icons.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const LayeredIcons = ({ icon1, icon1Color, icon1Size, icon2, icon2Color, icon2Size, location }) => {
  return (
    <div className='layered-icons'>
        <FontAwesomeIcon 
            icon={icon1} 
            style={icon1Color ? {'color': icon1Color} : ''} 
            size={icon1Size}
        />
        <div className={`layered-icon ${location}`}>
            <FontAwesomeIcon 
                icon={icon2} 
                style={icon2Color ? {'color': icon2Color} : ''}  
                size={icon2Size} 
            />
        </div>
    </div>
  )
}

export default LayeredIcons