import React from 'react'
import './back-button.styles.scss';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const BackButton = ({ cancel }) => {
    return (
        <div className='back-button'>
            <button className='g-button text-only' onClick={() => cancel()}><FontAwesomeIcon icon={faArrowLeft} />Back</button>
        </div>
    )
}

export default BackButton