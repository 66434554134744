import React, { useEffect, useState } from 'react';
import './leaders-list.styles.scss';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { useNavigate } from 'react-router-dom';
import { sortPlayers } from '@/utils/sortPlayers';

const LeadersList = ({ gameData, playerList }) => {
    
    const [ playersToDisplay, setPlayersToDisplay ] = useState(null);
    const [ containerHeight, setContainerHeight ] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const height = document.getElementById('leader-card').offsetHeight;
        setContainerHeight(height - 84);
    }, [])

    useEffect(() => {
        if (!gameData || !playerList) return;
        let sort = gameData.sortLeaders ?? 'points';
        console.log(sort);
        const sortedPlayers = sortPlayers({
            'playerList': playerList, 
            'sortBy': sort,
            'filter': 'players'
        });
        // const playerArr = Object.values(playerList)
        // .filter(p => !p.team)
        // // .sort((a,b) => 
        // //     (a[sort] < b[sort]) ?
        // //     1 :
        // //     (a[sort] === b[sort]) ?
        // //     ((a.fName.toLowerCase() > b.fName.toLowerCase()) ?
        // //     1 :
        // //     -1) :
        // //     -1
        // // )
        let arr = []
        for (let p of sortedPlayers) {
            arr.push({
                'playerId': p.playerId,
                'icon': p.icon,
                'gradient': p.gradient,
                'fName': p.fName,
                'lName': p.lName ?? '',
                'data': p[sort] ?? 0,
                'path': p.path
            })
        }
        setPlayersToDisplay(arr); 
        console.log(arr);
    }, [gameData, playerList])

    return (
        <div className='leaders-list'>
            <div className='list-container' style={{'height': `${containerHeight}px`}}>
            {
                (playersToDisplay) &&
                playersToDisplay.map(p => (
                    <button 
                        key={p.playerId} 
                        className='g-button text-only leaders-list-item'
                        onClick={() => navigate(`/manage/${gameData.path}/players/${p.path}`)}
                    >
                        <div className='icon-div'>
                        {
                            (p.icon)
                            ?
                            <ImageContainer src={p.icon} />
                            :
                            <div className='gradient' style={{'background': p.gradient}}>{p.fName[0]}</div>
                        }
                        </div>
                        <div className='name-div'>
                            {p.lName ? `${p.fName} ${p.lName[0]}` : p.fName}
                        </div>
                        <div className='data-div'>
                            {p.data.toLocaleString()}
                        </div>
                    </button>
                ))
            }
            </div>
        </div>
    )
}

export default LeadersList