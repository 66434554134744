import React, { useEffect, useRef, useState } from 'react'
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import TextEditor from '@/components/SHARED/text-editor/text-editor.component';

import { useLocation, useNavigate } from 'react-router-dom';

const CreateEditForm1 = ({ 
    edit, 
    formData, 
    saveData, 
    verifyAndMoveOn, 
    cancel, 
    validJoinCode, 
    loading,
    setJoinCodeEdited
}) => {

    const [ textContent, setTextContent ] = useState(null);
    const [ joinCode, setJoinCode ] = useState('');
    const [ req ] = useState(['gameName', 'joinCode'])
    const [ existingDescription, setExistingDescription ] = useState(null);
    const [ clearEditor, setClearEditor ] = useState(false);
    const descRef = useRef();
    const dataRef = useRef();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        dataRef.current = {...formData};
        if (dataRef.current.joinCode) {
            console.log(dataRef.current.joinCode);
            setJoinCode(dataRef.current.joinCode)
        }
        if (!existingDescription) {
            setExistingDescription(dataRef.current.desc);
        }
    }, [formData])

    function saveJoinCode(e) {
        console.log(e)
        let code = e.substring(29, e.length).replace(/[^\w-]+/g, '').toLowerCase();
        // console.log(code)
        setJoinCode(code);
        saveData('joinCode', code);
    }

    function saveCodeAndMarkEdited() {
        // saveData('joinCode', joinCode);
        setJoinCodeEdited(true);
    }

    return (
        <div className='new-game-form' id='new-game-form'>
            {
                (formData) &&
                <>
                <div className='field'>
                    <label>Game Name <span className='required'>*</span></label>
                    <input 
                        type='text' 
                        defaultValue={formData.gameName ?? ''} 
                        onChange={(e) => saveData('gameName', e.target.value)} 
                    />
                </div>
                <div className='g-space-1'></div>
                <div className='field'>
                    <label>Join Code <span className='required'>*</span></label>
                    <input 
                        type='text' 
                        value={'https://app.gamably.com/join/' + joinCode} 
                        onChange={(e) => saveJoinCode(e.target.value)} 
                        onBlur={() => saveCodeAndMarkEdited()} 
                    />
                    {
                        (!validJoinCode) &&
                        <span 
                            className='required'
                        >
                            Join Code is Not Available
                        </span>
                    }
                </div>
                <div className='g-space-1'></div>
                <div className='field'>
                    <label>Short Description</label>
                    <input 
                        type='text' 
                        defaultValue={formData.shortDesc ?? ''} 
                        onChange={(e) => saveData('shortDesc', e.target.value)} 
                    />
                </div>
                <div className='g-space-1'></div>
                <div className='field'>
                    <label>Long Description</label>
                    <TextEditor 
                        setTextContent={(e) => saveData('desc', e)} 
                        // textContent={formData.desc} 
                        existingContent={existingDescription} 
                        clearEditor={clearEditor} 
                        setClearEditor={setClearEditor}
                    />
                </div>
                <div className='g-space-1'></div>
                <div className='required'>* = Required</div>
                <div className="g-space-1"></div>
                {   
                    (location.pathname.includes('/creategame')) &&
                    <div className='buttons'>
                        <button 
                            className='g-button' 
                            onClick={() => cancel()}
                        >
                            Cancel
                        </button>
                        {
                            (loading)
                            ?
                            <button className='g-button'><ThreeDotsLoader /></button>
                            :
                            <button 
                                className='g-button primary' 
                                onClick={() => verifyAndMoveOn(req, 'part2')}
                            >
                                Next
                            </button>
                        }
                    </div>
                } 
                </>
            }
        </div>
    )
}

export default CreateEditForm1