import React, { useEffect, useState } from 'react'
import './my-plan.styles.scss'
import Layout from '@/components/layout-auth/layout.comp'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ImageContainer from '@/components/SHARED/image-container/image-container.component'
import { membershipRestrictions } from '@/utils/membershipRestrictions'

const MyPlan = () => {

    const membership = useSelector(state => state.userData.membership);
    const [ activeButton, setActiveButton ] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        var r = document.querySelector(':root');
        r.style.setProperty('--primary', '#674fa7');
        r.style.setProperty('--primary-trans', '#674fa7a6');
    }, [])

    useEffect(() => {
        if (!membership) return;
        setActiveButton(membership.membership);
        console.log(membership)
    }, [membership])

    return (
        <div className='my-plan'>
            <Layout>
                <div className='my-plan-content'>
                    <div className='g-card'>
                        <div className='section-title with-icon'>
                            My Plan Info
                            <button 
                                className='g-button' 
                                onClick={() => navigate('/pricing')}
                            >Upgrade!</button>
                        </div>
                        <hr className='no-margin' />
                        <div className='plan-list'>
                            <div 
                                className={`g-list-item ${
                                    membership.membership === 0 
                                    ? 
                                    'current ' 
                                    : 
                                    ''
                                } ${
                                    activeButton === 0 
                                    ?
                                    'active'
                                    :
                                    ''
                                }`}
                            >
                                <ImageContainer 
                                    src={'/images/icons/free.png'} 
                                    className='icon' 
                                />
                                Free Plan
                            </div>
                            <div
                                className={`g-list-item ${
                                    membership.membership === 1 
                                    ? 
                                    'current ' 
                                    : 
                                    ''
                                }  ${
                                    activeButton === 1 
                                    ?
                                    'active'
                                    :
                                    ''
                                }`}
                            >
                                <ImageContainer 
                                    src={'/images/icons/pro.png'} 
                                    className='icon' 
                                />
                                Pro Plan
                            </div>
                            <div 
                                className={`g-list-item ${
                                    membership.membership === 2 
                                    ? 
                                    'current ' 
                                    : 
                                    ''
                                }${
                                    activeButton === 2 
                                    ?
                                    'active'
                                    :
                                    ''
                                }`}
                            >
                                <ImageContainer 
                                    src={'/images/icons/premium.png'} 
                                    className='icon' 
                                />
                                Premium Plan
                            </div>
                            <div 
                                className={`g-list-item ${
                                    membership.membership > 2 
                                    ? 
                                    'current ' 
                                    : 
                                    ''
                                }${
                                    activeButton === 3 
                                    ?
                                    'active'
                                    :
                                    ''
                                }`}
                            >
                                <ImageContainer 
                                    src={'/images/icons/enterprise.png'} 
                                    className='icon' 
                                />
                                Enterprise
                            </div>
                        </div>
                        <div className='g-space-1'></div>
                        <div className='plan-details'>
                            <table className='g-table'>
                                <thead>
                                    <tr>
                                        <td>Features</td>
                                        <td className='centered'>Free Plan</td>
                                        <td className='centered'>Pro Plan</td>
                                        <td className='centered'>Premium</td>
                                        <td className='centered'>Enterprise</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Active Games</td>
                                        <td className='centered info'>
                                            {
                                                membershipRestrictions[0].gameCount 
                                                ? 
                                                membershipRestrictions[0].gameCount.toLocaleString() 
                                                : 
                                                'Unlimited'
                                            }
                                        </td>
                                        <td className='centered info'>
                                            {
                                                membershipRestrictions[1].gameCount 
                                                ? 
                                                membershipRestrictions[1].gameCount.toLocaleString() 
                                                : 
                                                'Unlimited'
                                            }
                                        </td>
                                        <td className='centered info'>
                                            {
                                                membershipRestrictions[2].gameCount 
                                                ? 
                                                membershipRestrictions[2].gameCount.toLocaleString() 
                                                : 
                                                'Unlimited'
                                            }
                                        </td>
                                        <td className='centered info'>
                                            {
                                                membershipRestrictions[3].gameCount 
                                                ? 
                                                membershipRestrictions[3].gameCount.toLocaleString() 
                                                : 
                                                'Unlimited'
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Cost Per Additional Game</td>
                                        <td className='centered info na'>X</td>
                                        <td className='centered info'>$10</td>
                                        <td className='centered info'>$5</td>
                                        <td className='centered info na'>X</td>
                                    </tr>
                                    <tr>
                                        <td>Game Elements Per Game</td>
                                        <td className='centered info'>
                                            {
                                                membershipRestrictions[0].elementCount 
                                                ? 
                                                membershipRestrictions[0].elementCount.toLocaleString() 
                                                : 
                                                'Unlimited'
                                            }
                                        </td>
                                        <td className='centered info'>{membershipRestrictions[1].elementCount ? membershipRestrictions[1].elementCount.toLocaleString() : 'Unlimited'}</td>
                                        <td className='centered info'>{membershipRestrictions[2].elementCount ? membershipRestrictions[2].elementCount.toLocaleString() : 'Unlimited'}</td>
                                        <td className='centered info'>{membershipRestrictions[3].elementCount ? membershipRestrictions[3].elementCount.toLocaleString() : 'Unlimited'}</td>
                                    </tr>
                                    <tr>
                                        <td>Players/Teams Per Game</td>
                                        <td className='centered info'>{membershipRestrictions[0].playerCount ? membershipRestrictions[0].playerCount.toLocaleString() : 'Unlimited'}</td>
                                        <td className='centered info'>{membershipRestrictions[1].playerCount ? membershipRestrictions[1].playerCount.toLocaleString() : 'Unlimited'}</td>
                                        <td className='centered info'>{membershipRestrictions[2].playerCount ? membershipRestrictions[2].playerCount.toLocaleString() : 'Unlimited'}</td>
                                        <td className='centered info'>{membershipRestrictions[3].playerCount ? membershipRestrictions[3].playerCount.toLocaleString() : 'Unlimited'}</td>
                                    </tr>
                                    <tr>
                                        <td>Available Game Styles</td>
                                        <td className='centered info'>Points and Leaderboards or Currency and a Store</td>
                                        <td className='centered info'>All styles available</td>
                                        <td className='centered info'>All styles available</td>
                                        <td className='centered info'>All styles available</td>
                                    </tr>
                                    <tr>
                                        <td>Access to Gamably Content Hub</td>
                                        <td className='centered info na'>X</td>
                                        <td className='centered info'>Access to Pro Content Only</td>
                                        <td className='centered info'>Full Access</td>
                                        <td className='centered info'>Full Access</td>
                                    </tr>
                                    <tr>
                                        <td>Access to Template Games</td>
                                        <td className='centered info na'></td>
                                        <td className='centered info'>Pro Templates Only</td>
                                        <td className='centered info'>All Templates</td>
                                        <td className='centered info'>All Templates</td>
                                    </tr>
                                    <tr>
                                        <td>Experience Points (XP)</td>
                                        <td className='centered info'>Yes</td>
                                        <td className='centered info'>Yes</td>
                                        <td className='centered info'>Yes</td>
                                        <td className='centered info'>Yes</td>
                                    </tr>
                                    <tr>
                                        <td>Leaderboards</td>
                                        <td className='centered info'>Yes</td>
                                        <td className='centered info'>Yes</td>
                                        <td className='centered info'>Yes</td>
                                        <td className='centered info'>Yes</td>
                                    </tr>
                                    <tr>
                                        <td>Badges</td>
                                        <td className='centered info'>Yes</td>
                                        <td className='centered info'>Yes</td>
                                        <td className='centered info'>Yes</td>
                                        <td className='centered info'>Yes</td>
                                    </tr>
                                    <tr>
                                        <td>Secret Codes</td>
                                        <td className='centered info'>Yes</td>
                                        <td className='centered info'>Yes</td>
                                        <td className='centered info'>Yes</td>
                                        <td className='centered info'>Yes</td>
                                    </tr>
                                    <tr>
                                        <td>Challenges</td>
                                        <td className='centered info'>Yes</td>
                                        <td className='centered info'>Yes</td>
                                        <td className='centered info'>Yes</td>
                                        <td className='centered info'>Yes</td>
                                    </tr>
                                    <tr>
                                        <td>Levels</td>
                                        <td className='centered info'>Yes</td>
                                        <td className='centered info'>Yes</td>
                                        <td className='centered info'>Yes</td>
                                        <td className='centered info'>Yes</td>
                                    </tr>
                                    <tr>
                                        <td>Currency & Items</td>
                                        <td className='centered info'>Yes</td>
                                        <td className='centered info'>Yes</td>
                                        <td className='centered info'>Yes</td>
                                        <td className='centered info'>Yes</td>
                                    </tr>
                                    <tr>
                                        <td>Marketplace</td>
                                        <td className='centered info'>Yes</td>
                                        <td className='centered info'>Yes</td>
                                        <td className='centered info'>Yes</td>
                                        <td className='centered info'>Yes</td>
                                    </tr>
                                    <tr>
                                        <td>Item Prize Packs</td>
                                        <td className='centered info na'>X</td>
                                        <td className='centered info'>Yes</td>
                                        <td className='centered info'>Yes</td>
                                        <td className='centered info'>Yes</td>
                                    </tr>
                                    <tr>
                                        <td>Item Collections</td>
                                        <td className='centered info na'>X</td>
                                        <td className='centered info na'>X</td>
                                        <td className='centered info'>Yes</td>
                                        <td className='centered info'>Yes</td>
                                    </tr>
                                    <tr>
                                        <td>Quests</td>
                                        <td className='centered info na'>X</td>
                                        <td className='centered info na'>X</td>
                                        <td className='centered info'>Yes</td>
                                        <td className='centered info'>Yes</td>
                                    </tr>
                                </tbody>
                            </table>
                            
                        </div>
                    </div>
                    <div className="g-space-1-5"></div>
                </div>
            </Layout>
        </div>
    )
}

export default MyPlan