import React, { useEffect, useRef, useState } from 'react';
import './items-upload-modal.styles.scss';
import Modal, { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import { useSelector } from 'react-redux';
import { usePapaParse } from 'react-papaparse';
import { membershipRestrictions } from '@/utils/membershipRestrictions';
import { RandomString } from '@/utils/randomString';
import { UseCloudFunction } from '@/utils/firebase.utils';
import FullPageLoader from '@/components/loader/full-page-loader.component';
import GameElements from '../create-elements-container.component';

const ItemsUploadModal = ({ gameData, gameElements, show, cancel }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const membership = useSelector(state => state.userData.membership);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ arrToUpload, setArrToUpload ] = useState([]);
    const [ availableSpots, setAvailableSpots ] = useState(0);
    const [ loading, setLoading ] = useState(false);
    const fileReader = new FileReader();
    const { readString } = usePapaParse();
    const approveRef = useRef();

    useEffect(() => {
        if (!membership || !membershipRestrictions) return;
        const num = membershipRestrictions[membership.membership].elementCount;
        setAvailableSpots(num - (gameData.createdElementTotal ?? 0));
    }, [membership, membershipRestrictions])

    function clickUpload() {
        document.getElementById('fileUpload').click();
    }

    async function handleUpload(file) {
        const ext = file.name.match(/\.([^\.]+)$/)[1].toLowerCase();
        console.log(ext);
        const allowed = ['csv']
        if (!allowed.includes(ext)) {
            setAlertMessage('That file type is not allowed to be uploaded. Try a different file.');
            setArrToUpload([]);
            document.getElementById('fileUpload').value = null;
            return;
        }
        const csvOutput = await readFileAsync(file)
		// console.log(csvOutput)
		const arr = await getParsedData(csvOutput)
        console.log(arr);
		const objArr = createObjs(arr)
        console.log(objArr);
        setArrToUpload(objArr);
    }

    function readFileAsync(file) {
        return new Promise((resolve, reject) => {
            fileReader.onload = (event) => {
                resolve(event.target.result);	
            };
            fileReader.readAsText(file);
        })
    }

    function getParsedData(arr) {
		return new Promise((resolve, reject) => {
			readString(arr, {
		      	worker: true,
		      	complete: (results) => {
			        // console.log('---------------------------');
			        // console.log(results.data);
			        // console.log('---------------------------');
					resolve(results.data)
			  	},
		    });
		})
	}

    function createObjs(arr) {
        const template = [
            "Name",
            "Description",
            "Item Type",
            "Category",
            "Collections",
            "Overall Visibility",
            "Preview Visibility",
            "How many of these items can a player hold?",
            "How many of these items can exist in a game?",
            "Cost in the Marketplace",
            "Contribution Goal",
            "Prerequisite Points",
            "Prerequisite Minimum Level",
            "Prerequisite Badge(s)",
            "Prerequisite Item(s)",
            "Team Requirement",
            "Buyback price",
            "XP Earned if Used",
            "Currency Earned if Used",
            "Badge(s) Earned",
            "Item(s) Earned",
            "Prize Pack Earned",
            "Minimum Prize Pack Items Earned",
            "Maximum Prize Pack Items Earned",
            "Available Date",
            "Unavailable Date"
        ]
		const headers = arr[0]
        console.log(template);
        console.log(headers);
        let validCSV = true;
        for (let i=0; i<template.length; i++) {
            if (template[i] !== headers[i]) {
                validCSV = false;
                break;
            }
        }
		if (!validCSV) {
            setAlertMessage('Please use the correct template when uploading a list of Items.');
            return false;
        }
        let objArr = [];
        let startingPoint = 1;
        if (arr[1][0].includes('required')) {
            startingPoint = 2;
        }
        const keyList = [
            "name",
            "desc",
            "opt_itemType",
            "opt_cat",
            "opt_collections",
            "opt_visible",
            "opt_preview",
            "opt_limitInd",
            "opt_limitTotal",
            "opt_marketPrice",
            "opt_contributionGoal",
            "opt_prereqPoints",
            "opt_prereqLevel",
            "opt_prereqBadges",
            "opt_prereqItems",
            "opt_prereqTeams",
            "opt_resalePrice",
            "opt_earnedPoints",
            "opt_earnedCurrency",
            "opt_earnedBadges",
            "opt_earnedItems",
            "opt_earnedPrizePacks",
            "opt_earnedPrizeMin",
            "opt_earnedPrizeMax",
            "opt_dateOpen",
            "opt_dateClose"
        ]
        const now = new Date().getTime();
        const gameCode = gameData.gameId.substring(gameData.gameId.indexOf('-')-5, gameData.gameId.indexOf('-')+6);
        const len = arr.length - startingPoint <= availableSpots ? arr.length : availableSpots;
        console.log(startingPoint, len);
		for (let a=startingPoint; a<len; a++) {
			const row = arr[a];
			let obj = {};
            console.log(row);
			for (let r=0; r<row.length; r++) {
                // checks to make sure it's a number
				if (row[r] && (r === 6 || r === 7 || r === 8 || r === 9 || r === 15 || r === 16 || r === 17 || r === 21|| r === 22)) {
                    const val = Number(row[r].trim());
                    if (r === 2) {
                        if (
                            val !== "inventory" &&
                            val !== "realworld" &&
                            val !== "collectible" &&
                            val !== "lottery" &&
                            val !== "contribution"
                        ) continue;
                    }
                    if (isNaN(val)) {
                        obj[keyList[r]] = null;
                    } else {
                        obj[keyList[r]] = val;
                    }
                    // if index === 8, mark marketplace true or false
                    if (r === 8 && isNaN(val)) {
                        obj.opt_market = false;
                    } else if (r === 8) {
                        obj.opt_market = true;
                        if (val === 'contribution') {
                            obj.opt_market = true;
                            delete obj.opt_marketPrice
                        }
                    }
                    if (r === 10 && obj.opt_itemType === 'contribution' && ((val && isNaN(val)) || !val)) {
                        continue;
                    }
                    // if index === 14, mark resale true or false
                    if (r === 15 && isNaN(val)) {
                        obj.opt_resale = false;
                    } else if (r === 15) {
                        obj.opt_resale = true;
                    }
                } 
                // create arrays from comma separated strings
                else if (row[r] && (r === 3 || r === 12 || r === 13 || r === 14 || r === 18 || r === 19)) {
                    const val = row[r].replace(/\s/g, '').toLowerCase().split(',');
                    obj[keyList[r]] = val;
                } 
                // create booleans
                else if (row[r] && (r === 4 || r === 5)) {
                    obj[keyList[r]] = row[r].trim().toLowerCase() === 'true';
                } 
                // create date objects
                else if (row[r] && (r === 23 || r === 24)) {
                    obj[keyList[r]] = new Date(row[r]).getTime();
                }
                // default
                else if (row[r]) {
                    obj[keyList[r]] = row[r]
                }
			}
            if (!obj.opt_dateOpen) {
                obj.opt_dateOpen = now;
            }
            obj.appId = userProfile.appId;
            obj.ts_created = now + a;
            obj.ts = now + a;
            obj.gameId = gameData.gameId;
            obj.icon = "/images/icons/item-default.png";
            obj.id = obj.ts + '-' + gameCode + '-item';
            obj.sortGameType = gameData.gameId + '-item';
            obj.status = 'active';
            obj.opt_available = true;
            obj.type = 'item';
            obj.path = RandomString(18, 'abcdefghijklmnopqrstuvwxyz0123456789');
			objArr.push(obj)
		}
		return objArr;
	}

    function cancelUpload() {
        setArrToUpload([]);
        document.getElementById('fileUpload').value = null;
        cancel();
    }

    function proceedWithUpload() {
        setConfirmMessage(`Are you sure you are ready to upload ${arrToUpload.length} Items?`);
        async function uploadList() {
            setLoading(true);
            const res = await UseCloudFunction('uploadElements', {'arr': arrToUpload, 'appId': userProfile.appId, 'gameId': gameData.gameId, 'userName': `${userProfile.fName} ${userProfile.lName}`})
            if (res.error) {
                setAlertMessage('Something went wrong. Please try again later. Details: ' + res.error);
                return;
            }
            setLoading(false);
            cancelUpload();
        }
        approveRef.current = () => {
            uploadList();
        }
    }

    return (
        <div className='items-upload-modal'>
            <Modal show={show} cancel={cancel} closeButton={true} text="Upload a List of Items" cls="narrow" >
                <div className='scrolling'>
                    <p><b>Available Spots: {availableSpots}</b></p>
                    <p>You can upload a list of Items to make the content creation process easier. 
                        The only part of each item that cannot be created during this process is the icon. 
                        You will have to edit each item after it has been uploaded to give it an icon.</p>
                    <p>To upload a list of Items, make a copy of <b><a href='https://docs.google.com/spreadsheets/d/1A-Qv7en8-pqbpz0L0Zrvu_klyQAs2tLZphkOL8ni1ag/copy' target="_blank">THIS GOOGLE SHEET</a></b> and export it as a CSV or download <b><a href={`${import.meta.env.VITE_ROOT}/images/files/GamablySecretCodeUploadTemplate.csv`} target='_blank' download>THIS CSV</a></b>. Then, upload the completed roster here.</p>
                    <div className="g-space-1"></div>
                    <div style={{cursor: 'pointer'}}>
                        <input type="file" id="fileUpload" accept=".csv" onChange={(e) => handleUpload(e.target.files[0])}  />
                        <button className="g-button" type="button" tabIndex="-1" onClick={() => clickUpload()}>Upload a List of Items</button>
                    </div>
                    <div className="g-space-1"></div>
                    {
                        (arrToUpload.length > 0 && !loading) &&
                        <div className='upload-list'>
                            <hr />
                            <p><b>List of Items to be Uploaded</b></p>
                            {
                                arrToUpload.map(c => (
                                    <div key={c.id} className='upload-list-item'>{c.name}</div>
                                ))
                            }
                            <div className='g-space-1'></div>
                            {
                                (loading)
                                ?
                                <div className='buttons'>
                                    <button type='button' className='g-button'>Cancel</button>
                                    <button type='button' className='g-button' ><ThreeDotsLoader /></button>
                                </div>
                                :
                                <div className='buttons'>
                                    <button type='button' className='g-button' onClick={() => cancelUpload()}>Cancel</button>
                                    <button type='button' className='g-button primary' onClick={() => proceedWithUpload()}>Upload This List</button>
                                </div>
                            }
                            <div className='g-space-1'></div>
                        </div>
                    }
                </div>
            </Modal>
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
            <ModalConfirm show={confirmMessage} cancel={() => setConfirmMessage(null)} message={confirmMessage} onApprove={approveRef.current} />
            <FullPageLoader show={loading} />
        </div>
    )
}

export default ItemsUploadModal