import React, { useState } from 'react'
import './functions.styles.scss';
import { UseCloudFunction } from '@/utils/firebase.utils'
import Modal from '@/components/modal/modal.component';

const AdminFunctions = () => {

    const [ showCopyCollectionModal, setShowCopyCollectionModal ] = useState(false);
    const [ showUpdatePlayerDocsModal, setShowUpdatePlayerDocsModal ] = useState(false);

    async function updateActivity() {
        const res = await UseCloudFunction('updateActivity', {'obj': 'data'});
        console.log(res);
    }

    async function updateActivityAndGoals() {
        const res = await UseCloudFunction('updateActivityAndGoals', {'obj': 'data'});
        console.log(res);
    }

    async function copyFirestoreCollection() {
        const existingName = document.getElementById('existing-name').value;
        const newName = document.getElementById('new-name').value;
        const res = await UseCloudFunction('copyFirestoreCollection', {'existingName': existingName, 'newName': newName})
        console.log(res);
    }

    function cancelCopyFirestoreCollection() {
        const els = document.getElementsByClassName('copy-firestore-input')
        for (let el of els) {
            el.value = '';
        }
        setShowCopyCollectionModal(false)
    }

    async function moveGameElements() {
        const res = await UseCloudFunction('moveGameElements', {})
        console.log(res);
    }
    async function moveGameActivity() {
        const res = await UseCloudFunction('moveGameActivity', {})
        console.log(res);
    }

    async function updatePlayerDocs() {
        const gameId = document.getElementById('gameId').value;
        if (!gameId) return;
        const res = await UseCloudFunction(
            'updatePlayerDocs', 
            {
                'gameId': gameId
            }
        )
        console.log(res);
        cancelUpdatePlayerDocs();
    }

    function cancelUpdatePlayerDocs() {
        document.getElementById('gameId').value = ''
        setShowUpdatePlayerDocsModal(false);
    }

    async function runCloudFunction(fnName) {
        const res = await UseCloudFunction(fnName, {});
        console.log(res);
    }

    return (
        <div className='functions-container'>
            <div>
                <button 
                    className='g-button' 
                    onClick={() => updateActivityAndGoals()}
                >
                    Update Activity and Goals for multiple elements
                </button>
            </div>
            <div>
                <button 
                    className='g-button' 
                    onClick={() => updateActivity()}
                >
                    Update Activity Sort Titles
                </button>
            </div>
            <div>
                <button 
                    className='g-button' 
                    onClick={() => setShowCopyCollectionModal(true)}
                >
                    Copy a Firestore Collection
                </button>
            </div>
            <div>
                <button
                    className='g-button' 
                    onClick={() => moveGameElements()}
                >
                    Move Game Elements
                </button>
            </div>
            <div>
                <button 
                    className='g-button' 
                    onClick={() => moveGameActivity()}
                >
                    Move Game Activity
                </button>
            </div>
            <div>
                <button 
                    className='g-button' 
                    onClick={() => runCloudFunction('addOptAvailable')}
                >
                    Add Opt_Available to Game Elements
                </button>
            </div>
            <div>
                <button
                    className='g-button'
                    onClick={() => setShowUpdatePlayerDocsModal(true)}
                >
                    Update Player Docs
                </button>
            </div>
            {/* <div>
                <button className='g-button' onClick={() => createPlayerPaths()}>Create Player Paths</button>
            </div> */}
            <Modal 
                show={showCopyCollectionModal} 
                cancel={() => setShowCopyCollectionModal(false)} 
                text='Copy a Firestore Collection' 
                closeButton={true} 
                cls='narrow'
            >
                <div className='modal-children copy-firestore-collection-modal'>
                    <div className='field'>
                        <label>Existing Collection Name</label>
                        <input type='text' id='existing-name' />
                    </div>
                    <div className='field'>
                        <label>New Collection Name</label>
                        <input type='text' id='new-name' />
                    </div>
                    <div className='buttons'>
                        <button className='g-button' onClick={() => cancelCopyFirestoreCollection()}>Cancel</button>
                        <button className='g-button' onClick={() => copyFirestoreCollection()}>Copy Collection</button>
                    </div>
                </div>
            </Modal>
            <Modal 
                show={showUpdatePlayerDocsModal} 
                cancel={() => setShowUpdatePlayerDocsModal(false)}
                text='Update Player Docs'
                closeButton={true}
                cls='narrow'
            >
                <div className='modal-children copy-firestore-collection-modal'>
                    <div className='field'>
                        <label>Game Id</label>
                        <input type='text' id='gameId' />
                    </div>
                    <div className='g-space-1'></div>
                    <div className='buttons'>
                        <button className='g-button' onClick={() => cancelUpdatePlayerDocs()}>Cancel</button>
                        <button className='g-button' onClick={() => updatePlayerDocs()}>Update Player Docs</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default AdminFunctions