import React, { useEffect, useState } from 'react';
import './cart-widget.styles.scss';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { useDispatch, useSelector } from 'react-redux';
import { setShoppingCart } from '@/state/slices/userSlice';
import { useNavigate } from 'react-router-dom';

const CartWidget = ({ playerData }) => {

    const shoppingCart = useSelector(state => state.userData.shoppingCart);
    const [ totalQuantity, setTotalQuantity ] = useState(0);
    const [ totalCost, setTotalCost ] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!shoppingCart) {
            setTotalCost(0);
            setTotalQuantity(0);
            return;
        };
        const quant = Object.values(shoppingCart)
        .reduce((acc, cur) => acc + cur.quantity, 0);
        const total = Object.values(shoppingCart)
        .reduce((acc, cur) => acc + (cur.cost * cur.quantity), 0);
        setTotalQuantity(quant);
        setTotalCost(total);
    }, [shoppingCart])

    return (
        <div className='cart-widget'>
            <div><b>Shopping Cart</b></div>
            <div className="g-space-1"></div>
            <div className="quantity">Items: {totalQuantity}
            </div>
            <div className="g-space-0-5"></div>
            <div className="total">
                Total: <ImageContainer 
                    src="/images/icons/coin.png" 
                    className="coin-icon" 
                />
                <b className='total-text'>{totalCost.toLocaleString()}</b>
            </div>
            <div className="g-space-1"></div>
            <button 
                className={`g-button ${
                    totalQuantity > 0 ? 'primary' : 'disabled'}`
                }
                onClick={() => navigate('shoppingcart')}
            >
                Proceed to Cart
            </button>
            <div className="g-space-0-5"></div>
            <button className='g-button text-only' onClick={() => dispatch(setShoppingCart(null))}>
                <span className='meta'>Clear Cart</span>
            </button>
        </div>
    )
}

export default CartWidget;