import BackButton from '@/components/SHARED/back-button/back-button.component';
import React from 'react'
import { useNavigate } from 'react-router-dom'
import GameContentSettingsForm from './content-settings-form.component';

const SettingsElements = ({
    formData,
    saveData,
    saveSettings,
    gameData,
    cancel
}) => {
    
    const navigate = useNavigate();

    return (
        <div className='settings-elements'>
            <div className='g-card'>
                <BackButton cancel={cancel} />
                <div className="g-space-1"></div>
                <div className='card-title'>Settings: Game Elements</div>
                <div className="g-space-1"></div>
                <div className='g-list-item grey-background'>
                    <div className="g-space-1"></div>
                    <GameContentSettingsForm 
                        formData={formData} 
                        saveData={saveData} 
                        gameData={gameData}  
                    />
                </div>
                <div className="g-space-1"></div>
                <div className='buttons'>
                    <button 
                        className='g-button' 
                        onClick={cancel}
                    >
                        Cancel
                    </button>
                    <button 
                        className='g-button primary' 
                        onClick={() => saveSettings('elements')}
                    >
                        Save Changes
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SettingsElements