import React, { useEffect, useState } from 'react'
import './play-user-dropdown.styles.scss'

import { Link } from 'react-router-dom'
import { version } from '@/changelog-version'
import { useSelector } from 'react-redux'

const PlayUserDropdown = ({ show }) => {

    const currentUser = useSelector(state => state.userData.currentUser);
    const membership = useSelector(state => state.userData.membership);
    const [ isUpload, setIsUpload ] = useState(false);

    useEffect(() => {
        if (!currentUser) return;
        if (currentUser.provider === 'FirestoreAuth') {
            setIsUpload(true);
        }
    }, [currentUser])

    return (
        <div className={`play-dropdown-container ${show ? 'active ' : ''} ${isUpload ? 'upload' : ''}`}>
            <div className="dropdown-menu">
                {
                    (currentUser && !isUpload) &&
                    <>
                    <Link to='/home'>My Games</Link>
                    <Link to='/profile'>My Profile</Link>
                    {
                        (membership && membership.membership === 0) &&
                        <Link to='/pricing'>Upgrade</Link>
                    }
                    {
                        (membership && membership.membership > 0) &&
                        <Link to='/myplan'>Manage My Plan</Link>
                    }
                    </>
                }
                <Link to='/changelog' target='_blank'>Change Log</Link>
                <Link to='/logout'>Sign Out</Link>
                <p className='meta'>{version}</p>
            </div>
        </div>
    )
}

export default PlayUserDropdown