import React, { useRef, useState } from 'react'
import './collections.styles.scss'
import ImageContainer from '@/components/SHARED/image-container/image-container.component';

const MangageContentCollections = ({ type, formData, saveData, collections }) => {

    const [ selectedCollections, setSelectedCollections ] = useState([]);
    const collectionsRef = useRef([]);

    function chooseMultipleOption(id) {
        let tempList = [...collectionsRef.current];
        if (tempList.includes(id)) {
            tempList.splice(tempList.indexOf(id), 1);   
        } else {
            tempList.push(id);
        }
        collectionsRef.current = tempList;
        setSelectedCollections(collectionsRef.current);
        saveData('opt_collections', collectionsRef.current);
    }

    return (
        <div className='manage-content-collections g-list-item form-section'>
            <h3>Collections</h3>
            <hr />
            <div className="collections-content">
                <div className='field small'>
                    <label>Choose any collections to which you want to add this item.</label>
                    <div className='g-list-item'>
                        <div className='multiple-select'>
                        {
                            (collections.length > 0) 
                            ?
                            collections.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                            .map(c => (
                                <div 
                                    key={c.id} 
                                    className={`multiple-select-option ${selectedCollections.includes(c.path) ? 'active' : ''}`} 
                                    onClick={() => chooseMultipleOption(c.path)}
                                >
                                    <ImageContainer src={c.icon} alt={`${c.name} collection icon`} />
                                    {c.name}
                                </div>
                            ))
                            :
                            <span>No Collections Yet...</span>
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MangageContentCollections;