import React, { useEffect, useState } from 'react';
import './manage-mobile-head.styles.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faClock, faFolderTree, faHome, faInfoCircle, faListCheck, faMountainCity, faUser, faUsers, faX } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ManageGameSidebar from '../manage-game-sidebar/manage-game-sidebar.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';

const ManageMobileHeader = ({ gameData }) => {
    
    const actionItems = useSelector(state => state.contentData.actionItems);
    const screenSize = useSelector(state => state.contentData.screenSize);
    const [menuOpen, setMenuOpen] = useState(false);
    const location = useLocation();
    
    const navigate = useNavigate();
    
    useEffect(() => {
        // document.getElementById('slide-menu').classList.toggle('show');
    }, [menuOpen])

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const menuItems = [
        {
            'path': '',
            'label': 'My List'
        }
    ]

    return (
        <div className="manage-mobile-header">
            <div className={`mobile-header__logo ${screenSize}`}>
                <ImageContainer src={gameData.icon} alt="game logo" />
            </div>
            <div className="mobile-header__menu">
                <button className="mobile-header__menu-button" id="slide-menu" onClick={toggleMenu}>
                    <FontAwesomeIcon icon={faBars} />
                </button>
                <div className={`mobile-header__menu-items ${menuOpen ? "show": ""}`}>
                    <div className='close-button' onClick={() => setMenuOpen(!menuOpen)}><FontAwesomeIcon icon={faX} size='xl' /></div>
                    <ManageGameSidebar gameData={gameData} actionItems={actionItems} />
                </div>
                
            </div>

            <div className='mobile-bottom-menu'>
                <FontAwesomeIcon className='event-menu-item menu-assignments' icon={faHome} size='xl' onClick={() => navigate('')} />
                <FontAwesomeIcon className='event-menu-item menu-staff' icon={faUsers} size='xl' onClick={() => navigate('players')} />
                {/* <FontAwesomeIcon className='event-menu-item menu-groups' icon={faUsers} size='lg' onClick={() => navigate('groups')} /> */}
                <FontAwesomeIcon className='event-menu-item menu-locations' icon={faMountainCity} size='xl' onClick={() => navigate('locations')} />
                <FontAwesomeIcon className='event-menu-item menu-timeslots' icon={faClock} size='xl' onClick={() => navigate('timeslots')} />
                <FontAwesomeIcon className='event-menu-item menu-categories' icon={faFolderTree} size='xl' onClick={() => navigate('categories')} />
                <FontAwesomeIcon className='event-menu-item menu-information' icon={faInfoCircle} size='xl' onClick={() => navigate('information')} />
            </div>
            
        </div>
    );
};

export default ManageMobileHeader;