import React, { useEffect, useState } from 'react';
import './item-price.styles.scss';
import ImageContainer from '../image-container/image-container.component';
import { getCurrentSale } from '@/utils/getCurrentSale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTag } from '@fortawesome/free-solid-svg-icons';
import Modal from '@/components/modal/modal.component';
import SaleInfoDisplay from './sale-info-display.component';

const ItemPrice = ({ item, saleList, gameElements, no_button }) => {

    const [ saleData, setSaleData ] = useState(null);
    const [ showInfoModal, setShowInfoModal ] = useState(false);

    useEffect(() => {
        if (!item || saleList.length === 0) return;
        const saleObj = getCurrentSale(item, saleList);
        console.log(saleObj);
        if (saleObj) {
            setSaleData({...saleObj})
        }
    }, [item, saleList])

    return (
        <div className='item-price'>
            <ImageContainer 
                src="/images/icons/coin.png" 
                className="coin-icon" 
            />
            <b className={`total-text ${saleData ? 'sale' : ''}`}>{item.opt_marketPrice}</b>
            {
                (saleData) &&
                <><b className='total-text'>
                    {
                        saleData.newPrice.toFixed(0).toLocaleString()
                    }
                </b>
                {
                    (!no_button) &&
                    <button 
                        className='g-button text-only'
                        onClick={() => setShowInfoModal(true)}
                    >
                        <FontAwesomeIcon icon={faTag} className='info-icon' />
                    </button>
                }
                </> 
            }
            <Modal
                show={showInfoModal}
                cancel={() => setShowInfoModal(false)}
                text={'Sale Information'}
                closeButton={true}
                cls='narrow'
            >
                {
                    (saleData) &&
                    <SaleInfoDisplay
                        saleData={saleData}
                        gameElements={gameElements}
                        saleList={saleList}
                    />
                }
            </Modal>
        </div>
    )
}

export default ItemPrice