import React, { useEffect, useRef, useState } from 'react'
import './join-game-with-email.styles.scss';
import { useParams, useNavigate, Link } from 'react-router-dom';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import { getGameDataFromDb } from '@/utils/getDataFromDb';
import JoinGameFunction from '@/pages/join-page/join-game-fxn.component';

const JoinGameWithEmail = () => {

    const [ inputCode, setInputCode ] = useState(null);
    const [ noGame, setNoGame ] = useState(null);
    const [ gameData, setGameData ] = useState(null);
    const { joinCode } = useParams();
    const navigate = useNavigate();
    const gameRef = useRef();
    let gameListener;

    useEffect(() => {
        if (!joinCode) return;
        getGameData();

        return () => {
            if (gameListener) gameListener();
        }
    }, [joinCode])

    function goToApproval() {
        // if (!currentUser) {
        //     navigate('/signin');
        //     return;
        // }
        const inputCode = document.getElementById('join-code-input').value;
        navigate(inputCode.toLowerCase());
    }

    function getGameData() {
        setNoGame(false);
        gameRef.current = null;
        getGameDataFromDb({'joinCode': joinCode, 'status': 'active', 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            if (!data.enroll_email) {
                navigate('/signinwithcode');
                return;
            }
            gameRef.current = data;
            setGameData(gameRef.current);
        }
        function handleListener(unsub) {
            gameListener = unsub;
        }
        setTimeout(() => {
            if (!gameRef.current) {
                setNoGame(true);
            }
        }, 3000)
    }

    return (
        <div className='join-game-with-email'>
            {
                (!joinCode)
                ?    
                <div className='g-card'>
                    <h2>Join a Game</h2>
                    <hr />
                    <div className='icon-input'>
                        {/* <img src={icon} className='icon' /> */}
                        <div className='field'>
                            <label>Enter a Join Code</label>
                            <input 
                                type='text' 
                                id='join-code-input' 
                                value={inputCode ?? ''} 
                                onChange={(e) => setInputCode(e.target.value.replace(/[^\w-]+/g, '').toLowerCase())} 
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        goToApproval();
                                    }
                                }} 
                            />
                        </div>
                        <div>
                            <button className='g-button primary' onClick={() => goToApproval()}>
                                Go!
                            </button>
                        </div>
                    </div>
                </div>
                :
                <>
                    {
                        (noGame)
                        ?
                        <div className='join-code-form'>
                            <div className='g-card join-approval-card'>
                                <div className='no-game'>
                                    <h3>No Game Found. Try again!</h3>
                                    <Link to='/join'>
                                        <button className='g-button'>Ok</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        :
                        (gameData && gameData.enroll_email) 
                        ?
                        <div className='join-code-form'>
                            <div className='g-card join-approval-card'>
                                <div className='game-info'>
                                    <img src={gameData.icon} className='icon' />
                                    <div>
                                        <div className='title'>{gameData.gameName}</div>
                                        <div className='info'>
                                            Creator: {gameData.creatorName}<br />
                                            Join Code: {gameData.joinCode}
                                        </div>
                                    </div>
                                </div>
                                <div className="g-space-30"></div>
                                <div className='center'>
                                    <JoinGameFunction gameData={gameData} />
                                </div>
                            </div>
                        </div>
                        :
                        <div className='join-code-form'>
                            <div className='g-card join-approval-card'>
                                <div className='center-col'>
                                    <ThreeDotsLoader />
                                </div>
                            </div>
                        </div>
                    }
                </>
            }
        </div>
    )
}

export default JoinGameWithEmail