import React, { useEffect, useState } from 'react';
import './badge-view.styles.scss';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { useNavigate, useParams } from 'react-router-dom';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { format } from 'date-fns';

const BadgeView = ({ gameElements, playerData, playerActivity }) => {

    const { badgePath } = useParams();
    const [ badgeData, setBadgeData ] = useState(null);
    const [ activity, setActivity ] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!playerData || !badgePath) return;
        const badge = Object.values(gameElements).filter(e => e.type === 'badge' && e.status === 'active' && e.path === badgePath)[0];
        if (!badge || !playerData.badges[badge.id]) {
            navigate(-1);
        }
        setBadgeData(badge);
        if (playerActivity) {
            getBadgeActivity(badge.id);
        }
    }, [playerData, badgePath, playerActivity])

    function getBadgeActivity(badgeId) {
        let badgeActivity = [];
        for (let a of Object.values(playerActivity)) {
            if (a.data) {
                for (let d of Object.values(a.data)) {
                    if (d.id === badgeId) {
                        badgeActivity.push(a)
                    }
                }
            }
        }
        setActivity(badgeActivity.sort((a,b) => a.ts > b.ts ? 1 : -1));
        console.log(badgeActivity)
    }

    return (
        <div className='badge-view'>
            <div className='g-card'>
                <BackButton cancel={() => navigate(-1)} />
                <div className="g-space-1"></div>
                {
                    (badgeData)
                    ?
                    <div>
                        <div className='badge-view-header'>
                            <ImageContainer 
                                src={badgeData.icon} 
                                className='icon' 
                                alt={`${badgeData.name}'s icon`} 
                            />
                            <div>
                                <div className='card-title'>{badgeData.name}</div>
                                <div className="g-space-1"></div>
                                {badgeData.opt_cat && <div className='info'><b>Category:</b> {badgeData.opt_cat}</div>}
                            </div>    
                        </div>
                        {
                            (badgeData.desc) &&
                            <div dangerouslySetInnerHTML={{__html: badgeData.desc}}>
                            </div>
                        }
                        {
                            (activity && activity.length > 0) &&
                            <>
                            <hr />
                            <h3>Earning Record</h3>
                            <div className='earning-list'>
                                {
                                    activity.map(a => (
                                        <div key={a.ts} className='g-list-item'>
                                            <div className='icon'>
                                                <ImageContainer src={a.icon} />
                                            </div>
                                            <div className='info'>
                                                <h4>{a.activity}: {a.title}</h4>
                                                <p>
                                                {
                                                    (a.event === 'Secret Code Found') &&
                                                    <div>
                                                        <b>{a.info}</b>
                                                    </div>
                                                }
                                                Earned {format(new Date(a.ts), 'P')}
                                                {
                                                    (a.info) && 
                                                    <div>
                                                        Details: <br /><b>"{a.comments}"</b>
                                                    </div>
                                                }
                                                {
                                                    (a.comments) && 
                                                    <div>
                                                        Evaluator comments:<br /><b>"{a.comments}"</b>
                                                    </div>
                                                }
                                                </p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            </>
                        }

                    </div>
                    :
                    <div className='center-col'><ThreeDotsLoader /></div>
                }
            </div>
        </div>
    )
}

export default BadgeView