import ThreeDotsLoader from '@/components/loader/three-dots-loader.component'
import Modal, { ModalAlert, ModalConfirm } from '@/components/modal/modal.component'
import TextAreaWordCount from '@/components/SHARED/text-area-word-count/text-area-word-count.component'
import { UseCloudFunction } from '@/utils/firebase.utils'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

const MessageModal = ({ gameData, show, cancel, message }) => {
    
    const userProfile = useSelector(state => state.userData.userProfile);
    const [ formData, setFormData ] = useState({});
    const [ loading, setLoading ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const dataRef = useRef();
    const approveRef = useRef();

    useEffect(() => {
        if (!message) return;
        dataRef.current = {...message};
        setFormData({...dataRef.current});
    }, [message])

    function saveData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}}
        setFormData({...dataRef.current});
    }

    function saveMessage() {
        setConfirmMessage(`Are you sure you want to post this message?`);
        approveRef.current = () => {
            continueSave();
        }
        async function continueSave() {
            setLoading(true);
            let tempData = {...dataRef.current};
            const now = new Date().getTime();
            const gameCode = gameData.gameId.substring(gameData.gameId.indexOf('-')-5, gameData.gameId.indexOf('-')+6)
            if (!tempData.id) {
                tempData.id = now + '_' + gameCode + '_message'
                tempData.ts_created = now;
                tempData.gameId = gameData.gameId;
                tempData.appId = userProfile.appId;
            }
            tempData.ts = now;

            const res = await UseCloudFunction(
                'saveMessage',
                {
                    'data': tempData
                }
            )
            console.log(res)
            if (res.error) {
                setAlertMessage(`Something went wrong. Try again later. Error: ${res.error}`);
                setLoading(false);
                return;
            }
            setAlertMessage('Message posted successfully!');
            cancel();
            setLoading(false);
        }
    }
    
    return (
        <div className='message-modal'>
            <Modal
                show={show}
                cancel={cancel}
                closeButton={true}
                text="Post News to Your Players' Dashboard"
                cls="narrow"
            >
                <div className='field'>
                    <label>Title</label>
                    <input 
                        type='text' 
                        value={formData.title ?? ''}
                        onChange={(e) => saveData('title', e.target.value)}
                    />
                </div>
                <div className="g-space-1"></div>
                <div className='field'>
                    <label>Content</label>
                    <TextAreaWordCount
                        count={150}
                        saveData={saveData}  
                        existingText={formData.message ?? ''}
                        keyId='message'
                    />
                </div>
                <div className="g-space-1"></div>
                {
                    (loading)
                    ?
                    <div className='buttons'>
                        <button className='g-button'>Cancel</button>
                        <button className='g-button'><ThreeDotsLoader /></button>
                    </div>
                    :
                    <div className='buttons'>
                        <button 
                            className='g-button'
                            onClick={() => cancel()}
                        >
                            Cancel
                        </button>
                        <button
                            className='g-button primary'
                            onClick={() => saveMessage()}
                        >
                            Post News Item
                        </button>
                    </div>
                }    
            </Modal>
            <ModalAlert
                show={alertMessage}
                cancel={() => setAlertMessage(null)}
                message={alertMessage}
            />
            <ModalConfirm
                show={confirmMessage}
                cancel={() => setConfirmMessage(false)}
                message={confirmMessage}
                onApprove={approveRef.current}
            />
        </div>
    )
}

export default MessageModal