import React from 'react'
import './user-icon.styles.scss'
import ImageContainer from '@/components/SHARED/image-container/image-container.component'
import { useSelector } from 'react-redux'

const UserIcon = ({ profile, reverse, white, small, large, xlarge, noHover, planIcon }) => {

    const membership = useSelector(state => state.userData.membership);

    return (
        <div className={`user-icon-div ${reverse ? 'reverse' : ''}`}>
            {
                (profile.icon) 
                ?
                <ImageContainer 
                    src={profile.icon.url ?? profile.icon} 
                    className={`user-icon 
                        ${white ? 'white' : ''} 
                        ${small ? 'small' : ''}
                        ${large ? 'large' : ''} 
                        ${xlarge ? 'xlarge' : ''} 
                        ${noHover ? 'no-hover' : ''}`} 
                    alt="profile-pic"  
                    referrerPolicy="no-referrer" 
                />
                :
                <div 
                    className={`user-icon 
                        ${white && 'white'} 
                        ${small && 'small'}
                        ${large && 'large'} 
                        ${xlarge && 'xlarge'} 
                        ${noHover && 'no-hover'}`
                    }
                >
                    <div 
                        className='grad' 
                        style={{'background': profile.gradient}}
                    >
                        {profile.fName[0].toUpperCase()}
                    </div>
                </div>
            }
            {
                (membership && planIcon) &&
                <ImageContainer 
                    src={
                        (membership.membership === 0) ?
                        '/images/icons/free.png' :
                        (membership.membership === 1) ?
                        '/images/icons/pro.png' :
                        (membership.membership === 2) ?
                        '/images/icons/premium.png' :
                        null
                    }
                    title={
                        (membership.membership === 0) ?
                        'Free Member' :
                        (membership.membership === 1) ?
                        'Pro Member' :
                        (membership.membership === 2) ?
                        'Premium Member' :
                        null
                    }
                    className='membership-icon'  
                />
            }
        </div>
    )
}

export default UserIcon