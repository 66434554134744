import React, { useEffect, useRef, useState } from 'react'
import './manage-item-card.styles.scss';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import ItemPrice from '@/components/SHARED/item-price/item-price.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faPen, faSlash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import LayeredIcons from '@/components/SHARED/layered-icons/layered-icons.component';
import { faHand } from '@fortawesome/free-regular-svg-icons';
import { format } from 'date-fns';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import { useSelector } from 'react-redux';
import { UseCloudFunction } from '@/utils/firebase.utils';

const ManageItemCard = ({ item, saleList, gameElements, gameStats, gameData }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ closed, setClosed ] = useState([]);
    const [ loading, setLoading ] = useState(null);
    const navigate = useNavigate();
    const intRef = useRef();

    useEffect(() => {
        return () => {
            if (intRef.current) clearInterval(intRef.current);
        }
    }, [])

    useEffect(() => {
        if (!item) return;
        checkItemStatus();
        intRef.current = setInterval(() => {
            checkItemStatus();
        }, 60000);
    }, [item])

    function checkItemStatus() {
        const now = new Date().getTime();
        let closeArr = []
        if (!item.opt_available) closeArr.push('Item is marked as unavailable.')
        if (now < item.opt_dateOpen) closeArr.push(`Item opens on ${format(new Date(item.opt_dateOpen), 'Pp')}.`)
        if (now > item.opt_dateClose) closeArr.push(`Item closed on ${format(new Date(item.opt_dateClose), 'Pp')}.`)
        setClosed(closeArr);
    }

    async function toggleItemAvailability() {
        setLoading('available');
        let tempItem = {...item};
        tempItem.opt_available = !item.opt_available;
        const res = await UseCloudFunction(
            'toggleElementAvailability',
            {
                'appId': userProfile.appId,
                'elementData': tempItem,
            }
        )
        console.log(res);
        setLoading(null);
    }

    async function removeItemFromStore() {
        setLoading('remove');
        const res = await UseCloudFunction(
            'changeStoreStatus',
            {
                'item': item,
                'appId': userProfile.appId,
            }
        );
        console.log(res);
        setLoading(null);
    }

    return (
        <div className='manage-item-card g-list-item'>
            <div className='manage-item-grid'>
                <div className='store-item-icon-div'>
                    <ImageContainer src={item.icon} className='item-icon' />
                </div>
                <div className='store-item-text-div'>
                    <div className='item-name'>{item.name}</div>
                    <div className='item-desc'>{item.desc}</div>
                    <div className='item-price'>
                        Price: 
                        <ItemPrice 
                            item={item} 
                            saleList={saleList} 
                            gameElements={gameElements}
                        />
                    </div>
                    {
                        (item.opt_itemType === 'lottery') &&
                        <div className='item-desc'>
                            Tickets Sold: {gameStats[item.id] ? gameStats[item.id].toLocaleString() : 0}
                        </div> 
                    }
                    {
                        (closed) &&
                        <div className='access-list'>
                            {
                                closed.map(c => (
                                    <div key={c} className='meta access-item'>{c}</div>
                                ))
                            }
                        </div>
                    }
                    <div className='buttons'>                    
                        {
                            (loading === 'available') 
                            ?
                            <button className='g-button med-btn'><ThreeDotsLoader /></button>
                            :
                            closed.length === 0
                            ?
                            <button 
                                className='g-button med-btn open'
                                onClick={() => toggleItemAvailability()}
                            >
                                Available
                                <FontAwesomeIcon icon={faHand} />
                            </button>
                            :
                            closed.length === 1 && closed[0].includes('marked as unavailable')
                            ?
                            <button 
                                className='g-button med-btn closed'
                                onClick={() => toggleItemAvailability()}
                            >
                                Closed
                                <LayeredIcons 
                                    icon1={faHand}
                                    icon2={faSlash}
                                />
                            </button>
                            :
                            closed.length >= 1
                            &&
                            <button
                                className='g-button med-btn disabled'
                            >
                                Closed
                                <LayeredIcons 
                                    icon1={faHand}
                                    icon2={faSlash}
                                />
                            </button>
                        }
                        <button
                            className='g-button med-btn'
                            onClick={() => navigate(`/manage/${gameData.path}/elements/items/edit/${item.path}`)}
                        >
                            Edit
                            <FontAwesomeIcon icon={faPen} />
                        </button>
                        {
                            (loading === 'remove')
                            ?
                            <button className='g-button med-btn'><ThreeDotsLoader /></button>
                            :
                            <button
                                className='g-button med-btn'
                                onClick={() => removeItemFromStore()}
                            >
                                Remove
                                <FontAwesomeIcon icon={faBan} />
                            </button>
                        }
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default ManageItemCard