import React, { useEffect, useRef, useState } from 'react';
import './use-item-modal.styles.scss';
import Modal, { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import TextAreaWordCount from '@/components/SHARED/text-area-word-count/text-area-word-count.component';
import QuantityInput from '../store/store-components/quantity-input.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { RandomString } from '@/utils/randomString';
import { UseCloudDistribute } from '@/utils/firebase.utils';
import FullPageLoader from '@/components/loader/full-page-loader.component';

const UseItemModal = ({ 
    show, 
    cancel, 
    gameData,
    selectedItem, 
    playerData 
}) => {

    const [ formData, setFormData ] = useState({});
    const [ loading, setLoading ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ itemCount, setItemCount ] = useState(0);
    const dataRef = useRef();
    const approveRef = useRef();

    useEffect(() => {
        if (!playerData || !selectedItem) return;
        if (playerData.items && playerData.items[selectedItem.id]) {
            setItemCount(playerData.items[selectedItem.id].count);
        }
    }, [playerData, selectedItem])

    function saveData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}}
        setFormData(dataRef.current);
    }

    function changeQuantity(e) {
        // console.log(e);
        saveData('quantity', Number(e))
    }

    async function handleSubmit() {
        setConfirmMessage('Are you sure you are ready to use this Item?');
        approveRef.current = () => {
            continueApproval();
        }

        async function continueApproval() {
            const tempData = {...formData};
            setLoading(true);
            const now = new Date().getTime();
            const gameCode = gameData.gameId
            .substring(gameData.gameId.indexOf('-')-5, gameData.gameId.indexOf('-')+6)
            tempData.gameId = gameData.gameId;
            tempData.playerId = playerData.playerId;
            tempData.type = 'item';
            tempData.elementId = selectedItem.id;
            tempData.elementDocNum = selectedItem.docNum,
            tempData.id = `${now}-${gameCode}-item-actionitem`;
            tempData.status = 'pending';
            tempData.ts = now;
            tempData.ts_submitted = now;
            tempData.path = RandomString(18, 'abcdefghijklmnopqrstuvwxyz-_0123456789');
            console.log(tempData);
            const res = await UseCloudDistribute(
                'submitActionItem', 
                {
                    'playerId': tempData.playerId, 
                    'gameId': gameData.gameId, 
                    'actionItemData': tempData, 
                    'access': 'items'
                }
            )
            console.log(res);
            if (res.error) {
                setAlertMessage(`Something went wrong. Please try again later. (Error: ${res.error})`);
                setLoading(false);
                return;
            } else if (res.denied) {
                setAlertMessage(`Sorry, your access was denied. Reason: ${res.denied}`);
                setLoading(false);
                return;
            } 
            setAlertMessage('Your Item has been submitted successfully. You will be notified on your home page when your submission has been reviewed!');
            setLoading(false);
            cancel();
            return;
        }
    }

    return (
        <div className='use-item-modal'>
            <Modal 
                show={show} 
                cancel={cancel}
                text='Use This Item'
                closeButton={true}
                cls='narrow'
            >
                {
                    (selectedItem && itemCount) &&
                    <>
                    <div className='card-title'>
                        <ImageContainer
                            src={selectedItem.icon} 
                            className='head-icon'
                        />
                        {selectedItem.name}
                        <span className='count'>({itemCount})</span>
                    </div>
                    <div className='g-space-1'></div>
                    </>
                }
                <div className='field'>
                    <label>How many items do you want to use?</label>
                    {
                        (selectedItem && itemCount) &&
                        <QuantityInput 
                            initialValue={1}
                            max={itemCount}
                            min={1}
                            onQuantityChange={changeQuantity}
                        />
                    }
                </div>
                <div className="g-space-1"></div>
                <div className='field'>
                    <label>Include a Note (optional)</label>
                    <TextAreaWordCount
                        saveData={saveData}
                        count={50}
                        existingText={formData.note ?? ''}
                        keyId='inputText'
                    />
                </div>
                <div className="g-space-1"></div>
                <div className='buttons'>
                    <button 
                        className='g-button primary'
                        onClick={() => handleSubmit()}
                    >
                        Use Item
                    </button>
                </div>
            </Modal>
            <ModalAlert 
                show={alertMessage} 
                cancel={() => setAlertMessage(null)}
                message={alertMessage} 
            />
            <ModalConfirm
                show={confirmMessage}
                cancel={() => setConfirmMessage(null)}
                message={confirmMessage}
                onApprove={approveRef.current}
            />
            <FullPageLoader show={loading} />
        </div>
    )
}

export default UseItemModal