import React, { useEffect, useState } from 'react'
import './uploaded-file-button.styles.scss';
import { findIcon } from '@/utils/findIcon';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';

const UploadedFileButton = ({ url, path, type, name }) => {

    const [ intendedURL, setIntendedUrl ] = useState(null);
    const storage = getStorage();

    useEffect(() => {
        if (url) {
            setIntendedUrl(url);
        } else if (path) {
            async function getUrl() {
                const urlFromPath = await getDownloadURL(ref(storage, path))
                .then(uri => {
                    return uri;
                });
                setIntendedUrl(urlFromPath);
            }
            getUrl();
        }
    }, [url, path])

    function openWindow() {
        window.open(intendedURL, '_blank');
        return;
    }

    return (
        <div>
        {
            (intendedURL) &&
            <button className='g-button med-btn upload-btn' onClick={() => openWindow()}>
                {findIcon(type)} {name}
            </button>
        }
        </div>
    )
}

export default UploadedFileButton;