import React, { useEffect, useRef } from 'react'
import './games-page.styles.scss';
import HeadBar from '@/components/layout-auth/head-bar/head-bar.component';
import { Link } from 'react-router-dom';
import EnrolledList from '@/components/HOME/enrolled/enrolled-list.component';
import { useDispatch, useSelector } from 'react-redux';
import { setEnrolledList } from '@/state/slices/contentSlice';
import { getGameDataFromDb, getRegsFromDb } from '@/utils/getDataFromDb';

const GamesPage = () => {

    const profile = useSelector(state => state.userData.userProfile);
    const dispatch = useDispatch();
    const enrollRef = useRef();
    let regsListener;

    useEffect(() => {
        // change var(--primary) to default color
        var r = document.querySelector(':root');
        r.style.setProperty('--primary', '#674fa7');
        r.style.setProperty('--primary-trans', '#674fa7a6');

        return () => {
            // cancels any listeners
            if (regsListener) {regsListener();}
        }
    }, [])

    useEffect(() => {
        if (!profile) {return;}
        getData();
    }, [profile])

    function getData() {
        getRegs();
    }

    function getRegs() {
        // gets registration data for user, then gets game data for each registered game
        // also breaks data up into managing and playing
        // creates listener for reg changes - not game data changes
        getRegsFromDb({'appId': profile.appId, 'status': 'active', 'callback': callback, 'handleListener': handleListener })
        function callback(regData) {
            console.log(regData);
            // if (!managingList || !managingList[regData.gameId]) {
                getGameDataFromDb({'gameId': regData.gameId, 'once': true, 'callback': callback2});
                function callback2(gameData) {
                    gameData.roles = regData.roles[profile.appId];
                    gameData.lastEdit = regData.lastEdit;
                    console.log(gameData.roles);
                    
                    if (gameData.roles.includes('player')) {
                        enrollRef.current = {...enrollRef.current, ...{[gameData.gameId]: gameData}}
                        dispatch(setEnrolledList(enrollRef.current));
                    }
                }
            // }
        }   
        function handleListener(unsub) {
            regsListener = unsub;
        }
    }
    
    return (
        <div className='games-page'>
            <HeadBar />
            <div className='games-container'>
                <div className='g-card'>
                    <div className='section-title with-icon'>
                        <span>My Games</span>
                        <Link to='/home/joingame'>
                            <button className='g-button medium-btn'>Join a Game</button>    
                        </Link>
                    </div>
                    <hr className='no-top-margin' />
                    <EnrolledList />
                    
                </div>
            </div>
        </div>
    )
}

export default GamesPage