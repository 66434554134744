import React, { useEffect, useState } from 'react'
import './game-images.styles.scss'

import { resizeImage } from '@/utils/resizeImage';
import { existingHeaders } from './image-lists';
import { useSelector } from 'react-redux';
import Modal, { ModalAlert } from '@/components/modal/modal.component';
import placeholderHead from '@/assets/placeholder-head.jpg'
import ImageContainer from '@/components/SHARED/image-container/image-container.component';

const GameHeaderUpload = ({ saveData, formData, onApprove }) => {

    const [ showModal, setShowModal ] = useState(false);
    const [ image, setImage ] = useState(null);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ tempHeader, setTempHeader ] = useState(null);
    const [ resizedImage, setResizedImage ] = useState(null);
    const root = useSelector(state => state.userData.root);

    useEffect(() => {
        console.log(onApprove)
        if (!onApprove) {return;}
        saveChosenHeader();
    }, [onApprove])

    async function handleUpload(file) {
		// document.getElementById('selected-header').classList.add('hide')
		console.log(file.type)
		if (file.type.match(/image.*/)) {
			// const newFile = window.URL.createObjectURL(file)
            if (!file.type.includes('gif')) { 
                const newImage = await resizeImage(file, file.type)
                console.log(newImage)
                setResizedImage(newImage);
                const imageUrl = window.URL.createObjectURL(file)
                // c
                setTempHeader({'url': imageUrl, 'upload': true})
            } else {
                setTempHeader({'url': imageUrl, 'upload': true})
            }
		} else {
    		setAlertMessage('Please upload an image file.');
	    	return;
        }
	}

    function chooseIcon(path) {
        setShowModal(false);
        setTempHeader({'url': `${root}/${path}`, 'upload': false})
        // saveData('header', {upload: false, url: `${root}/${path}`});
    }

    function saveChosenHeader() {
        if (tempHeader.upload) {
            saveData('header', {'upload': true, 'url': tempHeader.url, 'file': resizedImage})
        } else {
            saveData('header', {'upload': false, 'url': tempHeader.url})
        }
    }

    return (
        <div className='new-game-form'>
            {/* <h4>Game Header  <span className='required'>*</span></h4><hr /> */}
            <div className="image-upload-form">
                <div className='icon-buttons center'>
                    <button className='g-button' onClick={() => setShowModal(true)}>
                        Choose an Existing Header
                    </button>
                    <div style={{cursor: 'pointer'}}>
                        <button className="g-button" type="button" tabIndex="-1">
                            <input type="file" accept="image/*" id="fileUpload" onChange={(e) => handleUpload(e.target.files[0])}  />
                            Upload a Custom header
                        </button>
                    </div>
                </div>
                <div className="center">
                    {/* <div id="selected-header">
                        {!tempHeader &&
                            <img className="game-header" referrerPolicy="no-referrer" src={placeholderHead} />
                        }
                    </div> */}
                    <div id="uploaded-header">
						{
                            (tempHeader) 
                            ?
                            <img className="game-header" referrerPolicy="no-referrer" src={tempHeader.url} />
                            :
                            (formData.header)
                            ?
                            <ImageContainer src={formData.header.url  ?? formData.header} alt='header image' />
                            :
                            <img className='game-header' referrerPolicy='no-referrer' src={placeholderHead} />
                        }
                    </div>
                </div>
            </div>
            <Modal show={showModal} cancel={() => setShowModal(false)} closeButton={true} cls='full' text='Choose an existing header'>
                <div className='modal-children header-choices'>
                    {
                        existingHeaders.map((icon, index) => (
                            <div className='header-choice' key={index} onClick={() => chooseIcon(icon)}>
                                <ImageContainer src={icon} alt='header icon to choose' className='header' />
                            </div>
                        ))
                    }   
                </div> 
            </Modal>
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
        </div>
    )
}

export default GameHeaderUpload