import { createSlice } from '@reduxjs/toolkit'
import { revertAll } from './userSlice'

const initialState = {
    managingList: {},
    enrolledList: null,
    tempGame: null,
    orgList: null,
    myOrgs: null,
    userActivity: null,
    screenSize: null,
    actionItems: null,
    newStuffCount: 0,
    pendingActionItems: 0,
    newPasscode: null
}

export const contentSlice = createSlice({
    name: 'content',
    initialState,
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
    reducers: {
        setManagingList: (state, action) => {
            state.managingList = action.payload
        },
        setEnrolledList: (state, action) => {
            state.enrolledList = action.payload
        },
        setTempGame: (state, action) => {
            state.tempGame = action.payload
        },
        setOrgList: (state, action) => {
            state.orgList = action.payload
        },
        setMyOrgs: (state, action) => {
            state.myOrgs = action.payload
        },
        setUserActivity: (state, action) => {
            state.userActivity = action.payload
        },
        setScreenSize: (state, action) => {
            state.screenSize = action.payload
        },
        setActionItems: (state, action) => {
            state.actionItems = action.payload
        },
        setNewStuffCount: (state, action) => {
            state.newStuffCount = action.payload
        },
        setPendingActionItems: (state, action) => {
            state.pendingActionItems = action.payload
        },
        setNewPasscode: (state, action) => {
            state.newPasscode = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { 
    setManagingList,
    setEnrolledList,
    setTempGame,
    setOrgList,
    setMyOrgs,
    setUserActivity,
    setScreenSize,
    setActionItems,
    setNewStuffCount,
    setPendingActionItems,
    setNewPasscode
} = contentSlice.actions

export default contentSlice.reducer