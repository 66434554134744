import React, { useEffect, useRef, useState } from 'react'
import './profile.styles.scss'

import ProfileIcon from './profile-icon.component'
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { setUserProfile } from '@/state/slices/userSlice';
import flatpickr from 'flatpickr'

import logo_long from '@/assets/logo_long.png';
import Footer from '@/components/layout-unauth/footer/footer.component';
import Modal, { ModalAlert } from '@/components/modal/modal.component';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import { getRandomLinearGradient } from '@/utils/randomGradient';

const ProfilePage = () => {

    const currentUser = useSelector(state => state.userData.currentUser);
    const userProfile = useSelector(state => state.userData.userProfile);
    const [ formData, setFormData ] = useState({});
    const [ loading, setLoading ] = useState(false);
    const [ fp, setFp ] = useState(null);
    const [ ofAge, setOfAge ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ lockBirthday, setLockBirthday ] = useState(false);
    const { joinCode } = useParams();
    const dataRef = useRef();
    const fpRef = useRef();
    let fpObj;
    const storage = getStorage();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (userProfile) {
            console.log(userProfile)
            dataRef.current = userProfile;
            setFormData(dataRef.current);
            setLockBirthday(true);
        } else {
            const tempProfile = JSON.parse(window.localStorage.getItem('tempProfile'));
            if (tempProfile) {
                tempProfile.gradient = getRandomLinearGradient();
                dataRef.current = tempProfile;
                setFormData(dataRef.current);
            }
        }
    }, [userProfile])

    useEffect(() => {
        if (!currentUser) {return;}
        saveData('email', currentUser.email);
    }, [currentUser])

    function saveData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}};
        setFormData(dataRef.current);
        console.log(dataRef.current);
    }

    useEffect(() => {
		const date1Element = document.getElementById('date');
        fpObj = flatpickr(date1Element, 
            {
                altInput: true,
                altFormat: "F j, Y",
                enableTime: false,
                dateFormat: "Y-m-d",
                onChange: (dates) => {displayBirthday(new Date(dates[0]), true); saveData('birthday', new Date(dates[0]).getTime())}
            }
        );
        if (userProfile) {
            fpObj.setDate(new Date(dataRef.current.birthday))
            displayBirthday(new Date(dataRef.current.birthday));
        }
	}, []);

    function displayBirthday(date, alert) {
        
        var month_diff = Date.now() - date.getTime();  
        var age_dt = new Date(month_diff);
        var year = age_dt.getUTCFullYear();  
        var age = Math.abs(year - 1970);  
        if (alert) {
            setAlertMessage(`You have entered that you are ${age} years old. Just making sure that this is correct.`)
        }
        if (age >= 18) {
            setOfAge(true)
        } else {
            setOfAge(false)
        }
    }

    function saveBio(text) {
        const length = text.length;
        const count = document.getElementById('word-count')
        if (length <= 200) {
            count.innerHTML = `${length}/200`   
            saveData('bio', text)
        }
    }

    async function handleSubmit() {
        if (!formData.fName || !formData.lName || !formData.birthday) {
            setAlertMessage('Please include your name and your birthday before saving your profile.')
            return;
        }
        if (!formData.icon) {
            saveProfile(null)
            return;
        }
		if (formData.icon.upload && formData.icon.type.indexOf('gif') == -1) {
			formData.icon.croppie.result({
				type:'blob',
				size: {'width': 352, 'height': 352},
                quality: 0.7,
			})
			.then((blob) => {
				uploadImage(formData.icon, blob)
				return;
			})
		} else if (formData.icon.upload) {
			uploadImage(formData.icon, formData.icon.file)
			return;
		} else if (formData.icon.url) {
			saveProfile(formData.icon.url)
			return;
		} else if (formData.icon) {
			saveProfile(formData.icon)
		} else {
            saveProfile();
        }
	}
				
	function uploadImage(profileIcon, blob) {		
		const now = new Date().getTime();
		const profileRef = ref(storage, `uploaded-profile-icons/${now}-${formData.icon.name}`)
		const metadata = {
			contentType: formData.icon.type,
            customMetadata: {
                appId: userProfile.appId ?? currentUser.uid,
                email: formData.email
            }
		};
		uploadBytes(profileRef, blob, metadata).then((snapshot) => {
			getDownloadURL(profileRef)
			.then((url) => {
				saveProfile(url)
				return;
			})
		})
	}
				
	const saveProfile = async (url) => {
        const tempData = {...dataRef.current};
        setLoading(true)
		let appId;
        if (!userProfile && !tempData.appId) {
		    appId = currentUser.uid;
        } else if (userProfile) {
            appId = userProfile.appId;
        } else {
            appId = tempData.appId;
        }
        const now = new Date().getTime();
        
        const profile = {
            appId: appId,
            fName: tempData.fName,
            lName: tempData.lName,
            email: currentUser.email,
            birthday: tempData.birthday,
            name: `${tempData.fName} ${tempData.lName}`,
            ts: now,
            uid: currentUser.uid,
            status: 'active',
            purpose: tempData.purpose,
            gradient: tempData.gradient
        }

        if (url) {
            profile.icon = url;
        }
        if (!tempData.ts_joined) {
            profile.ts_joined = now;
        }
        
        const res = await UseCloudFunction('saveProfile', {'profile': profile})
        if (res.success) {
            setTimeout(() => {
                navigate(`/loading${joinCode ? `/${joinCode}` : ''}`)
            }, 1000)
        } else {
            setAlertMessage('Something went wrong. Try again later.');
        }
        window.localStorage.removeItem('tempProfile');
        dispatch(setUserProfile(profile));
        return;
	}

    return (
        <div className='profile-page'>
            <div className='profile-form'>
                <div className='g-card'>
                    <div className='card-head'>
                        <span>Create a Profile</span>
                        <img src={logo_long} className='profile-logo' />
                    </div>
                    <hr />
                    <div className='g-grid'>
                        <div className="g-half-col">
                            <div className="field small">
                                <label>First Name</label>
                                <input type='text' value={formData.fName ?? ''} onChange={(e) => saveData('fName', e.target.value)} />
                            </div>
                        </div>
                        <div className="g-half-col">
                            <div className="field small">
                                <label>Last Name</label>
                                <input type='text' value={formData.lName ?? ''} onChange={(e) => saveData('lName', e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className='field small'>
                        <label>Email</label>
                        <input type='text' className='disabled' defaultValue={formData.email} disabled />
                    </div>
                    
                    <hr />
                    <h4>On what day were you born? Be sure to pick the correct year.</h4>
                    <p>We use this information to help keep our users safe. Check out our <a href="/info/privacy" target="_blank">Privacy Policy</a> for more information.</p>
                    <div className="field">
                        <input className="flatpickr flatpickr-input" placeholder='Choose your birth date' type="text" id="date" disabled={lockBirthday} />
                    </div>
                    
                    {
                        (formData.birthday) && 
                        <>
                            <hr />
                            <ProfileIcon formData={formData} saveData={saveData} ofAge={ofAge} />
                        
                            <div className="g-space-20"></div>
                            {/* <div className='field'>
                                <label>Tell us about you</label>
                                <textarea defaultValue={formData.bio ? formData.bio : ''} onChange={(e) => saveBio(e.target.value)}></textarea>
                                <div id='word-count'>0/200</div>
                            </div> */}
                            <hr />
                            <div className='field'>
                                <label>Optional: For what purpose are you planning to use this website?</label>
                                <select value={formData.purpose ?? ''} onChange={e => saveData('purpose', e.target.value)}>
                                    <option value='' disabled>Please choose an option...</option>
                                    <option value='Education/Classroom Use'>Education/Classroom Use</option>
                                    <option value='Education/Professional Development'>Education/Professional Development</option>
                                    <option value='Personal Use'>Personal Use</option>
                                    <option value='Business'>Business</option>
                                    <option value='For Fun!'>For Fun!</option>
                                    <option value='Other'>Other</option>
                                </select>
                            </div>
                            <div className='g-space-20'></div>
                            <div className='field'>
                                <label>Optional: If you are part of a school or business, what is its name?</label>
                                <input type='text' defaultValue={formData.school ? formData.school : ''} onChange={(e) => saveData('school', e.target.value)} />
                            </div>
                        </>
                    }
                    <hr />
                    <div className='buttons'>
                        {
                            (userProfile) && 
                            <button className='g-button' onClick={() => navigate(-1)}>Cancel</button>
                        }
                        { 
                            (!loading)
                            ? 
                            <button className='g-button save-profile-button primary' onClick={() => handleSubmit()}>Save Your Profile</button>
                            :
                            <button className='g-button save-profile-button disabled' ><ThreeDotsLoader /></button>
                        }
                    </div>
                </div>
                {/* <LandingPage /> */}
                <ModalAlert show={alertMessage} cancel={() => setAlertMessage(false)} message={alertMessage} />
                
            </div>
            <Footer />
        </div>
    )
}

export default ProfilePage