import React, { useEffect } from 'react';
import './visibility.styles.scss';
import ToggleInput from '@/components/SHARED/toggle-input/toggle-input.component';

const ManageContentVisibility = ({ type, saveData, formData, previewText, visibilityText, disabled }) => {

    useEffect(() => {
        saveData('opt_visible', true)
        saveData('opt_preview', false)
    }, [])

    useEffect(() => {
        if (!formData.opt_visible) {
            saveData('opt_preview', false)
        }
    }, [formData.opt_visible])

    return (
        <div className={`g-list-item form-section ${disabled}`}>
            <h3>Visibility</h3>
            <hr />
            <div className='toggle-with-text'>
                <ToggleInput contentKey="opt_visible" checked={formData.opt_visible} saveData={saveData} />
                {/* <div className='toggle-button'>
                    <input type="checkbox" id="checkbox" checked={formData.opt_visible ?? true} onChange={(e) => saveData('opt_visible', e.target.checked)} />
                </div> */}
                <div className='toggle-text'>
                    {visibilityText}
                </div>
            </div>
            {
                (type !== 'challenge') &&
                <>
                <div className='g-space-2'></div>
                {
                    (previewText) &&
                    <div className={`toggle-with-text ${!formData.opt_visible ? 'disabled': ''}`}>
                        <ToggleInput contentKey="opt_preview" checked={formData.opt_preview} saveData={saveData} />
                        {/* <div className='toggle-button'>
                            <input type="checkbox" id="checkbox" checked={formData.opt_preview ?? true} onChange={(e) => saveData('opt_preview', e.target.checked)} />
                        </div> */}
                        <div className='toggle-text'>
                            <div dangerouslySetInnerHTML={{ __html: previewText}} />
                        </div>
                    </div>
                }
                </>
            }
            <div className="g-space-1"></div>
        </div>
    )
}

export default ManageContentVisibility;