import React from 'react'
import './template-game-form.styles.scss';
import CreateEditForm2v2 from '@/components/HOME/create-edit-game/create-edit-form-2v2.component';
import ToggleInput from '@/components/SHARED/toggle-input/toggle-input.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';

const TemplateGameForm1 = ({ gamePath, formData, saveData, verifyAndMoveOn }) => {
    
    function saveToggle(key) {
        console.log(key);
        let newAccess = formData.access ? [...formData.access] : [];
        
        function toggleKey(key) {
            if (newAccess.includes(key)) {
                newAccess.splice(newAccess.indexOf(key), 1)
            } else {
                newAccess.push(key);
            }
        }
        function addKey(key) {
            if (!newAccess.includes(key)) {
                newAccess.push(key)
            }
        }
        toggleKey(key);
        if (newAccess.includes('free')) {
            addKey('pro');
        }
        if (newAccess.includes('pro')) {
            addKey('premium');
        }
        console.log(newAccess)
        saveData('access', newAccess)
    }


    return (
        <div className='template-game-form'>
            <div className='g-card form-card'>
                <div className='card-title'>
                    {!gamePath ? 'Create a New Template Game' : 'Edit Template Game'}
                </div>
                <hr />
                <div className='field'>
                    <label>Template Name</label>
                    <input 
                        type='text' 
                        value={formData.name ?? ''} 
                        onChange={e => saveData('name', e.target.value)}
                    />
                </div>
                <div className='g-space-1'></div>
                <label>Membership Level Access</label>
                <div className='g-space-0-5'></div>
                <div className='member-toggles'>
                    <div className='field with-toggle'>
                        <label className='with-image'>
                            <ImageContainer 
                                src='/images/icons/free.png' 
                                alt='groups icon' 
                                className='label-image' 
                            />
                            Free Accounts
                        </label>
                        <ToggleInput 
                            contentKey='free' 
                            checked={
                                formData.access ? 
                                formData.access.includes('free') : 
                                false
                            } 
                            saveData={saveToggle} 
                        />
                    </div>
                    <div className='field with-toggle'>
                        <label className='with-image'>
                            <ImageContainer 
                                src='/images/icons/pro.png' 
                                alt='groups icon' 
                                className='label-image' 
                            />
                            Pro Accounts
                        </label>
                        <ToggleInput 
                            contentKey='pro' 
                            checked={
                                formData.access ? 
                                formData.access.includes('pro') : 
                                false
                            } 
                            saveData={saveToggle} 
                        />
                    </div>
                    <div className='field with-toggle'>
                        <label className='with-image'>
                            <ImageContainer 
                                src='/images/icons/premium.png' 
                                alt='groups icon' 
                                className='label-image' 
                            />
                            Premium Accounts
                        </label>
                        <ToggleInput 
                            contentKey='premium' 
                            checked={
                                formData.access ? 
                                formData.access.includes('premium') : 
                                false
                            } 
                            saveData={saveToggle} 
                        />
                    </div>
                </div>
                <div className='g-space-1'></div>
                <label>Game Design</label>
                <CreateEditForm2v2 
                    edit={!!gamePath} 
                    formData={formData}
                    saveData={saveData}
                    verifyAndMoveOn={verifyAndMoveOn}
                    template={true}
                />

            </div>
        </div>
    )
}

export default TemplateGameForm1