import React, { useEffect, useRef, useState } from 'react'
import './prerequisites.styles.scss';
import PrereqForm from './prereq-form.component';
import ToggleInput from '@/components/SHARED/toggle-input/toggle-input.component';

const ManageContentPrerequisites = ({ type, saveData, formData, gameElements, availableItems, disabled, teams }) => {

    const [ prereqsExist, setPrereqsExist ] = useState(false);

    useEffect(() => {
        if (!formData) return;
        console.log(formData)
        const keys = Object.keys(formData);
        let exists = false;
        for (let k of keys) {
            if (k.includes('prereq') && formData[k]) {
                if (typeof formData[k] === 'object' && formData[k].length > 0) {
                    exists = true;
                } else if (typeof formData[k] !== 'object') {
                    exists = true;
                } else {
                    exists = false;
                }
            }
        }
        setPrereqsExist(exists);
    }, [formData])

    return (
        <div className={`manage-content-prereq g-list-item form-section ${disabled}`}>
            <h3>Prerequisites</h3>
            <hr />
            <div className="prereq-content">
                <div className='toggle-with-text'>
                    <ToggleInput 
                        contentKey="opt_prereq" 
                        checked={prereqsExist} 
                        saveData={saveData} 
                    />
                    <div className='toggle-text'>
                        Require prerequisites before a player can access this item?
                    </div>
                </div>
                <hr />
                <PrereqForm 
                    type={type} 
                    formData={formData} 
                    saveData={saveData} 
                    gameElements={gameElements} 
                    availableItems={availableItems}
                    teams={teams} 
                    disabled={!prereqsExist}
                />
            </div>
        </div>
    )
}

export default ManageContentPrerequisites