import React, { useState } from 'react'
import './icon.styles.scss';
import placeholder_icon from '@/assets/mystery-challenge.png'
import Croppie from 'croppie';
import HostedIconModal from './hosted-icon-modal.component';
import { ModalAlert } from '@/components/modal/modal.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';

const ManageContentIconCropped = ({ type, saveData, formData }) => {

    const [ showIconModal, setShowIconModal ] = useState(false) 
	const [ croppedImage, setCroppedImage ] = useState('')
	const [ croppie, setCroppie ] = useState(null)
    const [ alertMessage, setAlertMessage ] = useState(null);
    let croppieInstance

    const destroyCroppie = () => {
		const el = document.getElementById("uploaded-image")
		el.innerHTML = ''
		el.classList.remove("croppie-container");
	}

    function clickUpload() {
        document.getElementById('fileUpload').click();
    }

	function handleUpload(file) {
		document.getElementById('selected-image').classList.add('hide')
		destroyCroppie()
		console.log(file.type)
		if (file.type.match(/image.*/)) {
			const fileType = file.type;
			const fileName = file.name;
			console.log('An image has been loaded');
			console.log(fileType)
			// Load the image
			if (fileType.indexOf('gif') == -1) {
				const newFile = window.URL.createObjectURL(file)
				// setCroppedImage(newFile)
				const el = document.getElementById("uploaded-image")
				if (el) {
					croppieInstance = new Croppie(el, {
						viewport: { width: 125, height: 125 },
						boundary: { width: 125, height: 125 },
						showZoomer: true,
						enableOrientation: true
					});
					croppieInstance.bind({
						url: newFile,
					});
					setCroppie(croppieInstance)
				}
				setCroppedImage(newFile)
				saveData('icon', {upload: true, croppie: croppieInstance, type: fileType, name: fileName})
				return;
			}
            setAlertMessage('Please upload an image file.')
			document.getElementById('selected-image').classList.remove('hide')
            saveData('icon', null);
			// const newFile = window.URL.createObjectURL(file)
			// saveData('icon', {upload: true, url: newFile, type: fileType, name: fileName, file: file})
			return;
		}
		setAlertMessage('Please upload an image file.')
		return;
	}

    function chooseHostedIcon(url) {
        document.getElementById('selected-image').classList.remove('hide')
		destroyCroppie()
        saveData('icon', {'url': url})
    }


    return (
        <div className='manage-content-icon g-list-item form-section'>
            <h3>Icon{type === 'secretCode' ? ' (Optional)' : <span className='required'>*</span>}</h3>
            <hr />
            <div className="icon-upload-content">
                <div className="image-buttons">
                    <div style={{cursor: 'pointer'}}>
                        <input type="file" id="fileUpload" accept="image/*" onChange={(e) => handleUpload(e.target.files[0])}  />
                        <button className="g-button" type="button" tabIndex="-1" onClick={() => clickUpload()}>Upload an Icon</button>
                    </div>
                    <button type='button' className='g-button' onClick={() => setShowIconModal(true)}>Choose an Icon</button>
				</div>
				
				<div className="image-div">
                    <div id="uploadDiv">
                        <div id="selected-image">
                            { 
                                (formData.icon && formData.icon.url) 
                                ? 
                                <ImageContainer 
                                    className="profile-image" 
                                    src={formData.icon.url}
                                    alt='profile image' 
                                />
                                : 
                                (formData.icon && !formData.icon.upload)
                                ?
                                <ImageContainer
                                    className="profile-image"  
                                    src={formData.icon}
                                    alt='profile image' 
                                />
                                : 
                                <img className="profile-image" referrerPolicy="no-referrer" src={placeholder_icon} />
                            }
                        </div>
                        <div id="uploaded-image">
                        
                        </div>
                        <a className="rotateButton" id="rotateButton"><i className="large redo icon"></i></a>
                    </div>
                </div>
			</div>
            <div className="g-space-1"></div>
            <HostedIconModal show={showIconModal} cancel={() => setShowIconModal(false)} selectIcon={(url) => chooseHostedIcon(url)} type={type} />
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
        </div>
    )
}

export default ManageContentIconCropped