import React, { useEffect, useState } from 'react'
import './leaderboard-item.styles.scss';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { getRandomLinearGradient } from '@/utils/randomGradient';
import { useSelector } from 'react-redux';

const LeaderboardItem = ({ leader, index, liveData, levels, badges, playerView }) => {

    const screenSize = useSelector(state => state.contentData.screenSize);
    const [ earnedLevel, setEarnedLevel ] = useState(null);

    useEffect(() => {
        if (!levels) return;
        setEarnedLevel(Object.values(levels).sort((a,b) => a.opt_pointMinimum < b.opt_pointMinimum ? 1 : -1)
        .filter(l => l.opt_pointMinimum <= leader.data)[0]);
    }, [levels])
    
    return (
        <div className={`g-list-item leaderboard-item ${leader.animate ? 'jello-horizontal' : ''} ${screenSize} ${playerView ? 'mobile' : ''}`}>
            <div className='info'>
                <div>{index + 1}</div>
                {
                    (liveData.nameDisplay !== 'secret' && leader.icon)
                    ?
                    <ImageContainer src={leader.icon} className='leader-icon' alt='leader-icon' />
                    :
                    (liveData.nameDisplay !== 'secret')
                    ?
                    <div className='gradient-icon' style={{'background': leader.gradient}}>{leader.name[0]}</div>
                    :
                    <div className='gradient-icon' style={{'background': getRandomLinearGradient()}}>{leader.name[0]}</div>
                }
                {
                    (liveData.levels && earnedLevel) &&
                    <ImageContainer src={earnedLevel.icon} alt='level icon' className='icon' title={earnedLevel.name} />
                }
                <div className='name-badges'>
                    <div>{leader.name}</div>
                    {/* {
                        (badges && liveData.badges && leader.badges) &&
                        <div className='badge-row'>
                            {
                                leader.badges.map(badgeId => (
                                    <div key={badgeId}>
                                        <ImageContainer src={badges[badgeId].icon} alt={badges[badgeId].name + ' badge icon'} title={badges[badgeId].name} className='badge-icon' />
                                    </div>
                                ))
                            }
                        </div>
                    } */}
                </div>
                
            </div>
            <div className='info'>
                <div>{liveData.totals ? leader.data.toLocaleString("en-US") : ''}</div>
                {
                    (liveData.totals)
                    ?
                    liveData.data === 'points'
                    ?
                    <ImageContainer src='/images/xp.png' className='icon' />
                    :
                    <ImageContainer src='/images/icons/coins.png' className='icon' />
                    :
                    ''
                }
            </div>
        </div>
    )
}

export default LeaderboardItem