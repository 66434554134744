import React, { useEffect, useState } from 'react'
import './hidden-text.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const HiddenText = ({ text, hidden, stars }) => {
    
    const [ hide, setHide ] = useState(false);
    const [ starCount, setStarCount ] = useState(6);
    const [ starDiv, setStarDiv ] = useState(null);

    useEffect(() => {
        if (hidden) {
            setHide(true);
        }
    }, [hidden])

    useEffect(() => {
        if (!stars) return;
        setStarCount(stars);
    }, [stars])

    return (
        <div className='hidden-text'>
            {
                (hide)
                ?
                <div className='hidden-text-div'>
                    {
                        (starCount) &&
                        <div className='stars'>
                            {[...Array(starCount)].map((_, index) => (
                                <div key={index}>
                                    <FontAwesomeIcon icon={faAsterisk} />
                                </div>
                            ))
                        }
                        </div>
                    }
                    <button 
                        className='g-button text-only'
                        onClick={() => setHide(!hide)}
                    >
                        <FontAwesomeIcon 
                            icon={faEye} 
                            className='show-icon' />
                    </button>
                </div>
                :
                <div className='hidden-text-div'>
                    {text}
                    <button 
                        className='g-button text-only'
                        onClick={() => setHide(!hide)}
                    >
                        <FontAwesomeIcon 
                            icon={faEyeSlash} 
                            className='show-icon'
                        />
                    </button>
                </div>
            }
        </div>
    )
}

export default HiddenText