import React from 'react'
import './pricing-table.styles.scss';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ImageContainer from '../image-container/image-container.component';

const PricingTable = () => {

    const currentUser = useSelector(state => state.userData.currentUser);
    const membership = useSelector(state => state.userData.membership);

    function launchPurchase(e) {
        if (e === 'premium') {
            window.open(
                `${import.meta.env.VITE_PREMIUM_LINK_TEST}${currentUser ? `?prefilled_email=${currentUser.email}` : ''}`,
                 '_new');
        } else {
            window.open(import.meta.env.VITE_PRO_LINK_TEST, '_new');
        }
    }

    return (
        <div className='pricing-table-container'>
            <div className='pricing-table-content'>
                <div className={`g-card pricing-card ${membership && membership.membership === 0 ? `current-membership` : ''}`}>
                    <ImageContainer src='/images/icons/free.png' className='pricing-icon' />
                    <div className="g-space-1"></div>
                    <div className='card-title'>
                        Basic Plan
                    </div>
                    <p className='meta'>For users getting started</p>
                    <div className="g-space-0-5"></div>
                    <div className='card-title'>
                        Free
                    </div>
                    <div className="g-space-1"></div>
                    <Link 
                        to="/signin" 
                        className={
                            (
                                membership &&
                                membership.membership > 0
                            ) ? 'disabled' : ''
                        }
                    >
                        <button className='g-button med-btn' >
                            Continue For Free
                        </button>
                    </Link>
                    <div className="g-space-1"></div>
                    <ul>
                        <li>2 Active Games</li>
                        <li>2 Available Game Styles: Points and a Leaderboard or Currency and a Store</li>
                        <li>Up to 50 Game Elements per Game</li>
                        <li>Up to 50 Players/Teams per Game</li>
                        {/* <li>Access to Basic Game Templates</li> */}
                        
                    </ul>
                    
                </div>
                <div className={`g-card pricing-card ${membership && membership.membership === 1 ? `current-membership` : ''}`}>   
                    <ImageContainer src='/images/icons/pro.png' className='pricing-icon' />
                    <div className="g-space-1"></div>
                    <div className='card-title'>
                        Pro Plan
                    </div>
                    <p className='meta'>Level up your gamification</p>
                    <div className="g-space-0-5"></div>
                    <div className='card-title'>
                        <span>$9</span><span className='meta'>/month</span>
                    </div>
                    <div className="g-space-1"></div>
                    {
                        (membership && membership.membership === 1) 
                        ?
                        <button className='g-button med-btn primary no-click' >
                            Purchased!
                        </button>
                        :
                        <a 
                            href={`${import.meta.env.VITE_PRO_LINK_TEST}${currentUser ? `?prefilled_email=${currentUser.email}` : ''}`} 
                            target="_new"
                            className={
                                (
                                    membership &&
                                    membership.membership > 0 &&
                                    membership.membership !== 1
                                ) ? 'disabled' : ''
                            }
                        >
                            <button className='g-button med-btn' >
                                Purchase Plan
                            </button>
                        </a>
                    }
                    <div className="g-space-1"></div>
                    <ul>
                        <li>10 Active Games</li>
                        <li>$10 per Additional Game</li>
                        <li>No Restricted Game Styles</li>
                        <li>Up to 200 Players/Teams per Game</li>
                        <li>Up to 200 Game Elements per Game</li>
                        <li>Access to Premium Features like collectibles, prize packs, and quests</li>
                        {/* <li>Access to All Game Templates</li> */}
                        
                    </ul>
                </div>
                <div className={`g-card pricing-card ${membership && membership.membership === 2 ? `current-membership` : ''}`}>   
                    <ImageContainer src='/images/icons/premium.png' className='pricing-icon' />
                    <div className="g-space-1"></div>
                    <div className='card-title'>
                        Premium Plan
                    </div>
                    <p className='meta'>For hardcore gamifiers</p>
                    <div className="g-space-0-5"></div>
                    <div className='card-title'>
                        <span>$15</span><span className='meta'>/month</span>
                    </div>
                    <div className="g-space-1"></div>
                    {
                        (membership && membership.membership === 2) 
                        ?
                        <button className='g-button med-btn primary  no-click' >
                            Purchased!
                        </button>
                        :
                        <a 
                            href={`${import.meta.env.VITE_PREMIUM_LINK_TEST}${currentUser ? `?prefilled_email=${currentUser.email}` : ''}`} 
                            target="_new"
                            className={
                                (
                                    membership &&
                                    membership.membership > 0 &&
                                    membership.membership >= 2
                                ) ? 'disabled' : ''
                            }
                        >
                            <button className='g-button med-btn' >
                                Purchase Plan
                            </button>
                        </a>                        
                    }
                    <div className="g-space-1"></div>
                    <ul>
                        <li>10 Active Games</li>
                        <li>$5 per Additional Game</li>
                        <li>No Restricted Game Styles</li>
                        <li>Up to 1,000 Players/Teams per Game</li>
                        <li>UNLIMITED Game Elements per Game</li>
                        <li>Access to Premium Features like collectibles, prize packs, and quests</li>
                        {/* <li>Access to All Game Templates</li> */}
                        
                    </ul>
                </div>
                <div className={`g-card pricing-card ${membership && membership.membership === 3 ? `current-membership` : ''}`}>   
                    <ImageContainer src='/images/icons/enterprise.png' className='pricing-icon' />
                    <div className="g-space-1"></div>
                    <div className='card-title'>
                        Enterprise Plan
                    </div>
                    <p className='meta'>For organizations</p>
                    <div className="g-space-0-5"></div>
                    <div className='card-title'>
                        Contact Us
                    </div>
                    <div className="g-space-1"></div>
                    {
                        (membership && membership.membership > 2) 
                        ?
                        <button className='g-button med-btn primary no-click' >
                            Purchased!
                        </button>
                        :
                        <Link to="/quote">
                            <button className='g-button med-btn' >
                                Request a Quote
                            </button>
                        </Link>
                    }
                    <div className="g-space-1"></div>
                    <ul>
                        <li>UNLIMITED Games</li>
                        <li>No Restricted Game Styles</li>
                        <li>Multiple Managers Per Game</li>
                        <li>Shared Game Content</li>
                        <li>UNLIMITED Players/Teams per Game</li>
                        <li>UNLIMITED Game Elements per Game</li>
                        <li>Access to Premium Features like collectibles, prize packs, and quests</li>
                        {/* <li>Access to All Game Templates</li> */}
                        
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default PricingTable