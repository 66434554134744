import React, { useState } from 'react'
import './icon.styles.scss';
import placeholder_icon from '@/assets/mystery-challenge.png'
import HostedIconModal from './hosted-icon-modal.component';
import { ModalAlert } from '@/components/modal/modal.component';
import { resizeImage } from '@/utils/resizeImage';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';

const ManageContentIconNoCrop = ({ type, saveData, formData }) => {

    const [ showIconModal, setShowIconModal ] = useState(false) 
    const [ alertMessage, setAlertMessage ] = useState(null);

    function clickUpload() {
        document.getElementById('fileUpload').click();
    }

    function handleUpload (file) {
        function cancelUpload() {
            document.getElementById('fileUpload').value = null;
            saveData('icon', null);
            // setFileName(null);
            return;
        }
        const ext = file.name.match(/\.([^\.]+)$/)[1].toLowerCase();
        console.log(ext);
        const allowed = ['jpg','bmp','png','gif','tif','webp']
        if (!allowed.includes(ext)) {
            setAlertMessage('That file type is not allowed to be uploaded. Try a different file.');
            cancelUpload();
            return;
        }
        console.log(file.size);
        if (file.size > 2 * 1024 * 1024) {
            setAlertMessage('That file is too large to be uploaded. You cannot upload files larger than 2MB.');
            cancelUpload();
            return;
        }
        setFile(file);
    }

	async function setFile(file) {
		// document.getElementById('selected-image').classList.add('hide')
		console.log(file.type)
		if (file.type.match(/image.*/)) {
			const fileType = file.type;
			const fileName = file.name;
			console.log('An image has been loaded');
			console.log(fileType)
			// Load the image
			if (fileType.indexOf('gif') == -1) {
				const newFile = await resizeImage(file);
                const newUrl = window.URL.createObjectURL(file);
				saveData('icon', {upload: true, url: newUrl, file: newFile, type: fileType, name: fileName})
                console.log(newFile);
				return;
			}
            // setAlertMessage('Please upload an image file.')
			// document.getElementById('selected-image').classList.remove('hide')
            // saveData('icon', null);
			const newFile = window.URL.createObjectURL(file)
			saveData('icon', {upload: true, url: newFile, type: fileType, name: fileName, file: file})
			return;
		}
		setAlertMessage('Please upload an image file.')
		return;
    }
    

    function chooseHostedIcon(url) {
        document.getElementById('selected-image').classList.remove('hide')
        saveData('icon', {'url': url})
    }


    return (
        <div className='manage-content-icon g-list-item form-section'>
            <h3>Icon<span className='required'>*</span></h3>
            <hr />
            <div className="icon-upload-content">
                <div className="image-buttons">
                    <div style={{cursor: 'pointer'}}>
                        <input type="file" id="fileUpload" accept="image/*" onChange={(e) => handleUpload(e.target.files[0])}  />
                        <button className="g-button" type="button" tabIndex="-1" onClick={() => clickUpload()}>Upload an Icon</button>
                    </div>
                    <button type='button' className='g-button' onClick={() => setShowIconModal(true)}>Choose an Icon</button>
				</div>
				
				<div className="image-div">
                    <div id="uploadDiv">
                        <div id="selected-image">
                            { 
                                (formData.icon && formData.icon.url) 
                                ? 
                                <ImageContainer
                                    className="profile-image" 
                                    src={formData.icon.url}
                                    alt='profile image'
                                />
                                : 
                                (formData.icon && !formData.icon.upload)
                                ?
                                <ImageContainer
                                    className="profile-image" 
                                    src={formData.icon}
                                    alt='profile image'
                                />
                                : 
                                <img className="profile-image" referrerPolicy="no-referrer" src={placeholder_icon} />
                            }
                        </div>
                        <div id="uploaded-image">
                        
                        </div>
                        <a className="rotateButton" id="rotateButton"><i className="large redo icon"></i></a>
                    </div>
                </div>
			</div>
            <div className="g-space-1"></div>
            <HostedIconModal show={showIconModal} cancel={() => setShowIconModal(false)} selectIcon={(url) => chooseHostedIcon(url)} type={type} />
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
        </div>
    )
}

export default ManageContentIconNoCrop