import React, { useEffect, useRef } from 'react'
import './dates.styles.scss';
import flatpickr from 'flatpickr';

const ManageContentDates = ({ type, saveData, formData, gameElements, text, disabled, title }) => {

    const date1Ref = useRef();
    const date2Ref = useRef();

    useEffect(() => {
        const openDateElement = document.getElementById('openDate');
        if (!date1Ref.current) {
            const flat1 = flatpickr(openDateElement, 
                {
                    altInput: true,
                    altFormat: "F j, Y, h:i K",
                    enableTime: true,
                    dateFormat: "Y-m-d",
                    onChange: (dates) => {
                        const date = new Date(dates[0]).getTime()
                        saveData('opt_dateOpen', date)
                    }
                }
            );
            date1Ref.current = flat1;
            // console.log('set fp')
        }
        const closeDateElement = document.getElementById('closeDate');
        if (!date2Ref.current) {
            const flat2 = flatpickr(closeDateElement, 
                {
                    altInput: true,
                    altFormat: "F j, Y, h:i K",
                    enableTime: true,
                    dateFormat: "Y-m-d",
                    onChange: (dates) => {
                        const date = new Date(dates[0]).getTime()
                        saveData('opt_dateClose', date)
                    }
                }
            );
            date2Ref.current = flat2;
            // console.log('set date2')
        }
    }, [])

    useEffect(() => {
        if (!formData || !date1Ref.current || !date2Ref.current) {return;}
        if (formData.opt_dateOpen) {
            date1Ref.current.setDate(new Date(formData.opt_dateOpen));
        }
        if (formData.opt_dateClose) {
            date2Ref.current.setDate(new Date(formData.opt_dateClose));
        }
    }, [formData, date1Ref.current, date2Ref.current])

    return (
        <div className={`manage-content-dates g-list-item form-section ${disabled}`}>
            <h3>{title ?? 'Dates of Availability'}</h3>
            <hr />
            <p>{text}</p>
            <div className="dates-content">
                <h3>Open Date</h3>
                <div className='field small'>
                    <label>Leave blank if you want it available immediately.</label>
                    <input className="flatpickr flatpickr-input"  id="openDate" placeholder='Open Date' />
                </div>
                <h3>Close Date</h3>
                <div className='field small'>
                    <label>Leave blank if you want it available indefinitely.</label>
                    <input className="flatpickr flatpickr-input" type="text" id="closeDate" placeholder='Close Date' />
                </div>
            </div>
            <div className="g-space-1"></div>
        </div>
    )
}

export default ManageContentDates