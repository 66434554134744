import React from 'react'
import './page-not-found.styles.scss'

import icon from '@/assets/icon.png';
import { useNavigate } from 'react-router-dom'

const PageNotFound = () => {

    const navigate = useNavigate();
    
    return (
        <div className="not-found-page">
            <div className="container">
                <div className="image">
                    <img src={icon} alt="404 Error" />
                </div>
                <div className="content">
                    <h1>404 Page Not Found</h1>
                    <p>The page you are looking for does not exist.</p>
                    <button className='g-button' onClick={() => navigate(-1)}>Back</button>
                </div>
            </div>
        </div>
    )
}

export default PageNotFound