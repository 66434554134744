import React, { useEffect, useState } from 'react'
import './visibility-button.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faRotate, faSlash } from '@fortawesome/free-solid-svg-icons';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { useSelector } from 'react-redux';
import LayeredIcons from '@/components/SHARED/layered-icons/layered-icons.component';
import { faHand } from '@fortawesome/free-regular-svg-icons';

const VisibilityButton = ({ element, store }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ visible, setVisible ] = useState(false);
    const [ available, setAvailable ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ availLoading, setAvailLoading ] = useState(false);
    const [ disabled, setDisabled ] = useState(false);  //!element.opt_visible
    let visInterval;

    useEffect(() => {
        if (!element) return;
        checkVisibility();
        visInterval = setInterval(() => {
            checkVisibility();
        }, 100000)

        return () => {
            if (visInterval) clearInterval(visInterval);
        }
    }, [element, store])

    function checkVisibility() {
        setVisible(element.opt_visible);
        const now = new Date().getTime();
        let avail = element.opt_available;
        let notyetopen = element.opt_dateOpen ? element.opt_dateOpen > now : false;
        let closed = element.opt_dateClose ? element.opt_dateClose < now : false;
        // console.log(notyetopen, closed);
        let combined = (element.opt_dateOpen ? element.opt_dateOpen > now : false) || 
        (element.opt_dateClose ? element.opt_dateClose < now : false);
        // console.log(combined);
        if (
            (element.opt_dateOpen ? element.opt_dateOpen > now : false) || 
            (element.opt_dateClose ? element.opt_dateClose < now : false)
        ) {
            // console.log('disabling')
            avail = false;
            setDisabled(true);
        } else {
            setDisabled(false);
        }
        if (!element.opt_visible) {
            avail = false;
            setDisabled(true);
        }
        setAvailable(avail);
    }

    async function changeVisibility(id, newSetting, key) {
        if (key === 'opt_visible') {
            setLoading(true);
        } else if (key === 'opt_available') {
            setAvailLoading(true);
        }
        const res = await UseCloudFunction(
            'changeElementVisibility', 
            {
                'gameId': element.gameId,
                'elementDocNum': element.docNum,
                'appId': userProfile.appId, 
                'elementId': id, 
                'newSetting': newSetting,
                'key': key
            }
        )
        if (key === 'opt_visible') {
            setLoading(false);
        } else if (key === 'opt_available') {
            setAvailLoading(false);
        }
        // console.log(res);
    }

    return (
        <div className='visibility-icons'>
            {
                (element) &&
                <>
                <button 
                    className={`g-button med-btn visibility-button ${disabled ? 'disabled' : ''} ${available ? 'green' : 'red'}`} 
                    title='Toggle Availablility' 
                    onClick={() => changeVisibility(element.id, !available, 'opt_available')}
                >
                    {
                        (availLoading)
                        ?
                        <FontAwesomeIcon icon={faRotate} className='spinning-icon' />
                        :
                        (available)
                        ?
                        <FontAwesomeIcon icon={faHand} />
                        :
                        <LayeredIcons
                            icon1={faHand}
                            icon2={faSlash}
                        />
                    }
                </button>
                {
                    (element.type !== 'secretCode') &&
                    <button 
                        className={`g-button med-btn visibility-button ${visible ? 'green' : 'red'}`} 
                        title='Toggle Visibility' 
                        onClick={() => changeVisibility(element.id, !visible, 'opt_visible')}>
                            <FontAwesomeIcon 
                                icon={
                                    (loading)
                                    ?
                                    faRotate
                                    :
                                    (visible) 
                                    ? 
                                    faEye 
                                    : 
                                    faEyeSlash
                                }
                                className={
                                    loading
                                    ?
                                    'spinning-icon'
                                    :
                                    ''
                                }  
                            />
                    </button>
                }
                </>
            }
        </div>
    )
}

export default VisibilityButton