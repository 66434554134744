import CreateEditForm2v2 from '@/components/HOME/create-edit-game/create-edit-form-2v2.component';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import React from 'react'
import { useNavigate } from 'react-router-dom'

const SettingsDesign = ({
    formData,
    saveData,
    saveSettings,
    cancel
}) => {

    const navigate = useNavigate();

    return (
        <div className='settings-design'>
            <div className='g-card'>
                <BackButton cancel={cancel} />
                <div className="g-space-1"></div>
                <div className='card-title'>Settings: Game Design</div>
                <div className="g-space-1"></div>
                <div className='g-list-item grey-background'>
                    <div className="g-space-1"></div>
                    <CreateEditForm2v2 
                        edit={true} 
                        formData={formData} 
                        saveData={saveData} 
                    />
                </div>
                <div className="g-space-1"></div>
                <div className='buttons'>
                    <button className='g-button' onClick={cancel}>Cancel</button>
                    <button className='g-button primary' onClick={() => saveSettings('design')}>Save Changes</button>
                </div>
            </div>
        </div>
    )
}

export default SettingsDesign