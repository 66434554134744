import React from 'react'
import './manage-trade-portal.styles.scss';

const ManageTradePortal = () => {
    return (
        <div className='manage-trade-portal'>
            ManageTradePortal
        </div>
    )
}

export default ManageTradePortal