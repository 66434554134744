import React, { useEffect } from 'react';
import'./transaction-item-card.styles.scss';
import ImageContainer from '../image-container/image-container.component';
import { properCase } from '@/utils/properCase';

const TransactionItemCard = ({ elementData, transType }) => {

    useEffect(() => {
        console.log(elementData);
    }, [elementData])

    return (
        <div className='transaction-item-card'>
            <div className='g-card'>
                <div className={`grid ${elementData.type === 'item' || elementData.type === 'items' ? 'with-qty' : ''} ${transType === 'purchase' ? 'with-price' : ''}`}>
                    <div className='card-icon'>
                        <ImageContainer
                            src={elementData.icon} />
                    </div>
                    <div className='card-info'>
                        <h4>{elementData.name ?? elementData.title}</h4>
                        <div className='meta'>
                            {properCase(elementData.type)}
                            {elementData.itemType && ` | ${properCase(elementData.itemType === 'realworld' ? 'real world' : elementData.itemType)}`}
                        </div>
                    </div>
                    {
                        (elementData.quantity) &&
                        <div className='card-info meta'>
                            Qty: {elementData.quantity}
                        </div>
                    }
                    {
                        (elementData.cost) &&
                        <div className='card-price meta'>
                           <ImageContainer src='/images/icons/coin.png' className='coin-icon' /><b>{elementData.cost}</b>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default TransactionItemCard