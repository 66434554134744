import React from 'react';
import './unauth-layout.styles.scss';
import UnauthHeader from './unauth-head.component';
import Footer from './footer/footer.component';

const UnauthLayout = ({ children }) => {
    return (
        <div className='unauth-layout'>
            <div className='header-container'>
                <UnauthHeader />
            </div>
            <div className='layout-body'>
                {children}
                <div className="g-space-2"></div>
            </div>
            <div className='footer-container'>
                <Footer />
            </div>
        </div>
    )
}

export default UnauthLayout