import React from 'react';
import './manage-item-list.styles.scss';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import ItemPrice from '@/components/SHARED/item-price/item-price.component';
import ManageItemCard from './manage-item-card.component';

const ManageItemList = ({ itemsToDisplay, saleList, gameElements, gameStats, gameData }) => {
    return (
        <div className='manage-item-list'>
            {
                (itemsToDisplay && gameElements) &&
                itemsToDisplay
                .sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                .map(item => {
                    return (
                    <div key={item.id} >
                        <ManageItemCard
                            item={item}
                            saleList={saleList}
                            gameElements={gameElements}
                            gameStats={gameStats}
                            gameData={gameData}
                        />
                    </div>
                    )
                })
            }
        </div>
    )
}

export default ManageItemList