import React, { useEffect, useRef, useState } from 'react'
import './leaderboard.styles.scss';
import { getGameElementsFromDbForPlayers, getLeaderboardDataFromDb, getLiveDataFromDb, getUniqueDocumentFromDb } from '@/utils/getDataFromDb';
import { useParams } from 'react-router-dom'
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import UserIcon from '@/components/layout-auth/user-icon/user-icon.component';
import LeaderboardItem from './leaderboard-item.component';
import { useSelector } from 'react-redux';

const LeaderboardPage = ({ playerView }) => {

    const screenSize = useSelector(state => state.contentData.screenSize);
    const { livePath } = useParams();
    const [ liveData, setLiveData ] = useState(null);
    const [ leaderData, setLeaderData ] = useState(null);
    const [ levels, setLevels ] = useState(null); 
    const [ badges, setBadges ] = useState(null);
    const [ gameData, setGameData ] = useState(null);
    const [ dataToDisplay, setDataToDisplay ] = useState({});
    const levelRef = useRef();
    const badgeRef = useRef();
    let liveListener;
    let gameListener;
    let leaderboardListener;
    let levelListener;
    let badgeListener;
 
    useEffect(() => {

        return () => {
            if (liveListener) liveListener();
            if (gameListener) gameListener();
            if (leaderboardListener) leaderboardListener();
            if (levelListener) levelListener();
            if (badgeListener) badgeListener();
        }
    }, [])

    useEffect(() => {
        if (!livePath) return;
        // console.log(livePath);
        
        getLiveData();
    }, [livePath])

    useEffect(() => {
        if (!leaderData || !liveData) return;
        let leaderObj = {};
        for (let l of Object.values(leaderData)) {
            if ((liveData.indOrGroups === 'groups' && !l.group) || (liveData.indOrGroups === 'individuals' && l.group)) {
                continue;
            }
            let obj = {
                'playerId': l.playerId,
                'data': l[liveData.data] ?? 0,
                'name': liveData.nameDisplay === 'full' 
                ? `${l.fName} ${l.lName}` 
                : liveData.nameDisplay === 'display' 
                ? l.displayName : 
                l.secretName,
                'icon': l.icon,
                'gradient': l.gradient,
                'badges': l.badges,
            }
            if (dataToDisplay[obj.playerId] && dataToDisplay[obj.playerId].data !== obj.data) {
                obj.animate = true;
            }
            leaderObj[obj.playerId] = obj
        }
        setDataToDisplay(leaderObj);
    }, [leaderData, liveData])

    function getLiveData() {
        // console.log('getting live data')
        getLiveDataFromDb({'path': livePath, 'status': 'active', 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            setLiveData(data);
            // console.log(data);
            getLeaderboardData(data.gameId);
            getGameData(data.gameId);
            if (data.levels) {
                getLevels(data.gameId);
            }
            // if (data.badges) {
            //     getBadges(data.gameId);
            // }
        }
        function handleListener(unsub) {
            liveListener = unsub;
        }
    }

    function getGameData(gameId) {
        getUniqueDocumentFromDb({
            'collection': 'games',
            'docId': gameId,
            'callback': callback,
            'handleListener': handleListener,
        })
        function callback(data) {
            setGameData(data);
        }
        function handleListener(unsub) {
            gameListener = unsub;
        }
    }
    
    function getLeaderboardData(gameId) {
        // console.log('getting leaderboard data')
        getLeaderboardDataFromDb({'gameId': gameId, 'status': 'active', 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            setLeaderData(data);
        }
        function handleListener(unsub) {
            leaderboardListener = unsub;
        }
    }

    function getLevels(gameId) {
        getGameElementsFromDbForPlayers({'gameId': gameId, 'type': 'level', 'status': 'active', 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            levelRef.current = {...levelRef.current, ...{[data.id]: data}}
            setLevels(levelRef.current);
        }
        function handleListener(unsub) {
            levelListener = unsub;
        }
    }

    return (
        <div className='leaderboard-page-container'>
        {
            (gameData) 
            ?
            <div className={`leaderboard-page-content ${screenSize}`} style={
                (gameData.bg.type === 'gradient')
                ?
                {'background': `linear-gradient(to bottom, ${gameData.bg.hexTop}, ${gameData.bg.hexBottom})`}
                :
                {'background': `url("${gameData.bg.url}") no-repeat center center fixed`}
            }>
                
                <div className='leaderboard-header content'>
                    <div className='leaderboard-head-image'>
                        <ImageContainer src={gameData.header} alt='game primary icon' />
                    </div>
                    {/* <div className='g-space-0-5'></div> */}
                    {
                        (liveData) &&
                        <div className='leaderboard-head-title'>
                            {liveData.name}
                        </div>
                    }
                </div>
                <div className='g-space-1-5'></div>
                {
                    (Object.values(dataToDisplay).length > 0 && liveData)
                    ?
                    <div className='leaderboard-content content'>
                    {
                        Object.values(dataToDisplay).sort((a,b) => {
                            if (a.data < b.data) {
                                return 1;
                            } else { 
                                return -1;
                            }
                        })
                        // .filter(p => (liveData.indOrGroups === 'groups' && p.group) || (liveData.indOrGroup === 'individuals' && !p.group))
                        .map((l, index) => {
                            if (index + 1 > liveData.count) return;
                            return (
                            <div  key={l.playerId}>
                                <LeaderboardItem leader={l} index={index} liveData={liveData} levels={levels} playerView={playerView} />
                            </div>
                            )
                        })
                    }
                    </div>
                    :
                    <div className='center'><h1>Loading...</h1></div>
                }
            </div>
            :
            <div className='center'><h1>Loading Game Data...</h1></div>
        }
        </div>
    )
}

export default LeaderboardPage