import React, { useEffect, useState } from 'react';
import './element-data-display.styles.scss';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { useNavigate, useParams } from 'react-router-dom';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { faArrowsUpToLine, faBoxOpen, faCubesStacked, faInfinity, faPercentage } from '@fortawesome/free-solid-svg-icons';
import DataCard from '../../game-dashboard/data-card.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '@/components/modal/modal.component';
import Chart3 from '../../game-dashboard/chart3.component';
import { format } from 'date-fns';

const ElementDataDisplay = ({
    gameData,
    gameStats,
    gameElements,
    playerList,
    gameActivity
}) => {

    const [ element, setElement ] = useState(null);
    const [ totalOwned, setTotalOwned ] = useState(null);
    const [ totalAllowed, setTotalAllowed ] = useState(null);
    const [ remaining, setRemaining ] = useState(0);
    const [ showLimitModal, setShowLimitModal ] = useState(false);
    const [ newLimit, setNewLimit ] = useState(0);
    const [ itemActivity, setItemActivity ] = useState(null);
    const [ owners, setOwners ] = useState(null);
    const [ elementTypePath, setElementTypePath ] = useState(null);
    const [ showAvailable, setShowAvailable ] = useState(false);
    const { elementPath } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!elementPath || !gameElements) return;
        let elmt = Object.values(gameElements)
        .filter(e => e.path === elementPath)[0];
        setElement(elmt);
        setNewLimit(elmt.opt_limitTotal ?? 0);
        if (elmt.type === 'item' && elmt.opt_limitTotal) {
            setShowAvailable(true);
        }
    },[elementPath, gameElements])

    useEffect(() => {
        if (!gameStats || !element) return;
        setTotalOwned(gameStats[element.id] ?? 0);
        if (!element.opt_limitTotal) {
            setRemaining(<FontAwesomeIcon icon={faInfinity} />);
            return;
        }
        let rem = element.opt_limitTotal - gameStats[element.id];
        setRemaining(rem);
    },[gameStats, element])

    useEffect(() => {
        if (!element || !gameActivity) return;
        let actArr = Object.values(gameActivity)
        .filter(a => 
            a.elementId === element.id ||
            (
                a.data &&
                Object.values(a.data)
                .filter(d => d.id === element.id)
                .length > 0
            )
        )
        setItemActivity([...actArr])
    }, [element, gameActivity])

    useEffect(() => {
        if (!element || !playerList) return;
        const type = element.type + 's';
        setElementTypePath(type);
        let players = Object.values(playerList)
        .filter(p => 
            !p.team &&
            p.status === 'active' &&
            p[type] &&
            p[type][element.id] &&
            p[type][element.id].count > 0
        )
        setOwners([...players])
        if (element.type === 'badge') {
            setTotalAllowed(
                Object.values(playerList)
                .filter(p => !p.team && p.status === 'active')
                .length
            )
        } else if (element.opt_limitTotal) {
            setTotalAllowed(element.opt_limitTotal)
        } else {
            setTotalAllowed(<FontAwesomeIcon icon={faInfinity} />)
        }
    }, [element, playerList])

    return (
        <div className='element-data-display'>
            <div className='element-data-content g-card'>
                <BackButton cancel={() => navigate(-1)} />
                <div className="g-space-1"></div>
                {
                    (element) &&
                    <div className='content'>
                        <div className='title-box with-icon'>
                            <ImageContainer 
                                src={element.icon} 
                                className='title-icon'
                            />
                            <div className='title-text'>
                                <div className='card-title'>
                                    {element.name}
                                </div>
                                <div className="g-space-0-5"></div>
                                <div className='meta'>
                                    {element.desc}
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className={`card-row ${showAvailable ? 'four-cards' : ''}`}>
                            <DataCard 
                                icon={faCubesStacked}
                                data={totalOwned}
                                text='Owned/ Earned'
                            />
                            <DataCard
                                icon={faArrowsUpToLine}
                                data={totalAllowed}
                                text='Total Allowed'
                            />
                            <DataCard
                                icon={faPercentage}
                                data={
                                    (!isNaN(totalAllowed)) ?
                                    ((totalOwned / totalAllowed) * 100).toFixed(0) + '%' :
                                    '0%'
                                }
                                text='Earned'
                            />
                            {
                                (showAvailable) &&
                                <DataCard
                                    icon={faBoxOpen}
                                    data={remaining}
                                    text='Available'
                                />
                            }
                            
                        </div>
                        <div className="g-space-1"></div>
                        <div className='button-row'>
                            <button
                                className='g-button'
                                onClick={() => setShowLimitModal(true)}
                            >
                                <FontAwesomeIcon icon={faArrowsUpToLine} />
                                Change Limit
                            </button>
                        </div>
                        {
                            (itemActivity) &&
                            <>
                            <div className='g-space-2'></div>
                            <div className=''>
                                Recent Activity
                            </div>
                            <div className='graph-row'>
                                <Chart3 
                                    recentActivity={itemActivity}
                                    chartHeight='175' 
                                />
                            </div>
                            </>
                        }
                        <div className='g-space-2'></div>
                        <div className='player-div'>
                            <div className=''>Player List</div>
                            <div className='g-space-1'></div>
                            <div className='player-list'>
                                {
                                    (owners) &&
                                    owners.map(p => (
                                        <button 
                                            key={p.playerId}
                                            className='g-button player-item med-btn' 
                                            onClick={() => navigate(`/manage/${gameData.path}/players/${p.path}`)}
                                        >
                                            <div className='name-div'>
                                                {
                                                (p.icon)
                                                ?
                                                <ImageContainer src={p.icon} />
                                                :
                                                <div 
                                                    className='gradient' 
                                                    style={{'background': p.gradient}}>
                                                    {p.fName[0]}
                                                </div>
                                                }
                                                {p.lName ? `${p.lName}, ${p.fName}` : p.fName}
                                            </div>
                                            
                                            {
                                                (itemActivity) &&
                                                <div className='activity meta'>
                                                    
                                                    Last Activity: {
                                                        itemActivity
                                                        .filter(a => a.playerId === p.playerId).length > 0
                                                        ?
                                                        format(new Date(
                                                            itemActivity
                                                            .filter(a => a.playerId === p.playerId)
                                                            .sort((a,b) => a.ts < b.ts ? 1 : -1)[0].ts
                                                        ), "P")
                                                        : ''
                                                    }
                                                </div>
                                            }
                                            {
                                                (elementTypePath) &&
                                                <div className='count'>
                                                    Count: {p[elementTypePath][element.id].count}
                                                </div>
                                            }
                                        </button>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
            <Modal
                show={showLimitModal}
                cancel={() => setShowLimitModal(false)}
                closeButton={true}
                cls='narrow'
                text='Change Game-Wide Limit'
            >
                <div className='limit-modal-content'>
                    <div className='field'>
                        <label>Change Total Limit</label>
                        <input 
                            type='number'
                            min='0'
                            value={
                                newLimit ??
                                0
                            }
                            onChange={(e) => setNewLimit(Number(e.target.value))}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ElementDataDisplay;