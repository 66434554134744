import React, { useState, useEffect, useRef } from 'react'
import './game-images.styles.scss'

import Croppie from 'croppie'
import { existingIcons } from './image-lists';
import { useSelector } from 'react-redux';
import Modal, { ModalAlert } from '@/components/modal/modal.component';
import placeholderIcon from '@/assets/placeholder-icon.png';
import gamablyIcon from '@/assets/icon.png';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';

const GameIconUpload = ({ saveData, formData, onApprove }) => {
    
    const [ gameIcon, setGameIcon ] = useState(null);
    const [ croppedImage, setCroppedImage ] = useState('')
	const [ croppie, setCroppie ] = useState(null)
    const [ showModal, setShowModal ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(false);
    const [ tempIcon, setTempIcon ] = useState(null);
    const root = useSelector(state => state.userData.root)
    let croppieInstance;
    let cropRef = useRef();

    useEffect(() => {
        if (!formData) {return;}
        setTempIcon({'url': (formData.icon && formData.icon.url) ? formData.icon.url : formData.icon, 'upload': false});
    },[formData])

    useEffect(() => {
        console.log(onApprove)
        if (!onApprove) {return;}
        saveCroppedIcon();
    }, [onApprove])

    const destroyCroppie = () => {
		const el = document.getElementById("uploaded-image")
		el.innerHTML = ''
		el.classList.remove("croppie-container");
        cropRef.current = null;
    }

    function handleUpload(file) {
		document.getElementById('selected-icon').classList.add('hide')
		destroyCroppie()
        // saveData('icon', null);
		console.log(file.type)
		if (file.type.match(/image.*/)) {
			const fileType = file.type;
			const fileName = file.name;
			console.log('An image has been loaded');
			console.log(fileType)
			// Load the image
			if (fileType.indexOf('gif') == -1) {
				const newFile = window.URL.createObjectURL(file)
				// setCroppedImage(newFile)
				const el = document.getElementById("uploaded-image")
				if (el) {
					croppieInstance = new Croppie(el, {
						viewport: { width: 120, height: 120 },
						boundary: { width: 120, height: 120 },
						showZoomer: true,
						enableOrientation: true
					});
					croppieInstance.bind({
						url: newFile,
					});
					cropRef.current = croppieInstance;
				}
				setCroppedImage(newFile)
				// saveData('icon', {upload: true, url: croppieInstance.data.url, croppie: croppieInstance, type: fileType, name: fileName})
                setTempIcon({'url': newFile, 'upload': true});
				return;
			}
			// document.getElementById('selected-icon').classList.remove('hide')
			const newFile = URL.createObjectURL(file)
			// saveData('icon', {upload: true, url: newFile, type: fileType, name: fileName, file: file})
            // setTempIcon({'url': newFile, 'upload': true});
			return;
		}
		setAlertMessage('Please upload an image file.');
		return;
	}

    function saveCroppedIcon() {
        console.log('saving icon')
        if (tempIcon.upload) {
            console.log(cropRef.current);
            if (!cropRef.current) {return;}
            cropRef.current.result({
                type:'blob',
                size: {width: 412, height: 412}
            })
            .then((blob) => {
                console.log(blob);
                const imageUrl = window.URL.createObjectURL(blob)
                saveData('icon', {'upload': true, 'url': imageUrl, 'file': blob})
                destroyCroppie();
                document.getElementById('selected-icon').classList.remove('hide')
                return;
            })
        } else {
            saveData('icon', {upload: false, url: tempIcon.url});
        }
    }

    function chooseIcon(icon) {
        document.getElementById('selected-icon').classList.remove('hide');
        setShowModal(false);
        // saveData('icon', {upload: false, url: `${root}/${icon}`, final: true});
        setTempIcon({'url': `${root}/${icon}`, 'upload': false});
        destroyCroppie();
    }

    return (
        <div className='new-game-form'>
            {/* <h4>Game Icon  <span className='required'>*</span></h4><hr /> */}
            <div className='image-upload-form'>
                <div className='icon-buttons center'>
                    <div>
                        <button className='g-button' onClick={() => setShowModal(true)}>
                            Choose an Existing Icon
                        </button>
                    </div>
                    <div style={{cursor: 'pointer'}}>
                        <button className="g-button" type="button" tabIndex="-1">
                            <input type="file" accept="image/*" id="fileUpload" onChange={(e) => handleUpload(e.target.files[0])}  />
                            Upload a Custom Icon
                        </button>
                    </div>
                </div>
                <div className='icon-display center'>
                    <div className='center' id="selected-icon">
                        { 
                            (tempIcon)
                            ?
                            <img className="game-icon" referrerPolicy="no-referrer" src={tempIcon.url} />
                            :
                            <img className='game-icon' referrerPolicy='no-referrer' src={gamablyIcon} />
                        }
                    </div>
                    <div id="uploaded-image">
                        
                    </div>
                    {/* {
                        (cropRef.current) &&
                        <button className='g-button' onClick={() => saveCroppedIcon()}>Save Cropped Image</button>
                    } */}
                </div>
            </div>
            <Modal show={showModal} cancel={() => setShowModal(false)} cls="full" closeButton={true} text='Choose an existing icon'>
                <div className='modal-children icon-choices'>
                    {
                        existingIcons.map((icon, index) => (
                            <div className='icon-choice' key={index} onClick={() => chooseIcon(icon)}>
                                <ImageContainer src={icon} alt='icon to choose' className='icon' />
                            </div>
                        ))
                    }   
                </div> 
            </Modal>
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} /> 
        </div>
    )
}

export default GameIconUpload