import React, { useEffect, useState } from 'react'
import './new-reward-modal.styles.scss'

import Modal from '@/components/modal/modal.component'
import { useNavigate } from 'react-router-dom'
import ImageContainer from '@/components/SHARED/image-container/image-container.component'
import { setNewStuffCount } from '@/state/slices/contentSlice'

const NewRewardModal = ({ gameData, playerData, playerActivity }) => {
    
    const [ show, setShow ] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!playerData || !playerActivity) return;
        let savedNewRewardCount = window.localStorage.getItem(`${gameData.gameId}_newRewardCount`);
        if (!savedNewRewardCount) {
            savedNewRewardCount = 0;
            window.localStorage.setItem(`${gameData.gameId}_newRewardCount`, 0);

        }
        if (playerData.newStuff && playerData.newStuff.length > 0) {
            const newList = Object.values(playerActivity).filter(a => playerData.newStuff.includes(a.id) && a.display === 'reward')
            setNewStuffCount(newList.length)
            if (newList.length > savedNewRewardCount) {
                console.log(newList);
                setShow(true);
            }
        }
    }, [playerData, playerActivity])

    function goToRewards() {
        window.localStorage.setItem(`${gameData.gameId}_newRewardCount`, playerData.newStuff.length);
        setShow(false);
        navigate('rewards');
    }

    function setLater() {
        window.localStorage.setItem(`${gameData.gameId}_newRewardCount`, playerData.newStuff.length);
        setShow(false);
    }

    return (
        <div>
            <Modal show={show} cancel={() => setShow(false)} cls='narrow'>
                <div className='modal-children new-reward-modal'>
                    <ImageContainer src='/images/icons/treasure-cl.png' alt='currency icon' className='treasure-gif' />
                    <h2>You have New Rewards to Discover!</h2>
                    <hr />
                    <div className="buttons">
                        <button className='g-button' onClick={() => setLater()}>Later</button>
                        <button className="g-button" onClick={() => goToRewards()}>Go to My Rewards!</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default NewRewardModal