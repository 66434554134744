import React from 'react'
import './contribution-goal.styles.scss';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';

const ManageContributionGoal = ({ saveData, formData, disabled }) => {
    return (
        <div className='contribution-goal g-list-item form-section'>
            <h3>Contribution Goal</h3>
            <hr />
            <div className='field small'>
                <label>What currency goal should be set for this Contribution Item?</label>
                <div className='input-div'>
                    <ImageContainer
                        src='/images/icons/coin.png'
                        className='icon'
                    />
                    <input 
                        className='short-input' 
                        type='number' 
                        value={formData.opt_contributionGoal ?? ''} 
                        onChange={(e) => saveData('opt_contributionGoal', Number(e.target.value))} 
                    />
                </div>
            </div>
            <div className="g-space-1"></div>
        </div>
    )
}

export default ManageContributionGoal