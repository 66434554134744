import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { UseCloudFunction } from '@/utils/firebase.utils';
import states from '@/utils/states.json'
import { ModalAlert } from '@/components/modal/modal.component';

const OrgInfo = ({ orgData, saveOrgData }) => {

    const profile = useSelector(state => state.userData.userProfile);
    const [ orgInfoForm, setOrgInfoForm ] = useState({});
    const [ alertMessage, setAlertMessage ] = useState(null);
    const dataRef = useRef();

    useEffect(() => {
        if (orgData) {
            console.log(orgData)
            dataRef.current = orgData;
            setOrgInfoForm(dataRef.current);
        }
    }, [orgData])

    function saveData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}};
        setOrgInfoForm(dataRef.current);
    }

    async function saveNewData() {
        let newOrgInfo = {...orgInfoForm};
        newOrgInfo.lastEdit = new Date().getTime();
        const res = await UseCloudFunction('saveOrgInfo', {'obj': newOrgInfo, 'appId': profile.appId});
        console.log(res);
        if (res.success) {
            setAlertMessage('Organization info saved successfully!');
            saveOrgData(res.newOrg)
        } else {
            setAlertMessage('Something went wrong. Try again later.');
        }
    }

    return (
        <div>
            <h3>Organization Info</h3><hr />
            <div className="field">
                <label>Organization Name</label>
                <input type='text' defaultValue={orgInfoForm ? orgInfoForm.name : ''} onChange={(e) => saveData('name', e.target.value)} />
            </div>
            <div className='field'>
                <label>Address</label>
                <input type='text' defaultValue={orgInfoForm.address ?? ''} onChange={(e) => saveData('address', e.target.value)} />
            </div>
            <div className='field'>
                <label>Address Line2</label>
                <input type='text' defaultValue={orgInfoForm.address2 ?? ''} onChange={(e) => saveData('address2', e.target.value)} />
            </div>
            <div className='field'>
                <label>City</label>
                <input type='text' defaultValue={orgInfoForm.city ?? ''} onChange={(e) => saveData('city', e.target.value)} />
            </div>
            <div className='g-double-col'>
                <div className='field'>
                    <label>State</label>
                    <select defaultValue={orgInfoForm.state ?? ''} onChange={(e) => saveData('state', e.target.value)} >
                        <option value=''>Choose...</option>
                        {
                            states &&
                            Object.keys(states).map(st => (
                                <option key={st} value={st}>{states[st]}</option>
                            ))
                        }
                    </select>
                </div>
                <div className='field'>
                    <label>Zip Code</label>
                    <input type='text' defaultValue={orgInfoForm.zip ?? ''} onChange={(e) => saveData('zip', e.target.value)} />
                </div>
                
            </div>
            <div className='field'>
                <label>Primary Contact Name</label>
                <input type='text' defaultValue={orgInfoForm.contactName ?? ''} onChange={(e) => saveData('contactName', e.target.value)} />
            </div>
            <div className='g-double-col'>
                <div className='field'>
                    <label>Primary Contact Email</label>
                    <input type='text' defaultValue={orgInfoForm.contactEmail ?? ''} onChange={(e) => saveData('contactEmail', e.target.value)} />
                </div>
                <div className='field'>
                    <label>Primary Contact Phone</label>
                    <input type='text' defaultValue={orgInfoForm.contactPhone ?? ''} onChange={(e) => saveData('contactPhone', e.target.value)} />
                </div>
            </div>
            <hr />
            <div className='buttons'>
                <button className='g-button primary' onClick={() => saveNewData()}>Save Changes</button>
            </div>
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
        </div>
    )
}

export default OrgInfo