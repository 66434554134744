import React, { useEffect, useRef, useState } from 'react'
import './create-leaderboard.styles.scss';
import ManageContentInformation from '../create-elements/blocks/information.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import ToggleInput from '@/components/SHARED/toggle-input/toggle-input.component';
import { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import { useSelector } from 'react-redux';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { RandomString } from '@/utils/randomString';
import { membershipRestrictions, testMemberRestrictions } from '@/utils/membershipRestrictions';
import UpgradeRequiredModal from '@/components/SHARED/upgrade-required-modal/upgrade-required-modal.component';

const CreateLeaderboard = ({ gameData, gameElements, playerList }) => {

    const membership = useSelector(state => state.userData.membership);
    const userProfile = useSelector(state => state.userData.userProfile);
    const [ formData, setFormData ] = useState({});
    const [ existingDescription, setExistingDescription ] = useState(null);
    const [ clearEditor, setClearEditor ] = useState(false);
    const [ groups, setGroups ] = useState([]);
    const [ alertMessage, setAlertMessage ] = useState(false);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ showUpgradeModal, setShowUpgradeModal ] = useState(null);
    const { leaderboardId } = useParams();
    const dataRef = useRef();
    const approveRef = useRef();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        saveData('totals', true);
    }, [])

    useEffect(() => {
        if (!gameData || !membership || !location) return;
        if (location.pathname.includes('/leaderboards/edit')) return;
        const res = testMemberRestrictions({'gameData':gameData, 'membership':membership});
        if (res.disabled && res.disabledReason.includes('elements')) {
            const planRest = membershipRestrictions[membership.membership];
            const message = `While using the ${planRest.title}, you are only able to have ${planRest.elementCount} active Game Elements per game.`;
            setShowUpgradeModal(message);
            return;
        }
    }, [gameData, membership, location])

    useEffect(() => {
        if (!playerList) return;
        setGroups(Object.values(playerList).filter(p => p.group))
    }, [playerList])

    useEffect(() => {
        if (!leaderboardId || !gameElements) return;
        dataRef.current = {...gameElements[leaderboardId]}
        setFormData(dataRef.current);
        setExistingDescription(gameElements[leaderboardId].desc)
    }, [leaderboardId, gameElements])

    function saveData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}};
        setFormData(dataRef.current)
        console.log(dataRef.current)
        if ((key === 'data' && value === 'currency') || (key === 'names' && value === 'groups')) {
            saveData('levels', false);
        }
        if (key === 'indOrGroups' && value === 'groups') {
            saveData('nameDisplay', 'display');
            saveData('count', 20);
        } else if (key === 'indOrGroups' && value === 'individuals') {
            saveData('count', 20);
        }
    }

    function cancelLeaderboard() {
        setConfirmMessage('Are you sure you want to leave this page? Any changes will be lost.');
        approveRef.current = () => {
            dataRef.current = {};   
            setFormData(dataRef.current)
            navigate(-1);
        }   
    }

    function handleSubmit() {
        let tempData = {...dataRef.current};
        console.log(tempData);
        const reqs = ['name','indOrGroups', 'count', 'data']
        for (let r of reqs) {
            if (!tempData[r]) {
                setAlertMessage('Please complete the required parts of the form before continuing.');
                return;
            }
        }
        setConfirmMessage('Are you sure you are ready to save this Leaderboard?');
        approveRef.current = () => {
            continueSubmit();
        }   
        async function continueSubmit() {
            setLoading(true);
            console.log(tempData);
            const now = new Date().getTime();
            const gameCode = gameData.gameId.substring(gameData.gameId.indexOf('-')-5, gameData.gameId.indexOf('-')+6);
            if (!tempData.id) {
                tempData.appId = userProfile.appId;
                tempData.ts_created = now;
                tempData.gameId = gameData.gameId;
                tempData.id = now + '-' + gameCode + '-leaderboard';
                tempData.status = 'active';
                tempData.type = 'leaderboard';
                tempData.public = true;
                tempData.icon = '/images/icons/leaderboard.png';
                tempData.path = RandomString(18, 'abcdefghijklmnopqrstuvwxyz-_0123456789');
                tempData.opt_visible = true;
                tempData.opt_available = true;
            } 
            tempData.ts = now;
            console.log(tempData);
            const res = await UseCloudFunction(
                'saveGameElement', 
                {
                    'appId': userProfile.appId, 
                    'elementData': tempData, 
                    'userName': `${userProfile.fName} ${userProfile.lName}`
                }
            )
            console.log(res);
            if (res.error) {
                setAlertMessage('Something went wrong. Please try again later. Error: ' + res.error);
                setLoading(false);
                return;
            }
            setAlertMessage('Leaderboard saved successfully.');
            dataRef.current = {};
            setFormData(dataRef.current);
            setLoading(false);
            navigate(-1);
        }    
    }

    return (
        <div className='create-leaderboard-form'>
            <ManageContentInformation
                type='leaderboard' 
                saveData={saveData} 
                formData={formData} 
                existingDescription={existingDescription} 
                clearEditor={clearEditor} 
                setClearEditor={setClearEditor}
                textEditorLabel='Leaderboard Description (optional)'
            />
            <div className='g-space-2'></div>
            <div className='form-section g-list-item'>
                <h3>Who will be displayed?<span className='required'>*</span></h3>
                <hr />
                <div className='button-row'>
                    <button className={`g-button ${formData.indOrGroups === 'individuals' ? 'primary' : ''}`} onClick={() => saveData('indOrGroups', 'individuals')}>
                        <FontAwesomeIcon icon={faUser} />
                        Individual Players
                    </button>
                    <button className={`g-button ${formData.indOrGroups === 'groups' ? 'primary' : ''} ${groups.length === 0 ? 'disabled' : ''}`} onClick={() => saveData('indOrGroups', 'groups')}>
                        <FontAwesomeIcon icon={faUsers} />
                        Group Names
                    </button>
                </div>
                <div className="g-space-1"></div>
            </div>
            <div className="g-space-2"></div>
            {
                (formData.indOrGroups === 'individuals') &&
                <>
                <div className='form-section g-list-item'>
                    <h3>Player Count<span className='required'>*</span></h3>
                    <hr />
                    {/* <PlayerSelector playerList={playerList} gameElements={gameElements} chosenPlayers={chosenPlayers} setChosenPlayers={setChosenPlayers} /> */}
                    <div className='field'>
                        <label>How many names should be displayed? (max 20)</label>
                        <input 
                            type='number' 
                            value={formData.count ?? 0} 
                            onChange={(e) => saveData('count', Number(e.target.value) > 20 ? 20 : Number(e.target.value))} />
                    </div>
                </div>
                
                <div className="g-space-2"></div>
                </>
            }
            
            {
                (formData.indOrGroups) &&
                <div className='form-section g-list-item'>
                    <h3>What data will be displayed?</h3>
                    <hr />
                    <p>Ranking Data<span className='required'>*</span></p>
                    <div className='button-row'>
                        <button 
                            className={`g-button med-btn ${formData.data === 'points' ? 'primary' : ''}`} 
                            onClick={() => saveData('data', 'points')}
                        >
                            <ImageContainer src='/images/xp.png' className='img-icon' />
                            Total XP
                        </button>
                        <button 
                            className={`g-button med-btn ${formData.data === 'currency' ? 'primary' : ''} ${formData.indOrGroups === 'groups' ? 'disabled' : ''}`} 
                            onClick={() => saveData('data', 'currency')}
                        >
                            <ImageContainer src='/images/icons/coins.png' className='img-icon' />
                            Current Balance
                        </button>
                        <button 
                            className={`g-button med-btn ${formData.data === 'currencyTotal' ? 'primary' : ''} ${formData.indOrGroups === 'groups' ? 'disabled' : ''}`} 
                            onClick={() => saveData('data', 'currencyTotal')}
                        >
                            <ImageContainer src='/images/icons/coins.png' className='img-icon' />
                            Total Currency Earned
                        </button>
                        
                    </div>
                    <div className='g-space-2'></div>
                    <p>Other Data to Display</p>
                    <div className={`field with-toggle ${formData.data === 'currency' || formData.data === 'currencyTotal' || formData.indOrGroups === 'groups' ? 'disabled' : ''}`}>
                        <label className='with-image'><ImageContainer src='/images/icons/rankings.png' alt='levels icon' className='label-image' />Display Individual Levels</label>
                        <ToggleInput contentKey='levels' checked={formData.levels} saveData={saveData} />
                    </div>
                    <div className={`field with-toggle ${!formData.data ? 'disabled' : ''}`}>
                        <label className='with-image'><ImageContainer src='/images/icons/points.png' alt='levels icon' className='label-image' />Display Point/Currency Totals</label>
                        <ToggleInput contentKey='totals' checked={formData.totals} saveData={saveData} />
                    </div>
                    {/* <div className={`field with-toggle ${!formData.data ? 'disabled' : ''}`}>
                        <label className='with-image'><ImageContainer src='/images/icons/badges.png' alt='badges icon' className='label-image' />Display Earned Badges</label>
                        <ToggleInput contentKey='badges' checked={formData.badges} saveData={saveData} />
                    </div> */}
                    {
                        (formData.indOrGroups === 'individuals' && formData.data) &&
                        <>
                        <div className="g-space-1-5"></div>
                        <div className='field'>
                            <label>Names to Display<span className='required'>*</span></label>
                            <select value={formData.nameDisplay ?? ''} onChange={(e) => saveData('nameDisplay', e.target.value)}>
                                <option value="">Choose...</option>
                                <option value="full">Full Names</option>
                                <option value="display">Display Names (only first name and last initial)</option>
                                <option value="secret">Secret Names known only to each individual</option>
                            </select>
                        </div>
                        </>
                    }
                </div>
            }
            <div className='g-space-2'></div>
            {
                (loading)
                ?
                <div className='buttons'>
                    <button className='g-button' >Cancel</button>
                    <button className='g-button' ><ThreeDotsLoader /></button>
                </div>
                :
                <div className='buttons'>
                    <button className='g-button' onClick={() => cancelLeaderboard()}>Cancel</button>
                    <button className={`g-button ${!formData.nameDisplay || !formData.data ? 'disabled' : 'primary'}`} onClick={() => handleSubmit()}>Save Leaderboard</button>
                </div>
            }
            <ModalConfirm show={confirmMessage} cancel={() => setConfirmMessage(null)} message={confirmMessage} onApprove={approveRef.current} />
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
            <UpgradeRequiredModal
                show={showUpgradeModal}
                cancel={() => setShowUpgradeModal(null)}
                message={showUpgradeModal}
            />
        </div>
    )
}

export default CreateLeaderboard