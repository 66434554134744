import React, { useEffect, useLayoutEffect } from 'react'
import './landing-page.styles.scss'

import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import UnauthLayout from '@/components/layout-unauth/unauth-layout.component';
import logo_tall from '@/assets/logo_tall.png';
// import { signInWithGoogle } from 'utils/firebase.utils';

const LandingPage = () => {

    const currentUser = useSelector(state => state.userData.currentUser);
    const userProfile = useSelector(state => state.userData.userProfile);
    const navigate = useNavigate();

    useEffect(() => {
        // navigate('/signin')
        // change var(--primary) to default color
        var r = document.querySelector(':root');
        r.style.setProperty('--primary', '#674fa7');
        r.style.setProperty('--primary-trans', '#674fa7a6');
    }, [])

    return (
        <div>
            <UnauthLayout>
            <div className='landing-page'>
                <img src={logo_tall} className='landing-icon' />
                <div className="g-space-20"></div>
                {
                    (currentUser) ?
                    <button className='g-button big' onClick={() => navigate('/home')}>My Games</button>
                    :
                    <div className='buttons-div'>
                        <button className='g-button big' onClick={() => navigate('/signin')}>Sign In</button>
                        <button className='g-button big' onClick={() => navigate('/register')}>Sign Up</button>
                        <button className='g-button big' onClick={() => navigate('/join')}>I Have a Code</button>
                    </div>
                }
                <div className="g-space-20"></div>
                <p>Looking for Gamably Version 1? <a href="https://app-v1.gamably.com">Click Here!</a></p>
            </div>
            </UnauthLayout>
        </div>
    )
}

export default LandingPage;