import React from 'react'
import './template-games-container.styles.scss';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import CreateEditTempateGame from './create-edit-template-game.component';

const TemplateGamesContainer = () => {

    const navigate = useNavigate();

    return (
        <div className='template-games-container'>
            <Routes>
                <Route path="" element={
                    <div className='template-game-list'>
                        <div className='section-title with-icon'>
                            <span>Template Games</span>
                            <FontAwesomeIcon className='icon' title="Create New Organization" icon={faPlusCircle} onClick={() => navigate('create')} />
                        </div>
                        <hr />
                    </div>
                } ></Route>
                <Route path='create/*' element={<CreateEditTempateGame />}></Route>
                <Route path=':gamePath' element={<CreateEditTempateGame />}></Route>
            </Routes>
        </div>
    )
}

export default TemplateGamesContainer;