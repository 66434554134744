export const existingIcons = [
    '/images/themes/icons/gamably.png',
    '/images/themes/icons/medieval.png',
    '/images/themes/icons/pirates.png',
    '/images/themes/icons/space.png',
    '/images/themes/icons/wizard.png',
    '/images/themes/icons/zombie.png',
]

export const existingHeaders = [
    '/images/themes/logos/gamably.png',
    '/images/themes/logos/zombie.png',
    '/images/themes/logos/wizard.png',
    '/images/themes/logos/space.png',
    '/images/themes/logos/professional.png',
    '/images/themes/logos/pirates.png',
    '/images/themes/logos/medieval.png',
]

export const existingBgs = [
    {
        'path':'/images/themes/bg/zombies.jpg',
        'button': '#CC0000',
    },
    {
        'path': '/images/themes/bg/space.jpg',
        'button': '#F58407',
    },
    {
        'path': '/images/themes/bg/pirates.jpg',
        'button': '#BB0703',
    },
    {
        'path': '/images/themes/bg/magic.jpg',
        'button': '#56AB8F',
    },
    {
        'path': '/images/themes/bg/golf.jpg',
        'button': '#FE0000',
    },
    {
        'path': '/images/themes/bg/desert.jpg',
        'button': '#CC0001',
    },
    {
        'path': '/images/themes/bg/castle.jpg',
        'button': '#EF6E4B',
    },
    {
        'path': '/images/themes/bg/80s.jpg',
        'button': '#FF2FFF',
    }   
]

export const existingGradients = [
    {hexBottom: '#d1cede', hexTop: '#e9e7f4', button: '#674EA7'},
    {hexBottom: '#2193b0', hexTop: '#6dd5ed', button: '#0E5769'},
    {hexBottom: '#203A43', hexTop: '#2C5364', button: '#508CA5'},
    {hexBottom: '#c471ed', hexTop: '#f64f59', button: '#F64F59'},
    {hexBottom: '#4A00E0', hexTop: '#8E2DE2', button: '#ac6ae5'},
]