import React, { useEffect, useState } from 'react'
import './player-challenge-item.styles.scss';
import { stripHtml } from '@/utils/sanitize';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { prereqFilter } from '@/utils/prereqFilter';
import { format } from 'date-fns';

const PlayerChallengeItem = ({ 
    playerData, 
    challenge, 
    keyId, 
    onClick, 
    actionItems, 
    gameElements 
}) => {

    const [ accessData, setAccessData ] = useState(null);
    const [ flagList, setFlagList ] = useState([]);

    useEffect(() => {
        if (!challenge) return;
        const sevenDaysFuture = new Date(new Date().setDate(new Date().getDate() + 7)).getTime();
        const sevenDaysPast = new Date(new Date().setDate(new Date().getDate() - 7)).getTime();
        const access = prereqFilter(challenge, playerData, gameElements)
        let flags = []
        let early = challenge.opt_dateOpen && challenge.opt_dateOpen > new Date().getTime();
        let late = challenge.opt_dateClose && challenge.opt_dateClose < new Date().getTime();

        if (!access) {
            flags.push({
                'class': 'closed',
                'text':'Closed'
            });
        }
        if (!access && challenge.opt_preview) {
            flags.push({
                'class': 'preview',
                'text': 'Preview'
            });
        }
        if (!access && early && challenge.opt_dateOpen < sevenDaysFuture) {
            flags.push({
                'class': 'coming-soon',
                'text': 'Coming Soon'
            });
        }
        if (access && !early && challenge.opt_dateOpen && challenge.opt_dateOpen > sevenDaysPast) {
            flags.push({
                'class': 'new',
                'text': 'New'
            });
        }
        if (access && !early && challenge.opt_dateClose && challenge.opt_dateClose < sevenDaysFuture) {
            flags.push({
                'class': 'closing-soon',
                'text': 'Closing Soon'
            });
        }
        if (actionItems) {
            const relatedActionItems = Object.values(actionItems)
            .filter(i => i.elementId === challenge.id)
            .sort((a,b) => a.ts > b.ts ? 1 : -1)
            for (let r of relatedActionItems) {
                if (!challenge.opt_multiple && r.status === 'approved') {
                    flags=[{
                        'class': 'completed',
                        'text':'Completed'}];
                }
                if (r.status === 'pending') {
                    flags=[{
                        'class': 'pending',
                        'text':'Pending'
                    }];
                }
                if (!challenge.opt_multipleTries && r.status === 'declined') {
                    flags = [{
                        'class': 'closed',
                        'text':'Declined'
                    }];
                }
                if (challenge.opt_multipleTries && r.status === 'declined') {
                    flags = [{
                        'class': 'new',
                        'text':'Try Again'
                    }];
                }
            }
        }
        // console.log(flags)
        setAccessData(access);
        setFlagList([...flags]);
    }, [challenge])

    return (
        <div 
            key={keyId} 
            className={`player-challenge-item g-list-item ${
                (accessData || challenge.opt_preview) ? 
                'clickable with-shadow': 
                ''
            }`} 
            onClick={
                (accessData || challenge.opt_preview) ? 
                onClick : 
                null
            }>
            {
                (challenge && flagList)
                ?
                <>
                    <div className='flags'>
                        {
                            (flagList.length > 0) &&
                            flagList.map(f => (
                                <div key={f.text} className={`indicator ${f.class}`}>
                                    {f.text}
                                </div>
                            ))
                        }
                    {/* {
                        (!challenge.opt_multiple && challenge.flags.includes('Completed'))
                        ?
                            <div className='indicator completed'>COMPLETED</div>
                        :
                        (!challenge.opt_multiple && challenge.flags.includes('Pending'))
                        ?
                            <div className='indicator pending'>PENDING</div>
                        :
                        (!challenge.opt_multipleTries && challenge.flags.includes('Declined'))
                        ?
                            <div className='indicator closed'>DECLINED</div>
                        :
                        (challenge.opt_multipleTries && challenge.flags.includes('Declined'))
                        ?
                            <div className='indicator new'>TRY AGAIN!</div>
                        :
                        <>
                        {
                            (challenge.flags && challenge.flags.includes('Preview')) &&
                            <div className='indicator preview'>Preview</div>
                        }
                        {
                            (challenge.flags && challenge.flags.includes('Coming Soon')) &&
                            <div className='indicator coming-soon'>Coming Soon</div>
                        }
                        {
                            (challenge.flags && challenge.flags.includes('New')) &&
                            <div className='indicator new'>NEW!</div>
                        }
                        {
                            (challenge.flags && challenge.flags.includes('Closing Soon')) &&
                            <div className='indicator closing-soon'>Closing Soon</div>
                        }
                        {
                            (challenge.flags && challenge.flags.includes('Closed')) &&
                            <div className='indicator closed'>Closed</div>
                        }
                        </>
                    } */}
                    </div>
                    <ImageContainer src={challenge.icon} alt={`${challenge.name} challenge icon`} className='challenge-icon' />
                    <div className='challenge-name'>
                        {challenge.name}
                        {
                            (challenge.opt_dateClose) &&
                            <div className='meta'>Closes {format(new Date(challenge.opt_dateClose), "Pp")}</div>
                        }
                    </div>
                    <div className='challenge-desc'>
                        {
                            challenge.desc && stripHtml(challenge.desc).substring(0,80)
                        }
                        {
                            challenge.desc && stripHtml(challenge.desc).length > 80 ? 
                            '...' : 
                            ''
                        }
                    </div>
                </>
                :
                (challenge)
                ?
                <>
                    <ImageContainer src={'/images/icons/win.png'} alt={`${challenge.name} challenge icon`} className='challenge-icon disabled' />
                    <div className='challenge-name'>{challenge.name}</div>
                    <div className='challenge-desc'>You do not have access to this challenge yet.</div>
                </>
                :
                null
            }
        </div>
    )
}

export default PlayerChallengeItem