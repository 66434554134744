import React, { useEffect, useRef, useState } from 'react';
import './cart-contribution-card.styles.scss';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { ModalConfirm } from '@/components/modal/modal.component';

const CartContributionCard = ({ cartItem, itemData, changeContribution, removeItem }) => {

    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const approveRef = useRef();

    function saveChangedContribution(newQuantity) {
        changeContribution(newQuantity, cartItem.id);
    }

    function saveRemovedItem() {
        setConfirmMessage('Are you sure you want to remove this contribution from your shopping cart?')
        approveRef.current = () => {
            removeItem(cartItem.id);
        }
    }

    return (
        <div className='cart-contribution-card g-list-item'>
            {
                (cartItem && itemData) &&
                <div className='cart-contribution-grid'>
                    <div className='card-icon'>
                        <ImageContainer src={itemData.icon} className='icon' />
                    </div>
                    <div className='card-text'>
                        <div className='item-name'>{itemData.name}</div>
                        {/* <div className='item-quantity'>Quantity: {cartItem.quantity}</div> */}
                        {/* <div className='item-price'>
                            Unit Price: 
                            
                            
                        </div> */}
                        <div className='item-quantity'>
                            <div className='field small'>
                                <label>Contribution Amount</label>
                                <div className='input-div'>
                                    <ImageContainer 
                                        src="/images/icons/coin.png" 
                                        className="coin-icon" 
                                    />
                                    <input 
                                        className='short-input'
                                        type='number'
                                        value={cartItem.cost}
                                        onChange={(e) => saveChangedContribution(Number(e.target.value))}
                                    />
                                    <button 
                                        className='g-button text-only meta'
                                        onClick={() => saveRemovedItem()}    
                                    >
                                        Remove
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className='card-total'>
                        Total: 
                        <ImageContainer 
                            src="/images/icons/coin.png" 
                            className="coin-icon" 
                        />
                        <b>{(cartItem.quantity * cartItem.cost).toLocaleString()}</b>
                    </div>
                </div>
            }
            <ModalConfirm 
                show={confirmMessage} 
                cancel={() => setConfirmMessage(null)}
                message={confirmMessage}
                onApprove={approveRef.current}
            />
        </div>
    )
}

export default CartContributionCard