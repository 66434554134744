import React, { useEffect, useState } from 'react'

import { existingBgs, existingGradients } from './image-lists';
import { useSelector } from 'react-redux';
import Modal from '@/components/modal/modal.component';
import placeholderBg from '@/assets/placeholder-bg.jpg';

const GameBgChoice = ({ saveData, formData, onApprove }) => {

    const [ showModal, setShowModal ] = useState(false);
    const [ tempBg, setTempBg ] = useState(null);
    const root = useSelector(state => state.userData.root);

    useEffect(() => {
        console.log(onApprove)
        if (!onApprove) {return;}
        saveChosenBg();
    }, [onApprove])

    async function chooseBg(choice, type) {
        setShowModal(false);
        if (type === 'image') {
            setTempBg({'type': type, 'url': `${root}/${choice.path}`, 'button': choice.button})
            // saveData('bg', {type: type, url:`${root}/${choice}`})
        } else {
            setTempBg({'type': type, 'hexBottom': choice.hexBottom, 'hexTop': choice.hexTop, 'button': choice.button})
            // saveData('bg', { type: type, hexBottom: choice.hexBottom, hexTop: choice.hexTop })
        }
    }

    function saveChosenBg() {
        if (tempBg.type === 'image') {
            saveData('bg', {'type': tempBg.type, 'url':tempBg.url})
            saveData('color', tempBg.button)
        } else {
            saveData('bg', {'type': tempBg.type, 'hexBottom': tempBg.hexBottom, 'hexTop': tempBg.hexTop})
            saveData('color', tempBg.button);
        }
    }

    return (
        <div className='new-game-form'>
            {/* <h4>Game Background  <span className='required'>*</span></h4><hr /> */}
            <div className="image-upload-form">
                <div className='icon-buttons center'>
                    <button className='g-button' onClick={() => setShowModal(true)}>
                        Choose an Existing Background
                    </button>
                </div>
                <div className="center">
                    <div id="selected-bg">
                        {
                            (tempBg && tempBg.type === 'image') 
                            ?
                            <img className="game-bg" referrerPolicy="no-referrer" src={tempBg.url} />
                            : 
                            (tempBg && tempBg.type === 'gradient')
                            ?
                            <div className='game-bg' style={{
                                background: tempBg.hexBottom, 
                                background: `-webkit-linear-gradient(to top, ${tempBg.hexBottom}, ${tempBg.hexTop})`,
                                background: `linear-gradient(to top, ${tempBg.hexBottom}, ${tempBg.hexTop})`
                            }} ></div>
                            :
                            (formData.bg && formData.bg.type === 'image')
                            ?
                            <img className="game-bg" referrerPolicy="no-referrer" src={formData.bg.url} />
                            :
                            (formData.bg && formData.bg.type === 'gradient')
                            ?
                            <div className='game-bg' style={{
                                background: formData.bg.hexBottom, 
                                background: `-webkit-linear-gradient(to top, ${formData.bg.hexBottom}, ${formData.bg.hexTop})`,
                                background: `linear-gradient(to top, ${formData.bg.hexBottom}, ${formData.bg.hexTop})`
                            }} ></div>
                            :
                            <img className="game-bg" referrerPolicy="no-referrer" src={placeholderBg} />
                        }
                    </div>
                </div>
            </div>
            <Modal show={showModal} cancel={() => setShowModal(false)} closeButton={true} cls='full' headText='Choose an existing header'>
                <div className='modal-children choice-container new-game-form'>
                    <p>Images:</p>
                    <div className='bg-choices'>    
                        {
                            existingBgs.map((bg, index) => (
                                <div className='bg-choice' key={index} onClick={() => chooseBg(bg, 'image')}>
                                    <div className='bg-image' style={{
                                        backgroundImage: `url("${root}/${bg.path}")`,
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat'
                                    }} />
                                </div>
                            ))
                        } 
                    </div>
                    <p>Gradients:</p>
                    <div className='bg-choices'>    
                        {
                            existingGradients.map((grad, index) => (
                                <div className='bg-choice' key={index} onClick={() => chooseBg(grad, 'gradient')}>
                                    <div className='bg-image' style={{
                                        background: grad.hexBottom, 
                                        background: `-webkit-linear-gradient(to top, ${grad.hexBottom}, ${grad.hexTop})`,
                                        background: `linear-gradient(to top, ${grad.hexBottom}, ${grad.hexTop})`
                                    }}/>
                                </div>
                            ))
                        } 
                    </div>
                </div> 
            </Modal>
        </div>
    )
}

export default GameBgChoice