import React, { useEffect, useRef, useState } from 'react';
import './org-settings.styles.scss';
import OrgInfo from './settings-components/org-info.component';
import OrgIcon from './settings-components/org-icon.component';
import { useLocation } from 'react-router-dom';
import OrgInvites from './settings-components/org-invites.component';
import { getInvitesFromDb } from '@/utils/getAdminData';

const OrgSettings = ({ orgData, saveOrgData }) => {

    const [ invites, setInvites ] = useState(null)
    let inviteListener;
    const inviteRef = useRef();
    const location = useLocation();

    useEffect(() => {

        return () => {
            if (inviteListener) inviteListener();
        }
    } ,[])

    useEffect(() => {
        console.log(orgData)
        if (!orgData) return;
        getOrgInvites();
    }, [orgData])

    function getOrgInvites() {
        getInvitesFromDb({
            'orgId': orgData.orgId,
            'callback': callback,
            'handleListener': handleListener
        })
        function callback(data, id) {
            inviteRef.current = {...inviteRef.current, ...{[id]: data}}
            setInvites({...inviteRef.current});
        }
        function handleListener(unsub) {
            inviteListener = unsub;
        }
    }

    return (
        <div className='org-settings-container'>
            <div className='org-settings'>
                <div className='g-card'>
                    <OrgInfo orgData={orgData} saveOrgData={saveOrgData} />
                </div>
                <div className="g-space-40"></div>
                <div className='g-card'>
                    <OrgIcon orgData={orgData} saveOrgData={saveOrgData} />
                </div>
                <div className="g-space-40"></div>
                <div className='g-card'>
                    <OrgInvites orgData={orgData} invites={invites} />
                </div>
                <div className="g-space-40"></div>
            </div>
        </div>
    )
}

export default OrgSettings;