import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const AgeCheck = () => {
    const profile = useSelector(state => state.userData.userProfile);
    const navigate = useNavigate();

    useEffect(() => {
        if (!profile) {return;}
        const now = new Date().getTime();
        const sixteenYrs = 31622400000 * 16
        if (profile.birthday > now - sixteenYrs) {
            navigate('/games');
        }
    },[profile])

    return (
        <div></div>
    )
}

export default AgeCheck