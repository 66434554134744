import React, { useEffect, useState } from 'react'
import './use-item.styles.scss';
import coin from '@/assets/icons/coin.png'
import RewardsForm from './rewards-form.component';
import ToggleInput from '@/components/SHARED/toggle-input/toggle-input.component';

const ManageContentUseItem = ({ type, formData, saveData, gameElements, gameData }) => {

    const [ unsellable, setUnsellable ] = useState(true);
    const [ rewardsDisabled, setRewardsDisabled ] = useState(true);
    
    // FIX THIS SHIT!!
    useEffect(() => {
        if (!formData) return;
        if (!formData.opt_resale && formData.opt_resalePrice) {
            saveData('opt_resalePrice', null);
            console.log('saving data')
        }
    }, [formData])

    return (
        <div className='manage-content-use-item g-list-item form-section'>
            <h3>How the Item can be used</h3>
            <hr />
            <div className="use-item-content">
                <div className='toggle-with-text'>
                    <ToggleInput contentKey="opt_resale" checked={formData.opt_resale} saveData={saveData} />
                    {/* <div className='toggle-button'>
                        <input 
                            type="checkbox" 
                            id="checkbox" 
                            checked={formData.opt_resale ? true : false} 
                            onChange={(e) => toggleSellable(e.target.checked)} 
                        />
                    </div> */}
                    <div className='toggle-text'>
                        This Item is available to be sold back for currency. 
                    </div>
                </div>
                <div className="g-space-2"></div>
                <div className={`field small ${!formData.opt_resale ? 'disabled' : ''}`}>
                    <label>What is the buyback value for this item?<span className='required'>*</span></label>
                    <div className='input-with-icon'>
                        <img src={coin}  />
                        <input 
                            type='number' 
                            className='short-input' 
                            placeholder='Cost' 
                            value={formData.opt_resalePrice ?? ''} 
                            onChange={(e) => saveData('opt_resalePrice', Number(e.target.value))} 
                        />
                    </div>
                </div>
                <hr />
                <div className='toggle-with-text'>
                    <ToggleInput contentKey="opt_usable" checked={formData.opt_usable} saveData={saveData} />
                    {/* <div className='toggle-button'>
                        <input 
                            type="checkbox" 
                            id="checkbox" 
                            checked={formData.opt_usable ? true : false} 
                            onChange={(e) => toggleUsable(e.target.checked)} 
                        />
                    </div> */}
                    <div className='toggle-text'>
                        This Item can be turned in and used for whatever its purpose is.
                    </div>
                </div>
                <div className="g-space-2"></div>
                <RewardsForm 
                    type={type} 
                    formData={formData} 
                    saveData={saveData} 
                    gameElements={gameElements} 
                    disabled={!formData.opt_usable} 
                    gameData={gameData}
                />
            </div>
        </div>
    )
}

export default ManageContentUseItem