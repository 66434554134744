import React, { useEffect, useState } from 'react'
import './text-area-word-count.styles.scss';
import { countWords } from '@/utils/countWords';
import { sanitize } from '@/utils/sanitize';

const TextAreaWordCount = ({ saveData, count, keyId, existingText }) => {

    const [text, setText] = useState('');

    useEffect(() => {
        if (!existingText) return;
        setText(existingText);
    }, [existingText])

    function handleChange(e) {
        const inputValue = sanitize(e.target.value);
        // if (text.split(/(\s+)/).length > count && inputValue.length >= text.length) return;
        // const words = inputValue.split(/(\s+)/);
        // const limitedWords = words.slice(0, count + 1);
        // const newText = limitedWords.join(" ");
        // Split the input by whitespace but preserve line breaks
        const words = inputValue.split(/(\s+)/);
        // Count words, including handling multiple spaces/newlines correctly
        let wordCount = 0;
        let limitedWords = [];
        for (let word of words) {
            // Check if the word is not just whitespace (excluding line breaks)
            if (word.trim().length > 0) {
              wordCount++;
            }
      
            // Add the word to the limitedWords array if we haven't exceeded maxWords
            if (wordCount <= count || word.trim().length === 0) {
              limitedWords.push(word);
            }
        }
      
        // Join the words back into a string, preserving spaces and new lines
        const newText = limitedWords.join("");
        setText(newText);
        saveData(keyId, newText);
    }

    return (
        <div className='text-area-word-count'>
            <textarea 
                type='text' 
                rows='4' 
                value={text} 
                onChange={handleChange} 
            ></textarea>
            <div className='char-count meta'>
                {`${text ? countWords(text) : 0}/${count} words`}
            </div>
        </div>
    )
}

export default TextAreaWordCount