import React, { useEffect, useRef, useState } from 'react'
import './message-creator.styles.scss';
import TextEditor from '@/components/SHARED/text-editor/text-editor.component'
import { stripHtml } from '@/utils/sanitize'
import MessageModal from './message-modal.component';
import linkifyHtml from 'linkify-html';
import { format } from 'date-fns';

const MessageCreator = ({ gameData, latestMessage }) => {

    const [ divHeight, setDivHeight ] = useState('2rem');
    const [ showMessageModal, setShowMessageModal ] = useState(false);
    const [ message, setMessage ] = useState(null);
    const [ messageToEdit, setMessageToEdit ] = useState(null);
    const timeoutRef = useRef();

    useEffect(() => {
        const height = document.getElementById('message-card').offsetHeight;
        console.log(height);
        setDivHeight(height - 129);

        return () => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
        }
    }, [])

    useEffect(() => {
        if (!latestMessage) return;
        timeoutRef.current = setTimeout(() => {
            setMessage(latestMessage.message);
        }, 500)
    }, [latestMessage])

    return (
        <div className='message-creator-container'>
            <div className='message-creator'>
                <b>Post a News Item</b>
                <div className="g-space-1"></div>
                Current News: 
                <div className="g-space-0-5"></div>
                <div className='g-list-item message-box' style={{'height': divHeight + 'px'}}>
                    {
                        (latestMessage && message)
                        ?
                        <>
                            <p>{latestMessage.title}</p>
                            <div className='meta'>Posted {format(new Date(latestMessage.ts), "Pp")}</div>
                            <div className='meta' dangerouslySetInnerHTML={{__html: linkifyHtml(message, {'target': '_blank'})}}></div>
                        </>
                        :
                        <div className='meta'>No message currently posted</div>
                    }
                </div>
                <div className='g-space-0-5'></div>
                <div className='center-col'>
                    {/* <button 
                        className='g-button small-btn'
                        onClick={() => setShowMessageModal(true)}
                    >
                        Edit
                    </button> */}
                    <button 
                        className='g-button small-btn'
                        onClick={() => setShowMessageModal(true)}
                    >
                        New News Item 
                    </button>
                </div>
            </div>
            <MessageModal
                gameData={gameData}
                show={showMessageModal}
                cancel={() => setShowMessageModal(false)}
                message={messageToEdit}
            />
        </div>
    )
}

export default MessageCreator