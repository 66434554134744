import React from 'react';
import './sale-amount.styles.scss';

const SaleAmount = ({ type, saveData, formData }) => {
    return (
        <div className='manage-content-sale-amount g-list-item form-section'>
            <h3>Sale Percentage<span className='required'>*</span></h3>
            <hr />
            <div className='field small'>
                <label>Enter the percenage of currency you want removed from the cost of the selected items.</label>
                <div className='input-div'>
                    <h2>%</h2>
                    <input 
                        className='short-input'
                        type='number'
                        value={formData.opt_salePercentage ?? ''}
                        onChange={e => saveData('opt_salePercentage', Number(e.target.value))}
                    />
                </div>
            </div>
        </div>
    )
}

export default SaleAmount;