import React, { useEffect, useState } from 'react';
import './leaderboard-list.styles.scss';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faLink, faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { properCase } from '@/utils/properCase';
import { ModalAlert } from '@/components/modal/modal.component';
import { membershipRestrictions, testMemberRestrictions } from '@/utils/membershipRestrictions';
import { useSelector } from 'react-redux';
import UpgradeRequiredModal from '@/components/SHARED/upgrade-required-modal/upgrade-required-modal.component';

const LeaderboardList = ({ gameElements, gameData }) => {

    const membership = useSelector(state => state.userData.membership);
    const [ leaderboardsToDisplay, setLeaderboardsToDisplay ] = useState([]);
    const [ alertMessage, setAlertMessage ] = useState(false);
    const [ showUpgradeModal, setShowUpgradeModal ] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!gameElements) return;
        setLeaderboardsToDisplay(Object.values(gameElements).filter(e => e.type === 'leaderboard' && e.status === 'active'));
    }, [gameElements])

    function checkMembership(e) {
        const res = testMemberRestrictions({'gameData':gameData, 'membership':membership});
        if (res.disabled && res.disabledReason.includes('elements')) {
            const planRest = membershipRestrictions[membership.membership];
            const message = `While using the ${planRest.title}, you are only able to have ${planRest.elementCount} active Game Elements per game.`;
            setShowUpgradeModal(message);
            return;
        }
        navigate('create');
    }

    function copyPath(path) {
        navigator.clipboard.writeText(`${window.location.origin}/live/${path}`);
        setAlertMessage('The link to the leaderboard has been copied to your clipboard.');
    }

    return (
        <div className='leaderboard-list-container'>
            <div className='buttons'>
                <button type='button' className='g-button med-btn' onClick={() => checkMembership()}>Create a New Leaderboard<FontAwesomeIcon icon={faPlus} /></button>
            </div>
            <div className="g-space-1"></div>
            <div className='leaderboard-list-div'>
                <div className='g-list-item row leaderboard-list-head'>
                    <div>Name</div>
                    <div>Type</div>
                    <div>Data</div>
                    <div>Display</div>
                    <div>Count</div>
                    <div></div>
                </div>
                {
                    (leaderboardsToDisplay.length > 0) 
                    ?
                    leaderboardsToDisplay.map(l => (
                        <div key={l.id} className='row g-list-item'>
                            <div className=''>{l.name}</div>
                            <div className='meta'>{properCase(l.indOrGroups)}</div>
                            <div className='meta'>{properCase(l.data)}</div>
                            <div className='meta'>{properCase(l.nameDisplay)}</div>
                            <div className='meta'>{l.count}</div>
                            <div className='buttons'>
                                <button className='g-button med-btn' onClick={() => navigate(`edit/${l.id}`)}>
                                    <FontAwesomeIcon icon={faPen} />
                                </button>
                                <button className='g-button med-btn' onClick={() => copyPath(l.path)}>
                                    <FontAwesomeIcon icon={faLink} />
                                </button>
                                <Link to={`/live/${l.path}`} target="_blank">
                                    <button className='g-button med-btn' >
                                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                                    </button>
                                </Link>
                            </div>
                        </div>
                    ))
                    :
                    <p>No Leaderboards yet...</p>
                }
            </div>
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
            <UpgradeRequiredModal
                show={showUpgradeModal}
                cancel={() => setShowUpgradeModal(false)}
                closeButton={true}
                message={showUpgradeModal}
            />
        </div>
    )
}

export default LeaderboardList