import React, { useEffect, useRef, useState } from 'react';
import './edit-player.styles.scss';
import { useNavigate } from 'react-router-dom';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { useSelector } from 'react-redux';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import ProfileIcon from '@/pages/profile/profile-icon.component';
import FullPageLoader from '@/components/loader/full-page-loader.component';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';

const EditPlayer = ({ gameData, playerData, setMainAlert }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ formData, setFormData ] = useState({});
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ enrollMethod, setEnrollMethod ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ passcodeFound, setPasscodeFound ] = useState(false);
    const dataRef = useRef();
    const approveRef = useRef();
    const navigate = useNavigate();
    const storage = getStorage();

    useEffect(() => {
        if (!playerData || !userProfile) return;
        if (playerData.enroll_method === 'email') {
            setEnrollMethod('email');
            getEmailAddress();
        } else {
            getPasscode();
            setEnrollMethod('upload');
        }
        dataRef.current = {...playerData};
        setFormData(dataRef.current)
    }, [playerData, userProfile])

    async function getPasscode() {
        console.log(userProfile);
        const res = await UseCloudFunction('getPlayerPasscode', {'userProfile': userProfile, 'playerData': playerData})
        console.log(res);
        if (res.error) {
            setAlertMessage(`Sorry, we are unable to retrieve this user's passcode. Please try again later. (Error: ${res.error})`);
            return;
        }
        saveData('passcode', res.success);
        setPasscodeFound(true);
    }

    async function getEmailAddress() {
        const res = await UseCloudFunction('getPlayerEmail', {'userProfile': userProfile, 'playerData': playerData})
        console.log(res)
        if (res.error) {
            setAlertMessage(`Sorry, we are unable to retrieve this user's email address. Please try again later. (Error: ${res.error})`);
            return;
        }
        saveData('email', res.success);
    }

    function saveData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}}
        setFormData(dataRef.current);
        console.log(dataRef.current);
    }

    function removePlayer() {
        const playerToRemove = {...playerData}
        setConfirmMessage(
            playerToRemove.enroll_method === 'email' ?
            'Are you sure you want to remove this player? Because this user joined the game with an email-based account, their account will be added to the "Banned User" list. You can allow this user to rejoin by going to the Game Settings and reactivating them from the Removed Player List.' :
            'Are you sure you want to remove this player? Because this user was uploaded, you can reinstate this user by going to Game Settings and reactivating them from the Removed Player List.'
        );
        approveRef.current = () => {
            continueRemoval();
        }
        
        async function continueRemoval() {
            setLoading(true);
            const res = await UseCloudFunction('removePlayer', {'appId': userProfile.appId, 'gameId': gameData.gameId, 'playerData': playerData})
            setLoading(false);
            console.log(res);
            if (res.error) {
                setAlertMessage(`Something went wrong. Please try again later. (Error: ${res.error})`);
                return;
            }
            setMainAlert('Player removed successfully!');
            setTimeout(() => {
                navigate(`/manage/${gameData.path}/players`);
            }, 1000)
            
        }
    }

    function verifySave() {
        setConfirmMessage('Are you sure you want to save these changes?');
        approveRef.current = () => {
            setLoading(true);
            handleSubmit();
        }

    }

    async function handleSubmit() {
        if (!formData.icon) {
            saveProfile(null)
            return;
        }
		if (formData.icon.upload && formData.icon.type.indexOf('gif') == -1) {
			formData.icon.croppie.result({
				type:'blob',
				size: {'width': 352, 'height': 352},
                quality: 0.7,
			})
			.then((blob) => {
				uploadImage(formData.icon, blob)
				return;
			})
		} else if (formData.icon.upload) {
			uploadImage(formData.icon, formData.icon.file)
			return;
		} else if (formData.icon.url) {
			saveProfile(formData.icon.url)
			return;
		} else if (formData.icon) {
			saveProfile(formData.icon)
		} else {
            saveProfile();
        }
	}
				
	function uploadImage(profileIcon, blob) {		
		const now = new Date().getTime();
		const profileRef = ref(storage, `${gameData.gameId}/profile-icons/${now}-${formData.icon.name}`)
		const metadata = {
			contentType: formData.icon.type,
            customMetadata: {
                appId: playerData.appId
            }
		};
		uploadBytes(profileRef, blob, metadata).then((snapshot) => {
			getDownloadURL(profileRef)
			.then((url) => {
				saveProfile(url)
				return;
			})
		})
	}
				
	const saveProfile = async (url) => {
        const tempData = {...dataRef.current};
        const now = new Date().getTime();
        tempData.ts = now;
        if (url) {
            tempData.icon = url;
        }
        if (enrollMethod === 'email') {
            delete tempData.email;
        } 

        const res = await UseCloudFunction('saveUploadedPlayerProfile', {'profile': tempData, 'appId': userProfile.appId})
        if (res.error) {
            setAlertMessage(`Something went wrong. Try again later. (Error: ${res.error})`);
            return;
        }
        setAlertMessage('Player profile updated successfully.')
        navigate(-1);
	}

    return (
        <div className='edit-player-container'>
            <div className='g-card'>
                <BackButton cancel={() => navigate(-1)} />
                <div className="g-space-1"></div>
                <div className='card-title'>Edit Player</div>
                <hr />
                <div className='g-double-col'>
                    <div className='field small'>
                        <label>First Name (required)</label>
                        <input 
                            type='text'
                            className={enrollMethod === 'email' ? 'disabled' : ''} 
                            value={formData.fName ?? ''} 
                            onChange={(e) => saveData('fName', e.target.value)} 
                        />
                    </div>
                    <div className='field small'>
                        <label>Last Name, Initial, ID etc. (optional)</label>
                        <input 
                            type='text' 
                            className={enrollMethod === 'email' ? 'disabled' : ''}
                            value={formData.lName ?? ''} 
                            onChange={(e) => saveData('lName', e.target.value)} 
                        />
                    </div>
                </div>
                <div className="g-space-1"></div>
                <div className='field small'>
                    <label>Player Display Name (hidden last name)</label>
                    <input
                        type='text'
                        value={formData.displayName ?? ''}
                        onChange={(e) => saveData('displayName', e.target.value)}
                    />
                </div>
                <div className="g-space-1"></div>
                <div className='g-double-col'>
                    <div className='field small'>
                        <label>Additional Information (optional)</label>
                        <input type='text' value={formData.info ?? ''} onChange={(e) => saveData('info', e.target.value)} />
                    </div>
                    {
                        (passcodeFound) &&
                        <div className='field small'>
                            <label>Passcode (required)</label>
                            <input 
                                type='text' 
                                value={formData.passcode ?? ''} 
                                onChange={(e) => saveData('passcode', e.target.value)} 
                            />
                        </div>
                    }
                    {
                        (formData.email) &&
                        <div className='field small'>
                            <label>Email Address</label>
                            <input 
                                type='text' 
                                className='disabled'
                                defaultValue={formData.email ?? ''} 
                            />
                        </div>
                    }
                </div>
                <hr />
                {
                    (enrollMethod === 'upload') &&
                    <>
                    <ProfileIcon formData={formData} saveData={saveData} ofAge={true} />
                    <hr />
                    </>    
                }
                
                <div className='edit-buttons'>
                    <button className='g-button med-btn red' onClick={() => removePlayer()}>Remove Player<FontAwesomeIcon icon={faTrash} /></button>
                    <div className='buttons'>
                        <button className='g-button' onClick={() => navigate(-1)}>Cancel</button>
                        <button className='g-button primary' onClick={() => verifySave()}>Save Changes</button>
                    </div>
                </div>
            </div>
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
            <ModalConfirm show={confirmMessage} cancel={() => setConfirmMessage(null)} message={confirmMessage} onApprove={approveRef.current} />
            <FullPageLoader show={loading} />
        </div>
    )
}

export default EditPlayer