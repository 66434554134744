import React, { useEffect, useRef, useState } from 'react';
import './store-purchases.styles.scss';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faShoppingBasket } from '@fortawesome/free-solid-svg-icons';
import { sortPlayers } from '@/utils/sortPlayers';
import flatpickr from 'flatpickr';
import { format } from 'date-fns';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import CsvDownload from 'react-json-to-csv';

const StorePurchases = ({ gameData, gameElements, playerList, purchases }) => {

    const [ purchasesToDisplay, setPurchasesToDisplay ] = useState(null);
    const [ playersToDisplay, setPlayersToDisplay ] = useState(null);
    const [ playerInfoToDisplay, setPlayerInfoToDisplay ] = useState(null);
    const [ itemsToDisplay, setItemsToDisplay ] = useState(null);
    const [ csvData, setCsvData ] = useState(null);
    const [ filterData, setFilterData ] = useState({});
    const date1Ref = useRef();
    const date2Ref = useRef();
    const filterRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        if (!purchases || !gameElements) return;
        // console.log(purchases);
        displayPurchases();
        const itemIdArr = [...new Set(
            Object.values(purchases)
            .map(p => p.id))
        ]
        // console.log(itemIdArr);
        const itemDataArr = itemIdArr.map(id => {
            return {
                'id': id,
                'name': gameElements[id] ? gameElements[id].name : ''
            }
        })
        setItemsToDisplay(
            itemDataArr.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
        )
    }, [purchases, gameElements, filterData])

    useEffect(() => {
        const openDateElement = document.getElementById('startDate');
        if (!date1Ref.current) {
            const flat1 = flatpickr(openDateElement, 
                {
                    altInput: true,
                    altFormat: "F j, Y",
                    enableTime: false,
                    dateFormat: "Y-m-d",
                    onChange: (dates) => {
                        const date = new Date(dates[0]).getTime()
                        saveData('startDate', date)
                    }
                }
            );
            date1Ref.current = flat1;
            // console.log('set fp')
        }
        const closeDateElement = document.getElementById('endDate');
        if (!date2Ref.current) {
            const flat2 = flatpickr(closeDateElement, 
                {
                    altInput: true,
                    altFormat: "F j, Y",
                    enableTime: false,
                    dateFormat: "Y-m-d",
                    onChange: (dates) => {
                        const date = new Date(dates[0]).getTime() + 86400000 - 1;
                        saveData('endDate', date)
                    }
                }
            );
            date2Ref.current = flat2;
            // console.log('set date2')
        }
    }, [])

    useEffect(() => {
        if (!playerList) return;
        const sortedPlayers = sortPlayers({
            'playerList': playerList,
            'filter': 'players'
        })
        setPlayersToDisplay([...sortedPlayers])
        const infoArr = [...new Set(Object.values(playerList).filter(p => !p.team).map(p => p.info))]
        setPlayerInfoToDisplay(infoArr.sort())
    }, [playerList])

    function saveData(key, value) {
        filterRef.current = {...filterRef.current, ...{[key]: value}};
        setFilterData(filterRef.current);
    }

    function displayPurchases() {
        let filteredPurchases = Object.values(purchases).sort((a,b) => a.ts < b.ts ? 1 : -1);
        if (filterData.playerId) {
            filteredPurchases = filteredPurchases.filter(p => p.playerId === filterData.playerId);
        }
        if (filterData.playerInfo) {
            filteredPurchases = filteredPurchases.filter(p => p.info === filterData.playerInfo);
        }
        if (filterData.itemId) {
            filteredPurchases = filteredPurchases.filter(p => p.id === filterData.itemId);
        }
        if (filterData.startDate) {
            filteredPurchases = filteredPurchases.filter(p => p.ts >= filterData.startDate);
        }
        if (filterData.endDate) {
            filteredPurchases = filteredPurchases.filter(p => p.ts <= filterData.endDate);
        }
        setPurchasesToDisplay(filteredPurchases)
        setCsvData(Object.values(filteredPurchases));
    }

    function clearFilter() {
        date1Ref.current.clear();
        date2Ref.current.clear();
        filterRef.current = {}
        setFilterData(filterRef.current);
    }

    return (
        <div className='store-purchases'>
            <div className='filter-row'>
                <div className='field small'>
                    <label>Player</label>
                    <select 
                        value={filterData.playerId}
                        onChange={(e) => saveData('playerId', e.target.value)}
                    >
                        <option value=''>Choose...</option>
                        {
                            (playersToDisplay) &&
                            playersToDisplay.map(p => (
                                <option key={p.playerId} value={p.playerId}>{`${p.lName ? `${p.lName}, ` : ''}${p.fName}`}</option>
                            ))
                        }
                    </select>
                </div>
                <div className='field small'>
                    <label>Player Info</label>
                    <select 
                        value={filterData.playerInfo}
                        onChange={(e) => saveData('playerInfo', e.target.value)}
                    >
                        <option value=''>Choose...</option>
                        {
                            (playerInfoToDisplay) &&
                            playerInfoToDisplay.map(p => (
                                <option key={p} value={p}>{p}</option>
                            ))
                        }
                    </select>
                </div>
                <div className='field small'>
                    <label>Item</label>
                    <select
                        value={filterData.itemId}
                        onChange={(e) => saveData('itemId', e.target.value)}
                    >
                        <option value=''>Choose...</option>
                        {
                            (itemsToDisplay && gameElements) &&
                            itemsToDisplay.map(i => (
                                <option key={i.id} value={i.id}>{i.name}</option>
                            ))
                        }
                    </select>
                </div>
                <div className='field small'>
                    <label>Start Date</label>
                    <input 
                        className="flatpickr flatpickr-input" 
                        type="text" 
                        id="startDate" 
                        placeholder='Choose a date...' 
                    />
                </div>
                <div className='field small'>
                    <label>End Date</label>
                    <input 
                        className="flatpickr flatpickr-input" 
                        type="text" 
                        id="endDate" 
                        placeholder='Choose a date...' 
                    />
                </div>
                <div className='action-buttons'>
                    <button 
                        className='g-button med-btn'
                        onClick={() => clearFilter({})}
                    >
                        Clear
                    </button>
                    <CsvDownload 
                        data={csvData}
                        filename='GamablyPurchases.csv'
                        className='g-button med-btn'
                        delimiter=','
                    >
                        <FontAwesomeIcon icon={faDownload} size='xl' />
                    </CsvDownload>
                </div>
            </div>
            <div className="g-space-2"></div>
            <div className='purchase-list'>
                <div className='row purchase-list-head g-list-item'>
                    <div>Date</div>
                    <div>Item</div>
                    <div></div>
                    <div>Purchased By</div>
                    <div>Player Info</div>
                    <div>Qty</div>
                    <div>Total</div>
                    <div></div>
                </div>
                <div className="g-space-0-5"></div>
                <div className='purchases'>
                {
                    (purchasesToDisplay && playerList) &&
                    purchasesToDisplay.map(p => (
                        <div key={p.purchaseInstanceId} className='row g-list-item'>
                            <div>{format(new Date(p.ts), 'P')}</div>
                            <div>
                                <ImageContainer src={p.icon} className='item-icon' />
                            </div>
                            <div>{p.title ?? p.name}</div>
                            <div>{playerList[p.playerId].fName}{playerList[p.playerId].lName ? ` ${playerList[p.playerId].lName}` : ''}</div>
                            <div>{playerList[p.playerId].info}</div>
                            <div>{p.quantity}</div>
                            <div className='purchase-price'>
                                <ImageContainer src='/images/icons/coin.png' className='coin-icon' />
                                {p.cost}
                            </div>
                            <div className='buttons'>
                                <button 
                                    className='g-button small-btn'
                                    onClick={() => navigate(`/manage/${gameData.path}/transaction/${p.path}`)}
                                >
                                    View
                                </button>
                            </div>
                        </div>
                    ))
                }
                </div>
            </div>
        </div>
    )
}

export default StorePurchases