import React, { useEffect, useState } from 'react'
import { HexColorPicker } from 'react-colorful';

const GameColorChoice = ({ saveData, formData, onApprove }) => {

    const [ tempColor, setTempColor ] = useState('#');
    const [ validCode, setValidCode ] = useState(true);

    useEffect(() => {
        if (!formData) {return;}
        setTempColor(formData.color);
    }, [formData])

    useEffect(() => {
        if (!onApprove) {return;}
        saveData('color', tempColor);
    }, [onApprove]);

    function checkHexCode(code) {
        var hexaPattern = /^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/;
        function getContrastYIQ(hexcolor){
            var r = parseInt(hexcolor.substring(1,3),16);
            var g = parseInt(hexcolor.substring(3,5),16);
            var b = parseInt(hexcolor.substring(5,7),16);
            var yiq = ((r*299)+(g*587)+(b*114))/1000;
            return (yiq >= 128) ? 'var(--dark-grey)' : 'white';
        }
        const textColor = getContrastYIQ(code)
        return hexaPattern.test(code);
    }

    return (
        <div className='new-game-form'>
            {/* <h4>Game Colors  <span className='required'>*</span></h4><hr /> */}
            {
                (tempColor) &&
                <div className='color-choice'>
                    <div>
                        <HexColorPicker color={tempColor} onChange={(color) => setTempColor(color)} />
                    </div>
                    <div>
                        <div className='field'>
                            <label>Hex Code</label>
                            <input type='text' value={tempColor ?? '#'} onChange={(e) => {
                                    const code = e.target.value;
                                    if (code) {
                                        setTempColor(code);
                                    } else {
                                        setTempColor('#')
                                    }
                                    const isValid = checkHexCode(code);
                                    setValidCode(isValid);
                                }} 
                            />
                            <div className="g-space-20"></div>
                            <div className='center'>
                                <div className='color-sample' style={{'backgroundColor': tempColor}}>
                                    Text
                                </div>
                            </div>
                            {
                                (!validCode) &&
                                <span className='required'>Not a valid color code</span>
                            }
                        </div>
                    </div>
                </div>
            } 
        </div>
    )
}

export default GameColorChoice;