import React, { useRef, useState } from 'react'
import './player-submit-form.styles.scss';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Modal, { ModalAlert } from '@/components/modal/modal.component';
import { sanitize } from 'isomorphic-dompurify';

const PlayerSubmitForm = ({ formData, saveData }) => {

    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ showUploadInfo, setShowUploadInfo ] = useState(false);

    function clickUpload() {
        document.getElementById('fileUpload').click();
    }

    function handleUpload (file) {
        function cancelUpload() {
            document.getElementById('fileUpload').value = null;
            saveData('file', null);
            saveData('fileName', null);
            saveData('upload', false);
            // setFileName(null);
            return;
        }
        const ext = file.name.match(/\.([^\.]+)$/)[1].toLowerCase();
        console.log(ext);
        const allowed = ['png', 'jpg', 'webp', 'bmp', 'gif', 'tif', 'heif', 'psd', 'webm', 'mp4', 'mkv', 'mpg', 'ogg', 'mp3', 'm4a', 'flac', 'wav', 'wma', 'aac', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'csv', 'txt', 'zip', 'tar', 'rar', '7z' ]
        if (!allowed.includes(ext)) {
            setAlertMessage('That file type is not allowed to be uploaded. Try a different file.');
            cancelUpload();
            return;
        }
        console.log(file.size);
        if (file.size > 2 * 1024 * 1024) {
            setAlertMessage('That file is too large to be uploaded. You cannot upload files larger than 2MB.');
            cancelUpload();
            return;
        }
        saveData('file', file);
        saveData('fileName', file.name);
        saveData('fileType', file.type);
        saveData('upload', true);
    }

    return (
        <div className='player-submit-form'>
            <div className='field'>
                <label>Input your text here<br /></label>
                <span className='meta'>(Required)</span>
                <textarea rows='3' value={formData.inputText ?? ''} onChange={(e) => saveData('inputText', sanitize(e.target.value))}></textarea>
            </div>
            <div className='field'>
                <label className='with-info-icon'>Upload a File<FontAwesomeIcon icon={faInfoCircle} className='clickable' onClick={() => setShowUploadInfo(true)} /></label>
                <span className='meta'>(Optional)</span>
                <div className='upload-div'>
                    <input type="file" id="fileUpload" onChange={(e) => handleUpload(e.target.files[0])}  />
                    <button className="g-button" type="button" tabIndex="-1" onClick={() => clickUpload()}>Choose a File to Upload</button>
                    <div>
                    {
                        formData.fileName ?
                        formData.fileName.length > 40 ?
                        formData.fileName.substring(0,40) + '...' :
                        formData.fileName :
                        'No file chosen...'
                    }
                    </div>
                </div>
            </div>
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
            <Modal show={showUploadInfo} cancel={() => setShowUploadInfo(false)} closeButton={true} cls='narrow' text='What kinds of files can be uploaded?'>
                <div className='modal-children'>
                    <p>You can upload lots of different kinds of files as a response to a challenge. But remember that your file cannot be larger than <b>2Mb</b>. Here's what you can upload:</p>
                    <ul>
                        <li><b>Images</b> (png, jpg, webp, bmp, gif, tif, heif, psd)</li>
                        <li><b>Videos</b> (webm, mp4, mkv, mpg, ogg)</li>
                        <li><b>Audio Files</b> (mp3, m4a, flac, wav, wma, aac)</li>
                        <li><b>Pdfs</b></li>
                        <li><b>Documents</b> (doc, docx, xls, xlsx, csv, txt)</li>
                        <li><b>Zip Files</b> (zip, tar, rar, 7z)</li>
                    </ul>
                    <p>If the file you need to submit is too large, or it isn't one of the above file types, you can always host the file in Google Drive or Dropbox or something similar and share the link in the text box.</p>
                    <hr/>
                    <div className='buttons'>
                        <button className='g-button' onClick={() => setShowUploadInfo(false)}>Ok</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default PlayerSubmitForm;