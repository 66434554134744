import React, { useEffect, useRef, useState } from 'react'
import './market.styles.scss';
import coin from '@/assets/icons/coin.png'
import ToggleInput from '@/components/SHARED/toggle-input/toggle-input.component';
import flatpickr from 'flatpickr';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';

const ManageContentMarket = ({ type, formData, saveData, gameElements }) => {
    
    const [ disabled, setDisabled ] = useState(true);
    // const date1Ref = useRef();
    // const date2Ref = useRef();
    // let date1Obj;

    useEffect(() => {
        setDisabled(!formData.opt_market);
    //     console.log(formData.opt_market);
    //     if (formData.opt_market) {
    //         const startDateElement = document.getElementById('marketStartDate');
    //         const flat1 = flatpickr(startDateElement, 
    //             {
    //                 altInput: true,
    //                 altFormat: "F j, Y, h:i K",
    //                 enableTime: true,
    //                 dateFormat: "Y-m-d",
    //                 onChange: (dates) => {
    //                     if (dates.length > 0) {
    //                         const date = new Date(dates[0]).getTime()
    //                         saveData('opt_marketDateStart', date)
    //                     } else {
    //                         saveData('opt_marketDateStart', null)
    //                     }
    //                 }
    //             }
    //         );
    //         date1Ref.current = flat1;
    //         date1Obj = flat1;
    //             // console.log('set fp')
    //         const endDateElement = document.getElementById('marketEndDate');
    //         const flat2 = flatpickr(endDateElement, 
    //             {
    //                 altInput: true,
    //                 altFormat: "F j, Y, h:i K",
    //                 enableTime: true,
    //                 dateFormat: "Y-m-d",
    //                 onChange: (dates) => {
    //                     const date = new Date(dates[0]).getTime()
    //                     saveData('opt_marketDateEnd', date)
    //                 }
    //             }
    //         );
    //         date2Ref.current = flat2;
    //         // console.log('set date2')
    // } else {
    //         saveData('opt_marketPrice', null);
    //         if (date1Ref.current) {
    //             date1Ref.current.clear();
    //         }
    //         if (date2Ref.current) {
    //             date2Ref.current.clear();
    //         }
    //     }
    }, [formData.opt_market])

    useEffect(() => {
        
    }, [])

    // useEffect(() => {
    //     if (!formData || !date1Ref.current || !date2Ref.current) return;
    //     if (formData.opt_marketDateStart) {
    //         date1Ref.current.setDate(new Date(formData.opt_marketDateStart));
    //     }
    //     if (formData.opt_marketDateEnd) {
    //         date2Ref.current.setDate(new Date(formData.opt_marketDateEnd));
    //     }
    // }, [formData, date1Ref.current, date2Ref.current])
    
    return (
        <div className='manage-content-market g-list-item form-section'>
            <h3>Put this item up for sale in the Marketplace</h3>
            <hr />
            <div className="market-content">
                <div className='market-toggle'>
                    <div className='toggle-with-text'>
                        <ToggleInput 
                            contentKey="opt_market" 
                            checked={formData.opt_market} 
                            saveData={saveData} 
                        />
                        <div className='toggle-text'>
                            This Item is listed in the Marketplace. 
                        </div>
                    </div>
                </div>
                <div className='market-toggle'>
                    <div className='toggle-with-text'>
                        <ToggleInput 
                            contentKey="opt_market" 
                            checked={
                                formData.opt_available &&
                                (
                                    (!formData.opt_dateOpen || formData.opt_dateOpen < new Date().getTime()) &&
                                    (!formData.opt_dateClose || formData.opt_dateClose > new Date().getTime())
                                )
                            } 
                            saveData={saveData} 
                        />
                        <div className='toggle-text'>
                            This Item is available to be purchased. 
                        </div>
                    </div>
                </div>
                <div className='market-price'>
                {
                    (formData.opt_itemType !== 'contributiona') &&
                    <div className={`field small ${disabled ? 'disabled' : ''}`}>
                        <label>How much should this item cost?<span className='required'>*</span></label>
                        <div className='input-with-icon'>
                            <img src={coin}  />
                            <input 
                                type='number' 
                                className='short-input' 
                                placeholder='Cost' 
                                value={formData.opt_marketPrice ?? ''} 
                                onChange={(e) => saveData('opt_marketPrice', Number(e.target.value))} 
                            />
                        </div>
                    </div>
                }
                </div>
                {/* <div className='market-dates'>
                    <div className={disabled ? 'disabled' : ''}>
                        <h3>Open Date</h3>
                        <div className="g-space-0-5"></div>
                        <div className='field small'>
                            <input 
                                className="flatpickr flatpickr-input" 
                                type="text" 
                                id="marketStartDate" 
                                placeholder='Start Date' 
                            />
                            <label>Leave blank if you want it available immediately.</label>
                        </div>
                    </div>
                    <div className={disabled ? 'disabled' : ''}>
                        <h3>Close Date</h3>
                        <div className="g-space-0-5"></div>
                        <div className='field small'>
                            <input 
                                className="flatpickr flatpickr-input" 
                                type="text" 
                                id="marketEndDate" 
                                placeholder='End Date' 
                            />
                            <label>Leave blank if you want it available indefinitely.</label>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className='g-space-1'></div>
        </div>
    )
}

export default ManageContentMarket;