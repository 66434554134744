import React, { useEffect } from 'react'
import './view-rewards-modal.styles.scss'

import Modal from '@/components/modal/modal.component'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'
import ImageContainer from '@/components/SHARED/image-container/image-container.component'

const ViewRewardsModal = ({ isOpen, onClose, activity }) => {
    
    const root = useSelector(state => state.userData.root)

    return (
        <div>
            <Modal show={isOpen} cancel={onClose} cls={'narrow'} closeButton={true} text='New Rewards!' >
                {
                    (activity) &&
                    <div  className='modal-children view-rewards-modal'>
                        <div className='view-rewards-head g-card'>
                            <div className='view-rewards-title'>
                                <ImageContainer src={activity.icon} alt='clickable treasure icon' className='view-rewards-big-icon' />
                                <h2>{activity.title}</h2>
                            </div>
                            <div className='view-rewards-head-text'>
                                
                                <p>
                                {
                                    (activity.event === 'Secret Code Found') &&
                                    <>
                                        <b>{activity.info}</b><br />
                                    </>
                                }
                                Earned {format(new Date(activity.ts), 'P')}
                                {
                                    (activity.comments) && 
                                    <>
                                        <br />Evaluator comments:<br /><b>"{activity.comments}"</b>
                                    </>
                                }
                                </p>
                            </div>
                        </div>
                        {
                            (activity.data && Object.values(activity.data).length > 0) &&
                            <div className='earnings-box'>    
                                <div className="g-space-20"></div>
                                <hr />
                                <h2>Rewards</h2>
                                
                                {
                                    Object.values(activity.data)
                                    .map((earning, index) => (
                                        <div key={index} className="g-card">
                                            <ImageContainer src={earning.icon} alt='reward icon' className='earning-icon' />
                                            <div className='earning-text'>
                                                {
                                                    (earning.title.indexOf(':') > -1)
                                                    ?
                                                    <><b>{earning.title.split(': ')[1]}</b><br /><span style={{"fontSize":"14px"}}>({earning.title.split(': ')[0]})</span></>
                                                    :
                                                    <b>{earning.title}</b>
                                                }
                                            </div>
                                            {
                                                (earning.quantity) &&
                                                <div>x {earning.quantity}</div>
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        }
                    </div>
                }
            </Modal>
        </div>
    )
}

export default ViewRewardsModal;