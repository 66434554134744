import React, { useEffect, useState } from 'react'
import './submit-code.styles.scss';
import { switchPlayerMenu } from '../side-menu.component';
import { UseCloudDistribute } from '@/utils/firebase.utils';
import { useSelector } from 'react-redux';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import { ModalAlert } from '@/components/modal/modal.component';
import HiddenText from '@/components/SHARED/hidden-text/hidden-text.component';
import { format } from 'date-fns';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import FoundCodeItem from './found-code-item.component';
import FullPageLoader from '@/components/loader/full-page-loader.component';

const SubmitCode = ({ gameData, playerData, playerActivity, gameElements }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ loading, setLoading ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(null);

    useEffect(() => {
        const pageTitle = document.getElementById('page-title');
        if (pageTitle) {
            pageTitle.innerText = 'Secret Codes'
        }
        switchPlayerMenu('secret-btn');
    }, []);

    async function submitCode() {
        setLoading(true);
        const code = document.getElementById('code-input').value;
        const formattedCode = code.toUpperCase().replace(/\s/gi,'');
        const res = await UseCloudDistribute('checkCode', {
            'code': formattedCode, 
            'gameId': gameData.gameId, 
            'playerId': playerData.playerId, 
            'playerData': userProfile, 
            'gameElements': gameElements, 
            'ts': new Date().getTime()
        })
        console.log(res);
        setLoading(false);
        if (res.error) {
            setAlertMessage('Something went wrong. Please try again soon. (Error: ' + res.error + ')')
            return;
        } else if (res.text && res.text === 'code not found') {
            setAlertMessage('Sorry, that code is incorrect. Please try a different code.')
            return;
        } else if (res.text && res.text === 'no access') {
            setAlertMessage('Sorry, that code is not available. Please try a different code.')
            return;
        } else if (res.text && res.text === 'code already found') {
            setAlertMessage("Sorry, you've already found that code. Please try a different code.")
            return;
        } else if (res.success) {
            setAlertMessage("CONGRATS! You've found a secret code! Check your rewards to see what you earned.")
            return;
        }
    }

    return (
        <div className='submit-code-container'>
            <div className='submit-code-content'>
                <div className='g-card'>
                    <div className='player-title'>
                        <ImageContainer src='/images/icons/secret.png' alt='secret codes icon' className='player-title-icon' />
                        Secret Codes
                    </div>
                    <hr />
                    <div className='secret-card'>
                        <div className='code-input'>
                            <div className='input field'>
                                <label>Submit a Secret Code!</label>
                                <input type='text' id='code-input' />
                                {
                                    (loading)
                                    ?
                                    <div className='buttons'>
                                        <button className='g-button' ><ThreeDotsLoader /></button>
                                    </div>
                                    :
                                    <div className='buttons'>
                                        <button className='g-button primary' onClick={() => submitCode()}>Submit</button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="g-space-2"></div>
                    <h3>Found Secret Codes</h3>
                    <hr />
                    <div className='found-codes-list'>
                    {
                        (playerActivity) &&
                        Object.values(playerActivity)
                        .sort((a,b) => a.ts < b.ts ? 1 : -1)
                        .filter(a => a.type === 'secretCode')
                        .map(a => (
                            <div key={a.id} >
                                <FoundCodeItem codeInfo={a} />
                            </div>
                        ))
                    }
                    </div>
                </div>
            </div>   
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} /> 
            <FullPageLoader show={loading} />
        </div>
    )
}

export default SubmitCode