import React, { useEffect, useRef, useState } from 'react'
import './settings-removed.styles.scss';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { getPlayerDataFromDb } from '@/utils/getDataFromDb';
import UserIcon from '@/components/layout-auth/user-icon/user-icon.component';
import { ModalConfirm } from '@/components/modal/modal.component';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { useSelector } from 'react-redux';
import { sortPlayers } from '@/utils/sortPlayers';

const SettingsRemoved = ({ gameData, cancel }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ removedPlayers, setRemovedPlayers ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const approveRef = useRef();
    const removedRef = useRef();
    let removedListener;

    useEffect(() => {
        if (!gameData) return;
        getRemovedUsers();

        return () => {
            if (removedListener) removedListener();
        }
    }, [gameData])

    function getRemovedUsers() {
        getPlayerDataFromDb({
            'gameId': gameData.gameId, 
            'removed': true, 
            'callback': callback, 
            'handleListener': handleListener
        })
        function callback(data) {
            removedRef.current = {...removedRef.current, ...{[data.id]: data}}
            const sortedPlayers = sortPlayers({
                'playerList': removedRef.current, 
                'sortBy': 'name',
                'filter': 'players'
            });
            setRemovedPlayers(sortedPlayers);
        }
        function handleListener(unsub) {
            removedListener = unsub;
        }
    }

    async function reinstatePlayer(p) {
        setConfirmMessage(`Are you sure you want to reinstate this player?${p.enrollMethod === 'email' ? ` This will remove this user's email from the banned list.` : ''}`);
        approveRef.current = () => {
            continueWithReinstate();
        }

        async function continueWithReinstate() {
            const res = UseCloudFunction('reinstatePlayer', {
                'appId': userProfile.appId,
                'player': p,
                'gameId': gameData.gameId,
            })
        }
    }

    return (
        <div className='settings-removed'>
            <div className='g-card'>
                <BackButton cancel={cancel} />
                <div className="g-space-1"></div>
                <div className='card-title'>Settings: Removed Players</div>
                <div className="g-space-1"></div>
                <div className='player-list'>
                    {
                        (removedPlayers) &&
                        Object.values(removedPlayers)
                        .sort((a,b) => a.lName.toLowerCase())
                        .map(p => (
                            <div 
                                key={p.playerId} 
                                className='player-item g-list-item'
                            >
                                <div className='player-info'>
                                    <UserIcon profile={p} />
                                    {
                                        p.lName ? 
                                        `${p.lName}, ${p.fName}` : 
                                        p.fName
                                    }
                                </div>
                                <div>
                                    <button 
                                        className='g-button med-btn' 
                                        onClick={() => reinstatePlayer(p)}
                                    >
                                        Reinstate
                                    </button>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="g-space-1"></div>
            </div>
            <ModalConfirm 
                show={confirmMessage} 
                cancel={() => setConfirmMessage(null)} 
                message={confirmMessage}
                onApprove={approveRef.current}
            /> 
        </div>
    )
}

export default SettingsRemoved