import React, { useEffect, useRef, useState } from 'react'
import './player-store.styles.scss';
import { useAsyncError, useLocation } from 'react-router-dom';
import SearchBar from '@/components/SHARED/search-bar/search-bar.component';
import StoreItemCard from './store-components/store-item-card.component';
import CartWidget from './store-components/cart-widget.component';
import StoreContributionCard from './store-components/store-contribution-card.component';
import ItemSearchBar from '@/components/SHARED/search-bar/item-search-bar.component';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import PlayerCollectibles from './player-collectibles.component';


const PlayerStore = ({ 
    gameData, 
    playerData, 
    gameElements, 
    availableItems,
    itemType, 
    gameStats,
    teamMembers,
    saleList
}) => {

    const [ itemsToDisplay, setItemsToDisplay ] = useState([]);
    const [ filteredItems, setFilteredItems ] = useState([]);
    const [ collections, setColletions ] = useState([]);
    const availableRef = useRef();
    const location = useLocation();

    useEffect(() => {
        if (!availableItems || !itemType) return;
        // console.log(itemType);
        const availItems = availableItems
        .filter(e => e.opt_itemType === itemType);
        // console.log(availItems);
        availableRef.current = [...availItems];
        setFilteredItems([...availableRef.current]);
        const coll = Object.values(gameElements).filter(e => e.type === 'collection');
        setColletions([...coll]);
        console.log(coll);
        setItemsToDisplay([...availableRef.current]);
    }, [availableItems, itemType]);

    function search(text) {
        if (!text) {
            setItemsToDisplay([...availableRef.current])
            return;
        }
        let tempItems = [...availableRef.current]
        let filteredItems = tempItems
        .filter(i => 
            i.name.toLowerCase().includes(text.toLowerCase()) ||
            i.desc.toLowerCase().includes(text.toLowerCase())
        )
        setItemsToDisplay([...filteredItems]);
    }

    return (
        <div className='player-store'>
            <div className='left-col'>
                
                {
                    (itemType && itemType !== 'collectible')
                    ?
                    <>
                    <ItemSearchBar 
                        availableItems={filteredItems}
                        setItemsToDisplay={setItemsToDisplay} 
                        itemType={itemType}
                        collections={collections}
                        noTypes={true}
                    />
                    <div className="g-space-1"></div>
                    <div className='store-item-list'>
                        {
                            (itemsToDisplay.length > 0)
                            ?
                            itemsToDisplay.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                            .map(i => (
                                <div key={i.id}>
                                    {
                                        (i.opt_itemType === 'contribution')
                                        ?
                                        <StoreContributionCard 
                                            item={i} 
                                            playerData={playerData} 
                                            gameStats={gameStats} 
                                            gameElements={gameElements}
                                            teamMembers={teamMembers}
                                        />
                                        :
                                        <StoreItemCard 
                                            item={i} 
                                            playerData={playerData} 
                                            gameStats={gameStats} 
                                            gameElements={gameElements}
                                            teamMembers={teamMembers}
                                            saleList={saleList}
                                        />
                                    }
                                </div>
                            ))
                            :
                            <div className='center-col'>
                                <h4>No items for sale in this category.</h4>
                            </div>
                        }
                    </div>
                    </>
                    :
                    (itemType && itemType === 'collectible')
                    ?
                    <PlayerCollectibles
                        playerData={playerData}
                        gameStats={gameStats}
                        gameElements={gameElements}
                        teamMembers={teamMembers}
                        collections={collections}
                        itemsToDisplay={itemsToDisplay}
                        saleList={saleList}
                    />
                    :
                    <div className='center-col'>
                        <ThreeDotsLoader />
                    </div>
                }                
            </div>
            <div className='right-col'>
                <div className='g-list-item'>
                    <CartWidget
                        playerData={playerData} 
                    />
                    
                </div> 
            </div>
        </div>
    )
}

export default PlayerStore;