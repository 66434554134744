import React, { useEffect } from 'react'
import './toggle-input.styles.scss';

const ToggleInput = ({ contentKey, checked, saveData, value }) => {

    useEffect(() => {
        // console.log(contentKey);
    }, [contentKey])

    return (
        <div className='toggle-input'>
            <input 
                className='toggle' 
                type='checkbox' 
                checked={checked ?? false} 
                onChange={(e) => saveData(contentKey, value ?? e.target.checked)} 
            />
        </div>
    )
}

export default ToggleInput