import React, { useEffect } from 'react'
import './sample-layout.styles.scss';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';

const SampleLayout = ({ formData }) => {

    function toggleHover(e, enter) {
        if (enter) {
            e.style.backgroundColor = formData.color;
            e.style.color = '#fff';
        } else {
            e.style.backgroundColor = '#fff';
            e.style.color = 'inherit';
        }
    }

    return (
        <div className='sample-layout '>
            {
                (formData.bg && formData.icon && formData.header) &&
                <div className='g-card' style={
                    formData.bg.type === 'image'
                    ?
                    {
                        backgroundImage: `url("${formData.bg.url}")`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                    }
                    :
                    {
                        background: formData.bg.hexBottom, 
                        background: `-webkit-linear-gradient(to top, ${formData.bg.hexBottom}, ${formData.bg.hexTop})`,
                        background: `linear-gradient(to top, ${formData.bg.hexBottom}, ${formData.bg.hexTop})`
                    }
                }>
                    <div className='game-logo'>
                        <ImageContainer src={formData.header.url ?? formData.header} />
                    </div>
                    <div className="g-space-20"></div>
                    <div className='g-card title-card'>
                        <div className='info'>
                            <div className='title'>{formData.gameName}</div>
                            <div className='desc meta'>{formData.shortDesc}</div>
                            <div className='meta'>Join Code: {formData.joinCode}</div>
                        </div>
                        <ImageContainer src={formData.icon.url ?? formData.icon} />
                    </div>
                    <div className="g-space-20"></div>
                    <div className="sample-button-row">
                        <button className='g-button' onMouseEnter={(e) => toggleHover(e.target, true)} onMouseLeave={(e) => toggleHover(e.target, false)}>Button 1</button>
                        <button className='g-button'onMouseEnter={(e) => toggleHover(e.target, true)} onMouseLeave={(e) => toggleHover(e.target, false)}>Button 2</button>
                        <button className='g-button'onMouseEnter={(e) => toggleHover(e.target, true)} onMouseLeave={(e) => toggleHover(e.target, false)}>Button 3</button>
                        <button className='g-button'onMouseEnter={(e) => toggleHover(e.target, true)} onMouseLeave={(e) => toggleHover(e.target, false)}>Button 4</button>
                    </div>
                </div>
            }
        </div>
    )
}

export default SampleLayout