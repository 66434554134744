export async function resizeImage(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const maxWidth = 450;
      const maxHeight = 450;
      reader.onload = (event) => {
        const img = new Image();
        
        img.onload = () => {
          let newWidth = img.width;
          let newHeight = img.height;
  
          if (img.width > maxWidth) {
            newWidth = maxWidth;
            newHeight = (img.height * maxWidth) / img.width;
          }
  
          if (newHeight > maxHeight) {
            newHeight = maxHeight;
            newWidth = (img.width * maxHeight) / img.height;
          }
  
          const canvas = document.createElement('canvas');
          canvas.width = newWidth;
          canvas.height = newHeight;
  
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, newWidth, newHeight);
  
          canvas.toBlob(
            (resizedBlob) => {
              resolve(resizedBlob);
            },
            file.type,
            0.7 // Adjust the quality here if needed
          );
        };
  
        img.src = event.target.result;
      };
  
      reader.onerror = (error) => {
        reject(error);
      };
  
      reader.readAsDataURL(file);
    });
}

/**
 * OLD
 * @param {*} url 
 * @param {*} fileType 
 * @returns 
 */

export const resizeImageOLD = async ( url, fileType ) => {
    console.log(fileType)
    const options = {
        maxWidth: 650,
        maxHeight: 650,
        contentType: fileType,
        quality: 0.7
    }
    const calculateSize = (img) => {
        let w = img.width,
            h = img.height;
        if (w > h) {
            if (w > options.maxWidth) {
                h = Math.round((h * options.maxWidth) / w);
                w = options.maxWidth;
            }
        } else {
            if (h > options.maxHeight) {
                w = Math.round((w * options.maxHeight) / h);
                h = options.maxHeight;
            }
        }
        return [w, h];
    };
    
    return new Promise((resolve) => {
        const img = new Image();
        img.src = url;
        img.onerror = function () {
            URL.revokeObjectURL(this.src);
        };
        img.onload = function () {
            URL.revokeObjectURL(this.src);
            const [newWidth, newHeight] = calculateSize(
                img,
                options.maxWidth,
                options.maxHeight
            );
            console.log(newWidth, newHeight)
            const canvas = document.createElement("canvas");
            canvas.width = newWidth;
            canvas.height = newHeight;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, newWidth, newHeight);
    
            const resultUrl = canvas.toDataURL(options.contentType, options.quality),
            result = {
                url: resultUrl,
                contentType: resultUrl.match(/^data\:([^\;]+)\;base64,/im)[1] || "",
                b64: resultUrl.replace(/^data\:([^\;]+)\;base64,/gim, "")
            };
    
            canvas.toBlob(
                (blob) => {
                    result.size = blob.size;
                    resolve(result);
                },
                options.contentType,
                options.quality
            );
        };
    });
};