import React, { useEffect, useState } from 'react';
import './print-logins.styles.scss';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UseCloudFunction } from '@/utils/firebase.utils';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import { ModalAlert } from '@/components/modal/modal.component';
import { sortPlayers } from '@/utils/sortPlayers';
import UserIcon from '@/components/layout-auth/user-icon/user-icon.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';

const PrintLogins = () => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ loading, setLoading ] = useState(true);
    const [ players, setPlayers] = useState(null);
    const [ gameData, setGameData ] = useState(null);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const { gamePath } = useParams();
    
    useEffect(() => {
        const timer = setTimeout(() => {
            if (!players) {
                setLoading(false);
            }
        }, 5000)

        return () => {
            if (timer) clearInterval(timer);
        }
    }, [])

    useEffect(() => {
        if (!userProfile || !gamePath) return;
        getPrintableData();
    }, [userProfile, gamePath])

    async function getPrintableData() {
        const res = await UseCloudFunction(
            'getPrintableData',
            {
                'gamePath': gamePath,
                'userProfile': userProfile
            }
        )
        console.log(res);
        if (res.error) {
            return;
        }
        const activePlayerArr = Object.values(res.players).filter(p => p.status === 'active');
        const sortedPlayers = sortPlayers({
            'playerList': activePlayerArr,
            'filter': 'players'
        });
        setPlayers([...sortedPlayers]);
        setGameData(res.gameData);
    }

    function printPage() {
        window.print();
    }

    return (
        <div className='print-logins'>
            <div className='print-logins-content'>
                {
                    (loading)
                    ?
                    <div className='center-col'>
                        <ThreeDotsLoader />
                    </div>
                    :
                    (players && gameData)
                    ?
                    <div>
                        <div id='print-button'> 
                            <div className="g-space-2"></div>
                            <div className='buttons'>
                                <button 
                                    
                                    className='g-button'
                                    onClick={() => printPage()}
                                >
                                    Print Login Cards
                                </button>
                            </div>
                            <div className="g-space-2"></div>
                        </div>
                        <div className='player-list'>
                        {
                            players.map((p, index) => (
                                <div key={p.playerId} className={`player-item ${(index + 1)%10 === 0 ? 'break' : ''}`}>
                                    <div className='player-icon'>
                                        <UserIcon profile={p} noHover={true} med={true} />
                                        <ImageContainer src='/images/themes/logos/gamably.png' />
                                    </div>
                                    <div className='player-info'>
                                        <div className='name'>
                                            {p.displayName}
                                        </div>
                                        <div>
                                            Go to <b>app.gamably.com</b>,<br />click "I Have a Code".
                                        </div>
                                        <div>
                                            Game Code: <b>{gameData.joinCode}</b>
                                        </div>
                                        <div>
                                            Passcode: <b>{p.passcode}</b>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        </div>
                    </div>
                    :
                    <h3>No players found</h3>
                }
            </div>
            <ModalAlert 
                show={alertMessage}
                cancel={() => setAlertMessage(null)}
                message={alertMessage}
            /> 
        </div>
    )
}

export default PrintLogins