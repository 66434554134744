import React from 'react';
import './info-links.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/free-regular-svg-icons';

const InfoLinks = () => {
    return (
        <div className='info-links-container'>
            <div>
                <FontAwesomeIcon icon={faCopyright} />{" "}
                Copyright {new Date().getFullYear()} Gamably, LLC
            </div>
            <div className='info-links'>
                <div className='row'>
                    <a href="https://blog.tamingthetech.com/reader.html?a=1580440246431" target="_blank" className="footer-link" rel="noreferrer">
                        About
                    </a>
                    |
                    <a href="mailto:adam@gamably.com" target="_blank" className="footer-link" rel="noreferrer">
                        Contact
                    </a>
                </div>
                |
                <div className='row'>
                    <a href="/terms" target="_blank" className="footer-link" rel="noreferrer">
                        Terms of Use
                    </a>
                    |
                    <a href="/privacy" target="_blank" className="footer-link" rel="noreferrer">
                        Privacy Policy
                    </a>
                </div>
            </div>
        </div>
    )
}

export default InfoLinks