import React from 'react'

const ManageContentMinimumPoints = ({ formData, saveData, type }) => {
    return (
        <div className='manage-content-minimum-points g-list-item form-section'>
            <h3>Minimum Points<span className='required'>*</span></h3>
            <hr />
            <p>What is the minimum total XP that must be earned for this level?</p>
            <div className="minium-points-content">
                <div className='field small'>
                    <input type='number' className='short-input' placeholder='Minimum XP earned' value={formData.opt_pointMinimum ?? ''} onChange={(e) => saveData('opt_pointMinimum', Number(e.target.value))} />
                </div>
            </div>
        </div>
    )
}

export default ManageContentMinimumPoints