export function sanitize(str) {
    const div = document.createElement('div')
    div.textContent = str
    const regex = /[^A-Za-z0-9\s"',./\-_;:?!()@#&$]/g;
    const cleanedString = div.innerHTML.replace(regex, "");
    return cleanedString;
}

export function stripHtml(str) {
    const cleanedString = str.replace(/(<([^>]+)>)/ig, '')
    return cleanedString;
}
