import React from 'react'
import './coppa.styles.scss';
import UnauthHeader from '@/components/layout-unauth/unauth-head.component';
import Footer from '@/components/layout-unauth/footer/footer.component';
import UnauthLayout from '@/components/layout-unauth/unauth-layout.component';
import file from "@/assets/files/coppa.docx";

const Coppa = () => {

    const onButtonClick = () => {
        const link = document.createElement("a");
        link.href = file;
        link.download = "coppa.docx"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className='coppa'>
            <UnauthLayout>
            <div className='coppa-container'>
                <div className='g-space-2'></div>
                <div className='info-card g-card'>
                    <div className='card-title'>
                        COPPA and Gamably
                    </div>
                    <hr />
                    <div className='coppa-content'>
                        <p>By law, schools in the United States must comply with the Children’s Online Privacy Protection Act (COPPA) before using Gamably. This law requires written parental consent for children under the age of 13 before they can use online services that store any sort of personal information (including uploads, challenge or quest submissions, game earnings, and so on). This information will be protected as per the <a href="/privacy" target="_blank">Gamably Privacy Policy</a>.</p>

                        <p>If you are a teacher with students under the age of 13, you will need to notify their parents and obtain their consent before adding your students to the websie.</p>

                        <p>You can download the Gamably/COPPA consent form <button className='g-button text-only inline' onClick={onButtonClick}>here</button>. We recommend keeping these forms on file once they are completed.</p>

                    </div>
                </div>
                <div className='g-space-2'></div>
            </div>
            </UnauthLayout>
        </div>
    )
}

export default Coppa