import React, { useState } from 'react'
import './head-bar-admin.styles.scss'

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UserIcon from '@/components/layout-auth/user-icon/user-icon.component';
import UserDropdown from '@/components/layout-auth/head-bar/user-dropdown.component';
import gamably_logo_white from '@/assets/logo_long_white.png';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';

const HeadBarAdmin = () => {

    const user = useSelector(state => state.userData.currentUser);
    const profile = useSelector(state => state.userData.userProfile);
    const [ showDropdown, setShowDropdown ] = useState(false);
    const navigate = useNavigate();

    return (
        <div className="head-bar-admin-container">
            <div className="logo-container" onClick={() => navigate('/loading')}>
                    {/* <div className="g-card"> */}
                    
                    <img src={gamably_logo_white} alt='Gamably logo' className="logo" />
                    <div className="admin-menu-spacer"></div>
                    <h3>Admin</h3>
                {/* </div> */}
                
            </div>
            {/* <SearchContainer setShowSearchModal={setShowSearchModal} location='head-bar' /> */}
            <div className='head-menu-box'>
                <div className='head-menu-flex'>
                    <div className='head-menu'>
                        <div className='head-menu-item' onClick={() => navigate('/loading')}>
                            Home
                        </div>
                        <div className='menu-spacer'></div>
                        {/* {
                            (profile.admin) &&
                            <div className='head-menu-item'>
                                Admin
                            </div>
                        } */}
                    </div>
                    <button className='head-user-info g-button text-only'  onClick={() => setShowDropdown(!showDropdown)}>
                        {
                            (user && profile) &&
                            <>
                                <div>{profile.fName}{profile.lName ? ` ${profile.lName}` : ''}</div>
                                <div className='user-icon'>
                                    <UserIcon profile={profile} reverse={true} white={true} />
                                </div>

                            </>
                            
                        }
                    </button>
                </div>
                <UserDropdown show={showDropdown} />
            </div>
        </div>
    )
}

export default HeadBarAdmin;