import React, { useEffect, useState } from 'react'
import './categories.styles.scss';
import { sanitize } from '@/utils/sanitize';

const ManageContentCategories = ({ type, saveData, formData, categories, disabled }) => {

    const [ textCat, setTextCat ] = useState('');
    const [ selectCat, setSelectCat ] = useState('');

    useEffect(() => {
        // console.log(categories);
    }, [categories])

    useEffect(() => {
        if (!formData) {return;}
        if (formData.opt_cat) {
            setSelectCat(formData.opt_cat);
        }
    }, [formData])

    function chooseCategory(cat, format) {
        if (format === 'new') {
            setSelectCat('')
            setTextCat(cat);
        } else {
            setTextCat('')
            setSelectCat(cat);
        }
        saveData('opt_cat', cat)
    }


    return (
        <div className={`manage-content-categories g-list-item form-section ${disabled}`}>
            <h3>Category</h3>
            <hr />
            <div className="categories-content">
                <div className='field small'>
                    <label>Assign a Category</label>
                    <input type='text' id='category-input' value={textCat} placeholder='Category Name' onChange={(e) => chooseCategory(sanitize(e.target.value), 'new')} />
                </div>
                <div className='field small'>
                    <label>OR Choose an Existing Category</label>
                    <select id='category-select' value={selectCat} onChange={(e) => chooseCategory(e.target.value, 'existing')} required>
                        <option value='' >Choose a Category...</option>
                        {
                            (categories.length > 0) &&
                            categories.map(c => (
                                <option key={c} value={c}>{c}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
            <div className="g-space-1"></div>
        </div>
    )
}

export default ManageContentCategories