import React from 'react';
import './sale-info-display.styles.scss';
import ImageContainer from '../image-container/image-container.component';
import { format } from 'date-fns';
import ItemPrice from './item-price.component';

const SaleInfoDisplay = ({ saleData, saleList, gameElements }) => {
    return (
        <div className='sale-info-display'>
            <div className='head'>
                <ImageContainer src={saleData.sale.icon} className='icon' />
                <div className='info'>
                    <div className='name'>{saleData.sale.name}</div>
                    <div className='name'><b>{saleData.sale.opt_salePercentage}% OFF!</b></div>
                    <div className='desc'>{saleData.sale.desc}</div>
                    
                    {
                        (saleData.sale.opt_dateOpen) &&
                        <div className=''>Sale Starts: {format(new Date(saleData.sale.opt_dateOpen), "Pp")}</div>
                    }
                    {
                        (saleData.sale.opt_dateClose) &&
                        <div className=''>Sale Ends: {format(new Date(saleData.sale.opt_dateClose), "Pp")}</div>
                    }
                </div>
            </div>
            {
                (saleData.sale.opt_itemList && saleData.sale.opt_itemList.length > 0) &&
                <>
                <hr />
                Items Included:
                <div className="g-space-1"></div>
                <div className='item-list'>
                {
                    (gameElements) &&
                    saleData.sale.opt_itemList.map(itemId => {
                        const itemData = gameElements[itemId];
                        if (!itemData) return;
                        return (
                            <div className='item-list-item'>
                                <div className='icon-container'>
                                    <ImageContainer
                                        src={itemData.icon}
                                        className='item-list-icon'
                                    />
                                </div>
                                <div className='name'>
                                    {itemData.name}
                                </div>
                                <div className='price'>
                                    <ItemPrice
                                        item={itemData}
                                        saleList={saleList}
                                        no_button={true}
                                    />
                                </div>
                            </div>
                        )
                    })
                }
                </div>
                </> 
                
            }
        </div>
    )
}

export default SaleInfoDisplay