import React, { useEffect, useRef, useState } from 'react';
import './sale-form.styles.scss';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import ManageContentInformation from '../../create-elements/blocks/information.component';
import ManageContentIconCropped from '../../create-elements/blocks/icon.component';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import ManageContentDates from '../../create-elements/blocks/dates.component';
import ItemSelector from '../../create-elements/blocks/item-selector.component';
import SaleAmount from '../../create-elements/blocks/sale-amount.component';
import { useSelector } from 'react-redux';
import { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import FullPageLoader from '@/components/loader/full-page-loader.component';
import { RandomString } from '@/utils/randomString';
import { UseCloudFunction } from '@/utils/firebase.utils';

const SaleForm = ({ gameData, gameElements }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ formData, setFormData ] = useState({});
    const [ existingDescription, setExistingDescription ] = useState(null);
    const [ clearEditor, setClearEditor ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ storeItems, setStoreItems ] = useState(null);
    const { saleId } = useParams();
    const dataRef = useRef();
    const approveRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        if (!gameElements) return;
        let obj = {}
        Object.values(gameElements)
        .filter(e => e.type === 'item' && e.opt_market && e.opt_marketPrice)
        .map(e => {obj[e.id] = e})
        setStoreItems(obj);
    }, [gameElements])

    function saveData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}};
        setFormData(dataRef.current);
    }

    async function handleSubmit() {
        let tempData = {...dataRef.current};
        console.log(tempData);
        if (
            !tempData.name || 
            !tempData.opt_itemList || 
            !tempData.opt_salePercentage
        ) {
            setAlertMessage('Please complete the required parts of the form before continuing.');
            return;
        }
        setConfirmMessage('Are you sure you are ready to save this Sale? If the current date falls within your chosen start and end dates or you did not choose a start date, the sale will be effective immediately.')
        approveRef.current = () => {
            completeSave(tempData);
        }

        async function completeSave(tempData) {
            setLoading(true);
            const now = new Date().getTime();
            const gameCode = gameData.gameId.substring(gameData.gameId.indexOf('-')-5, gameData.gameId.indexOf('-')+6)
            tempData = {
                ...tempData,
                ...{
                    'icon': 'images/icons/sale.png',
                    'ts': now,
                    'appId': userProfile.appId,
                    'ts_created': now,
                    'gameId': gameData.gameId,
                    'id': now + '-' + gameCode + '-sale',
                    'status': 'active',
                    'type': 'item',
                    'public': true,
                    'path': RandomString(18, 'abcdefghijklmnopqrstuvwxyz-_0123456789')
                }
            }
            console.log(tempData);
            const res = await UseCloudFunction(
                'saveGameElement', 
                {
                    'appId': userProfile.appId,
                    'elementData': tempData,
                    'userName': `${userProfile.fName} ${userProfile.lName}`,
                }
            )
            if (res.error) {
                setAlertMessage('Something went wrong: ' + res.error)
                setLoading(false);
                return;
            }
            setAlertMessage('Sale saved successfully!');
            setLoading(false);
            navigate(`/manage/${gameData.path}/store/sales`)
        }
    }

    return (
        <div className='sale-form-container'>
            
            <div className='sale-form'>
                <ManageContentInformation 
                    type='sale' 
                    saveData={saveData} 
                    formData={formData} 
                    existingDescription={existingDescription} 
                    clearEditor={clearEditor} 
                    setClearEditor={setClearEditor}
                    textEditorLabel='Sale Explanation (optional)'
                />
                <div className='g-space-2'></div>
                <ItemSelector 
                    type='sale' 
                    formData={formData} 
                    saveData={saveData} 
                    gameElements={storeItems} 
                    gameData={gameData}
                    saveKey='opt_itemList'
                    headText="Select Items for this Sale"
                />
                <div className='g-space-2'></div>
                <SaleAmount
                    type='sale'
                    formData={formData}
                    saveData={saveData}
                />
                <div className='g-space-2'></div>
                <ManageContentDates
                    type='sale' 
                    saveData={saveData} 
                    formData={formData} 
                    gameElements={gameElements}
                    text='Sales'
                    title='Start and End Dates'
                />
                <div className='g-space-2'></div>
                <span className='required'>* = Required</span>
                <div className='buttons'>
                    {
                        (loading) 
                        ?
                        <>
                            <button type='button' className='g-button'>Cancel</button>
                            <button type='button' className='g-button'><ThreeDotsLoader /></button>
                        </>
                        :
                        <>
                            <button type='button' className='g-button' onClick={() => cancelCreate()}>Cancel</button>
                            <button type='button' className='g-button primary' onClick={() => handleSubmit()}>Save Level</button>
                        </>
                    }
                </div>
            </div>
            <ModalAlert
                show={alertMessage}
                cancel={() => setAlertMessage(null)}
                message={alertMessage}
            />
            <ModalConfirm
                show={confirmMessage}
                cancel={() => setConfirmMessage(null)}
                message={confirmMessage}
                onApprove={approveRef.current}
            />
            <FullPageLoader show={loading} />
        </div>
    )
}

export default SaleForm;