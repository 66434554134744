import React, { useEffect } from 'react';
import './progress-bar.styles.scss';

const ProgressBar = ({ progressValue, itemId }) => {

    useEffect(() => {
        if (!progressValue) return;
        const bar = document.getElementById(`${itemId}_bar`);
        bar.style.width = `${progressValue}%`;
    }, [progressValue]);
    
    return (
        <div className='progress-container'>
            <div className="progress">
                <div className="progress-bar" id={itemId + '_bar'} aria-valuenow={progressValue} min="0" max="100" >
                </div>
            </div>
            <div className='meta'>{progressValue.toLocaleString(undefined, { maximumFractionDigits: 1 })}%</div>
        </div>
    )
}

export default ProgressBar