import React, { useEffect, useState } from 'react';
import './sale-container.styles.scss';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTag } from '@fortawesome/free-solid-svg-icons';
import SaleForm from './sale-form.component';

const SaleContainer = ({ gameData, gameElements }) => {

    const [ saleList, setSaleList ] = useState([])
    const { saleId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!gameElements) return;
        const sales = Object.values(gameElements)
        .filter(e => e.type === 'sale')
        setSaleList([...sales]);
    }, [gameElements])

    return (
        <div className='sale-container'>
            <Routes>
                <Route 
                    path=''
                    element={
                        <div>
                            <div className='card-title'>
                                <div className='title-with-icon'>
                                    <FontAwesomeIcon icon={faTag} />
                                    Manage Sales
                                </div>
                                <button 
                                    className='g-button'
                                    onClick={() => navigate('create')}
                                >
                                    Create a Sale
                                    <FontAwesomeIcon icon={faPlus} />
                                </button>
                            </div>
                            <hr />
                            {
                                (saleList) &&
                                saleList.map(s => (
                                    <div
                                        key={s.id} 
                                        className='sale'>
                                        {s.name}
                                    </div>
                                ))
                            }
                        </div>
                    }
                ></Route>
                <Route 
                    path='create'
                    element={
                        <div>
                            <div className='card-title'>
                                <div className='title-with-icon'>
                                    <FontAwesomeIcon icon={faTag} />
                                    Manage Sales
                                </div>
                            </div>
                            <hr />
                            <SaleForm
                                gameData={gameData}
                                gameElements={gameElements}     
                            />
                        </div>
                    }
                ></Route>
            </Routes>
        </div>
    )
}

export default SaleContainer 