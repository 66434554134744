export function createAlias() {
    const randomAdj = adj[Math.floor(Math.random() * adj.length)];
    const randomNoun = noun[Math.floor(Math.random() * adj.length)];
    return `${randomAdj} ${randomNoun}`;
}

const adj = [
	"Able",
	"Accepting",
	"Adventurous",
	"Aggressive",
	"Ambitious",
	"Articulate",
	"Athletic",
	"Bold",
	"Brave",
	"Bright",
	"Busy",
	"Calm",
	"Careful",
	"Caring",
	"Cautious",
	"Cheerful",
	"Clever",
	"Compassionate",
	"Complex",
	"Confident","Considerate","Cooperative","Courageous","Creative","Curious","Dainty","Daring","Dark","Defiant","Determined","Disagreeable","Disgruntled","Eager","Efficient","Energetic","Excited","Fair","Faithful","Fancy","Forgiving","Free","Friendly","Fun-loving","Funny","Generous","Gentle","Giving","Gorgeous","Gracious","Grouchy","Handsome","Happy","Hard-working","Helpful","Honest","Hopeful","Humble","Humorous","Imaginative","Impulsive","Independent","Intelligent","Inventive","Jealous","Joyful","Keen","Kind","Knowledgeable","Lazy","Leader","Light","Light-hearted","Likeable","Lively","Lovable","Loyal","Mature","Melancholy","Merry","Messy","Mischievous","Naive","Neat","Nervous","Noisy","Opinionated","Organized","Outgoing","Passive","Patient","Patriotic","Personable","Pleasant","Pleasing","Popular","Pretty","Prim","Proper","Proud","Questioning","Quiet","Radical","Realistic","Rebellious","Reflective","Relaxed","Reliable","Reserved","Respectful","Responsible","Reverent","Sarcastic","Self-confident","Sensible","Sensitive","Serious","Shy","Silly","Simple","Smart","Stable","Strong","Stubborn","Studious","Successful","Tall","Tense","Thoughtful","Thrilling","Timid","Tireless","Tolerant","Tough","Tricky","Trusting","Understanding","Unique","Unlucky","Unselfish", "Warm", "Wild", "Willing", "Wise","Witty"];
const noun = [
    "Aardvark",
    "Albatross",
    "Alligator",
    "Alpaca",
    "Ant",
    "Anteater",
    "Antelope",
    "Ape",
    "Armadillo",
    "Donkey",
    "Baboon",
    "Badger",
    "Barracuda",
    "Bat",
    "Bear",
    "Bee",
    "Bison",
    "Boar",
    "Buffalo",
    "Butterfly",
    "Camel",
    "Capybara",
    "Caribou",
    "Cassowary",
    "Cat",
    "Caterpillar",
    "Cattle",
    "Chamois",
    "Cheetah",
    "Chicken",
    "Chimpanzee",
    "Chinchilla",
    "Clam",
    "Cobra",
    "Cockroach",
    "Cod",
    "Cormorant",
    "Coyote",
    "Crab",
    "Crane",
    "Crocodile",
    "Crow",
    "Curlew",
    "Deer",
    "Dinosaur",
    "Dog",
    "Dogfish",
    "Dolphin",
    "Dotterel",
    "Dove",
    "Dragonfly",
    "Duck",
    "Dugong",
    "Dunlin",
    "Eagle",
    "Echidna",
    "Eel",
    "Eland",
    "Elephant",
    "Elk",
    "Emu",
    "Falcon",
    "Ferret",
    "Finch",
    "Fish",
    "Flamingo",
    "Fly",
    "Fox",
    "Frog",
    "Gaur",
    "Gazelle",
    "Gerbil",
    "Giraffe",
    "Gnat",
    "Gnu",
    "Goat",
    "Goldfinch",
    "Goldfish",
    "Goose",
    "Gorilla",
    "Goshawk",
    "Grasshopper",
    "Grouse",
    "Guanaco",
    "Gull",
    "Hamster",
    "Hare",
    "Hawk",
    "Hedgehog",
    "Heron",
    "Herring",
    "Hippopotamus",
    "Hornet",
    "Horse",
    "Human",
    "Hummingbird",
    "Hyena",
    "Ibex",
    "Ibis",
    "Jackal",
    "Jaguar",
    "Jay",
    "Jellyfish",
    "Kangaroo",
    "Kingfisher",
    "Koala",
    "Kookabura",
    "Kouprey",
    "Kudu",
    "Lapwing",
    "Lark",
    "Lemur",
    "Leopard",
    "Lion",
    "Llama",
    "Lobster",
    "Locust",
    "Loris",
    "Louse",
    "Lyrebird",
    "Magpie",
    "Mallard",
    "Manatee",
    "Mandrill",
    "Mantis",
    "Marten",
    "Meerkat",
    "Mink",
    "Mole",
    "Mongoose",
    "Monkey",
    "Moose",
    "Mosquito",
    "Mouse",
    "Mule",
    "Narwhal",
    "Newt",
    "Nightingale",
    "Octopus",
    "Okapi",
    "Opossum",
    "Oryx",
    "Ostrich",
    "Otter",
    "Owl",
    "Oyster",
    "Panther",
    "Parrot",
    "Partridge",
    "Peafowl",
    "Pelican",
    "Penguin",
    "Pheasant",
    "Pig",
    "Pigeon",
    "Pony",
    "Porcupine",
    "Porpoise",
    "Quail",
    "Quelea",
    "Quetzal",
    "Rabbit",
    "Raccoon",
    "Rail",
    "Ram",
    "Rat",
    "Raven",
    "RedDeer",
    "RedPanda",
    "Reindeer",
    "Rhinoceros",
    "Rook",
    "Salamander",
    "Salmon",
    "SandDollar",
    "Sandpiper",
    "Sardine",
    "Scorpion",
    "Seahorse",
    "Seal",
    "Shark",
    "Sheep",
    "Shrew",
    "Skunk",
    "Snail",
    "Snake",
    "Sparrow",
    "Spider",
    "Spoonbill",
    "Squid",
    "Squirrel",
    "Starling",
    "Stingray",
    "Stinkbug",
    "Stork",
    "Swallow",
    "Swan",
    "Tapir",
    "Tarsier",
    "Termite",
    "Tiger",
    "Toad",
    "Trout",
    "Turkey",
    "Turtle",
    "Viper",
    "Vulture",
    "Wallaby",
    "Walrus",
    "Wasp",
    "Weasel",
    "Whale",
    "Wildcat",
    "Wolf",
    "Wolverine",
    "Wombat",
    "Woodpecker",
    "Worm",
    "Wren",
    "Yak",
    "Zebra"
]