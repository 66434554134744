import React, { useEffect, useState } from 'react'
import './player-leaderboards-container.styles.scss';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { switchPlayerMenu } from '../side-menu.component';
import { Route, Routes, useNavigate } from 'react-router-dom';
import LeaderboardPage from '@/pages/leaderboard/leaderboard.page';

const PlayerLeaderboardsContainer = ({ gameElements }) => {

    const [ leaderboards, setLeaderboards ] = useState(null);
    const [ visibleLeaderboard, setVisibleLeaderboard ] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const pageTitle = document.getElementById('page-title');
        if (pageTitle) {
            pageTitle.innerText = 'Leaderboards'
        }
        switchPlayerMenu('leaderboards-btn');
    }, []);

    useEffect(() => {
        if (!gameElements) return;
        setLeaderboards(Object.values(gameElements).filter(e => e.type === 'leaderboard'))
    }, [gameElements])

    useEffect(() => {
        if (!visibleLeaderboard) return;

    }, [visibleLeaderboard])

    return (
        <div className='player-leaderboards-container'>
            <div className='g-card player-leaderboards'>
                <div className='player-title'>
                    <ImageContainer src='/images/icons/leaderboard.png' alt='rewards icon' className='player-title-icon' />
                    Leaderboards
                </div>
                <hr />
                <div className='leaderboard-list'>
                {
                    (leaderboards) &&
                    leaderboards.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                    .map(l => (
                        <div key={l.id}>
                            <button className='g-button' onClick={() => navigate(l.path)}>{l.name}</button>
                        </div>
                    ))
                }
                </div>
                <div className='g-space-1'></div>
                <div className='leaderboard-view'>
                    <Routes>
                        <Route path='/:livePath' element={<LeaderboardPage playerView={true} />}></Route>
                    </Routes>
                </div>
            </div>
        </div>
    )
}

export default PlayerLeaderboardsContainer