import React from 'react'
import './footer.styles.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopyright } from '@fortawesome/free-regular-svg-icons'
import FooterLinks from './footer-links.component'
import InfoLinks from '@/components/SHARED/info-links/info-links.component'

const Footer = () => {
  return (
    <footer>
        <div className='footer-content'>
            <InfoLinks />
        </div>
	</footer>
  )
}

export default Footer