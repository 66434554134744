import React, { useEffect, useRef, useState } from 'react';
import './action-items-realworld.styles.scss';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import SearchBar from '@/components/SHARED/search-bar/search-bar.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { format, set } from 'date-fns';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { useSelector } from 'react-redux';
import CategoryFilterModal from '../create-elements/components/category-filter-modal.component';

const ActionItemsRealWorld = ({
    gameData,
    gameElements, 
    playerList,
    actionItems
}) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ filterType, setFilterType ] = useState(null);
    const [ itemsToDisplay, setItemsToDisplay ] = useState(null);
    const [ playerFilter, setPlayerFilter ] = useState([]);
    const [ selectedPlayers, setSelectedPlayers ] = useState([]);
    const [ playerInfoFilter, setPlayerInfoFilter ] = useState([]);
    const [ selectedPlayerInfo, setSelectedPlayerInfo ] = useState([]);
    const [ elementFilter, setElementFilter ] = useState([]);
    const [ selectedElements, setSelectedElements ] = useState([]);
    const [ pendingCount, setPendingCount ] = useState(0);
    const [ view, setView ] = useState('pending');
    const availableRef = useRef([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!actionItems || !gameElements || !playerList || !view) return;
        let arr = []
        let playerObj = {};
        let playerInfoArr = [];
        let elementObj = {};
        for (let i of Object.values(actionItems).filter(i => 
                i.status === view &&
                i.type === 'realworld'
            )
        ) {
            if (!gameElements[i.elementId] || !playerList[i.playerId]) {
                continue;
            }
            const obj = {
                'fName': playerList[i.playerId].fName,
                'lName': playerList[i.playerId].lName,
                'playerId': i.playerId,
                'info': playerList[i.playerId].info,
                'icon': gameElements[i.elementId].icon,
                'elementId': i.elementId,
                'element': gameElements[i.elementId].name,
                'event': i.event,
                'ts': i.ts,
                'id': i.id,
                'path': i.path,
                'type': i.type,
                'quantity': i.quantity,
                'status': i.status
            }
            arr.push(obj);
            const player = {
                'name': obj.lName ? `${obj.lName}, ${obj.fName}` : obj.fName,
                'playerId': i.playerId
            }
            if (!playerObj[i.playerId]) playerObj[i.playerId] = player;
            if (!playerInfoArr.includes(obj.info)) playerInfoArr.push(obj.info);
            const element = {
                'name': obj.element,
                'id': i.elementId
            }
            if (!elementObj[i.elementId]) elementObj[i.elementId] = element;
        }
        const sortedItems = arr.sort((a,b) => 
            a.lName.toLowerCase() > b.lName.toLowerCase() ?
            1 :  
            a.lName.toLowerCase() === b.lName.toLowerCase() ?
            a.fName.toLowerCase() > b.fName.toLowerCase() ? 
            1 : -1 : -1
        )
        availableRef.current = [...sortedItems];
        setItemsToDisplay(availableRef.current);
        setPlayerFilter(Object.values(playerObj));
        setPlayerInfoFilter([...playerInfoArr]);
        setElementFilter(Object.values(elementObj));
    }, [actionItems, gameElements, playerList, view])

    useEffect(() => {
        if (!actionItems) return;
        setPendingCount(Object.values(actionItems).filter(i => 
            i.status === 'pending' &&
            i.type === 'realworld'
        ).length)
    }, [actionItems])

    useEffect(() => {
        filterData();
    }, [selectedPlayers, selectedPlayerInfo, selectedElements])

    function switchView() {
        if (view === 'pending') {
            setView('approved');
        } else {
            setView('pending');
        }
    }

    function selectFilter(id) {
        if (filterType === 'player') {
            let tempPlayers = [...selectedPlayers];
            if (tempPlayers.includes(id)) {
                tempPlayers.splice(tempPlayers.indexOf(id), 1);
            } else {
                tempPlayers.push(id);
            }
            setSelectedPlayers([...tempPlayers])
        } else if (filterType === 'playerInfo') {
            let tempPlayerInfo = [...selectedPlayerInfo];
            if (tempPlayerInfo.includes(id)) {
                tempPlayerInfo.splice(tempPlayerInfo.indexOf(id), 1);
            } else {
                tempPlayerInfo.push(id);
            }
            setSelectedPlayerInfo([...tempPlayerInfo]);
        } else if (filterType === 'element') {
            let tempElements = [...selectedElements];
            if (tempElements.includes(id)) {
                tempElements.splice(tempElements.indexOf(id), 1);
            } else {
                tempElements.push(id)
            }
            setSelectedElements([...tempElements]);
        }
    }

    function filterData() {
        const filteredItems = availableRef.current.filter(a => {
            let meets = true;
            if (
                selectedPlayers.length > 0 && 
                !selectedPlayers.includes(a.playerId)
            ) {
                meets = false;
            }
            if (
                selectedPlayerInfo.length > 0 && 
                !selectedPlayerInfo.includes(a.info)
            ) {
                meets = false;
            }
            if (
                selectedElements.length > 0 && 
                !selectedElements.includes(a.elementId)
            ) {
                meets = false;
            }
            return meets;
        })
        setItemsToDisplay([...filteredItems])
    }

    async function toggleDistributed(actionItemId) {
        const actionItem = actionItems[actionItemId];
        if (!actionItem) return;
        const res = await UseCloudFunction(
            'toggleDistributedRealWorldItem',
            {
                'actionItem': actionItem,
                'ts': new Date().getTime(),
                'status': actionItem.status === 'pending' ? 'approved' : 'pending',
                'appId': userProfile.appId,
                'approver_name': `${userProfile.fName} ${userProfile.lName}`
            }
        )
        console.log(res);
    }

    return (
        <div className='action-items-realworld'>
            <div className='action-items-content'>
                <div className='g-card'>
                    <BackButton cancel={() => navigate(-1)} />
                    <div className="g-space-1"></div>
                    <div className='card-title'>
                        <FontAwesomeIcon icon={faShoppingBag} size='xl' />
                        Real World Item Distribution
                    </div>
                    <p>Pending Items: {pendingCount}</p>
                    <div className='action-item-list-search'>
                        {/* <SearchBar search={searchItems} /> */}
                        <button type='button' className='g-button med-btn' onClick={() => setFilterType('player')}>
                            Filter by Player
                            <FontAwesomeIcon 
                                icon={faFilter}
                                className={selectedPlayers.length > 0 ? 'full' : 'empty'} 
                            />
                        </button>
                        <button type='button' className='g-button med-btn' onClick={() => setFilterType('playerInfo')}>
                            Filter by Player Info
                            <FontAwesomeIcon 
                                icon={faFilter}
                                className={selectedPlayerInfo.length > 0 ? 'full' : 'empty'} 
                            />
                        </button>
                        <button type='button' className='g-button med-btn' onClick={() => setFilterType('element')}>
                            Filter by Element
                            <FontAwesomeIcon 
                                icon={faFilter}
                                className={selectedElements.length > 0 ? 'full' : 'empty'} 
                            />
                        </button>
                        <button type='button' className='g-button med-btn' onClick={() => switchView()}>
                            Switch To {view === 'pending' ? 'Distributed' : 'Pending'} Items
                        </button>
                    </div>
                    <div className="g-space-2"></div>
                    <div className='action-item-list'>
                        <div className='g-list-item action-item-list-head'>
                            <div></div>
                            <div>Name</div>
                            <div>Info</div>
                            <div>Element</div>
                            <div>Event</div>
                            <div className='center-col'>Quantity</div>
                            <div className='center-col'>Distributed</div>
                        </div>
                        {
                            (itemsToDisplay) &&
                            itemsToDisplay.map(i => (
                                <div key={i.id} className='action-item g-list-item'>
                                    <div className='action-item-icon'>
                                        <ImageContainer src={i.icon} alt={`${i.element} icon`} />
                                    </div>
                                    <div className='action-item-username'>
                                        {i.fName} {i.lName}
                                    </div>
                                    <div>
                                        {i.info}
                                    </div>
                                    <div>
                                        {i.element}
                                    </div>
                                    <div>
                                        {i.event}
                                    </div>
                                    <div className='center-col'>
                                        {i.quantity}
                                    </div>
                                    <div className='center-col'> 
                                        {/* <button 
                                            className='g-button small-btn' 
                                            onClick={() => navigate(i.path)}
                                        >
                                            <FontAwesomeIcon 
                                                icon={faArrowRight} 
                                                size='xl' 
                                            />
                                        </button> */}

                                        <button 
                                            className='g-button text-only'
                                            onClick={() => toggleDistributed(i.id)}
                                        >
                                            {
                                                (i.status === 'pending')
                                                ?
                                                <FontAwesomeIcon 
                                                    icon={faSquare} 
                                                    size='xl' 
                                                />
                                                :
                                                <FontAwesomeIcon 
                                                    icon={faCheckSquare} 
                                                    size='xl' 
                                                    className='green'
                                                />
                                            }
                                        </button>
                                    </div>
                                </div>
                            ))                        
                        }
                    </div>
                </div>
            </div>
            <CategoryFilterModal 
                show={filterType}
                cancel={() => setFilterType(null)}
                chooseCategory={selectFilter}
                categoryList={
                    filterType === 'player' ? playerFilter :
                    filterType === 'playerInfo' ? playerInfoFilter :
                    filterType === 'element' ? elementFilter : null
                }
                selectedCategories={
                    filterType === 'player' ? selectedPlayers : 
                    filterType === 'playerInfo' ? selectedPlayerInfo : 
                    filterType === 'element' ? selectedElements : null
                }
                text = {
                    filterType === 'player' ? 'Select a Player' :
                    filterType === 'playerInfo' ? 'Select Player Info' :
                    filterType === 'element' ? 'Select an Element' : null
                }
            />
        </div>
    )
}

export default ActionItemsRealWorld