import React, { useEffect } from 'react'
import './manage-user-dropdown.styles.scss'

import { Link, useLocation } from 'react-router-dom'
import { version } from '@/changelog-version'
import { useSelector } from 'react-redux'
import InfoLinks from '@/components/SHARED/info-links/info-links.component'

const ManageUserDropdown = ({ show }) => {
    
    const membership = useSelector(state => state.userData.membership);
    const location = useLocation();

    return (
        <div className={show ? 'active manage-dropdown-container' : 'manage-dropdown-container'}>
            <div className="dropdown-menu">
                <Link to='/home'>My Games</Link>
                <Link to='/profile'>My Profile</Link>
                <Link to='/myplan'>My Plan</Link>
                <Link to='/changelog' target='_blank'>Change Log</Link>
                <Link to='/logout'>Sign Out</Link>
                <div className="g-space-0-5"></div>
                <div className='meta'>{version}</div>
                <div className="g-space-0-5"></div>
                <InfoLinks />
            </div>
        </div>
    )
}

export default ManageUserDropdown