import React, { useEffect, useRef, useState } from 'react'
import './items-form.styles.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import ManageContentInformation from '../blocks/information.component';
import ManageContentVisibility from '../blocks/visibility.component';
import ManageContentCategories from '../blocks/categories.component';
import ManageContentLimitations from '../blocks/limitations.component';
import ManageContentDates from '../blocks/dates.component';
import { useSelector } from 'react-redux';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { UseCloudFunction } from '@/utils/firebase.utils';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import MangageContentCollections from '../blocks/collections.component';
import ManageContentUseItem from '../blocks/use-item.component';
import ManageContentIconNoCrop from '../blocks/icon-no-crop.component';
import { membershipRestrictions, testMemberRestrictions } from '@/utils/membershipRestrictions';
import UpgradeRequiredModal from '@/components/SHARED/upgrade-required-modal/upgrade-required-modal.component';
import ManageContentPrerequisites from '../blocks/prerequisites.component';
import ManageContentMarket from '../blocks/market.component';
import ManageItemType from '../blocks/item-type.component';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { RandomString } from '@/utils/randomString';
import FullPageLoader from '@/components/loader/full-page-loader.component';
import ManageContributionGoal from '../blocks/contribution-goal.component';
import { areIntervalsOverlapping } from 'date-fns';

const ItemsForm = ({ gameElements, gameData, playerList }) => {

    const membership = useSelector(state => state.userData.membership);
    const userProfile = useSelector(state => state.userData.userProfile);
    const [ formData, setFormData ] = useState({});
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ existingDescription, setExistingDescription ] = useState(null);
    const [ clearEditor, setClearEditor ] = useState(false);
    const [ categories, setCategories ] = useState([]);
    const [ collections, setCollections ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ availableItems, setAvailableItems ] = useState(null);
    const [ showUpgradeModal, setShowUpgradeModal ] = useState(false);
    const { itemPath } = useParams();
    const dataRef = useRef();
    const approveRef = useRef();
    const navigate = useNavigate();
    const storage = getStorage();
    const location = useLocation();

    useEffect(() => {
        if (!gameData || !membership || !location) return;
        if (location.pathname.includes('/elements/items/edit')) return;
        const res = testMemberRestrictions({'gameData':gameData, 'membership':membership, 'create': true});
        if (res.disabled && res.disabledReason.includes('elements')) {
            const planRest = membershipRestrictions[membership.membership];
            const message = `While using the ${planRest.title}, you are only able to have ${planRest.elementCount} active Game Elements per game.`;
            setShowUpgradeModal(message);
            return;
        }
    }, [gameData, membership, location])

    useEffect(() => {
        if (!itemPath || !gameElements) {return;}
        dataRef.current = Object.values(gameElements).filter(e => e.path === itemPath)[0];
        setFormData(dataRef.current);
        console.log(dataRef.current);
        if (dataRef.current.desc) {
            setExistingDescription(dataRef.current.desc);
        }
    }, [itemPath, gameElements])

    useEffect(() => {
        if (!gameElements) {return;}
        let arr = [];
        for (let c of Object.values(gameElements).filter(c => c.type === 'item')) {
            if (c.opt_cat && !arr.includes(c.opt_cat)) {
                arr.push(c.opt_cat)
            }
        }
        setCategories(arr.sort())
        setCollections(Object.values(gameElements).filter(c => c.type === 'collection'))
        const avail = Object.values(gameElements)
        .filter(e => 
            e.type === 'item' && 
            (
                e.opt_itemType === 'inventory' ||
                e.opt_itemType === 'collectible'
            )
        )
        setAvailableItems([...avail])
    }, [gameElements])

    useEffect(() => {
        if (!playerList) return;
        console.log(playerList);
    }, [playerList])

    function saveData(key, value) {
        let tempData = {...dataRef.current};
        if (!value) {
            delete tempData[key];
            dataRef.current = {...tempData};
        } else {
            dataRef.current = {...dataRef.current, ...{[key]: value}};
        }
        setFormData(dataRef.current);
        // console.log(key, value);
        if (key === 'opt_itemType' && value === "inventory") {
            saveData('opt_usable', true);
        }
        if (key === 'opt_itemType' && value === 'contribution') {
            saveData('opt_market', true);
        }
        console.log(dataRef.current);
    }
    
    function cancelCreate() {
        setConfirmMessage('Are you sure you want to cancel? All of your data will be lost.');
        approveRef.current = () => {
            dataRef.current = {};
            setFormData(dataRef.current);
            navigate(-1)
        }
    }

    async function handleSubmit() {
        let tempData = {...dataRef.current}
        const reqs = [
            'name', 
            'icon',
            'opt_itemType'
        ]
        for (let r of reqs) {
            if (!tempData[r]) {
                setAlertMessage('Please include a name, a description, an icon, and an item type for this item before saving.');
                return;
            }
        }
        if (tempData.opt_market && !tempData.opt_marketPrice && tempData.opt_itemType !== 'contribution') {
            setAlertMessage('Please include a market price for this item before saving.');
            return;
        }
        if (tempData.opt_resale && !tempData.opt_resalePrice) {
            setAlertMessage('Please include a resale value for this item before saving.');
            return;
        }
        setConfirmMessage('Are you sure you are ready to save this item? All of these settings can be changed later.')
        approveRef.current = () => {
            finishApproval()
        }
    }

    async function finishApproval() {
        setLoading(true);
        let tempData = {...dataRef.current}
        console.log(tempData);
        if (tempData.icon.upload) {
			uploadImage(tempData.icon)
		} else if (tempData.icon.url) {
			saveItem(tempData.icon.url)
			return;
		} else if (tempData.icon) {
            saveItem(tempData.icon)
            return; 
        } else {
			setAlertMessage('Something went wrong with the icon. Please try using a different image.');
            setLoading(false);
            return;
		}
    }

    function uploadImage(icon) {		
		const now = new Date().getTime();
		const iconRef = ref(storage, `${gameData.gameId}/itemIcons/${now}-${icon.name}`)
		const metadata = {
			contentType: icon.type,
            customMetadata: {
                appId: userProfile.appId,
                email: userProfile.email
            }
		};
		uploadBytes(iconRef, icon.file, metadata).then((snapshot) => {
			getDownloadURL(iconRef)
			.then((url) => {
				saveItem(url)
				return;
			})
		})
	}

    async function saveItem(iconUrl) {
        let tempData = {...dataRef.current}
        console.log(tempData);
        const now = new Date().getTime();
        const gameCode = gameData.gameId.substring(gameData.gameId.indexOf('-')-5, gameData.gameId.indexOf('-')+6)
        if (!tempData.id) {
            tempData.appId = userProfile.appId;
            tempData.ts_created = now;
            tempData.gameId = gameData.gameId;
            tempData.id = now + '-' + gameCode + '-item';
            tempData.status = 'active';
            tempData.type = 'item';
            tempData.public = true;
            tempData.path = RandomString(18, 'abcdefghijklmnopqrstuvwxyz-_0123456789');
            tempData.opt_available = true;
        } 
        if (
            tempData.opt_itemType === 'contribution'
        ) {
            tempData.opt_market = true;
        }
        tempData.ts = now;
        tempData.icon = iconUrl;
        console.log(tempData);
        const res = await UseCloudFunction('saveGameElement', {'appId': userProfile.appId, 'elementData': tempData, 'userName': `${userProfile.fName} ${userProfile.lName}`})
        console.log(res);
        if (res.error) {
            setAlertMessage('Something went wrong. Please try again later. Error: ' + res.error);
            setLoading(false);
            return;
        }
        setAlertMessage('Item saved successfully.');
        dataRef.current = {};
        setFormData(dataRef.current);
        setLoading(false);
        navigate(-1);
    }

    return (
        <div className='items-form'>
            <div className='form-grid'>
                <div className='g-card items-card'>
                    <BackButton cancel={() => navigate(-1)} />
                    <div className="g-space-1"></div>
                    <div className='card-title'>
                        <ImageContainer src='/images/icons/items.png' alt='items icon' className='head-icon' />
                        Create and Edit Items
                    </div>
                    <div className="g-space-1"></div>
                    <div className='create-item-form'>
                        <ManageContentInformation 
                            type='item' 
                            saveData={saveData} 
                            formData={formData} 
                            existingDescription={existingDescription} 
                            clearEditor={clearEditor} 
                            setClearEditor={setClearEditor}
                            textEditorLabel='Item Explanation (optional)'
                        />
                        <div className='g-space-2'></div>
                        <ManageContentIconNoCrop
                            type='item' 
                            saveData={saveData} 
                            formData={formData} 
                        />
                        <div className='g-space-2'></div>
                        <ManageItemType
                            type='item'
                            saveData={saveData}
                            formData={formData}
                        />
                        {
                            (formData.opt_itemType !== 'collectible') &&
                            <div>
                            <div className='g-space-2'></div>
                            <ManageContentCategories
                                type='item' 
                                saveData={saveData} 
                                formData={formData} 
                                categories={categories}
                                disabled={formData.opt_itemType ? '' : 'disabled'}
                            />
                            </div>
                        }
                        <div className='g-space-2'></div>
                        <ManageContentVisibility 
                            type='item' 
                            saveData={saveData} 
                            formData={formData} 
                            previewText='Allow players to see this item in their Inventory before they earned it. (If toggled off, players will see a mystery item.)'
                            visibilityText="Turn visibility ON for this Item. If toggled off, it will be totally hidden from players. If this is an Inventory item, they will NOT be able to access it in their Inventory."
                            disabled={formData.opt_itemType ? '' : 'disabled'}
                        /> 

                        {
                            (formData.opt_itemType === 'contribution') &&
                            <div>
                                <div className='g-space-2'></div>
                                <ManageContributionGoal
                                    saveData={saveData}
                                    formData={formData}
                                    disabled={formData.opt_itemType ? '' : 'disabled'}
                                />
                            </div>
                        }   
                        
                        {
                            (formData.opt_itemType === 'collectible') &&
                            <div>
                                
                                <div className='g-space-2'></div>
                                <MangageContentCollections
                                    type='item' 
                                    saveData={saveData} 
                                    formData={formData} 
                                    collections={collections}
                                />
                            </div>
                        }
                        {
                            (
                                formData.opt_itemType === 'realworld' ||
                                formData.opt_itemType === 'collectible' ||
                                formData.opt_itemType === 'inventory' ||
                                formData.opt_itemType === 'lottery'
                            ) &&
                            <div>
                                <div className='g-space-2'></div>
                                <ManageContentMarket
                                    type='item' 
                                    saveData={saveData} 
                                    formData={formData}
                                    gameElements={gameElements} 
                                />
                            </div>
                        }
                        { 
                            (formData.opt_itemType === 'inventory') &&
                            <div>
                                <div className='g-space-2'></div>
                                <ManageContentUseItem
                                    type='item' 
                                    saveData={saveData} 
                                    formData={formData} 
                                    gameElements={gameElements}
                                    gameData={gameData}
                                />
                            </div>
                        }
                        {
                            <div>
                                <div className='g-space-2'></div>
                                <ManageContentPrerequisites
                                    type='item' 
                                    formData={formData} 
                                    saveData={saveData} 
                                    gameElements={gameElements} 
                                    availableItems={availableItems}
                                    disabled={formData.opt_itemType ? '' : 'disabled'}
                                    teams={
                                        Object.values(playerList)
                                        .filter(p => p.team && p.status === 'active')
                                        .sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                                    }
                                />
                                {
                                    (formData.opt_itemType !== 'contribution') &&
                                    <>
                                    <div className='g-space-2'></div>
                                    <ManageContentLimitations
                                        type='item' 
                                        saveData={saveData} 
                                        formData={formData} 
                                        disabled={formData.opt_itemType ? '' : 'disabled'}
                                    />
                                    </>
                                }
                                <div className='g-space-2'></div>
                                <ManageContentDates
                                    type='item' 
                                    saveData={saveData} 
                                    formData={formData} 
                                    gameElements={gameElements}
                                    text={`Items will only be available to purchase before or after these dates. Players can access them and interact with them in their Inventory any time as long as the Item's visibility is ON.`}
                                    disabled={formData.opt_itemType ? '' : 'disabled'}
                                />
                                <div className='g-space-2'></div>
                                <span className='required'>* = Required</span>
                                <div className='buttons'>
                                    {
                                        (loading) 
                                        ?
                                        <>
                                            <button type='button' className='g-button'>Cancel</button>
                                            <button type='button' className='g-button'><ThreeDotsLoader /></button>
                                        </>
                                        :
                                        <>
                                            <button type='button' className='g-button' onClick={() => cancelCreate()}>Cancel</button>
                                            <button type='button' className='g-button submit-button' onClick={() => handleSubmit()}>Save item</button>
                                        </>
                                    }
                                </div>
                                <span className='meta'>{formData.id}</span>
                            </div>
                        }
                        <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
                        <ModalConfirm show={confirmMessage} cancel={() => setConfirmMessage(null)} message={confirmMessage} onApprove={approveRef.current} />
                        <UpgradeRequiredModal
                            show={showUpgradeModal}
                            cancel={() => setShowUpgradeModal(false)}
                            message={showUpgradeModal}
                        />
                    </div>
                </div>
                <div >
                    <div className='g-card fixed-card'>
                        <button 
                            className='g-button submit-button'
                            onClick={() => handleSubmit()}
                        >
                            Save Item
                        </button>
                    </div>
                </div>
            </div>
            <FullPageLoader show={loading} />
        </div>
    )
}

export default ItemsForm