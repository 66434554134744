import React, { useRef, useState } from 'react'
import './create-edit-template-game.styles.scss';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import TemplateGameForm1 from './create-form/template-game-form-1.component';

const CreateEditTempateGame = () => {

    const [ formData, setFormData ] = useState({});
    const { gamePath } = useParams();
    const dataRef = useRef();
    const navigate = useNavigate();

    function saveData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}}
        setFormData(dataRef.current);
        console.log(dataRef.current)
    }

    function verifyAndMoveOn(req, path) {
        navigate(path);
    }

    return (
        <div className='create-edit-template-games'>
            <Routes>
                <Route 
                    path='' 
                    element={
                        <TemplateGameForm1 
                            gamePath={gamePath} 
                            formData={formData} 
                            saveData={saveData}
                            verifyAndMoveOn={verifyAndMoveOn} 
                        />
                    }>
                </Route>
            </Routes>
        </div>
    )
}

export default CreateEditTempateGame