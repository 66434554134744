import React, { useEffect, useState } from 'react'
import './shopping-cart-icon.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

const ShoppingCartIcon = ({ cart }) => {

    const [ itemCount, setItemCount ] = useState(0);

    useEffect(() => {
        if (!cart) return;
        setItemCount(Object.values(cart).reduce((acc, cur) => acc + cur.quantity, 0))
    }, [cart])

    return (
        <div className='shopping-cart-icon'>
            <FontAwesomeIcon 
                className='icon'
                icon={faShoppingCart} 
                size="xl" 
            />
            <div className='count-div'>
                <div className='count-circle'>
                    {itemCount}
                </div>
            </div>
        </div>
    )
}

export default ShoppingCartIcon