import React, { useEffect } from 'react'
import './logout-page.styles.scss';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { revertAll } from '@/state/slices/userSlice';
import Loader from '@/components/loader/loader.comp';
import { signOut } from '@/utils/firebase.utils';

const LogoutPage = () => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        async function removeUser() {
            dispatch(revertAll())
            await signOut();
            setTimeout(() => {
                navigate('/')
            }, 1000);
        }
        removeUser();

    }, [])

    return (
        <div className='logout-page'>
            <Loader />  
        </div>
    )
}

export default LogoutPage