import { createAction, createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentUser: null,
  userProfile: null,
  membership: null,
  shoppingCart: null,
  root: 'https://app-dev.gamably.com'
}

export const revertAll = createAction('REVERT_ALL')

export const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setCurrentUser: (state, action) => {
        state.currentUser = action.payload
    },
    setUserProfile: (state, action) => {
        state.userProfile = action.payload
    },
    setMembership: (state, action) => {
        state.membership = action.payload
    },
    setShoppingCart: (state, action) => {
        state.shoppingCart = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { 
  setCurrentUser, 
  setUserProfile,
  setMembership,
  setShoppingCart,
} = userSlice.actions

export default userSlice.reducer