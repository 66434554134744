import React, { useState, useEffect } from 'react'
import './org-icon.styles.scss'
import "croppie/croppie.css"
import Croppie from "croppie"
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'
import { UseCloudFunction } from '@/utils/firebase.utils'
import { useDispatch, useSelector } from 'react-redux'
import { setMyOrgs } from '@/state/slices/contentSlice'
import { useLocation } from 'react-router-dom'
import { ModalAlert } from '@/components/modal/modal.component'
import org_icon from '@/assets/placeholder-icon-org.jpg'

const OrgIcon = ({ orgData, saveOrgData }) => {
	
    const profile = useSelector(state => state.userData.userProfile);
    const myOrgs = useSelector(state => state.contentData.myOrgs);
	const [ croppedImage, setCroppedImage ] = useState('')
	const [ croppie, setCroppie ] = useState(null);
    const [ imageInfo, setImageInfo ] = useState(null);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const storage = getStorage();
    const dispatch = useDispatch();
    const location = useLocation();
    let croppieInstance

    useEffect(() => {
        console.log(orgData)
    }, [orgData])
    
	const destroyCroppie = () => {
		const el = document.getElementById("uploaded-image")
		el.innerHTML = ''
		el.classList.remove("croppie-container");
	}

    function clickUpload() {
        document.getElementById('fileUpload').click();
    }

	function handleUpload(file) {
		console.log(file.type)
        if (file.type.indexOf('gif') > -1) {
            setAlertMessage('Please upload an image, not a gif.');
            return;
        }
        document.getElementById('selected-image').classList.add('hide')
		destroyCroppie()
		if (file.type.match(/image.*/)) {
			const fileType = file.type;
			const fileName = file.name;
			console.log('An image has been loaded');
			console.log(fileType)
			// Load the image
			
            const newFile = window.URL.createObjectURL(file)
            // setCroppedImage(newFile)
            const el = document.getElementById("uploaded-image")
            if (el) {
                croppieInstance = new Croppie(el, {
                    viewport: { width: 150, height: 150 },
                    boundary: { width: 150, height: 150 },
                    showZoomer: true,
                    enableOrientation: true
                });
                croppieInstance.bind({
                    url: newFile,
                });
                setCroppie(croppieInstance)
            }
            setCroppedImage(newFile)
            setImageInfo({'name': fileName, 'type': fileType})
            return;
			
		}
		setAlertMessage('Please upload an image file.');
		return;
	}

    function uploadImage() {	
        croppie.result({
            type:'blob',
            size: {'width': 512}
        })
        .then((blob) => {	
            const now = new Date().getTime();
            const profileRef = ref(storage, `uploaded-org-icons/${now}-${imageInfo.name}`)
            const metadata = {
                contentType: imageInfo.type,
            };
            uploadBytes(profileRef, blob, metadata).then((snapshot) => {
                getDownloadURL(profileRef)
                .then((url) => {
                    saveImage(url)
                    return;
                })
            })
        });
    }

    async function saveImage(url) {
        console.log(url)
        const res = await UseCloudFunction(
            'saveOrgIcon', 
            {
                'icon': url, 
                'orgId': orgData.orgId, 
                'ts': new Date().getTime(), 
                'appId': profile.appId
            }
        );
        console.log(res)
        if (res.error) {
            setAlertMessage('Something went wrong. Please try again later.');
            return;
        } else {
            setAlertMessage('Organization icon saved successfully.');
            if (!location.pathname.includes('/admin/')) {
                saveOrgData({...orgData, ...{'icon': url}});
                const newOrg = {...myOrgs[orgData.orgId]}
                newOrg.icon = url;
                dispatch(setMyOrgs({...myOrgs, ...{[orgData.orgId]: newOrg}}))
            }
        }
    }

	return (
		<div className='org-icon-container'>
            <h3>Organziation Icon</h3><hr />
			<div className="g-grid">
                <div className="g-half-col center-col">
					<div className="image-buttons">
						<div className="uk-margin">
							<div style={{cursor: 'pointer'}}>
								<input type="file" id="fileUpload" accept='image/*' onChange={(e) => handleUpload(e.target.files[0])}  />
								<button className="g-button" type="button" tabIndex="-1" onClick={() => clickUpload()}>Upload an Icon</button>
							</div>
						</div>
					</div>
				</div>
				
				<div className="g-half-col center-col">
					<div className="image-div">
						<div id="uploadDiv">
							<div id="selected-image">
								{ 
									(orgData && orgData.icon) 
										? <img className="profile-image" referrerPolicy="no-referrer" src={orgData.icon.url || orgData.icon} />
										: <img className="profile-image" referrerPolicy="no-referrer" src={org_icon} />
								}
							</div>
							<div id="uploaded-image">
							
							</div>
							<a className="rotateButton" id="rotateButton"><i className="large redo icon"></i></a>
						</div>
					</div>
				</div>
			</div>
            <hr />
            <div className='buttons'>
                <button className="g-button primary" onClick={() => uploadImage()}>Save New Icon</button>
            </div>
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
		</div>
	)
}

export default OrgIcon;