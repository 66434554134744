import React, { useEffect, useState } from 'react'
import './item-history.styles.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DetailedActivityList from '@/components/SHARED/detailed-activity-list/detailed-activity-list.component';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';

const ItemHistory = ({ 
    gameData,
    playerData, 
    playerActivity,
    playerList, 
    gameElements 
}) => {

    const [ activityToDisplay, setActivityToDisplay ] = useState([]); 
    const [ elementToDisplay, setElementToDisplay ] = useState(null);
    const {itemPath} = useParams();
    const navigate = useNavigate();
    const location= useLocation();

    useEffect(() => {
        if (!playerActivity || !itemPath || !gameElements) return;
        console.log(playerActivity);
        const itemData = Object.values(gameElements).filter(e => e.path === itemPath)[0];
        setElementToDisplay({...itemData});
        console.log(itemData);
        const activity = Object.values(playerActivity).filter(a => 
            a.elementId === itemData.id ||
            (
                a.data &&
                Object.values(a.data).filter(d => d.id === itemData.id).length > 0
            ) 
        )
        console.log(activity);
        setActivityToDisplay([...activity]);
        // const activity = Object.values(playerActivity)

    }, [playerActivity, itemPath, gameElements])

    return (
        <div className='item-history'>
            {
                (elementToDisplay) &&
                <div className='g-card item-history-card'>
                    <BackButton cancel={() => navigate(-1)} />
                    <div className='g-space-1'></div>
                    <div className='card-title'>
                        <ImageContainer src={elementToDisplay.icon} className='head-icon' />
                        {elementToDisplay.name} | Item History
                    </div>
                    <hr />
                    {
                        (activityToDisplay.length > 0)
                        ?
                        <DetailedActivityList 
                            gameData={gameData}
                            playerData={playerData}
                            activity={activityToDisplay}
                            hidePlayer={true}  
                            playerList={{
                                [playerData.playerId]: playerData
                            }}
                            gameElements={gameElements}
                            manager={
                                location.pathname.includes('/manage/') ? true : false
                            }
                        />
                        :
                        <div className='center-col'>
                            <h4>No history to view</h4>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default ItemHistory