import React, { useEffect, useState } from 'react'
import './badges-landing.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import BadgesList from './badges-list.component';
import BadgesForm from './badges-form.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { membershipRestrictions, testMemberRestrictions } from '@/utils/membershipRestrictions';
import { useSelector } from 'react-redux';
import UpgradeRequiredModal from '@/components/SHARED/upgrade-required-modal/upgrade-required-modal.component';
import { properCase } from '@/utils/properCase';

const BadgesLandingPage = ({ gameElements, gameData }) => {

    const membership = useSelector(state => state.userData.membership);
    const [ showUpgradeModal, setShowUpgradeModal ] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        checkMembership();
    }, [])

    function checkMembership() {
        const element = 'badges'
        const res = testMemberRestrictions({'gameData': gameData, 'membership': membership, 'element': element})
        // console.log(res);
        if (res.disabled) {
            let message = ''
            const planRest = membershipRestrictions[membership.membership];
            if (res.disabledReason.includes(element)) {
                message = `While using the ${planRest.title}, you are not able to access ${properCase(element)}.`;
            }
            setShowUpgradeModal(message)
        }
    }

    return (
        <div className='badges-landing'>
            <div className='g-card badges-card'>
                <BackButton cancel={() => navigate(-1)} />
                <div className="g-space-1"></div>
                <div className='card-title'>
                    <ImageContainer src='/images/icons/badges.png' alt='badges icon' className='head-icon' />
                    Create and Edit Badges
                </div>
                <div className='g-space-1'></div>
                <div className='badges-landing-content'>
                    <Routes>
                        <Route path='' element={<BadgesList gameElements={gameElements} gameData={gameData} />}></Route>
                        <Route path='create' element={<BadgesForm gameElements={gameElements} gameData={gameData} />}></Route>
                        <Route path='edit/:badgePath' element={<BadgesForm gameElements={gameElements} gameData={gameData} />}></Route>
                        <Route path="*" element={<Navigate replace to="/404" />} />
                    </Routes>
                </div>
            </div>
            <UpgradeRequiredModal
                show={showUpgradeModal}
                cancel={() => setShowUpgradeModal(null)}
                message={showUpgradeModal}
            />
        </div>
    )
}

export default BadgesLandingPage