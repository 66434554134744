import React, { useEffect, useState } from 'react'
import './selected-players.styles.scss';
import UserIcon from '@/components/layout-auth/user-icon/user-icon.component';
import { sortPlayers } from '@/utils/sortPlayers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from '@fortawesome/free-regular-svg-icons';

const SelectedPlayers = ({
    formData,
    saveData,
    playerList,
    gameElements,
    removePlayer
}) => {

    const [ playersToDisplay, setPlayersToDisplay ] = useState(null);

    useEffect(() => {
        if (!playerList || !formData || !formData.opt_members) return;
        let arr = []
        for (let p of formData.opt_members) {
            console.log(playerList[p]);
            if (!playerList[p]) continue;
            arr.push(playerList[p]);
        }
        const sortedPlayers = sortPlayers({
            'playerList': arr,
        })
        setPlayersToDisplay(sortedPlayers);
    }, [playerList, formData])

    return (
        <div className='manage-selected-players g-list-item form-section'>
            <h3>Selected Players</h3>
            <hr />
            <div className='g-list-item player-list'>
            {
                (playersToDisplay) &&
                playersToDisplay.map((p, index) => (
                    <div 
                        key={p.playerId} 
                        className='g-list-item player-item'>
                        <div>{index+1}</div>
                        <UserIcon profile={p} small={true} />
                        <div>{p.lName ? `${p.lName}, ${p.fName}` : p.fName}</div>
                        <div>{p.info ?? ''}</div>
                        {/* <div>
                            <button 
                                className='g-button small-btn red'
                                onClick={() => removePlayer(p.playerId)}>
                                <FontAwesomeIcon icon={faXmarkCircle}  />
                            </button>
                        </div> */}
                    </div>
                ))
            }
            </div>
        </div>
    )
}

export default SelectedPlayers