import React, { useEffect, useRef, useState } from 'react'
import './teams-list.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import SearchBar from '@/components/SHARED/search-bar/search-bar.component';
import { stripHtml } from '@/utils/sanitize';
import { useNavigate } from 'react-router-dom';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';

const TeamsList = ({ playerList }) => {
    
    const [ showFilterModal, setShowFilterModal ] = useState(false);
    const [ contentToDisplay, setContentToDisplay ] = useState([]);
    const [ selectedCategories, setSelectedCategories ] = useState([]);
    const catRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        catRef.current = [];
    }, [])

    useEffect(() => {
        if (!playerList) return;
        setContentToDisplay([]);
        setContentToDisplay(Object.values(playerList).filter(a => a.team))
    }, [playerList])

    function search(e) {
        if (!e) {
            setContentToDisplay(Object.values(playerList).filter(a => a.team))
            return;
        }
        setContentToDisplay(Object.values(playerList).filter(a => a.team && (a.name.toLowerCase().includes(e) || (a.desc && a.desc.toLowerCase().includes(e)))))
    }
    
    return (
        <div className='teams-list'>
            <div className='teams-list-search'>
                <SearchBar search={search} />
                <button type='button' className='g-button med-btn' onClick={() => navigate('create')}>Create a New Team<FontAwesomeIcon icon={faPlus} /></button>
            </div>
            <div className="g-space-2"></div>
            <div className='teams-list-div'>
                <div className='g-list-item teams-list-head row'>
                    <div></div>
                    <div>Name</div>
                    <div>Description</div>
                    <div className='center'># of Members</div>
                    <div></div>
                </div>
                <div className='g-space-0-5'></div>
                <div className='teams-list'>
                    {
                        (contentToDisplay.length > 0)
                        ?
                        contentToDisplay.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                        .map(c => (
                            <div key={c.playerId} className='g-list-item row'>
                                <div className='teams-icon-div'>
                                    <ImageContainer src={c.icon} alt={`${c.name} teams icon`} className='teams-icon' />
                                </div>
                                <div className='teams-name'>{c.name}</div>
                                <div className='teams-desc meta'>{c.desc && stripHtml(c.desc).substring(0,60)}{c.desc && stripHtml(c.desc).length > 60 ? '...' : ''}</div>
                                <div className='meta center'>{c.opt_members ? c.opt_members.length : 0}</div>
                                <div className='buttons'>    
                                    <button className='g-button med-btn' title='View team data'><FontAwesomeIcon icon={faChartPie} /></button>
                                    <button className='g-button med-btn' onClick={() => navigate(`edit/${c.path}`)} title='Edit this team'><FontAwesomeIcon icon={faPen} /></button>
                                </div>
                            </div>
                        ))
                        :
                        <p>No Teams yet...</p>
                    }
                </div>
            </div>
        </div>
    )
}

export default TeamsList