import React, { useEffect, useState } from 'react';
import './quantity-input.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

function QuantityInput({ initialValue, max, min, onQuantityChange }) {
    
    const [ quantity, setQuantity ] = useState(initialValue || 0);
    const [ minimum, setMinimum ] = useState(0);

    useEffect(() => {
        onQuantityChange(quantity);
    }, [quantity])

    useEffect(() => {
        if (!min) return;
        setMinimum(min);
    }, [min])
  
    const handleIncrement = () => {
        if (quantity < max) {
            setQuantity(quantity + 1);
        }
    };
  
    const handleDecrement = () => {
        if (quantity > minimum) {
            setQuantity(quantity - 1);
        }
    };
  
    const handleChange = (event) => {
        const newQuantity = parseInt(event.target.value, 10);
        if (!isNaN(newQuantity) && newQuantity >= 1 && newQuantity <= max) {
            setQuantity(newQuantity);
        }
    };
  
    return (
        <div className="quantity-input-container">
            <div className='quantity-input field small'>
                <button className='g-button small-btn' onClick={handleDecrement}>
                    <FontAwesomeIcon icon={faMinus} />
                </button>
                <input
                    type="number"
                    value={quantity}
                    onChange={handleChange}
                    min={minimum}
                    max={max}
                    disabled
                />
                <button className='g-button small-btn' onClick={handleIncrement}>
                    <FontAwesomeIcon icon={faPlus} />
                </button>
            </div>
        </div>
    );
}
  
  export default QuantityInput;