import React, { useEffect, useState } from 'react'
import './my-levels-list.styles.scss';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { format } from 'date-fns';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { useLocation, useNavigate } from 'react-router-dom';
import RewardsCard from '../rewards-card/rewards-card.component';
import PlayerNameIcon from '../player-name-icon.component';

const MyLevelsList = ({ playerData, gameElements, playerActivity }) => {
    
    const [ loading, setLoading ] = useState(true);
    const [ sortedList, setSortedList ] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    
    useEffect(() => {
        const timer = setTimeout(() => {
            if (!sortedList) {
                setLoading(false);
            }
        }, 3000)

        return () => {
            if (timer) clearTimeout(timer);
        }
    }, [])

    useEffect(() => {
        if (!playerData || !gameElements) return;
        // console.log(playerActivity);
        const levels = Object.values(gameElements).filter(e => e.type === 'level')
        let newLevels = [];
        levels.sort((a,b) => a.opt_pointMinimum < b.opt_pointMinimum ? 1 : -1)
        .map(l => {
            // console.log(l);
            let newLevel = {...l}
            if (playerActivity && playerData.levels && playerData.levels[newLevel.id]) {
                const levelActivity = Object.values(playerActivity)
                .filter(a => a.type === 'levels' && a.spec.includes(newLevel.id))[0]
                if (!levelActivity) {
                    newLevels.push(newLevel);
                    return;
                }
                // console.log(levelActivity);
                newLevel.earned = levelActivity.ts
                if (levelActivity.data) {
                    newLevel.data = levelActivity.data;
                }
            }
            newLevels.push(newLevel);
        })
        setSortedList(newLevels);
        setLoading(false);
    }, [playerData, gameElements, playerActivity])

    return (
        <div className='level-earnings-list'>
            <div className='g-card list-card'>
                <BackButton cancel={() => navigate(-1)} />
                <div>
                    <div className='card-title split'>
                        Game Levels
                        {
                            (location.pathname.includes('/manage/')) &&
                            <PlayerNameIcon player={playerData} />
                        }
                    </div>
                    <hr />
                </div>
                {
                    (loading)
                    ?
                    <div className='center-col'><ThreeDotsLoader /></div>
                    :
                    (sortedList)
                    ?
                    sortedList.map(l => {
                        // console.log(l);
                        return (
                            <div key={l.id} className='g-list-item level-item'>
                                <ImageContainer src={l.icon} className={`icon ${!l.earned && !l.opt_preview ? 'opaque' : ''}`} grayscale={!l.earned} />
                                <div className='info'>
                                    <div className='title'>{l.name}</div>
                                    {
                                        (l.earned) &&
                                        <div className='meta'>
                                            Earned: {format(new Date(l.earned), "Pp")}
                                        </div>
                                    }
                                </div>
                                <div className='desc' dangerouslySetInnerHTML={{__html: l.desc}}>
                                </div>
                                <div className='rewards'>
                                    {
                                        (l.data && Object.values(l.data).length > 0) &&
                                        Object.values(l.data).map(r => (
                                            <div key={r.type}>
                                                <RewardsCard reward={r} />
                                            </div>
                                        )) 
                                    }
                                </div>
                            </div>
                        )
                    })
                    : 
                    <h4 className='center-col'>
                        No earnings yet...
                    </h4>
                }
            </div>
        </div>
    )
}

export default MyLevelsList