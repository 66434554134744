import React, { useRef, useState } from 'react';
import './sign-in.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useAsyncError, useNavigate, useParams } from 'react-router-dom';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile } from 'firebase/auth';
// import { UseCloudFunction, auth } from 'utils/firebase.utils';

import { auth } from '@/utils/firebase.utils';
import UnauthLayout from '@/components/layout-unauth/unauth-layout.component';
import icon from '@/assets/icon.png';
import { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';

const Register = () => {

    const [ formData, setFormData ] = useState({});
    const [ showPassword, setShowPassword ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const { joinCode } = useParams();
    const dataRef = useRef();
    const approveRef = useRef();
    const navigate = useNavigate();

    function saveData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}};
        setFormData(dataRef.current);
    }

    async function handleSubmit() {
        let profile = {...formData};
        const now = new Date().getTime();
        profile.ts = now;
        profile.joined = now;
        profile.name = `${profile.fName} ${profile.lName}`;
        profile.email = profile.email.toLowerCase().trim();
        let password = profile.password.trim();
        delete profile.password;
        const userData = await createUserWithEmailAndPassword(auth, profile.email, password)
        .then((userCredential) => {
            // Signed in 
        
            updateProfile(auth.currentUser, {
                displayName: profile.name,
            })
            const user = userCredential.user;
            window.localStorage.setItem('tempProfile', JSON.stringify(profile))
            return user;
        // ...
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
            if (errorCode === 'auth/email-already-in-use') {
                setAlertMessage('That email address is already in our system. Try using a different address.');
                return;    
            } else if (errorCode === 'auth/weak-password') {
                setAlertMessage('Sorry, there was an issue with your password. ' + errorMessage);
                return;
            }
            setAlertMessage('Something went wrong. Try again later.');
            return;
        });

        if (!userData) return;

        // profile.appId = userData.uid;
        // profile.uid = userData.uid;
        // const res = await UseCloudFunction('createEmailPassword', profile)
        // if (res.message) {
        function onApprove() {
            // navigate('/logout')
            signInWithEmailAndPassword(auth, profile.email, password)
            .then((userCredential) => {
                // Signed in 
                // const user = userCredential.user;
                // ...
                console.log(userCredential)
                navigate(`/loading${joinCode ? `/${joinCode}/?join=true` : ''}`);
            }).catch(err => {
                setConfirmMessage(`Sorry, there's been an issue. Please logout and log back in`);
                approveRef.current = () => {
                    navigate('/logout')
                }
            })
        }
        approveRef.current = onApprove;
        setConfirmMessage('Your account has been created! Click OK to start using Gamably.')

        // } else {
        //     UIkit.modal.alert('Something went wrong. Try again later.')
        //     return;
        // }
    }

    return (
        <div>
            <UnauthLayout>
            <div className='sign-in-container'>
                <div className='sign-in-content'>
                    <div className='g-card'>
                        <div className='icon-container'>
                            <img className='main-icon' src={icon} alt="main-icon" />
                            <span className='main-text'>Create an Account</span>
                        </div>
                        <div className="g-space-1"></div>
                        
                        <div className="field">
                            {/* <label>First Name</label> */}
                            <input type="text" aria-label="first name" placeholder="First Name" defaultValue={formData.fName ? formData.fName : ''} onChange={(e) => saveData('fName', e.target.value)} />
                        </div>
                        <div className="g-space-1"></div>
                        <div className="field">
                            {/* <label>Last Name</label> */}
                            <input type="text" aria-label="last name" placeholder="Last Name" defaultValue={formData.lName ? formData.lName : ''} onChange={(e) => saveData('lName', e.target.value)} />
                        </div>
                        <div className="g-space-1"></div>
                        <div className="field">
                            {/* <label>Email Address</label> */}
                            <input type="text" aria-label="email" placeholder="Email Address" defaultValue={formData.email ? formData.email : ''} onChange={(e) => saveData('email', e.target.value)} />
                        </div>
                        <div className="g-space-1"></div>
                        <div className="field">
                            {/* <label>Password</label> */}
                            
                            <div className='input-icon'>
                                <input type={showPassword ? 'text': 'password'} placeholder="Create a Password" aria-label="password" defaultValue={formData.password ? formData.password : ''} onChange={(e) => saveData('password', e.target.value)} />
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className='icon' onClick={() => setShowPassword(!showPassword)} />
                            </div>
                        </div>  
                        <div className="g-space-2"></div>
                        <div className='buttons'>
                            <button className='g-button' onClick={() => navigate(-1)}>Cancel</button>
                            <button className='g-button primary' onClick={() => handleSubmit()}>Create Account</button>
                        </div> 
                    </div>
                </div>
            </div>
            </UnauthLayout>
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
            <ModalConfirm show={confirmMessage} cancel={() => setConfirmMessage(null)} message={confirmMessage} onApprove={approveRef.current} noCancel={true} />
        </div>
    )
}

export default Register