import React from 'react'
import './rewards.styles.scss';
import RewardsForm from './rewards-form.component';

const ManageContentRewards = ({ type, saveData, formData, gameElements, text, gameData }) => {

    return (
        <div className='manage-content-rewards g-list-item form-section'>
            <h3>Rewards</h3>
            <hr />
            <p>Players can earn rewards for {text}. Leave any of these items blank if you do not want to include them as default rewards.</p>
            <div className="rewards-content">
                <RewardsForm type={type} saveData={saveData} formData={formData} gameElements={gameElements} gameData={gameData} />
            </div>
        </div>
    )
}

export default ManageContentRewards 