import React, { useEffect, useRef, useState } from 'react';
import './bug-reports.styles.scss';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { getBugReports } from '@/utils/getAdminData';

const AdminBugReports = () => {
    
    const [ bugReports, setBugReports ] = useState(null);
    const [ display, setDisplay ] = useState(null);
    const reportRef = useRef();
    let listener;

    useEffect(() => {
        getBugReportsFromDb()

        return () => {
            if (listener) {
                listener();
            }
        }
    }, []) 

    async function getBugReportsFromDb() {
        getBugReports({'onlyActive': true, 'callback': callback, 'receiveListener': receiveListener})
        function callback(data) {
            reportRef.current = {...reportRef.current, ...{[data.ts]: data}}
            setBugReports(reportRef.current);
            setDisplay(reportRef.current);
        }
        function receiveListener(unsubscribe) {
            listener = unsubscribe;
        } 
    }
    
    return (
        <div>
            <div className='section-title'>
                <span>Bug Reports</span>
            </div>
            <table className='g-table'>
                <thead>
                    <tr>
                        <th>Time</th>
                        <th>User</th>
                        <th>Title</th>
                        <th>Desc</th>
                        <th>Image</th>
                        <th>Org</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                {
                    (display) &&
                    Object.values(display).sort((a,b) => a.ts < b.ts ? 1 : -1)
                    .map(r => (
                        <tr key={r.ts}>
                            <td>{format(new Date(r.ts), 'Pp')}</td>
                            <td>{r.lName}, {r.fName}</td>
                            <td>{r.title}</td>
                            <td>{r.desc}</td>
                            <td>{r.imageURL && <a href={r.imageURL} target="_blank">Image</a>}</td>
                            <td>{r.orgName}</td>
                            <td><FontAwesomeIcon icon={faCheck} /></td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </div>
    )
}

export default AdminBugReports;