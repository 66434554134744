import React, { useEffect } from 'react'
import './content-settings-form.styles.scss';
import ToggleInput from '@/components/SHARED/toggle-input/toggle-input.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { membershipRestrictions } from '@/utils/membershipRestrictions';
import { useSelector } from 'react-redux';

const GameContentSettingsForm = ({ formData, saveData, gameData }) => {

    const membership = useSelector(state => state.userData.membership);

    useEffect(() => {
        console.log(gameData);
    }, [gameData])

    function saveToggle(key) {
        console.log(key);
        if (!formData && !formData.access) {return;}
        let newAccess = [...formData.access];
        if (newAccess.includes(key)) {
            newAccess.splice(newAccess.indexOf(key), 1);
        } else {
            newAccess.push(key);
        }
        if (!newAccess.includes('points')) {
            if (newAccess.includes('levels')) {
                newAccess.splice(newAccess.indexOf('levels'), 1)
            }
        }
        if (!newAccess.includes('currency')) {
            if (newAccess.includes('store')) {
                newAccess.splice(newAccess.indexOf('store'), 1)
            }
        }
        if (!newAccess.includes('points') && !newAccess.includes('currency')) {
            if (newAccess.includes('leaderboards')) {
                newAccess.splice(newAccess.indexOf('leaderboards'), 1)
            }
        }
        if (!newAccess.includes('items')) {
            if (newAccess.includes('store')) {
                newAccess.splice(newAccess.indexOf('store'), 1)
            }
            if (newAccess.includes('collections')) {
                newAccess.splice(newAccess.indexOf('collections'), 1)
            }
            if (newAccess.includes('prizePacks')) {
                newAccess.splice(newAccess.indexOf('prizePacks'), 1)
            }
        }
        if (!newAccess.includes('items') || !newAccess.includes('store')) {
            if (newAccess.includes('tradePortal')) {
                newAccess.splice(newAccess.indexOf('tradePortal'), 1)
            }
        }
        console.log(newAccess)
        saveData('access', newAccess)
    }

    const openUpgrade = () => {
        const newTabUrl = '/pricing'; // Replace with your desired URL
        window.open(newTabUrl, '_blank', 'noopener,noreferrer');
      };

    return (
        <div className='content-settings-container'>
            {
                (membership && membership.membership < 2) &&
                <div className='g-list-item upgrade-box'>
                    <p>Want access to ALL of Gamably's features? </p>
                    <div>
                        <button 
                            className='g-button med-btn primary'
                            onClick={() => openUpgrade()}
                        >
                            Upgrade Here!
                        </button>
                    </div>
                </div>
            }
            {
                (formData && gameData) &&
                <div className='content-settings-content'>
                    <h3>Primary Unit of Comparison</h3>
                    <p>Choose either XP or Currency to be the point of comparison on the Manager's and the Players' dashboards.</p>
                    <div className={`field with-toggle ${
                        gameData.type === 'currency' && membership.membership < 1
                        ? 'disabled'
                        : ''
                    }`}>
                        <label className='with-image'>
                            <ImageContainer 
                                src='/images/xp.png' 
                                alt='xp icon' 
                                className='label-image' 
                            />
                            Experience Points(XP)
                        </label>
                        <ToggleInput 
                            contentKey='sortLeaders' 
                            checked={
                                !formData.sortLeaders ||
                                formData.sortLeaders === 'points'
                            } 
                            saveData={() => saveData('sortLeaders', 'points')} 
                        />
                    </div>
                    <div className={`field with-toggle ${
                        gameData.type === 'points' && membership.membership < 1
                        ? 'disabled'
                        : ''
                    }`}>
                        <label className='with-image'>
                            <ImageContainer 
                                src='/images/icons/coin.png' 
                                alt='currency icon' 
                                className='label-image' 
                            />
                            Currency
                        </label>
                        <ToggleInput 
                            contentKey='sortLeaders' 
                            checked={
                                formData.sortLeaders === 'currency'
                            } 
                            saveData={() => saveData('sortLeaders', 'currency')} 
                        />
                    </div>
                </div>
            }
            {
                (formData && gameData && membership) &&
                <div className='content-settings-content'>
                    <h3>Player Interactives</h3>
                    <div className='field with-toggle'>
                        <label className='with-image'>
                            <ImageContainer 
                                src='/images/icons/groups.png' 
                                alt='teams icon' 
                                className='label-image' 
                            />
                            Teams
                        </label>
                        <ToggleInput 
                            contentKey='teams' 
                            checked={
                                formData.access ? 
                                formData.access.includes('teams') : 
                                false
                            } 
                            saveData={saveToggle} 
                        />
                    </div>
                    <div className='field with-toggle'>
                        <label className='with-image'>
                            <ImageContainer 
                                src='/images/icons/challenge.png' 
                                alt='challenges icon' 
                                className='label-image' 
                            />
                            Challenges
                        </label>
                        <ToggleInput 
                            contentKey='challenges' 
                            checked={
                                formData.access ? 
                                formData.access.includes('challenges') : 
                                false
                            } 
                            saveData={saveToggle} 
                        />
                    </div>
                    <div className='field with-toggle'>
                        <label className='with-image'>
                            <ImageContainer 
                                src='/images/icons/secret.png' 
                                alt='secret codes icon' 
                                className='label-image' 
                            />
                            Secret Codes
                        </label>
                        <ToggleInput 
                            contentKey='secretCodes' 
                            checked={
                                formData.access ? 
                                formData.access.includes('secretCodes') : 
                                false
                            } 
                            saveData={saveToggle} 
                        />
                    </div>
                    <div className='field with-toggle'>
                        <label className='with-image'>
                            <ImageContainer 
                                src='/images/icons/badges.png' 
                                alt='badges icon' 
                                className='label-image' 
                            />
                            Badges
                        </label>
                        <ToggleInput 
                            contentKey='badges' 
                            checked={
                                formData.access ? 
                                formData.access.includes('badges') : 
                                false
                            } 
                            saveData={saveToggle} 
                        />
                    </div>
                    <h3>Points and Leaderboard Features</h3>
                    <div className={
                        `field with-toggle ${
                            (membership.membership < 1 &&
                            gameData.type === 'currency') ? 
                            'disabled' : 
                            ''
                        }`
                    }>
                        <label className='with-image'>
                            <ImageContainer 
                                src='/images/xp.png' 
                                alt='xp icon' 
                                className='label-image' 
                            />
                            Experience Points (XP)
                        </label>
                        <ToggleInput 
                            contentKey='points' 
                            checked={
                                formData.access ? 
                                formData.access.includes('points') : 
                                false
                            } 
                            saveData={saveToggle} 
                        />
                    </div>
                    <div className={
                        `field with-toggle ${
                            (membership.membership < 1 &&
                            gameData.type === 'currency') ||
                            (
                                !formData.access ||
                                !formData.access.includes('points')
                            )
                            ? 
                            'disabled' : 
                            ''
                        }`
                    }>
                        <label className='with-image'>
                            <ImageContainer 
                                src='/images/icons/rankings.png' 
                                alt='levels icon' 
                                className='label-image' 
                            />
                            Levels (requires XP)
                        </label>
                        <ToggleInput 
                            contentKey='levels' 
                            checked={
                                formData.access ? 
                                formData.access.includes('levels') : 
                                false
                            } 
                            saveData={saveToggle} 
                        />
                    </div>
                    <div className={
                        `field with-toggle ${
                            (
                                !formData.access ||
                                (
                                    !formData.access.includes('points') &&
                                    !formData.access.includes('currency')
                                )
                            ) ? 
                            'disabled' : 
                            ''
                        }`
                    }>
                        <label className='with-image'>
                            <ImageContainer 
                                src='/images/icons/leaderboard.png' 
                                alt='leaderboards icon' 
                                className='label-image' 
                            />
                            Leaderboards (requires XP and/or Currency)
                        </label>
                        <ToggleInput 
                            contentKey='leaderboards' 
                            checked={
                                formData.access ? 
                                formData.access.includes('leaderboards') : 
                                false
                            } 
                            saveData={saveToggle} 
                        />
                    </div>
                    <h3>Marketplace Features</h3>
                    <div className={
                        `field with-toggle ${
                            membership.membership < 1 &&
                            gameData.type === 'points' ? 
                            'disabled' : 
                            ''
                        }`
                    }>
                        <label className='with-image'>
                            <ImageContainer 
                                src='/images/icons/coins.png' 
                                alt='currency icon' 
                                className='label-image' 
                            />
                            Currency
                        </label>
                        <ToggleInput 
                            contentKey='currency' 
                            checked={
                                formData.access ? 
                                formData.access.includes('currency') : 
                                false
                            } 
                            saveData={saveToggle} 
                        />
                    </div>
                    <div className={
                        `field with-toggle ${
                            (membership.membership < 1 &&
                            gameData.type === 'points') ? 
                            'disabled' : 
                            ''
                        }`
                    }>
                        <label className='with-image'>
                            <ImageContainer 
                                src='/images/icons/items.png' 
                                alt='items icon' 
                                className='label-image' 
                            />
                            Items
                        </label>
                        <ToggleInput 
                            contentKey='items' 
                            checked={
                                formData.access ? 
                                formData.access.includes('items') : 
                                false
                            } 
                            saveData={saveToggle} 
                        />
                    </div>
                    <div className={
                        `field with-toggle ${
                            (
                                membership.membership < 1 &&
                                gameData.type === 'points'
                            ) || (
                                !formData.access ||
                                (
                                    !formData.access.includes('currency') ||
                                    !formData.access.includes('items')
                                )
                            ) ? 
                            'disabled' : 
                            ''
                        }`
                    }>
                        <label className='with-image'>
                            <ImageContainer 
                                src='/images/icons/shopping-cart.png' 
                                alt='store icon' 
                                className='label-image' 
                            />
                            Store (requires Currency and Items)
                        </label>
                        <ToggleInput 
                            contentKey='store' 
                            checked={
                                formData.access ? 
                                formData.access.includes('store') : 
                                false
                            } 
                            saveData={saveToggle} 
                        />
                    </div>
                    <div className={
                        `field with-toggle ${
                            (
                                membership.membership < 1 && 
                                gameData.type === 'points'
                            ) ||
                            membershipRestrictions[membership.membership]
                            .blocked.includes('prizePacks') ||
                            (
                                !formData.access ||
                                !formData.access.includes('items')
                            ) ? 
                            'disabled' : 
                            ''
                        }`
                    }>
                        <label className='with-image'>
                            <ImageContainer 
                                src='/images/icons/prizepacks.png' 
                                alt='prize packs icon' 
                                className='label-image' 
                            />
                            Prize Packs (requires Items)
                        </label>
                        <ToggleInput 
                            contentKey='prizePacks' 
                            checked={
                                formData.access ? 
                                formData.access.includes('prizePacks') : 
                                false
                            } 
                            saveData={saveToggle} 
                        />
                    </div>
                    <div className={
                        `field with-toggle ${
                            (membership.membership < 1 &&
                            gameData.type === 'points') ||
                            membershipRestrictions[membership.membership]
                            .blocked.includes('collections') ||  
                            (
                                !formData.access ||
                                !formData.access.includes('items')
                            ) ?
                            'disabled' : 
                            ''
                        }`
                    }>
                        <label className='with-image'>
                            <ImageContainer 
                                src='/images/icons/collections.png' 
                                alt='collections icon' 
                                className='label-image' 
                            />
                            Collections (requires Items)
                        </label>
                        <ToggleInput 
                            contentKey='collections' 
                            checked={
                                formData.access ? 
                                formData.access.includes('collections') : 
                                false
                            } 
                            saveData={saveToggle} 
                        />
                    </div>
                    <div className={
                        `field with-toggle ${
                            (membership.membership < 1 &&
                            gameData.type === 'points') ||
                            membershipRestrictions[membership.membership]
                            .blocked.includes('tradePortal') ||
                            (
                                !formData.access ||
                                (
                                    !formData.access.includes('items') ||
                                    !formData.access.includes('store')
                                )
                            ) ? 
                            'disabled' : 
                            ''
                        }`
                    }>
                        <label className='with-image'>
                            <ImageContainer 
                                src='/images/icons/trade.png' 
                                alt='tradePortal icon' 
                                className='label-image' 
                            />
                            Trade Portal (requires Items and Store)
                        </label>
                        <ToggleInput 
                            contentKey='tradePortal' 
                            checked={
                                formData.access ? 
                                formData.access.includes('tradePortal') : 
                                false
                            } 
                            saveData={saveToggle} 
                        />
                    </div>
                </div>
            }
            <div className="g-space-1"></div>
        </div>
    )
}

export default GameContentSettingsForm