import React, { useEffect, useState } from 'react'
import './my-xp-earnings-list.styles.scss';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { format } from 'date-fns';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { useNavigate } from 'react-router-dom';
import PlayerNameIcon from '../player-name-icon.component';

const MyXpEarningsList = ({ playerData, gameElements, playerActivity }) => {
    
    const [ loading, setLoading ] = useState(true);
    const [ sortedList, setSortedList ] = useState([]);
    const navigate = useNavigate();
    
    useEffect(() => {
        const timer = setTimeout(() => {
            if (!playerData || !playerActivity) {
                setLoading(false);
            }
        }, 3000)

        return () => {
            if (timer) clearTimeout(timer);
        }
    }, [])

    useEffect(() => {
        console.log(playerActivity);
        // console.log(playerData);
        // console.log(gameElements);
        if (!playerData || !gameElements || !playerActivity) return;
        let activityArr = [];
        for (let activity of Object.values(playerActivity)) {
            if (!activity.data) continue;
            for (let data of Object.values(activity.data)) {
                if (data.type === 'xp') {
                    activityArr.push(activity);
                    continue;
                }
            }
        }
        activityArr.sort((a,b) => a.ts < b.ts ? 1 : -1)
        setSortedList(activityArr);
        setLoading(false);
    }, [playerData, gameElements, playerActivity])

    return (
        <div className='xp-earnings-list'>
            <div className='g-card list-card'>
                <BackButton cancel={() => navigate(-1)} />
                <div>
                    <div className='card-title split'>
                        My XP Earnings
                        {
                            (location.pathname.includes('/manage/')) &&
                            <PlayerNameIcon player={playerData} />
                        }
                    </div>
                    <div className='g-space-1'></div>
                    <hr className='no-margin'/>
                    <div className='g-space-0-5'></div>
                    {
                        (playerData) &&
                        <div className='summary meta'>
                            <div className='info'>
                                Total XP Earned: 
                                <ImageContainer src='/images/xp.png' className='summary-icon' />
                                <b>{playerData.points.toLocaleString()}</b>
                            </div>
                        </div>
                    }
                </div>
                {
                    (loading)
                    ?
                    <div className='center-col'><ThreeDotsLoader /></div>
                    :
                    (sortedList)
                    ?
                    sortedList.map(a => {
                        const data = Object.values(a.data).filter(d => d.type === 'xp')[0];
                        return (
                            <div key={a.ts} className='g-list-item xp-item'>
                                <ImageContainer src={a.icon} className='icon' />
                                <div className='info'>
                                    <div className='title'>
                                        <ImageContainer src='/images/xp.png' />
                                        {data.title}
                                    </div>
                                    <div className='meta'>
                                        {a.event}<br />
                                        {format(new Date(a.ts), "Pp")}
                                    </div>
                                </div>
                                {
                                    (a.type === 'secretCode')
                                    ?
                                    <div className='comment'>
                                        Info:<br /><b>{a.info}</b>
                                    </div>
                                    :
                                    (a.comments)
                                    ?
                                    <div className='comment'>
                                        Comments:<br /><b>"{a.comments}"</b>
                                    </div>
                                    :
                                    (a.info)
                                    ?
                                    <div className='comment'>
                                        Info:<br /><b>{a.info}</b>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        )
                    })
                    : 
                    <h4 className='center-col'>
                        No earnings yet...
                    </h4>
                }
            </div>
            <div className="g-space-2"></div>
        </div>
    )
}

export default MyXpEarningsList