import React from 'react'
import './upgrade-required-modal.styles.scss';
import { useNavigate } from 'react-router-dom'
import ImageContainer from '../image-container/image-container.component';
import Modal from '@/components/modal/modal.component'

const UpgradeRequiredModal = ({ show, cancel, message, closeButton, newTab }) => {
    
    const navigate = useNavigate();

    const openNewTab = () => {
        const newTabUrl = '/pricing'; // Replace with your desired URL
        window.open(newTabUrl, '_blank', 'noopener,noreferrer');
      };

    return (
        <div className='upgrade-required-modal'>
            <Modal show={show} cancel={cancel} text='Upgrade Required!' closeButton={closeButton} >
                <div className='info-box'>
                    <div className='icon'>
                        <ImageContainer src='/images/icons/premium.png' />
                    </div>
                    <div className='text'>
                        <div dangerouslySetInnerHTML={{__html: message}}></div>
                        <p>
                            To explore pricing and options for upgrading, click the button below!
                        </p>
                    </div>
                </div>
                <hr />
                <div className='center-col'>
                    <button className='g-button' onClick={() => newTab ? openNewTab() : navigate('/pricing')}>View Upgrade Options</button>
                </div>
            </Modal>
        </div>

    )
}

export default UpgradeRequiredModal