import React from 'react'
import './image-container.styles.scss';

const ImageContainer = ({ src, alt, className, onClick, keyId, id, title, grayscale, circle }) => {
    return (
        <div className='image-container-div'>
            {
            (src) &&
            <img 
                id={id ?? null}
                key={keyId}
                src={(src.includes('https://') || src.includes('blob:')) ? src : import.meta.env.VITE_ROOT + src} 
                className={`image-container ${className ?? ''}${grayscale ? ' grayscale' : ''}${circle ? ' circle' : ''}`} 
                alt={alt ?? 'decorative image'} 
                referrerPolicy='no-referrer'
                loading='lazy'
                onClick={onClick} 
                title={title ?? null}
            />
            }
        </div>
    )
}

export default ImageContainer